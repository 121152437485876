import { add } from './service';

const Model = {
  namespace: 'innerOrderModel',
  state: {
    text: '',
  },
  effects: {
    *add({ payload }, { call, put }) {
      const res = yield call(add, payload);
      yield put({
        type: 'global/handlerResponse',
        payload: {
          ...res,
        },
      });
    },
  },
  reducers: {
  },
};
export default Model;
