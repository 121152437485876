import request from '@/utils/request';
import { openLink } from '@/utils/help';


export async function query(params) {
  return request('/api/jdorder', {
    params,
  });
}

export async function exportExcel(params) {
  return openLink('/order/jd/export', params);
}
