import request from '@/utils/request';

export async function query(params) {
  return request('/api/tconfig', {
    params,
  });
}
export async function add(params) {
  return request('/api/tconfig', {
    method: 'POST',
    data: { ...params },
  });
}
export async function update({ id, ...params }) {
  return request(`/api/tconfig/${id}`, {
    method: 'PUT',
    data: { ...params },
  });
}

export async function getPwd() {
  return request('/sd/getPwd');
}

export async function savePwd(params) {
  return request('/sd/savePwd', {
    method: 'POST',
    data: { ...params },
  });
}
