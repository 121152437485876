import request from '@/utils/request';
import { openLink } from '@/utils/help';

export async function query(params) {
  return request('/order/aliPunishOrder', {
    params,
  });
}

export async function stat(params) {
  return request('/order/aliPunishOrder/stat', {
    params,
  });
}

export async function exportExcel(params) {
  return openLink('/order/aliPunishOrder/export', params);
}