import { Form, Modal } from 'antd';
import React from 'react';
import FormCreator from '@/components/FormCreator';

const CreateForm = props => {
  const { modalVisible, handleAdd, closeModal, form, handleUpdate, modfiyForm, } = props;
  const isModify = Object.keys(modfiyForm).length > 0;

  const okHandle = () => {
    form.validateFields((err, fieldsValue) => {
      if (err) return;
      form.resetFields();
      // 判断是更新还是新增
      const fn = isModify ? handleUpdate : handleAdd;
      fn(fieldsValue);
    });
  };

  const layout = {
    labelCol: {
      span: 5,
    },
    wrapperCol: {
      span: 15,
    },
  }

  const configs = [
    {
      key: 'material_id',
      name: '活动id',
      config: {
        rules: [
          {
            required: true,
            message: '请输入活动id，从官方获取',
          },
        ],
      },
      props: {
        placeholder: '请输入活动id，从官方获取'
      }
    },
    {
      key: 'title',
      name: '活动名称',
      config: {
        rules: [
          {
            required: true,
            message: '请输入活动名称',
          },
        ],
      },
    },
    {
      key: 'start_time',
      name: '活动开始时间',
      type: 'datePicker',
      config: {
        rules: [
          {
            required: true,
            message: '请选择活动开始时间'
          }
        ]
      }
    },
    {
      key: 'end_time',
      name: '活动结束时间',
      type: 'datePicker',
      config: {
        rules: [
          {
            required: true,
            message: '请选择活动结束时间'
          }
        ]
      }
    },
  ];

  return (
    <Modal
      destroyOnClose
      title={isModify ? '更新活动爬取配置' : '增加活动爬取配置'}
      visible={modalVisible}
      onOk={okHandle}
      onCancel={() => closeModal()}
    >
      <FormCreator form={ form } configs={configs} params={modfiyForm} layout={layout} />
    </Modal>
  );
};

export default Form.create()(CreateForm);
