import {
  Button,
  Card,
  Form,
  message,
  Divider,
} from 'antd'
import React, { Component, Fragment } from 'react';
import { PageHeaderWrapper } from '@ant-design/pro-layout';
import { connect } from 'dva';
import moment from 'moment';
import StandardTable from '@/components/StandardTable';
import PowerAssert from '@/components/PowerAssert';
import SearchForm from '@/components/SearchForm';
import copy from 'copy-to-clipboard';


import styles from './style.less';

const getStatus = ['失效', '已用', '可用'];

const getValue = obj =>
  Object.keys(obj)
    .map(key => obj[key])
    .join(',');

/* eslint react/no-multi-comp:0 */
@connect(({ wechatLjModel: { data }, loading }) => ({
    data,
    page: data.page,
    size: data.size,
    count: data.count,
    loading: loading.models.wechatLjModel,
  }))
class TableList extends Component {
  state = {
    modalVisible: false,
    selectedRows: [],
    formValues: {},
    modfiyForm: {},
    exportModalVisible: false,
  };

  columns = [
    {
      title: 'id',
      dataIndex: 'id',
    },
    {
      title: '活动id',
      dataIndex: 'wechat_act_id',
    },
    {
      title: '口令',
      dataIndex: 'pwd',
    },
    {
      title: '商品',
      dataIndex: 'wechatAct',
      width: 70,
      render: (val, record) => <a href={`http://detail.tmall.com/item.htm?id=${val.item_id}`} target="_blank" rel="noopener noreferrer"><img alt="商品图片" style={{ width: 60, height: 60 }} src={val.img} /></a>,
    },
    {
      title: '状态',
      dataIndex: 'status',
      render: val => <span>{getStatus[val + 1]}</span>,
    },
    {
      title: '领取时间',
      dataIndex: 'received_at',
      render: val => <span>{moment(val).format('YYYY-MM-DD HH:mm:ss')}</span>,
    },
    {
      title: '更新时间',
      dataIndex: 'updatedAt',
      render: val => <span>{moment(val).format('YYYY-MM-DD HH:mm:ss')}</span>,
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      render: val => <span>{moment(val).format('YYYY-MM-DD HH:mm:ss')}</span>,
    },
    {
      title: '操作',
      render: (text, record) => (
          <Fragment>
            <PowerAssert code="wechatLj_update">
              {record.status === 1 ? <a onClick={() => this.handleUpdate(record, -1)}>失效</a> : <a onClick={() => this.handleUpdate(record, 1)}>恢复</a>}
            </PowerAssert>
          </Fragment>
      ),
    },
  ];

  handleCopy = record => {
    const url = `https://sxy.aa7w.cn/lj?k=${record.code}`;
    if (copy(url)) {
      message.success('复制成功！')
    } else {
      message.error('复制失败!')
    }
  }

  componentDidMount() {
    const { page, size, location: { query: { id } } } = this.props;
    let defaultWhere = {};
    if (id) {
      defaultWhere = {
        wechat_act_id: id,
      };
    }
    this.fetchData({ page, size }, defaultWhere);
  }

  handleStandardTableChange = (pagination, filtersArg, sorter) => {
    const { formValues } = this.state;
    const filters = Object.keys(filtersArg).reduce((obj, key) => {
      const newObj = { ...obj };
      newObj[key] = getValue(filtersArg[key]);
      return newObj;
    }, {});
    const params = {
      ...formValues,
      ...filters,
    };

    if (sorter.field) {
      params.sorter = `${sorter.field}_${sorter.order}`;
    }
    this.handleSelectRows([]);

    this.fetchData({
      page: pagination.current,
      size: pagination.pageSize,
    }, params);
  };

  handleFormReset = () => {
    const { form, size } = this.props;
    form.resetFields();
    this.handleSelectRows([]);
    this.setState({
      formValues: {},
    });
    this.fetchData({ page: 0, size });
  };

  handleSelectRows = rows => {
    this.setState({
      selectedRows: rows,
    }, () => {
    });
  };

  handleSearch = values => {
    const { size } = this.props;
    this.handleSelectRows([]);
    this.setState({
      formValues: values,
    });
    this.fetchData({ page: 0, size }, values);
  };

  handleModalVisible = flag => {
    this.setState({
      modalVisible: !!flag,
    });
  };

  closeModal = () => {
    this.setState({
      modalVisible: false,
      exportModalVisible: false,
      modfiyForm: {},
    });
  }

  handleUpdateModal = record => {
    this.setState({
      modfiyForm: record || {},
      modalVisible: true,
    });
  };

  handleUpdate = (fields, flag) => {
    const params = {
      ...fields,
    };
    if (flag !== undefined) {
      params.status = flag;
    }
    const { dispatch, size } = this.props;
    dispatch({
      type: 'wechatLjModel/update',
      payload: params,
      callback: () => {
        this.closeModal();
        this.fetchData({ page: 0, size });
      },
    });
  }

  fetchData(pager = null, fieldValue = {}, saveParams) {
    const { page, size, dispatch } = this.props;
    let tempPager = {
      page,
      size,
    }
    tempPager = {
      ...tempPager,
      ...pager,
    }
    let params = {
      ...fieldValue,
      ...tempPager,
    }
    if (saveParams) {
      const { formValues } = this.state;
      params = {
        ...params,
        ...formValues,
      }
    }
    dispatch({
      type: 'wechatLjModel/fetch',
      payload: params,
    });
  }

  render() {
    const {
      data,
      page,
      count,
      size,
      loading,
      location: {
        query: {
          id
        }
      }
    } = this.props;
    const initV = id ? {
      initialValue: id,
    } : {};

    const searchKeys = [{
      label: '活动id',
      type: 'input',
      key: 'wechat_act_id',
      init: initV
    },
    {
      label: '口令',
      type: 'input',
      key: 'pwd',
    }, {
      label: '状态',
      key: 'status',
      type: 'select',
      options: [
        {
          key: -1,
          val: '失效',
        },
        {
          key: 0,
          val: '已用',
        },
        {
          key: 1,
          val: '可用',
        },
      ],
    }, ];

    const { selectedRows } = this.state;
    const pager = {
      page,
      size,
      count,
    }
    return (
      <PageHeaderWrapper>
        <Card bordered={false}>
          <div className={styles.tableList}>
            <SearchForm search={this.handleSearch} reset={this.handleFormReset} searchConfigs={searchKeys} />
            <div className={styles.tableListOperator}>
            </div>
            <StandardTable
              selectedRows={selectedRows}
              loading={loading}
              data={data}
              columns={this.columns}
              onSelectRow={this.handleSelectRows}
              onChange={this.handleStandardTableChange}
              pager={pager}
            />
          </div>
        </Card>
      </PageHeaderWrapper>
    );
  }
}

export default Form.create()(TableList);
