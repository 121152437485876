import { query } from './service';

const Model = {
  namespace: 'pwdReport',
  state: {
    data: {
      pv: 0,
      uv: 0,
      hour_uv: 0,
      hour_pv: 0,
    }
  },
  effects: {
    *query({ payload }, { call, put }) {
      yield put({
        type: 'save',
        payload: {
          data: {
            pv: 0,
            uv: 0,
            hour_uv: 0,
            hour_pv: 0,
          },
        },
      });
      const { forms } = payload;
      const res = yield call(query, forms);
      yield put({
        type: 'global/handlerResponse',
        payload: {
          ...res,
        },
      });
      if (res.code === 1) {
        yield put({
          type: 'save',
          payload: {
            data: res.data,
          },
        });
      }
    },
  },
  reducers: {
    save(state, action) {
      return { ...state, ...action.payload };
    },
  },
};
export default Model;
