import { add } from './service';

const Model = {
  namespace: 'shortModel',
  state: {
    shortUrl: '',
  },
  effects: {
    *add({ payload, callback }, { call, put }) {
      const { forms } = payload;
      yield put({
        type: 'save',
        payload: {
          shortUrl: '',
        },
      });
      const res = yield call(add, forms);
      yield put({
        type: 'global/handlerResponse',
        payload: {
          ...res,
        },
      });
      if (res.code === 1) {
        yield put({
          type: 'save',
          payload: {
            shortUrl: res.data,
          },
        });
      }
      if (callback) callback();
    },
  },
  reducers: {
    save(state, action) {
      return { ...state, ...action.payload };
    },
  },
};
export default Model;
