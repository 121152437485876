import { query, exportExcel, exportExcelByDay } from './service';

const Model = {
  namespace: 'aliOrder',
  state: {
    data: {
      list: [],
      page: 0,
      size: 20,
      count: 0,
      pay: 0,
      effect: 0,
      own: 0,
      sum_effect_get: 0,
      data: [],
    },
    isShowChart: false,
    chartOption: {}
  },
  effects: {
    *exportExcel({ payload, callback }, { call, put }) {
      const res = yield call(exportExcel, payload);
      yield put({
        type: 'global/handlerResponse',
        payload: {
          ...res,
        },
      });
      if (callback) callback();
    },
    *exportExcelByDay({ payload, callback }, { call, put }) {
      const res = yield call(exportExcelByDay, payload);
      yield put({
        type: 'global/handlerResponse',
        payload: {
          ...res,
        },
      });
      if (callback) callback();
    },
    *fetch({ payload }, { call, put }) {
      let tempPage = payload.page;
      if (tempPage > 0) {
        tempPage -= 1;
      }

      const res = yield call(query, {
        ...payload,
        page: tempPage,
      });
      const {
        code, data: { rows, page, size, count, pay, effect, own, sum_effect_get },
      } = res;
      if (code === 1) {
        yield put({
          type: 'save',
          payload: {
            data: res.data,
            list: rows,
            page,
            size,
            count,
            pay,
            effect,
            own,
            sum_effect_get,
          },
        });
      }
      if (payload.groupByDay) {
        yield put({
          type: 'setChartOptions',
          payload: rows
        })
      }
      yield put({
        type: 'global/handlerResponse',
        payload: {
          ...res,
          successNotice: false,
        },
      });
    },
  },
  reducers: {
    save(state, action) {
      return { ...state, data: action.payload };
    },
    toggleChart(state, action) {
      return {...state, isShowChart: !state.isShowChart}
    },
    setChartOptions(state, action) {
      const { payload } = action
      const enumLegend = [
        {value: 'total', name: '出单'},
        {value: 'pay', name: 'GMV'},
        {value: 'effect', name: '佣金'},
        {value: 'sum_effect_get', name: '到手'},
      ];
      const chartOption = {
        title: {
          show: false
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: enumLegend.map(i => i.name),
          selectedMode: 'multiple',
          selected: {
            '出单': true,
            'GMV': false,
            '佣金': false,
            '到手': false,
          }
        },
        toolbox: {
          feature: {
            // saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: []
        },
        yAxis: {
          type: 'value'
        },
        series: []
      };
      chartOption.xAxis.data = payload.map(i => i.day)
      chartOption.xAxis.data.reverse();
      chartOption.series = enumLegend.map(i => {
        const item = {};
        item.name = i.name;
        item.type = 'line';
        item.data = payload.map(j => {
          if (i.value === 'pay' || i.value === 'effect' || i.value === 'sum_effect_get') {
            return (j[i.value] / 100).toFixed(2);
          } else {
            return j[i.value];
          }
        })
        item.data.reverse();
        return item;
      })
      return {...state, chartOption}
    }
  },
};
export default Model;
