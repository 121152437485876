import request from '@/utils/request';
import { openLink } from '@/utils/help';

export async function query(params) {
  return request('/api/ukey', {
    params,
  });
}

export async function exportExcel(params) {
  return openLink('/api/ukey', params);
}

export async function add(data) {
  return request('/api/ukey', {
    method: 'POST',
    data,
  });
}

export async function del(id) {
  return request('/api/ukeyDel/' + id);
}

export async function update ({ id, ...data }) {
  return request(`/api/ukey/${id}`, {
    method: 'PUT',
    data,
  });
}
