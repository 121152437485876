import {
  Card,
  Form,
  Button,
  Icon,
} from 'antd';
import React, { Component } from 'react';
import { PageHeaderWrapper } from '@ant-design/pro-layout';
import { connect } from 'dva';
import FormCreator from '@/components/FormCreator';
import './style.less';

const FormItem = Form.Item;

class Manage extends Component {
  state = {
  };

  componentDidMount () {
    const { location: { query: { id } } } = this.props;
    this.fetchAllCode();
    if (id) {
      this.fetchDetail(id);
    }
  }

  handleSubmit = e => {
    const { dispatch, form, location: { query: { id } } } = this.props;
    e.preventDefault();
    form.validateFieldsAndScroll((err, values) => {
      if (id) {
        values.id = id;
      }
      console.log(values);
      if (!err) {
        dispatch({
          type: 'roleModel/submit',
          payload: values,
        });
      }
    });
  };

  fetchAllCode() {
    const { dispatch } = this.props;
    const pager = {
      page: 0,
      size: 50,
    };
    dispatch({
      type: 'codeAndList/fetch',
      payload: pager,
    });
  }

  fetchDetail(id) {
    const { dispatch } = this.props;
    dispatch({
      type: 'roleModel/detail',
      payload: {
        id,
      },
    });
  }

  render() {
    const { fetching, submitting, form, codes, currentRole, location: { query: { id } } } = this.props;
    const modifyForm = id ? currentRole : {};
    const submitFormLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 10,
          offset: 7,
        },
      },
    };
    const configs = [
      {
        key: 'title',
        name: '名称',
        config: {
          rules: [
            {
              required: true,
              message: '请输入名称',
              min: 1,
            },
          ],
        },
      },
      {
        key: 'codes',
        name: '权限',
        type: 'tree',
        config: {
        },
        options: codes,
      },
    ];

    return (
      <PageHeaderWrapper>
        <Card bordered={false} loading={fetching}>
        <Form
          onSubmit={this.handleSubmit}
          style={{
            marginTop: 8,
          }}
        >
          <FormCreator form={ form } configs={configs} params={modifyForm}/>
            <FormItem
              {...submitFormLayout}
              style={{
                marginTop: 32,
              }}
            >
              <Button type="primary" htmlType="submit" loading={submitting}>
                提交
              </Button>
            </FormItem>
          </Form>
        </Card>
      </PageHeaderWrapper>
    );
  }
}

export default Form.create()(
  connect(({ loading, codeAndList: { data }, roleModel: { currentRole } }) => ({
    submitting: loading.effects['roleModel/submit'],
    fetching: loading.effects['roleModel/detail'],
    codes: data.list,
    currentRole,
  }))(Manage),
);
