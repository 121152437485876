import { modify } from './service';

const Model = {
  namespace: 'userModify',
  state: {
  },
  effects: {
    *submit({ payload }, { call, put }) {
      const res = yield call(modify, payload);
      yield put({
        type: 'global/handlerResponse',
        payload: {
          ...res,
        },
      });
    },
  },
  reducers: {
  },
};
export default Model;
