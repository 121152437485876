import {
  Card,
  Form,
  Tag,
  Button,
  Divider,
  message,
} from 'antd';
import React, { Component, Fragment } from 'react';
import { PageHeaderWrapper } from '@ant-design/pro-layout';
import { connect } from 'dva';
import moment from 'moment';
import StandardTable from '@/components/StandardTable';
import SearchForm from '@/components/SearchForm';
import CreateForm from './components/CreateForm';

import copy from 'copy-to-clipboard';


import styles from './style.less';

const getValue = obj =>
  Object.keys(obj)
    .map(key => obj[key])
    .join(',');

/* eslint react/no-multi-comp:0 */
@connect(({ tbAuthModel: { data }, loading }) => ({
  data,
  page: data.page,
  size: data.size,
  count: data.count,
  loading: loading.models.tbAuthModel,
}))
class TableList extends Component {
  state = {
    modalVisible: false,
    selectedRows: [],
    formValues: {},
    modfiyForm: {},
  };

  columns = [
    {
      title: 'id',
      dataIndex: 'id',
    },
    {
      title: '联盟账号',
      dataIndex: 'login_name',
    },
    {
      title: '阿里妈妈账户ID',
      dataIndex: 'alimama_account_id',
    },
    {
      title: '备注',
      dataIndex: 'remarks',
    },
    {
      title: '过期时间',
      dataIndex: 'access_token_expire_at',
      render: val => <span>{moment(val).format('yyyy-MM-DD HH:mm:ss')}</span>,
    },
    {
      title: '添加时间',
      dataIndex: 'create_date',
      render: val => <span>{moment(val).format('yyyy-MM-DD HH:mm:ss')}</span>,
    },
    {
      title: '操作',
      render: (text, record) => (
        <Fragment>
            <a onClick={() => this.handleUpdateModal(record)}>编辑</a>
          <Divider type="vertical" />
            <a href={`http://open.xuanwonainiu.com/auth/acc/token/${record.id}`} target="_blank">授权</a>
        </Fragment>
      ),
    },
  ];

  componentDidMount() {
    const { size } = this.props;
    this.fetchData({ page: 0, size });
  }

  handleStandardTableChange = (pagination, filtersArg, sorter) => {
    const { formValues } = this.state;
    const filters = Object.keys(filtersArg).reduce((obj, key) => {
      const newObj = { ...obj };
      newObj[key] = getValue(filtersArg[key]);
      return newObj;
    }, {});
    const params = {
      ...formValues,
      ...filters,
    };

    if (sorter.field && sorter.order) {
      params.sorter = `${sorter.field}_${sorter.order}`;
    }
    this.handleSelectRows([]);

    this.fetchData({
      page: pagination.current,
      size: pagination.pageSize,
    }, params);
  };

  handleFormReset = () => {
    const { form, size } = this.props;
    form.resetFields();
    this.handleSelectRows([]);
    this.setState({
      formValues: {},
    });
    this.fetchData({ page: 0, size });
  };

  handleSelectRows = rows => {
    this.setState({
      selectedRows: rows,
    }, () => {
    });
  };

  handleSearch = values => {
    const { size } = this.props;
    // 更新state，这样子翻页什么的时候也能跟进
    this.handleSelectRows([]);
    this.setState({
      formValues: values,
    });
    this.fetchData({ page: 0, size }, values);
  };

  handleModalVisible = flag => {
    this.setState({
      modalVisible: !!flag,
    });
  };

  handleUpdateModal = record => {
    this.setState({
      modfiyForm: record || {},
      modalVisible: true,
    });
  };

  handleCopyLink = () => {
    if (copy('http://open.xuanwonainiu.com/auth/acc/token/-1')) {
      message.success('复制成功！')
    } else {
      message.error('复制失败!')
    }
  }

  fetchData(pager = null, fieldValue = {
  }) {
    const { page, size, dispatch } = this.props;
    let tempPager = {
      page,
      size,
    }
    tempPager = {
      ...tempPager,
      ...pager,
    }
    dispatch({
      type: 'tbAuthModel/fetch',
      payload: {
        ...fieldValue,
        ...tempPager,
      },
    });
  }

  closeModal = () => {
    this.setState({
      modfiyForm: {},
      modalVisible: false,
    })
  }

  handleUpdate = fields => {
    const { dispatch, size, page } = this.props;
    const { modfiyForm: { id } } = this.state;
    dispatch({
      type: 'tbAuthModel/update',
      payload: {
        id,
        ...fields,
      },
      callback: () => {
        this.fetchData({ page: page + 1, size });
      },
    });
    this.closeModal();
  };

  render() {
    const {
      data,
      page,
      count,
      size,
      loading,
    } = this.props;

    const searchKeys = [{
      label: '联盟账号',
      key: 'title',
    }];

    const { selectedRows, modalVisible, modfiyForm } = this.state;
    const pager = {
      page,
      size,
      count,
    }

    const parentMethods = {
      handleUpdate: this.handleUpdate,
      handleModalVisible: this.handleModalVisible,
      closeModal: this.closeModal,
    };
    return (
      <PageHeaderWrapper>
        <Card bordered={false}>
          <div className={styles.tableList}>
            <SearchForm expandForm search={this.handleSearch} reset={this.handleFormReset} searchConfigs={searchKeys} />
            <div className={styles.tableListOperator}>
                <Button icon="plus" type="primary" onClick={() => this.handleCopyLink()}>
                  复制授权链接
                </Button>
            </div>
            <Divider />
            <StandardTable
              selectedRows={selectedRows}
              loading={loading}
              data={data}
              columns={this.columns}
              onSelectRow={this.handleSelectRows}
              onChange={this.handleStandardTableChange}
              pager={pager}
            />
          </div>
          <CreateForm {...parentMethods} modfiyForm={modfiyForm} modalVisible={modalVisible} />
        </Card>
      </PageHeaderWrapper>
    );
  }
}

export default Form.create()(TableList);
