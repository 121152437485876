import request from '@/utils/request';
import { openLink } from '@/utils/help';

export async function query(params) {
  return request('/api/tlj', {
    params,
  });
}

export async function add(data) {
  return request('/api/tlj', {
    method: 'POST',
    data,
  });
}

export async function stop(data) {
  return request('/api/tljStop', {
    method: 'POST',
    data,
  });
}

export async function update(data) {
  return request('/api/tlj', {
    method: 'PUT',
    data,
  });
}

export async function updateOrder(data) {
  return request('/api/tljOrder', {
    method: 'PUT',
    data,
  });
}

export async function del(data) {
  return request('/api/tljDel', {
    method: 'PUT',
    data,
  });
}

export async function exportExcel(params) {
  return openLink('/api/tljExport', params);
}

export async function getTljStat() {
  return request('/api/tlj/today');
}

export async function createSdPage(data) {
  return request('/tool/yzkClone', {
    method: 'POST',
    data,
  });
}
export async function createSdNewPage(data) {
  return request('/tool/yzkNewClone', {
    method: 'POST',
    data,
  });
}

export async function checkBlack(data) {
  return request('/tool/checkBlackProduct', {
    method: 'POST',
    data,
  });
}

export async function createSignPage(data) {
  return request('/tool/yzkCloneSign', {
    method: 'POST',
    data,
  });
}

export async function createTaote(data) {
  return request('/api/createTaote', {
    method: 'POST',
    data,
  });
}
