import { Form, Modal } from 'antd';
import React from 'react';
import FormCreator from '@/components/FormCreator';

const CreateForm = props => {
  const { modalVisible, closeModal, form, handleUpdate, modfiyForm } = props;

  const okHandle = () => {
    form.validateFields((err, fieldsValue) => {
      if (err) return;
      form.resetFields();
      handleUpdate(fieldsValue);
    });
  };

  const layout = {
    labelCol: {
      span: 5,
    },
    wrapperCol: {
      span: 15,
    },
  }

  const configs = [
    {
      key: 'remark',
      name: '备注',
    },
    {
      key: 'nickname',
      name: '昵称',
    },
    {
      key: 'score_user_id',
      name: '上级ID',
    },
  ];

  return (
    <Modal
      title="用户管理"
      visible={modalVisible}
      onOk={okHandle}
      onCancel={() => closeModal()}
    >
      <FormCreator form={ form } configs={configs} params={modfiyForm} layout={layout} />
    </Modal>
  );
};

export default Form.create()(CreateForm);
