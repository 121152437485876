import { Form, Button } from 'antd';
import React from 'react';
import FormCreator from '@/components/FormCreator';

const CreateForm = props => {
  const { handleSet, form, coupon, imgCheck, title } = props;

  const okHandle = () => {
    form.validateFields((err, fieldsValue) => {
      if (err) return;
      handleSet(fieldsValue);
    });
  };

  const layout = {
    labelCol: {
      span: 5,
    },
    wrapperCol: {
      span: 15,
    },
  }

  const configs = [
    {
      key: 'coupon',
      name: '优惠券面额',
      type: 'number',
    },
    {
      key: 'title',
      name: '标题',
    },
    {
      key: 'imgCheck',
      name: '图片选择',
      type: 'select',
      props: {
        style: { width: '100%' },
      },
      options: [
        {
          value: 0,
          name: '主图',
        },
        {
          value: 1,
          name: '白底图',
        }
      ],
    },
  ];

  const modfiyForm = {
    coupon,
    imgCheck,
    title,
  };

  return (
    <div>
      <FormCreator form={ form } configs={configs} params={modfiyForm} layout={layout} />
      <Button icon="plus" type="primary" onClick={okHandle}>
        生成
      </Button>
    </div>
  );
};

export default Form.create()(CreateForm);
