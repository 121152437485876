import {
  Button,
  Card,
  Dropdown,
  Form,
  Icon,
  Menu,
  Tag,
  Popconfirm,
  message,
  Divider, Tooltip,
} from 'antd'
import React, { Component, Fragment } from 'react';
import { PageHeaderWrapper } from '@ant-design/pro-layout';
import { connect } from 'dva';
import moment from 'moment';
import CreateForm from './components/CreateForm';
import StandardTable from '@/components/StandardTable';
import PowerAssert from '@/components/PowerAssert';
import SearchForm from '@/components/SearchForm';
import copy from 'copy-to-clipboard';

import styles from './style.less';

const getValue = obj =>
  Object.keys(obj)
    .map(key => obj[key])
    .join(',');

/* eslint react/no-multi-comp:0 */
@connect(({ userPid: { pidList: data, accountList }, loading, user: { currentUser } }) => ({
  data,
  currentUser,
  page: data.page,
  size: data.size,
  count: data.count,
  loading: loading.models.userPid,
  accountList,
}))
class TableList extends Component {
  state = {
    modalVisible: false,
    selectedRows: [],
    formValues: {},
    modfiyForm: {},
  };

  columns = [
    {
      title: 'pid',
      dataIndex: 'pid',
    },
    {
      title: '联盟',
      dataIndex: 'account_no',
    },
    {
      title: '备注',
      dataIndex: 'remark',
    },
    {
      title: '操作',
      render: (text, record) => (
        <Fragment>
            <PowerAssert code={['taoteMs', 'taoteMs_update']}>
              <a onClick={() => this.handleUpdateModal(record)}>编辑</a>
            </PowerAssert>
        </Fragment>
      ),
    },
  ];

  componentDidMount() {
    const { page, size } = this.props;
    this.fetchData({ page, size });
    this.fetchAsset()
  }


  handleStandardTableChange = (pagination, filtersArg, sorter) => {
    const { formValues } = this.state;
    const filters = Object.keys(filtersArg).reduce((obj, key) => {
      const newObj = { ...obj };
      newObj[key] = getValue(filtersArg[key]);
      return newObj;
    }, {});
    const params = {
      ...formValues,
      ...filters,
    };

    if (sorter.field) {
      params.sorter = `${sorter.field}_${sorter.order}`;
    }
    this.handleSelectRows([]);

    this.fetchData({
      page: pagination.current,
      size: pagination.pageSize,
    }, params);
  };

  handleFormReset = () => {
    const { form, size } = this.props;
    form.resetFields();
    this.handleSelectRows([]);
    this.setState({
      formValues: {},
    });
    this.fetchData({ page: 0, size });
  };

  handleSelectRows = rows => {
    this.setState({
      selectedRows: rows,
    }, () => {
    });
  };

  handleSearch = values => {
    const { size } = this.props;
    // 更新state，这样子翻页什么的时候也能跟进
    this.handleSelectRows([]);
    this.setState({
      formValues: values,
    });
    this.fetchData({ page: 0, size }, values);
  };

  handleModalVisible = flag => {
    this.setState({
      modalVisible: !!flag,
    });
    if (!flag) {
      this.setState({
        modfiyForm: {},
      });
    }
  };

  closeModal = () => {
    this.setState({
      modalVisible: false,
      modfiyForm: {},
    });
  }

  handleUpdateModal = record => {
    this.setState({
      modfiyForm: record || {},
      modalVisible: true,
    });
  };

  handleCopyLink = () => {
    if (copy(`http://open.xuanwonainiu.com/auth/acc/token/u${this.props.currentUser.id}`)) {
      message.success('复制成功！')
    } else {
      message.error('复制失败!')
    }
  }

  handleAdd = fields => {
    const { dispatch, size, accountList } = this.props;
    const account = accountList.find(ac => ac.id === fields.user_alimama_id);
    dispatch({
      type: 'userPid/add',
      payload: {
        forms: {
          ...fields,
          account_no: account.account_no,
        },
      },
      callback: () => {
        this.fetchData({ page: 0, size });
      },
    });
    this.handleModalVisible();
  };

  handleUpdate = fields => {
    const { dispatch, size } = this.props;
    const { modfiyForm: { id } } = this.state;
    dispatch({
      type: 'userPid/update',
      payload: {
        id,
        ...fields,
      },
      callback: () => {
        this.fetchData({ page: 0, size });
      },
    });
    this.closeModal();
  };


  fetchData(pager = null, fieldValue = {}, saveParams) {
    const { page, size, dispatch } = this.props;
    let tempPager = {
      page,
      size,
    }
    tempPager = {
      ...tempPager,
      ...pager,
    }
    let params = {
      ...fieldValue,
      ...tempPager,
    }
    if (saveParams) {
      const { formValues } = this.state;
      params = {
        ...params,
        ...formValues,
      }
    }
    dispatch({
      type: 'userPid/fetch',
      payload: params,
    });
  }

  fetchAsset () {
    this.props.dispatch({
      type: 'userPid/fetchAccount',
    });
  }

  render() {
    const {
      data,
      page,
      count,
      size,
      loading,
      accountList = [],
    } = this.props;

    const searchKeys = [{
      label: 'pid',
      type: 'input',
      key: 'pid',
    }, {
      label: '备注',
      type: 'input',
      key: 'remark',
    }];

    const { selectedRows, modalVisible, modfiyForm } = this.state;
    const parentMethods = {
      handleAdd: this.handleAdd,
      handleUpdate: this.handleUpdate,
      handleModalVisible: this.handleModalVisible,
      closeModal: this.closeModal,
    };

    const pager = {
      page,
      size,
      count,
    }
    return (
      <PageHeaderWrapper>
        <Card bordered={false}>
          <div className={styles.tableList}>
             <SearchForm search={this.handleSearch} reset={this.handleFormReset} searchConfigs={searchKeys} />
            <div className={styles.tableListOperator}>
              <PowerAssert code={['taoteMs', 'taoteMs_create']}>
                <Button icon="plus" type="primary" onClick={() => this.handleModalVisible(true)}>
                  新增Pid
                </Button>
                <Divider />
                <Button icon="plus" type="primary" onClick={() => this.handleCopyLink()}>
                  复制专属授权链接
                </Button>
              </PowerAssert>
            </div>
            <StandardTable
              selectedRows={selectedRows}
              loading={loading}
              data={data}
              columns={this.columns}
              onSelectRow={this.handleSelectRows}
              onChange={this.handleStandardTableChange}
              pager={pager}
            />
          </div>
        </Card>
        <CreateForm {...parentMethods} modfiyForm={modfiyForm} accountList={accountList} modalVisible={modalVisible} />
      </PageHeaderWrapper>
    );
  }
}

export default Form.create()(TableList);
