import request from '@/utils/request';

export async function query(params) {
  return request('/auth/list', {
    params,
  });
}
export async function update({ id, ...params }) {
  return request(`/auth/${id}`, {
    method: 'PUT',
    data: { ...params },
  });
}
