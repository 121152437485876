import {
  Badge,
  Button,
  Card,
  Divider,
  Dropdown,
  Form,
  Icon,
  Menu,
} from 'antd';
import React, { Component, Fragment } from 'react';
import { PageHeaderWrapper } from '@ant-design/pro-layout';
import { connect } from 'dva';
import QiniuItem from './components/QiniuItem';
import PowerAssert from '@/components/PowerAssert';

import styles from './style.less';


const getValue = obj =>
  Object.keys(obj)
    .map(key => obj[key])
    .join(',');

/* eslint react/no-multi-comp:0 */
@connect(({ qiniuModel: { data }, loading }) => ({
  items: data.items,
  loading: loading.models.qiniuModel,
}))
class Pager extends Component {
  state = {
    keys: [],
    currentKey: 'xwdefault',
  };

  componentDidMount() {
    this.fetchData();
  }
  updateKeys = keys => {
    this.setState({
      keys,
    });
  }

  handleDel = () => {
    this.props.dispatch({
      type: 'qiniuModel/del',
      payload: {
        forms: {
          bucket: this.state.currentKey,
          keys: this.state.keys,
        }
      }
    });
  }

  fetchData = () => {
    const { dispatch } = this.props;
    dispatch({
      type: 'qiniuModel/fetch',
    });
  }

  modifyBucket = (key) => {
    const { dispatch } = this.props;
    this.setState({
      currentKey: key,
      keys: [],
    }, () => {
      dispatch({
        type: 'qiniuModel/modifyBucket',
        payload: key,
        callback: () => {
          this.fetchData();
        }
      });
    })
  }

  render() {
    const {
      items,
      loading,
    } = this.props;
    const {
      updateKeys
    } = this;
   
    return (
      <PageHeaderWrapper>
        <Card bordered={false}>
          <QiniuItem list={items} update={updateKeys} keys={this.state.keys} 
            del={this.handleDel}
            load={this.fetchData}
            currentKey={this.state.currentKey}
            modifyBucket={this.modifyBucket}
          />
        </Card>
      </PageHeaderWrapper>
    );
  }
}

export default Form.create()(Pager);
