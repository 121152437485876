import {
  Button,
  Col,
  DatePicker,
  Form,
  Icon,
  Input,
  InputNumber,
  Row,
  Select,
} from 'antd';
import React, { Component } from 'react';
import styles from './index.less';

const FormItem = Form.Item;
const { Option } = Select;

class SearchForm extends Component {
  state = {
    expandForm: false,
  };

  componentDidMount() {
    const { expandForm } = this.props;
    if (expandForm !== undefined) {
      this.setState({
        expandForm,
      })
    }
  }

  getFormType(conf) {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const { label, type, key, placeholder, options, init = {} } = conf;
    let optionItems = '';
    if (options) {
      optionItems = options.map(op => <Option value={op.key}>{op.val}</Option>);
    }
    let renderItem = '';
    switch (type) {
      case 'select':
        renderItem = <FormItem label={label}>
        {getFieldDecorator(key, init)(
          <Select
            placeholder={placeholder || '请输入'}
            style={{
              width: '100%',
            }}
            allowClear
            {...conf.props}
          >
          {optionItems}
          </Select>,
        )}
      </FormItem>
        break;
      case 'number':
        renderItem = <FormItem label={label}>
        {getFieldDecorator(key, init)(
                <InputNumber
                  style={{
                    width: '100%',
                  }}
                  {...conf.props}
                />,
              )}
      </FormItem>
        break;
      case 'dateRanger':
          renderItem = <FormItem label={label}>
          {getFieldDecorator(key, init)(
                  <DatePicker.RangePicker
                    style={{
                      width: '100%',
                    }}
                    {...conf.props}
                  />,
                )}
        </FormItem>
          break;
      case 'monthPicker':
          renderItem = <FormItem label={label}>
          {getFieldDecorator(key, init)(
                  <DatePicker.MonthPicker
                    style={{
                      width: '100%',
                    }}
                    placeholder={placeholder || '请选择月份'}
                    {...conf.props}
                  />,
                )}
        </FormItem>
          break;
      default:
      renderItem = <FormItem label={label}>
              {getFieldDecorator(key, init)(<Input allowClear placeholder={placeholder || '请输入'} {...conf.props} />)}
          </FormItem>
    }
    return renderItem;
  }

  getKeys () {
    return (this.props.searchConfigs || []).map(prop => prop.key);
  }

  toggleForm = () => {
    const { expandForm } = this.state;
    this.setState({
      expandForm: !expandForm,
    });
  };

  handleSearch = e => {
    e.preventDefault();
    const { form, search } = this.props;
    form.validateFields((err, values) => {
      if (err) return;
      const results = {};
      this.getKeys().forEach(key => {
        switch (this.findProp(key).type) {
          case 'dateTime': {
            results[key] = values[key] ? values[key].format() : null;
            break;
          }
          case 'monthPicker': {
            results[key] = values[key] ? values[key].format('YYYYMM') : void 0;
            break;
          }
          case 'dateRanger': {
            const { startKey, endKey, startOf, endOf } = this.findProp(key);
            let start = null;
            let end = null;
            if (values[key] && values[key][0]) {
              const valEnd = values[key][0];
              if (startOf) {
                start = valEnd.startOf(startOf).format();
              } else {
                start = valEnd.format();
              }
            }
            if (values[key] && values[key][1]) {
              const valEnd = values[key][1];
              if (endOf) {
                end = valEnd.endOf(endOf).format();
              } else {
                end = valEnd.format();
              }
            }
            results[startKey] = start;
            results[endKey] = end;
            break;
          }
          case 'number': {
            results[key] = values[key] === undefined ? null : values[key] * 1;
            break;
          }
          case 'inputGroup': {
            results[`${key}Min`] = values[`${key}Min`] ? values[`${key}Min`] : null;
            results[`${key}Max`] = values[`${key}Max`] ? values[`${key}Max`] : null;
            break;
          }
          default: {
            results[key] = values[key];
          }
        }
      });
      delete results.undefined;
      Object.keys(results).forEach(key => {
        if (results[key] === undefined || results[key] === '') {
          delete results[key];
        }
      });
      search && search(results);
    });
  };

  handleFormReset = () => {
    const { form, reset } = this.props;
    form.resetFields();
    // 更新上层props
    reset && reset();
  };


  findProp (key) {
    return (this.props.searchConfigs || []).find(data => key === data.key);
  }

  renderSimpleForm() {
    const { searchConfigs } = this.props;
    const baseConfig = searchConfigs.slice(0, 2);
    const BaseFormItem = baseConfig.map((conf, index) => <Col key={index} md={8} sm={24}>{this.getFormType(conf)}</Col>);
    return (
      <Form onSubmit={this.handleSearch} layout="inline">
        <Row
          gutter={{
            md: 8,
            lg: 24,
            xl: 48,
          }}
        >
         {BaseFormItem}
          <Col md={8} sm={24}>
            <span className={styles.submitButtons}>
              <Button type="primary" htmlType="submit">
                查询
              </Button>
              <Button
                style={{
                  marginLeft: 8,
                }}
                onClick={this.handleFormReset}
              >
                重置
              </Button>
              {searchConfigs.length > 2 && (
                <a
                  style={{
                    marginLeft: 8,
                  }}
                  onClick={this.toggleForm}
                >
                  展开 <Icon type="down" />
                </a>
              )}
            </span>
          </Col>
        </Row>
      </Form>
    );
  }

  renderAdvancedForm() {
    const { searchConfigs } = this.props;
    const threeConf = [];
    searchConfigs.forEach((conf, index) => {
      if (index % 3 === 0) {
        const temp = [conf];
        threeConf.push(temp);
      } else {
        threeConf[threeConf.length - 1].push(conf);
      }
    });

    const advFormItem = threeConf.map(conf => {
      const res = <Row gutter={{
                md: 8,
                lg: 24,
                xl: 48,
              }}
            >
              {conf.map((c, i) => <Col key={i} md={8} sm={24}>{this.getFormType(c)}</Col>)}
          </Row>
      return res;
    });


    return (
      <Form onSubmit={this.handleSearch} layout="inline">
        {advFormItem}
        <div
          style={{
            overflow: 'hidden',
          }}
        >
          <div
            style={{
              float: 'right',
              marginBottom: 24,
            }}
          >
            <Button type="primary" htmlType="submit">
              查询
            </Button>
            <Button
              style={{
                marginLeft: 8,
              }}
              onClick={this.handleFormReset}
            >
              重置
            </Button>
            <a
              style={{
                marginLeft: 8,
              }}
              onClick={this.toggleForm}
            >
              收起 <Icon type="up" />
            </a>
          </div>
        </div>
      </Form>
    );
  }

  renderForm() {
    const { expandForm } = this.state;
    return expandForm ? this.renderAdvancedForm() : this.renderSimpleForm();
  }

  render() {
    return (
      <div className={styles.tableListForm}>{this.renderForm()}</div>
    );
  }
}

export default Form.create()(SearchForm);
