import { routerRedux } from 'dva/router';
import { add, modify, detail } from './service';

const Model = {
  namespace: 'roleModel',
  state: {
    submiting: false,
    currentRole: {},
  },
  effects: {
    *submit({ payload }, { call, put }) {
      const method = payload.id ? modify : add;
      const res = yield call(method, payload);
      yield put({
        type: 'global/handlerResponse',
        payload: {
          ...res,
        },
      });
      yield put(routerRedux.push('/power/role'));
    },
    *detail({ payload: { id } }, { call, put }) {
      const { code, data } = yield call(detail, id);
      if (code === 1) {
        yield put({
          type: 'save',
          payload: data,
        });
      }
    },
  },
  reducers: {
    save(state, action) {
      return { ...state, currentRole: action.payload };
    },
  },
};
export default Model;
