import { TreeSelect } from 'antd';
import React from 'react';

const { TreeNode } = TreeSelect;

const renderTree = configs => {
  const items = configs.map(config => (
    <TreeNode value={config.id} title={config.title} key={config.id}>
      {renderTree(config.children || [])}
    </TreeNode>
  ));
  return items;
}

class TreeSelector extends React.Component {
  state = {
    value: undefined,
  };

  onChange = value => {
    const { onChange } = this.props;
    const nowValue = this.state.value;
    if (value !== nowValue) {
      this.setState({ value }, () => {
        onChange(value);
      })
    }
  };

  render() {
    const { options, ...otherProps } = this.props;
    return (
      <TreeSelect
        value={this.state.value}
        style={{ width: '100%' }}
        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
        allowClear
        treeDefaultExpandAll
        onChange={this.onChange}
        {...otherProps}
      >
        {renderTree(options)}
      </TreeSelect>
    );
  }
}

export default TreeSelector;
