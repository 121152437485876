import {
  Card,
  Form,
  Button,
  Icon,
  Descriptions,
  message,
  Divider,
  Row,
  Col
} from 'antd';
import React, { Component } from 'react';
import { PageHeaderWrapper } from '@ant-design/pro-layout';
import { connect } from 'dva';
import FormCreator from '@/components/FormCreator';
const moment = require('moment');

import style from './style.less';

const FormItem = Form.Item;

class BizStatPager extends Component {
  componentDidMount() {
    this.props.dispatch({
      type: 'tConfListModel/fetch',
      payload: {
        type: 8,
      },
    });
  }
  handleSubmit = e => {
    const { dispatch, form } = this.props;
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        dispatch({
          type: 'bizStatModel/submit',
          payload: values,
        });
      } else {
        console.log(err);
      }
    });
  };

  render() {
    const { loading, statCode, form } = this.props;
    const submitFormLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 10,
          offset: 7,
        },
      },
    };
    const configs = [
      {
        key: 'code',
        name: '统计代号',
        type: 'select',
        placeholder: '请选择统计代号',
        config: {
          rules: [
            {
              required: true,
              message: '请选择统计代号',
            },
          ],
        },
        props: {
          style: { width: '100%' },
          mode: 'multiple',
        },
        options: statCode,
      },
      {
        name: '时间',
        type: 'dateRanger',
        key: 'searchTime',
        startKey: 'start',
        endKey: 'end',
        startOf: 'day',
        endOf: 'day',
        config: {
          rules: [
            {
              required: true,
              message: '请选择时间',
            },
          ],
        },
        init: {
          initialValue: [ moment().startOf('day'), moment().endOf('day') ],
        },
      }, 
    ];

    return (
      <PageHeaderWrapper>
        <Card bordered={false}>
          <Form
                  onSubmit={this.handleSubmit}
                  style={{
                    marginTop: 8,
                  }}
                >
              <FormCreator form={ form } configs={configs} />
                <FormItem
                  {...submitFormLayout}
                  style={{
                    marginTop: 32,
                  }}
                >
                  <Button type="primary" htmlType="submit" loading={loading}>
                    提交
                  </Button>
                </FormItem>
              </Form>
        </Card>
      </PageHeaderWrapper>
    );
  }
}

export default Form.create()(
  connect(({ bizStatModel: {  }, tConfListModel: { configs }, loading }) => ({
    loading: loading.effects['bizStatModel/submit'],
    statCode: configs.type8 || [],
  }))(BizStatPager),
);
