import {
  Button,
  Card,
  Dropdown,
  Form,
  Icon,
  Menu,
  Tag,
  Popconfirm,
  message,
  Divider, Tooltip,
} from 'antd'
import React, { Component, Fragment } from 'react';
import { PageHeaderWrapper } from '@ant-design/pro-layout';
import { connect } from 'dva';
import moment from 'moment';
import CreateForm from './components/CreateForm';
import StandardTable from '@/components/StandardTable';
import PowerAssert from '@/components/PowerAssert';
import SearchForm from '@/components/SearchForm';
import copy from 'copy-to-clipboard';


import styles from './style.less';

const getStatus = ['下线', '在线'];

const getValue = obj =>
  Object.keys(obj)
    .map(key => obj[key])
    .join(',');

/* eslint react/no-multi-comp:0 */
@connect(({ ljModel: { data }, loading }) => ({
    data,
    page: data.page,
    size: data.size,
    count: data.count,
    loading: loading.models.ljModel,
  }))
class TableList extends Component {
  state = {
    modalVisible: false,
    selectedRows: [],
    formValues: {},
    modfiyForm: {},
    exportModalVisible: false,
  };

  columns = [
    {
      title: 'id',
      dataIndex: 'id',
    },
    {
      title: 'code',
      dataIndex: 'code',
    },
    {
      title: '备注',
      dataIndex: 'title',
    },
    {
      title: '类型',
      dataIndex: 'type',
      render: val => <span>
        { val === 1 ? '普通' : '专属' }
      </span>
    },
    {
      title: '商品ID',
      dataIndex: 'item_id',
    },
    {
      title: '非vip延迟时间',
      dataIndex: 'ot',
    },
    {
      title: '口令个数',
      dataIndex: 'content',
      render: val => <span>{val.split(',').length}</span>
    },
    {
      title: '状态',
      dataIndex: 'status',
      render: val => <span>{getStatus[val]}</span>,
    },
    {
      title: '更新时间',
      dataIndex: 'updatedAt',
      render: val => <span>{moment(val).format('YYYY-MM-DD HH:mm:ss')}</span>,
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      render: val => <span>{moment(val).format('YYYY-MM-DD HH:mm:ss')}</span>,
    },
    {
      title: '操作',
      render: (text, record) => (
          <Fragment>
            <PowerAssert code="lj_update">
              {record.status ? <a onClick={() => this.handleUpdate(record, 0)}>下架</a> : <a onClick={() => this.handleUpdate(record, 1)}>上架</a>}
            </PowerAssert>
            <Divider />
            <PowerAssert code="lj_update">
              <a onClick={() => this.handleUpdateModal(record)}>编辑</a>
            </PowerAssert>
            <Divider />
            <a onClick={() => this.handleCopy(record)}>复制一级链接</a>
            <Divider />
            <a onClick={() => this.handleCopy2(record)}>复制二级链接</a>
          </Fragment>
      ),
    },
  ];

  handleCopy2 = record => {
    const url = `https://sxy.aa7w.cn/lj?k=${record.code}&n=b1NranRzZ2tYM2p1OFhuanNtbXRkU25pWnQyVQ==`;
    if (copy(url)) {
      message.success('复制成功！')
    } else {
      message.error('复制失败!')
    }
  }

  handleCopy = record => {
    const url = `https://sxy.aa7w.cn/lj?k=${record.code}`;
    if (copy(url)) {
      message.success('复制成功！')
    } else {
      message.error('复制失败!')
    }
  }

  componentDidMount() {
    const { page, size } = this.props;
    this.fetchData({ page, size });
  }

  handleStandardTableChange = (pagination, filtersArg, sorter) => {
    const { formValues } = this.state;
    const filters = Object.keys(filtersArg).reduce((obj, key) => {
      const newObj = { ...obj };
      newObj[key] = getValue(filtersArg[key]);
      return newObj;
    }, {});
    const params = {
      ...formValues,
      ...filters,
    };

    if (sorter.field) {
      params.sorter = `${sorter.field}_${sorter.order}`;
    }
    this.handleSelectRows([]);

    this.fetchData({
      page: pagination.current,
      size: pagination.pageSize,
    }, params);
  };

  handleFormReset = () => {
    const { form, size } = this.props;
    form.resetFields();
    this.handleSelectRows([]);
    this.setState({
      formValues: {},
    });
    this.fetchData({ page: 0, size });
  };

  handleSelectRows = rows => {
    this.setState({
      selectedRows: rows,
    }, () => {
    });
  };

  handleSearch = values => {
    const { size } = this.props;
    // 更新state，这样子翻页什么的时候也能跟进
    this.handleSelectRows([]);
    this.setState({
      formValues: values,
    });
    this.fetchData({ page: 0, size }, values);
  };

  handleModalVisible = flag => {
    this.setState({
      modalVisible: !!flag,
    });
  };

  closeModal = () => {
    console.log(1);
    this.setState({
      modalVisible: false,
      exportModalVisible: false,
      modfiyForm: {},
    });
  }

  handleUpdateModal = record => {
    this.setState({
      modfiyForm: record || {},
      modalVisible: true,
    });
  };

  handleAdd = fields => {
    const { dispatch, size } = this.props;
    dispatch({
      type: 'ljModel/add',
      payload: {
        forms: fields,
      },
      callback: () => {
        this.fetchData({ page: 0, size });
      },
    });
    this.closeModal();
  };

  handleUpdate = (fields, flag) => {
    const params = {
      ...fields,
    };
    if (flag !== undefined) {
      params.status = flag;
    }
    const { dispatch, size } = this.props;
    dispatch({
      type: 'ljModel/update',
      payload: params,
      callback: () => {
        this.closeModal();
        this.fetchData({ page: 0, size });
      },
    });
  }

  fetchData(pager = null, fieldValue = {}, saveParams) {
    const { page, size, dispatch } = this.props;
    let tempPager = {
      page,
      size,
    }
    tempPager = {
      ...tempPager,
      ...pager,
    }
    let params = {
      ...fieldValue,
      ...tempPager,
    }
    if (saveParams) {
      const { formValues } = this.state;
      params = {
        ...params,
        ...formValues,
      }
    }
    dispatch({
      type: 'ljModel/fetch',
      payload: params,
    });
  }

  render() {
    const {
      data,
      page,
      count,
      size,
      loading,
    } = this.props;

    const searchKeys = [{
      label: '备注',
      type: 'input',
      key: 'title',
    },
    {
      label: '口令',
      type: 'input',
      key: 'content',
    },{
      label: 'code',
      type: 'input',
      key: 'code',
    },];

    const { selectedRows, modalVisible, modfiyForm, exportModalVisible } = this.state;
    const parentMethods = {
      handleAdd: this.handleAdd,
      handleUpdate: this.handleUpdate,
      closeModal: this.closeModal,
    };
    const pager = {
      page,
      size,
      count,
    }
    return (
      <PageHeaderWrapper>
        <Card bordered={false}>
          <div className={styles.tableList}>
            <SearchForm search={this.handleSearch} reset={this.handleFormReset} searchConfigs={searchKeys} />
            <div className={styles.tableListOperator}>
              <PowerAssert code={['lj_add', 'lj_all']}>
                <Button icon="plus" type="primary" onClick={() => this.handleModalVisible(true)}>
                  新增口令
                </Button>
              </PowerAssert>
            </div>
            <StandardTable
              selectedRows={selectedRows}
              loading={loading}
              data={data}
              columns={this.columns}
              onSelectRow={this.handleSelectRows}
              onChange={this.handleStandardTableChange}
              pager={pager}
            />
          </div>
        </Card>
        <CreateForm {...parentMethods} modfiyForm={modfiyForm} modalVisible={modalVisible} />
      </PageHeaderWrapper>
    );
  }
}

export default Form.create()(TableList);
