import React, { Component, Fragment } from 'react';
import style from './index.less';


class BreakLine extends Component {
  render() {
    return (
      <div className={style.breakLine}>
      </div>
    );
  }
}

export default BreakLine;
