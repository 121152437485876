import { Form, Modal } from 'antd';
import React from 'react';
import FormCreator from '@/components/FormCreator';

const CreateForm = props => {
  const { modalVisible, handleAdd, handleModalVisible, form, handleUpdate, modfiyForm, accountList = [] } = props;
  const isModify = Object.keys(modfiyForm).length > 0;

  const okHandle = () => {
    form.validateFields((err, fieldsValue) => {
      if (err) return;
      form.resetFields();
      // 判断是更新还是新增
      const fn = isModify ? handleUpdate : handleAdd;
      fn(fieldsValue);
    });
  };

  const layout = {
    labelCol: {
      span: 5,
    },
    wrapperCol: {
      span: 15,
    },
  }

  const configs = [
    {
      key: 'user_alimama_id',
      name: '阿里妈妈',
      type: 'select',
      config: {
        rules: [
          {
            required: true,
            message: '请选择联盟',
          },
        ],
      },
      props: {
        style: { width: '100%' },
      },
      options: accountList.map(item => ({
        value: item.id,
        name: item.account_no,
      })),
    },
    {
      key: 'pid',
      name: 'pid',
      config: {
        rules: [
          {
            required: true,
            message: '请输入pid',
          },
        ],
      },
    },
    {
      key: 'remark',
      name: '备注',
      config: {
        rules: [
          {
            required: true,
            message: '请输入备注',
          },
        ],
      },
    },
    
  ];
  if (isModify) {
    configs.splice(2, 1);
  }

  return (
    <Modal
      destroyOnClose
      title={isModify ? '更新Pid' : '新增Pid'}
      visible={modalVisible}
      onOk={okHandle}
      onCancel={() => handleModalVisible()}
    >
      <FormCreator form={ form } configs={configs} params={modfiyForm} layout={layout} />
    </Modal>
  );
};

export default Form.create()(CreateForm);
