import { Form, Modal } from 'antd';
import React from 'react';
import FormCreator from '@/components/FormCreator';

const CreateForm = props => {
  const { modalVisible, handleAdd, closeModal, form, events } = props;

  const okHandle = () => {
    form.validateFields((err, fieldsValue) => {
      if (err) return;
      form.resetFields();
      handleAdd(fieldsValue);
    });
  };

  const layout = {
    labelCol: {
      span: 5,
    },
    wrapperCol: {
      span: 15,
    },
  }
  const configs = [
    {
      key: 'special',
      name: '是否特殊积分',
      type: 'select',
      config: {
        rules: [
          {
            required: true,
            message: '请选择是否特殊积分',
          },
        ],
      },
      props: {
        style: { width: '100%' },
      },
      options: [
        {
          value: 0,
          name: '否'
        },
        {
          value: 1,
          name: '是'
        },
      ],
    },
    {
      key: 'score_event_id',
      name: '事件',
      type: 'select',
      props: {
        style: { width: '100%' },
        placeholder: '可以不选',
      },
      options: events.map(event => ({
        value: event.id,
        name: `${event.event_name}:${event.score}分`,
      })),
    },
    {
      key: 'remark',
      name: '自己看的备注',
    },
    {
      key: 'user_remark',
      name: '用户端备注',
    },
    {
      key: 'score',
      name: '自定义分数',
      type: 'number',
      placeholder: '可以不填，填了就不按规定的来',
      props: {
        style: { width: '100%' },
      },
    },
    {
      key: 'active_date',
      type: 'datePicker',
      name: '生效时间',
      props: {
        style: { width: '100%' },
        placeholder: '可以不填',
      },
    },
  ];

  const params = {
    special: 0,
  }

  return (
    <Modal
      destroyOnClose
      title="新增"
      visible={modalVisible}
      onOk={okHandle}
      onCancel={() => closeModal()}
    >
      <FormCreator form={ form } configs={configs} params={params}  layout={layout} />
    </Modal>
  );
};

export default Form.create()(CreateForm);
