import {
  Button,
  Card,
  Dropdown,
  Form,
  Icon,
  Menu,
  Tag,
  Popconfirm,
  message,
  Divider, Tooltip,
} from 'antd'
import React, { Component, Fragment } from 'react';
import { PageHeaderWrapper } from '@ant-design/pro-layout';
import { connect } from 'dva';
import moment from 'moment';
import copy from 'copy-to-clipboard';
import StandardTable from '@/components/StandardTable';
import PowerAssert from '@/components/PowerAssert';
import SearchForm from '@/components/SearchForm';
import { yhDomain } from '../../../config/custom';

import styles from './style.less';

const getStatus = ['下线', '在线'];
const getType = ['未知', '淘宝', '京东'];


const getValue = obj =>
  Object.keys(obj)
    .map(key => obj[key])
    .join(',');

/* eslint react/no-multi-comp:0 */
@connect(({ h5PageModel: { data }, loading }) => ({
    data,
    page: data.page,
    size: data.size,
    count: data.count,
    loading: loading.models.h5PageModel,
  }))
class TableList extends Component {
  state = {
    modalVisible: false,
    selectedRows: [],
    formValues: {},
    modfiyForm: {},
    exportModalVisible: false,
  };

  columns = [
    {
      title: 'id',
      dataIndex: 'id',
    },
    {
      title: '页面',
      dataIndex: 'title',
    },
    {
      title: '类型',
      dataIndex: 'type',
      render: val => <span>{getType[val]}</span>,
    },
    {
      title: '状态',
      dataIndex: 'status',
      render: val => <span>{getStatus[val]}</span>,
    },
    {
      title: '更新时间',
      dataIndex: 'updatedAt',
      render: (val, rec) => <span>{ moment(val).format('YYYY-MM-DD HH:mm:ss')}</span>,
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      render: val => <span>{moment(val).format('YYYY-MM-DD HH:mm:ss')}</span>,
    },
    {
      title: '操作',
      render: (text, record) => (
        <Fragment>
          <PowerAssert code="h5page_update">
            <Popconfirm title={`确定${record.status === 1 ? '下架' : '上架'}该活动`} onConfirm={() => this.toggleOnLine(record)}>
              <a>{ record.status ? '下架' : '上架'}</a>
            </Popconfirm>
            <Divider type="vertical" />
            <a onClick={() => this.handleCopy(record)}>复制h5链接</a>
            <Divider type="vertical" />
            { record.type === 1 ? <a target="_blank" href={`${yhDomain}/?key=${record.id}&pid=mm_125432133_42406427_76768350122&relationId=510119865`}>预览</a> : 
            <a target="_blank" href={`${yhDomain}/jdActivity?key=${record.id}`}>预览2</a>}
            <Divider type="vertical" />
            { record.type === 1 ? <a target="_blank" href={`${yhDomain}/bos?key=${record.id}`}>编辑</a> : 
            <a onClick={() => {this.goEdit(record.id)}}>编辑</a>} 
          </PowerAssert>
        </Fragment>
      ),
    },
  ];

  componentDidMount() {
    const { page, size } = this.props;
    this.fetchData({ page, size });
  }

  handleStandardTableChange = (pagination, filtersArg, sorter) => {
    const { formValues } = this.state;
    const filters = Object.keys(filtersArg).reduce((obj, key) => {
      const newObj = { ...obj };
      newObj[key] = getValue(filtersArg[key]);
      return newObj;
    }, {});
    const params = {
      ...formValues,
      ...filters,
    };

    if (sorter.field) {
      params.sorter = `${sorter.field}_${sorter.order}`;
    }
    this.handleSelectRows([]);

    this.fetchData({
      page: pagination.current,
      size: pagination.pageSize,
    }, params);
  };

  handleFormReset = () => {
    const { form, size } = this.props;
    form.resetFields();
    this.handleSelectRows([]);
    this.setState({
      formValues: {},
    });
    this.fetchData({ page: 0, size });
  };

  handleSelectRows = rows => {
    this.setState({
      selectedRows: rows,
    }, () => {
    });
  };

  handleSearch = values => {
    const { size } = this.props;
    // 更新state，这样子翻页什么的时候也能跟进
    this.handleSelectRows([]);
    this.setState({
      formValues: values,
    });
    this.fetchData({ page: 0, size }, values);
  };

  handleUpdate = fields => {
    const { dispatch, size } = this.props;
    const { modfiyForm: { id } } = this.state;
    dispatch({
      type: 'h5PageModel/update',
      payload: {
        id,
        ...fields,
      },
      callback: () => {
        this.fetchData({ page: 0, size });
      },
    });
    this.closeModal();
  };

  handleCopy = (record) => {
    const link = record.type === 1 ? `${yhDomain}/?key=${record.id}` : `${yhDomain}/jdActivity?key=${record.id}`;
    const res = copy(link);
    if (res) {
      message.success('链接复制成功！')
    } else {
      message.error('复制失败')
    }
  }

  handleAddActivity = () => {
    this.props.history.push('/yh/detail')
  }
  goEdit = id => {
    this.props.history.push(`/yh/detail?id=${id}`)
  }
  toggleOnLine = record => {
    const { dispatch, size } = this.props;
    dispatch({
      type: 'h5PageModel/update',
      payload: {
        id: record.id,
        status: record.status === 1 ? 0 : 1
      },
      callback: () => {
        this.fetchData({ page: 0, size });
      },
    })
  } 

  fetchData(pager = null, fieldValue = {}, saveParams) {
    const { page, size, dispatch } = this.props;
    let tempPager = {
      page,
      size,
    }
    tempPager = {
      ...tempPager,
      ...pager,
    }
    let params = {
      ...fieldValue,
      ...tempPager,
    }
    if (saveParams) {
      const { formValues } = this.state;
      params = {
        ...params,
        ...formValues,
      }
    }
    dispatch({
      type: 'h5PageModel/fetch',
      payload: params,
    });
  }

  render() {
    const {
      data,
      page,
      count,
      size,
      loading,
    } = this.props;

    const searchKeys = [{
      label: '标题',
      key: 'title'
    }];

    const { selectedRows } = this.state;
    const pager = {
      page,
      size,
      count,
    }
    return (
      <PageHeaderWrapper>
        <Card bordered={false}>
          <div className={styles.tableList}>
            <SearchForm search={this.handleSearch} reset={this.handleFormReset} searchConfigs={searchKeys} />
            <div className={styles.tableListOperator}>
                <a href={`${yhDomain}/bos`} target="_blank">
                  <Button icon="plus" type="primary">
                    新增淘宝页面
                  </Button>
                </a>
                <Divider/>
                <Button icon="plus" type="primary" onClick={() => this.handleAddActivity()}>
                  新增京东页
                </Button>
            </div>
            <StandardTable
              selectedRows={selectedRows}
              loading={loading}
              data={data}
              columns={this.columns}
              onSelectRow={this.handleSelectRows}
              onChange={this.handleStandardTableChange}
              pager={pager}
            />
          </div>
        </Card>
      </PageHeaderWrapper>
    );
  }
}

export default Form.create()(TableList);
