import {
  Button,
  Card,
  Dropdown,
  Form,
  Icon,
  Menu,
  Tag,
  Popconfirm,
  message,
  Divider,
  Tooltip,
} from 'antd';
import React, { Component, Fragment } from 'react';
import { PageHeaderWrapper } from '@ant-design/pro-layout';
import { connect } from 'dva';
import moment from 'moment';
import StandardTable from '@/components/StandardTable';
import SearchForm from '@/components/SearchForm';

import styles from './style.less';

const getValue = obj =>
  Object.keys(obj)
    .map(key => obj[key])
    .join(',');

const getPlatform = platform => {
  switch (platform) {
    case 0:
      return '老林';
    case 1:
      return '大牛';
    case 2:
      return '坚果';
    default:
      return '未知';
  }
};
// -2: 过程失败 -1: 未就绪 ,0, 待开始， 1 进行中 2 已完成 3失败
const getStatus = status => {
  switch (status) {
    case -2:
      return '失败';
    case -1:
      return '未就绪';
    case 0:
      return '待开始';
    case 1:
      return '进行中';
    case 2:
      return '完成';
    default:
      return '未知';
  }
};

const getLink = itemId =>
  itemId && itemId.toString().match(/^\d+$/)
    ? `http://detail.tmall.com/item.htm?id=${itemId}`
    : `https://uland.taobao.com/item/edetail?id=${itemId}`;

/* eslint react/no-multi-comp:0 */
@connect(({ timelinePlan: { data }, loading }) => ({
  data,
  page: data.page,
  size: data.size,
  count: data.count,
  loading: loading.models.timelinePlan,
}))
class TableList extends Component {
  state = {
    modalVisible: false,
    selectedRows: [],
    formValues: {},
    modfiyForm: {},
  };

  columns = [
    {
      title: '内容',
      dataIndex: 'content',
      width: 150,
    },
    {
      title: '图片',
      dataIndex: 'pic',
      render: val => (
        <div>
          {(JSON.parse(val) || []).map(c => (
            <img src={c} width="40" height="40" />
          ))}
        </div>
      ),
    },
    {
      title: '第三方时间',
      dataIndex: 'third_time',
      render: (val, rec) => <div>{moment(val).format('MM-DD HH:mm:ss')} </div>,
    },
    {
      title: '添加时间',
      dataIndex: 'createdAt',
      render: (val, rec) => <div>{moment(val).format('MM-DD HH:mm:ss')} </div>,
    },
    {
      title: '进度',
      dataIndex: 'process',
    },
    // {
    //   title: '平台',
    //   dataIndex: 'platform',
    //   render: (val, rec) => <div>{getPlatform(val)} </div>,
    // },
    {
      title: '状态',
      dataIndex: 'status',
      render: (val, rec) => <div>{getStatus(val)}</div>,
    },
    {
      title: '商品id',
      dataIndex: 'item_id',
      render: val => (
        <a href={getLink(val)} target="_blank" rel="noopener noreferrer">
          {val}
        </a>
      ),
    },
    {
      title: '商品标题',
      dataIndex: 'item_title',
      width: 150,
    },
    {
      title: '订单数',
      dataIndex: 'count',
    },
    {
      title: '总佣金',
      dataIndex: 'effect',
    },
  ];

  componentDidMount() {
    const { page, size } = this.props;
    this.fetchData({ page, size });
  }

  handleStandardTableChange = (pagination, filtersArg, sorter) => {
    const { formValues } = this.state;
    const filters = Object.keys(filtersArg).reduce((obj, key) => {
      const newObj = { ...obj };
      newObj[key] = getValue(filtersArg[key]);
      return newObj;
    }, {});
    const params = {
      ...formValues,
      ...filters,
    };

    if (sorter.field) {
      params.sorter = `${sorter.field}_${sorter.order}`;
    }
    this.handleSelectRows([]);

    this.fetchData(
      {
        page: pagination.current,
        size: pagination.pageSize,
      },
      params,
    );
  };

  handleFormReset = () => {
    const { form, size } = this.props;
    form.resetFields();
    this.handleSelectRows([]);
    this.setState({
      formValues: {},
    });
    this.fetchData({ page: 0, size });
  };

  handleSelectRows = rows => {
    this.setState(
      {
        selectedRows: rows,
      },
      () => {},
    );
  };

  handleSearch = values => {
    const { size } = this.props;
    // 更新state，这样子翻页什么的时候也能跟进
    this.handleSelectRows([]);
    this.setState({
      formValues: values,
    });
    this.fetchData({ page: 0, size }, values);
  };

  fetchData(pager = null, fieldValue = {}, saveParams) {
    const { page, size, dispatch } = this.props;
    let tempPager = {
      page,
      size,
    };
    tempPager = {
      ...tempPager,
      ...pager,
    };
    let params = {
      ...fieldValue,
      ...tempPager,
    };
    if (saveParams) {
      const { formValues } = this.state;
      params = {
        ...params,
        ...formValues,
      };
    }
    dispatch({
      type: 'timelinePlan/fetch',
      payload: params,
    });
  }
  render() {
    const { data, page, count, size, loading } = this.props;

    const searchKeys = [
      {
        label: '商品id',
        type: 'input',
        key: 'item_id',
      },
      {
        label: '商品标题',
        type: 'input',
        key: 'item_title',
      },
      {
        label: '内容',
        type: 'input',
        key: 'content',
      },
    ];

    const pager = {
      page,
      size,
      count,
    };
    return (
      <PageHeaderWrapper>
        <Card bordered={false}>
          <div className={styles.tableList}>
            <SearchForm
              search={this.handleSearch}
              reset={this.handleFormReset}
              searchConfigs={searchKeys}
            />
            <StandardTable
              selectedRows={[]}
              loading={loading}
              data={data}
              columns={this.columns}
              onSelectRow={this.handleSelectRows}
              onChange={this.handleStandardTableChange}
              pager={pager}
            />
          </div>
        </Card>
      </PageHeaderWrapper>
    );
  }
}

export default Form.create()(TableList);
