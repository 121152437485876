import { query, update } from './service';

const Model = {
  namespace: 'h5PageModel',
  state: {
    data: {
      list: [],
      page: 0,
      size: 50,
      count: 0,
    },
  },
  effects: {
    *fetch({ payload, callback }, { call, put }) {
      let tempPage = payload.page;
      if (tempPage > 0) {
        tempPage -= 1;
      }

      const res = yield call(query, {
        ...payload,
        page: tempPage,
      });
      const {
        code, data: { rows, page, size, count },
      } = res;
      if (code === 1) {
        yield put({
          type: 'save',
          payload: {
            list: rows,
            page,
            size,
            count,
          },
        });
        if (callback) callback();
      }
      yield put({
        type: 'global/handlerResponse',
        payload: {
          ...res,
          successNotice: false,
        },
      });
    },
    *update({ payload, callback }, { call, put }) {
      const res = yield call(update, payload);
      yield put({
        type: 'global/handlerResponse',
        payload: {
          ...res,
        },
      });
      if (callback) callback();
    },
  },
  reducers: {
    save(state, action) {
      return { ...state, data: action.payload };
    },
  },
};
export default Model;
