import request from '@/utils/request';

export async function getAccounts() {
  return request('/api/alimama', {
    params: {
      size: 100,
      page: 0,
    },
  });
}

export async function query() {
  return request('/api/pid', {
    params: {
      size: 100,
      page: 0,
    },
  });
}

export async function add(data) {
  return request('/api/pid', {
    method: 'POST',
    data
  });
}

export async function update ({id, ...data}) {
  return request(`/api/pid/${id}`, {
    method: 'PUT',
    data
  });
}


export async function del (id) {
  return request(`/api/pid/${id}`, {
    method: 'PUT',
  })
}
