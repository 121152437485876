import {
  Card,
  Form,
  Button,
  Icon,
  Divider,
  Row, Col,
} from 'antd';
import React, { Component } from 'react';
import { PageHeaderWrapper } from '@ant-design/pro-layout';
import { connect } from 'dva';
import FormCreator from '@/components/FormCreator';
import styles from './style.less';

const FormItem = Form.Item;


class H5PagesDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultForm: {},
    }
  }

  componentDidMount() {
    const { location: { query: { id } } } = this.props;
    if (id) {
      this.fetchDetail(id)
    }
  }

  componentWillUnmount() {
    this.props.dispatch({
      type: 'h5PagesDetail/reset'
    })
  }

  fetchDetail(id) {
    const { dispatch } = this.props;
    dispatch({
      type: 'h5PagesDetail/fetchDetail',
      payload: {
        id
      }
    })
  }

  handleSubmit = e => {
    const { dispatch, form, location: { query: { id } } } = this.props;
    e.preventDefault();
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const params = {
          content: JSON.stringify({
            h5Options: values
          }),
          type: 2,
        };
        if (id) {
          params.key = id;
        }
        dispatch({
          type: 'h5PagesDetail/add',
          payload: params
        })
      } else {
        console.log(err);
      }
    });
  };

  Preview = e => {
    this.setState({
      defaultForm: this.props.form.getFieldsValue(),
    })
  }

  render() {
    const { fetching, submitting, form, detailInfo } = this.props;
    const modifyForm = Object.keys(detailInfo).length ? detailInfo : {
      bgColor: '#f72f76',
      mainBtnColor: '#ce3462',
      mainTextColor: '#ffffff',
      mainBorderColor: '#ce3462',
      copyBtnColor: '#ff8a00',
      copyTextColor: '#ffffff',
      copyBorderColor: '#ff8a00',
      copyText: '复制链接赚钱',
      goBtnColor: '#f5222d',
      goTextColor: '#ffffff',
      goBorderColor: '#f5222d',
      goText: '去会场',
    };
    const submitFormLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 10,
          offset: 7,
        },
      },
    };
    const configs = [
      {
        key: 'banner',
        name: 'banner图',
        type: 'img',
        config: {
          rules: [
            {
              required: true,
              message: '请上传图片',
            },
          ],
        },
      },
      {
        key: 'pageTitle',
        name: 'h5显示标题',
        config: {
          rules: [
            {
              required: true,
              message: '请输入h5显示标题',
            },
          ],
        },
      },
      {
        key: 'summary',
        name: '副标题',
        config: {
          rules: [
            {
              required: true,
              message: '请输入副标题',
            },
          ],
        },
      },
      {
        key: 'desc',
        name: '分享文案',
        config: {
          rules: [
            {
              required: true,
              message: '请输入分享文案',
            },
          ],
        },
      },
      {
        key: 'link',
        name: '活动链接',
        config: {
          rules: [
            {
              required: true,
              message: '请输入活动链接',
            },
          ],
        },
      },
      {
        key: 'bgColor',
        name: '页面背景色',
        type: 'colorSelect',
      },
      {
        key: 'mainBtnColor',
        name: '主标题背景颜色',
        type: 'colorSelect',
      },
      {
        key: 'mainTextColor',
        name: '主标题文案颜色',
        type: 'colorSelect',
      },
      {
        key: 'mainBorderColor',
        name: '主标题边框颜色',
        type: 'colorSelect',
      },
      {
        key: 'copyBtnColor',
        name: '复制按钮背景颜色',
        type: 'colorSelect',
      },
      {
        key: 'copyTextColor',
        name: '复制按钮文案颜色',
        type: 'colorSelect',
      },
      {
        key: 'copyBorderColor',
        name: '复制按钮边框颜色',
        type: 'colorSelect',
      },
      {
        key: 'copyText',
        name: '复制按钮文案',
        config: {
          rules: [
            {
              required: true,
              message: '请输入复制按钮文案',
            },
          ],
        },
      },
      {
        key: 'goBtnColor',
        name: '跳转按钮背景颜色',
        type: 'colorSelect',
      },
      {
        key: 'goTextColor',
        name: '跳转按钮文案颜色',
        type: 'colorSelect',
      },
      {
        key: 'goBorderColor',
        name: '跳转按钮边框颜色',
        type: 'colorSelect',
      },
      {
        key: 'goText',
        name: '跳转按钮文案',
        config: {
          rules: [
            {
              required: true,
              message: '请输入跳转按钮文案',
            },
          ],
        },
      },
    ];
    
    return (
      <PageHeaderWrapper>
        <Card bordered={false} loading={fetching}>
        <Row>
        <Col span={12}>
          <Form
            onSubmit={this.handleSubmit}
            style={{
              marginTop: 8,
            }}
          >
            <FormCreator form={ form } configs={configs} params={modifyForm}/>
              <FormItem
                {...submitFormLayout}
                style={{
                  marginTop: 32,
                }}
              >
                <Button onClick={this.Preview}>
                  预览
                </Button>
                <Divider type="vertical" />
                <Button type="primary" htmlType="submit" loading={submitting}>
                  提交
                </Button>
              </FormItem>
            </Form>
        </Col>
        <Col span={12}>
                <Button onClick={this.Preview}>
                  预览
                </Button>
          <div className={styles.preview} style={{'background-color': this.state.defaultForm.bgColor}}>
                <img src={this.state.defaultForm.banner} className={styles.banner} />
                <p className={styles.title}
                  style={{'color': this.state.defaultForm.mainTextColor, 'border-color': this.state.defaultForm.mainBorderColor,
                  'backgroundColor': this.state.defaultForm.mainBtnColor
                }}
                >{this.state.defaultForm.pageTitle}</p>
              <div className={styles.actWrapper}>
                <p className={styles.summary}
                >
                  {this.state.defaultForm.summary}
                </p>
                <div className={styles.btnWrapper}>
                  <div className={styles.btn}
                    style={{'color': this.state.defaultForm.copyTextColor, 'border-color': this.state.defaultForm.copyBorderColor,
                    'backgroundColor': this.state.defaultForm.copyBtnColor
                  }}
                  >
                    {this.state.defaultForm.copyText}  
                  </div>
                  <div className={styles.btn}
                    style={{'color': this.state.defaultForm.goTextColor, 'border-color': this.state.defaultForm.goBorderColor,
                    'backgroundColor': this.state.defaultForm.goBtnColor
                  }}
                  >
                    {this.state.defaultForm.goText}  
                  </div>
                </div>

              </div>
          </div>

        </Col>
        </Row>
        </Card>
      </PageHeaderWrapper>
    );
  }
}

export default Form.create()(
  connect(({ h5PagesDetail, loading }) => {
    return ({
      submitting: loading.effects['h5PagesDetail/add'],
      detailInfo: h5PagesDetail.detailInfo
    })
  })(H5PagesDetail),
);
