import { query, fetchOrder } from './service';

const Model = {
  namespace: 'orderTaskAndList',
  state: {
    data: {
      list: [],
      page: 0,
      size: 50,
      count: 0,
    },
  },
  effects: {
    *add({ payload, callback }, { call, put }) {
      const { forms } = payload
      forms.start = forms.start.format('YYYY-MM-DD HH:mm:ss');
      forms.end = forms.end.format('YYYY-MM-DD HH:mm:ss');
      const res = yield call(fetchOrder, forms);
      yield put({
        type: 'global/handlerResponse',
        payload: {
          ...res,
        },
      });
      if (callback) callback();
    },
    *fetch({ payload }, { call, put }) {
      let tempPage = payload.page;
      if (tempPage > 0) {
        tempPage -= 1;
      }

      const res = yield call(query, {
        ...payload,
        page: tempPage,
      });
      const {
        code, data: { rows, page, size, count },
      } = res;
      if (code === 1) {
        yield put({
          type: 'save',
          payload: {
            list: rows,
            page,
            size,
            count,
          },
        });
      }
      yield put({
        type: 'global/handlerResponse',
        payload: {
          ...res,
          successNotice: false,
        },
      });
    },
  },
  reducers: {
    save(state, action) {
      return { ...state, data: action.payload };
    },
  },
};
export default Model;
