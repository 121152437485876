import { Form, Modal } from 'antd';
import React from 'react';
import FormCreator from '@/components/FormCreator';

const CreateBindForm = props => {
  const { modalVisible, handleBind, handleBindModalVisible, form, modfiyForm, wechats = [], handleWechatSearch } = props;
  let timer = null

  const okHandle = () => {
    form.validateFields((err, fieldsValue) => {
      if (err) return;
      form.resetFields();
      // 判断是更新还是新增
      handleBind('wechat', fieldsValue);
    });
  };

  const onSearch = (val) => {
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }

    timer = setTimeout(() => {
      handleWechatSearch(val)
    }, 300)
  }

  const layout = {
    labelCol: {
      span: 5,
    },
    wrapperCol: {
      span: 15,
    },
  }

  const configs = [
    {
      key: 'id',
      name: '选择微信号',
      type: 'select',
      config: {
        rules: [
          {
            required: true,
            message: '请选择绑定的微信号',
          },
        ],
      },
      props: {
        style: { width: '100%' },
        placeholder: '请输入微信号进行搜索，若无数据请先解绑或添加微信号',
        showSearch: true,
        filterOption: false,
        onSearch: onSearch,
        notFoundContent: null,
        showArrow: false
      },
      options: wechats.filter(item => !item.mobile).map(item => ({
        value: item.id,
        name: item.nick_name + '(' + item.user_name + ')',
      }))
    },
  ];

  return (
    <Modal
      destroyOnClose
      title={'绑定微信'}
      visible={modalVisible}
      onOk={okHandle}
      onCancel={() => handleBindModalVisible('wechat')}
    >
      <FormCreator form={ form } configs={configs} params={modfiyForm} layout={layout} />
    </Modal>
  );
};

export default Form.create()(CreateBindForm);
