import { Form, Button, Divider } from 'antd';
import React from 'react';
import FormCreator from '@/components/FormCreator';
import PowerAssert from '@/components/PowerAssert';
import Clipboard from 'react-clipboard.js';

const CreateForm = props => {
  const { handleAdd, form, text } = props;

  const okHandle = () => {
    form.validateFields((err, fieldsValue) => {
      if (err) return;
      form.resetFields();
      handleAdd(fieldsValue);
    });
  };

  const layout = {
    labelCol: {
      span: 5,
    },
    wrapperCol: {
      span: 15,
    },
  }

  const configs = [
    {
      key: 'pwd',
      name: '淘口令或券链接',
      type: 'textArea',
      props: {
        rows: 4,
      },
      config: {
        rules: [
          {
            required: true,
            message: '请输入淘口令或券+商品链接',
          },
        ],
      },
    },
  ];

  return (
    <PowerAssert code={['coupon_add']}>
      <FormCreator form={ form } configs={configs} layout={layout} />
      <Button icon="plus" type="primary" onClick={okHandle}>
        新增
      </Button>
      <Divider />
      <p>{text ? `点击下方按钮复制到剪贴板，去悦惠搜app:  ${text}` : ''}</p>
      <Clipboard className="ant-btn ant-btn-primary" data-clipboard-text={text}>一键复制</Clipboard>
    </PowerAssert>
  );
};

export default Form.create()(CreateForm);
