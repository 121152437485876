import {
  Badge,
  Button,
  Card,
  Dropdown,
  Form,
  Icon,
  Menu,
  Tag,
  Popconfirm,
  Divider, Tooltip,
} from 'antd'
import React, { Component, Fragment } from 'react';
import { PageHeaderWrapper } from '@ant-design/pro-layout';
import { connect } from 'dva';
import moment from 'moment';
import CreateForm from './components/CreateForm';
import CreateKeyForm from './components/CreateKeyForm';
import StandardTable from '@/components/StandardTable';
import PowerAssert from '@/components/PowerAssert';
import SearchForm from '@/components/SearchForm';

import styles from './style.less';

const getValue = obj =>
  Object.keys(obj)
    .map(key => obj[key])
    .join(',');

/* eslint react/no-multi-comp:0 */
@connect(({ scoreUserModel: { data }, loading }) => ({
    data,
    page: data.page,
    size: data.size,
    count: data.count,
    loading: loading.models.scoreUserModel,
  }))
class TableList extends Component {
  state = {
    modalVisible: false,
    selectedRows: [],
    formValues: {},
    modfiyForm: {},
    exportModalVisible: false,
    addKeyModalVisible: false,
  };

  columns = [
    {
      title: 'id',
      dataIndex: 'id',
    },
    {
      title: '是否vip',
      dataIndex: 'nickname',
      render: (val, rec) => <span>{rec.scoreVip ?  (rec.scoreVip.status ?<div><Tag color="#87d068">是</Tag> { rec.scoreVip.official ? '正式' : '预备' }</div> : <Tag color="#f5222d">失效</Tag> ) : <Tag color="#333">否</Tag>}</span>
    },
    {
      title: '上级ID',
      dataIndex: 'score_user_id',
    },
    {
      title: '昵称',
      dataIndex: 'nickname',
    },
    {
      title: '头像',
      dataIndex: 'headimgurl',
      width: 70,
      render: val => <img alt="图片" style={{ width: 60, height: 60 }} src={val} />,
    },
    {
      title: '能拍特价',
      dataIndex: 'istj',
      render: (val, record) => <span>
        { record.scoreVip &&  record.scoreVip.status ||  val ? <Badge status="success" text="是" /> : <Badge status="error" text="否" /> }
      </span>
    },
    {
      title: '能拍专属',
      dataIndex: 'isowntj',
      render: (val) => <span>
        { val ? <Badge status="success" text="是" /> : <Badge status="error" text="否" /> }
      </span>
    },
    {
      title: '城市',
      dataIndex: 'province',
      render: (val, rec) => <span>{rec.province ? rec.province : ''} - {rec.city ? rec.city : ''}</span>
    },
    {
      title: '性别',
      dataIndex: 'sex',
      render: val => <span>{['未知', '男', '女'][val]}</span>
    },
    {
      title: '备注',
      dataIndex: 'r',
      render: (val, rec) => <span>{rec.remark}</span>
    },
    {
      title: '余额/提现中',
      dataIndex: 'r',
      render: (val, rec) => <a href={`http://new.ab1a.cn/scoreCash?score_user_id=${rec.id}`} target="_blank" rel="noopener noreferrer">{rec.amount}/{rec.drawing}</a>,
    },
    {
      title: '最后访问时间',
      dataIndex: 'last_visit_time',
      render: val => <span>{moment(val).format('YYYY-MM-DD HH:mm:ss')}</span>,
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      render: val => <span>{moment(val).format('YYYY-MM-DD HH:mm:ss')}</span>,
    },
    {
      title: '操作',
      render: (text, record) => (
        <Fragment>
          <PowerAssert code="uconfig_query">
            <a onClick={() => this.handleKeyModal(record)}>新增尾号</a>
            <Divider type="vertical" />
            <a onClick={() => this.handleUpdateModal(record)}>编辑</a>
          </PowerAssert>
          <Divider type="vertical" />
          {
            (!record.scoreVip || !record.scoreVip.status) && (
              <PowerAssert code="uconfig_query">
                <Popconfirm
                title="确定升级为vip？"
                onConfirm={() => this.handleVip(record, 1)}
              ><a>升vip</a></Popconfirm>
              </PowerAssert>
            )
          }
          {
            (record.scoreVip && !!record.scoreVip.status) && (
              <PowerAssert code="uconfig_query">
                <Popconfirm
                title="确定降级为平民？"
                onConfirm={() => this.handleVip(record, 0)}
              ><a>变平民</a></Popconfirm>
              </PowerAssert>
            )
          }
          <Divider type="vertical" />
          {
            (!record.scoreVip) && (
              <PowerAssert code="uconfig_query">
                <Popconfirm
                title="确定升级为预备vip？"
                onConfirm={() => this.handlePreVip(record)}
              ><a>预备vip</a></Popconfirm>
              </PowerAssert>
            )
          }
          {
            (record.scoreVip && record.scoreVip && !record.scoreVip.official) && (
              <PowerAssert code="uconfig_query">
                <Popconfirm
                title="确定升级正式vip？"
                onConfirm={() => this.handleActiveVip(record)}
              ><a>正式vip</a></Popconfirm>
              </PowerAssert>
            )
          }
          <Divider type="vertical" />
          {
            (!record.scoreVip && !record.istj) && (
              <PowerAssert code="uconfig_query">
                <Popconfirm
                title="确定改为能拍特价版？"
                onConfirm={() => this.handleModify(record.id, {
                  istj: 1,
                })}
              ><a>能拍特价</a></Popconfirm>
              </PowerAssert>
            )
          }
          {
            (!record.scoreVip && !!record.istj) && (
              <PowerAssert code="uconfig_query">
                <Popconfirm
                title="确定改为不能拍特价版？"
                onConfirm={() => this.handleModify(record.id, {
                  istj: 0,
                })}
              ><a>不能拍特价</a></Popconfirm>
              </PowerAssert>
            )
          }
           <Divider type="vertical" />
          {
            (!record.isowntj) && (
              <PowerAssert code="uconfig_query">
                <Popconfirm
                title="确定改为能拍专属特价版？"
                onConfirm={() => this.handleModify(record.id, {
                  isowntj: 1,
                })}
              ><a>能拍专属</a></Popconfirm>
              </PowerAssert>
            )
          }
          {
            (!!record.isowntj) && (
              <PowerAssert code="uconfig_query">
                <Popconfirm
                title="确定改为不能拍专属特价版？"
                onConfirm={() => this.handleModify(record.id, {
                  isowntj: 0,
                })}
              ><a>不能拍专属</a></Popconfirm>
              </PowerAssert>
            )
          }
        </Fragment>
      ),
    },
  ];

  componentDidMount() {
    const { page, size } = this.props;
    this.fetchData({ page, size });
  }

  handleStandardTableChange = (pagination, filtersArg, sorter) => {
    const { formValues } = this.state;
    const filters = Object.keys(filtersArg).reduce((obj, key) => {
      const newObj = { ...obj };
      newObj[key] = getValue(filtersArg[key]);
      return newObj;
    }, {});
    const params = {
      ...formValues,
      ...filters,
    };

    if (sorter.field) {
      params.sorter = `${sorter.field}_${sorter.order}`;
    }
    this.handleSelectRows([]);

    this.fetchData({
      page: pagination.current,
      size: pagination.pageSize,
    }, params);
  };

  handleFormReset = () => {
    const { form, size } = this.props;
    form.resetFields();
    this.handleSelectRows([]);
    this.setState({
      formValues: {},
    });
    this.fetchData({ page: 0, size });
  };

  handleSelectRows = rows => {
    this.setState({
      selectedRows: rows,
    }, () => {
    });
  };

  handleSearch = values => {
    const { size } = this.props;
    // 更新state，这样子翻页什么的时候也能跟进
    this.handleSelectRows([]);
    this.setState({
      formValues: values,
    });
    this.fetchData({ page: 0, size }, values);
  };

  handleModalVisible = flag => {
    this.setState({
      modalVisible: !!flag,
    });
  };
  handleKeyModal = record => {
    this.setState({
      modfiyForm: record || {},
      addKeyModalVisible: true,
    });
  };

  closeModal = () => {
    this.setState({
      modalVisible: false,
      exportModalVisible: false,
      addKeyModalVisible: false,
      modfiyForm: {},
    });
  }

  handleUpdateModal = record => {
    this.setState({
      modfiyForm: record || {},
      modalVisible: true,
    });
  };

  handleAdd = fields => {
    const { formValues } = this.state;
    const { dispatch, size, page } = this.props;
    dispatch({
      type: 'scoreUserModel/add',
      payload: {
        forms: fields,
      },
      callback: () => {
        this.fetchData({ page: page + 1, size }, formValues);
      },
    });
    this.handleModalVisible();
  };

  handleUpdate = fields => {
    const { formValues } = this.state;
    const { dispatch, size, page } = this.props;
    const { modfiyForm: { id } } = this.state;
    dispatch({
      type: 'scoreUserModel/update',
      payload: {
        id,
        ...fields,
      },
      callback: () => {
        this.fetchData({ page: page + 1, size }, formValues);
      },
    });
    this.closeModal();
  };

  handleModify = (id, fields) => {
    const { formValues } = this.state;
    const { dispatch, size, page } = this.props;
    dispatch({
      type: 'scoreUserModel/update',
      payload: {
        id,
        ...fields,
      },
      callback: () => {
        this.fetchData({ page: page + 1, size }, formValues);
      },
    });
    this.closeModal();
  };

  handleAddKey = fields => {
    const { formValues } = this.state;
    const { dispatch, size, page } = this.props;
    dispatch({
      type: 'uKeyModel/add',
      payload: {
        forms: fields,
      },
      callback: () => {
        this.fetchData({ page: page + 1, size }, formValues);
      },
    });
    this.closeModal();
  };
  handleActiveVip = (record) => {
    const { formValues } = this.state;
    const { dispatch, size, page } = this.props;
    dispatch({
      type: 'scoreVipModel/activeVip',
      payload: {
        id: record.id,
      },
      callback: () => {
        this.fetchData({ page: page + 1, size }, formValues);
      },
    });
  };
  handlePreVip = (record, official = 0) => {
    const { formValues } = this.state;
    const { dispatch, size, page } = this.props;
    dispatch({
      type: 'scoreVipModel/beVip',
      payload: {
        id: record.id,
        flag: 1,
        official,
      },
      callback: () => {
        this.fetchData({ page: page + 1, size }, formValues);
      },
    });
  };
  handleVip = (record, flag) => {
    const { formValues } = this.state;
    const { dispatch, size, page } = this.props;
    dispatch({
      type: 'scoreVipModel/beVip',
      payload: {
        id: record.id,
        flag,
      },
      callback: () => {
        this.fetchData({ page: page + 1, size }, formValues);
      },
    });
  };

  fetchData(pager = null, fieldValue = {}, saveParams) {
    const { page, size, dispatch } = this.props;
    let tempPager = {
      page,
      size,
    }
    tempPager = {
      ...tempPager,
      ...pager,
    }
    let params = {
      ...fieldValue,
      ...tempPager,
    }
    if (saveParams) {
      const { formValues } = this.state;
      params = {
        ...params,
        ...formValues,
      }
    }
    dispatch({
      type: 'scoreUserModel/fetch',
      payload: params,
    });
  }

  render() {
    const {
      data,
      page,
      count,
      size,
      loading,
    } = this.props;

    const searchKeys = [{
      label: 'id',
      key: 'id',
    }, {
      label: '昵称',
      key: 'nickname',
    }, {
      label: '能拍特价',
      key: 'istj',
      type: 'select',
      options: [
        {
          key: 0,
          val: '否',
        },
        {
          key: 1,
          val: '是',
        },
      ],
    },  {
      label: '能拍专属',
      key: 'isowntj',
      type: 'select',
      options: [
        {
          key: 0,
          val: '否',
        },
        {
          key: 1,
          val: '是',
        },
      ],
    }];

    const { selectedRows, modalVisible, modfiyForm, addKeyModalVisible } = this.state;
    const parentMethods = {
      handleAdd: this.handleAdd,
      handleUpdate: this.handleUpdate,
      closeModal: this.closeModal,
    };
    const keyMethods = {
      handleAdd: this.handleAddKey,
      closeModal: this.closeModal,
    };
    const pager = {
      page,
      size,
      count,
    }
    return (
      <PageHeaderWrapper>
        <Card bordered={false}>
          <div className={styles.tableList}>
            <SearchForm search={this.handleSearch} reset={this.handleFormReset} searchConfigs={searchKeys} />
            <div className={styles.tableListOperator}>
            </div>
            <StandardTable
              selectedRows={selectedRows}
              loading={loading}
              data={data}
              columns={this.columns}
              onSelectRow={this.handleSelectRows}
              onChange={this.handleStandardTableChange}
              pager={pager}
            />
          </div>
        </Card>
        <CreateKeyForm {...keyMethods} modfiyForm={modfiyForm} modalVisible={addKeyModalVisible}/>
        <CreateForm {...parentMethods} modfiyForm={modfiyForm} modalVisible={modalVisible}/>
      </PageHeaderWrapper>
    );
  }
}

export default Form.create()(TableList);
