import request from '@/utils/request';

export async function add(data) {
  return request('/api/h5page', {
    method: 'POST',
    data,
  });
}

export async function getDetail ({id}) {
  return request(`/api/h5page/${id}`);
}

