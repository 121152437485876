import {
  Button,
  Card,
  Form,
  Divider,
} from 'antd';
import React, { Component } from 'react';
import { PageHeaderWrapper } from '@ant-design/pro-layout';
import { connect } from 'dva';
import CreateForm from './components/CreateForm';


import styles from './style.less';

/* eslint react/no-multi-comp:0 */
@connect(({ couponModel: { text }, loading }) => ({
  text,
  loading: loading.models.couponModel,
}))
class TableList extends Component {
  state = {
  };

  handleAdd = fields => {
    const { dispatch } = this.props;
    dispatch({
      type: 'couponModel/add',
      payload: {
        forms: fields,
      },
    });
  };

  render() {
    const {
      text,
      loading,
    } = this.props;
    const parentMethods = {
      handleAdd: this.handleAdd,
      handleModalVisible: this.handleModalVisible,
    };
    return (
      <PageHeaderWrapper>
        <Card bordered={false}>
          <CreateForm {...parentMethods} text={text} />
          <Divider />
        </Card>
      </PageHeaderWrapper>
    );
  }
}

export default Form.create()(TableList);
