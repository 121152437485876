import { message } from 'antd';
import { query, update, add, exportExcel, del } from './service';

const Model = {
  namespace: 'uKeyModel',
  state: {
    data: {
      list: [],
      page: 0,
      size: 50,
      count: 0,
    },
    config: {
    },
  },
  effects: {
    *fetch({ payload, callback }, { call, put }) {
      let tempPage = payload.page;
      if (tempPage > 0) {
        tempPage -= 1;
      }

      const res = yield call(query, {
        ...payload,
        page: tempPage,
      });
      const {
        code, data: { rows, page, size, count },
      } = res;
      if (code === 1) {
        yield put({
          type: 'save',
          payload: {
            list: rows,
            page,
            size,
            count,
          },
        });
        if (callback) callback();
      }
      yield put({
        type: 'global/handlerResponse',
        payload: {
          ...res,
          successNotice: false,
        },
      });
    },
    *add({ payload, callback }, { call, put }) {
      const { forms } = payload
      if (forms.key.length > 6) {
        forms.key = forms.key.replace(/\s/g, '').substr(13,6);
      }
      const res = yield call(add, forms);
      if (res.code === 1 && res.data.duplicate) {
        message.error('添加成功，但是尾号有重复!');
      } else {
        yield put({
          type: 'global/handlerResponse',
          payload: {
            ...res,
          },
        });
      }
      if (callback) callback();
    },
    *del({ payload, callback }, { call, put }) {
      const res = yield call(del, payload);
      yield put({
        type: 'global/handlerResponse',
        payload: {
          ...res,
        },
      });
      if (callback) callback();
    },
    *export({ payload }, { call, put }) {
      const { forms } = payload;
      if (forms.start) {
        forms.start = forms.start.format('YYYY-MM-DD HH:mm:ss');
      }
      yield call(exportExcel, forms);
    },
    *update({ payload, callback }, { call, put }) {
      const res = yield call(update, payload);
      yield put({
        type: 'global/handlerResponse',
        payload: {
          ...res,
        },
      });
      if (callback) callback();
    },
  },
  reducers: {
    save(state, action) {
      return { ...state, data: action.payload };
    },
  },
};
export default Model;
