import * as services from './service';
import moment from 'moment';

const Model = {
  namespace: 'fentuStat',
  state: {
    data: [],
    isShowChart: false,
    chartOption: {}
  },
  effects: {
    *fetch({ payload: {
      method,
      params,
    }}, { call, put }) {
      const res = yield call(services[method], {
        ...params,
      });
      const {
        code, data,
      } = res;
      if (code === 1) {
        yield put({
          type: 'save',
          payload: {
            data,
          },
        });
      }
      yield put({
        type: 'setChartOptions',
        payload: {
          type: method,
          data,
        }
      })
      yield put({
        type: 'global/handlerResponse',
        payload: {
          ...res,
          successNotice: false,
        },
      });
    },
  },
  reducers: {
    save(state, action) {
      return { ...state, data: action.payload.data };
    },
    toggleChart(state, action) {
      return {...state, isShowChart: !state.isShowChart}
    },
    setChartOptions(state, action) {
      const { payload: { type, data } } = action
      const enumLegend = [
        {value: 'weixin_nickname', name: '微信'},
        {value: 'total', name: '次数'},
        {value: 'no', name: '时间'},
      ];
      const chartOption = {
        title: {
          show: false
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: enumLegend.map(i => i.name),
          selectedMode: 'multiple',
          selected: {
            '微信': true,
            '次数': true,
            '时间': true,
          }
        },
        toolbox: {
          feature: {
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: []
        },
        yAxis: {
          type: 'value'
        },
        series: []
      };
      if (type === 'getByDay') {
        chartOption.xAxis.data = data.map(i => i.weixin_nickname)
        chartOption.xAxis.data.reverse();
      } else {
        chartOption.xAxis.data = data.map(i => i.no)
      }

      chartOption.series = enumLegend.map(i => {
        const item = {};
        item.name = i.name;
        item.type = 'line';
        item.data = data.map(it => {
          return it.total
        })
        if (type === 'getByDay') {
          item.data.reverse();
        }
        return item;
      })
      return {...state, chartOption}
    }
  },
};
export default Model;
