import { Form, Checkbox, Button, Divider, Radio } from 'antd';
import React from 'react';
import style from './style.less';
import moment from 'moment';

const Qinniu = props => {
  const { list, update, keys, del, load, modifyBucket, currentKey } = props;
  const okHandle = (values) => {
    update(values);
  };
  const onChangeBucket = e => {
    modifyBucket(e.target.value);
  }
  return (
  <div>
    <Radio.Group onChange={onChangeBucket} value={currentKey}>
      <Radio value="xwdefault">xwdefault</Radio>
      <Radio value="xweco">xweco</Radio>
      <Radio value="xwstatic">xwstatic</Radio>
    </Radio.Group>
    <Button onClick={del}>删除</Button>
    <Divider />
    <Checkbox.Group style={{ width: '100%' }} value={keys} onChange={okHandle}>
      {list.map(item => {
        let time = ('' + item.putTime).substr(0, 13);
        return <div className={[style.item, style.clearfix].join(' ')}>
          <img className={style.qImg} src={item.url} alt=""/>
          <Checkbox value={item.key}>{item.key}</Checkbox>
          <p className={style.time}>{moment(time * 1).format('MM-DD HH:mm:ss')}</p>
        </div>
      })}
    </Checkbox.Group>
    <Divider />
    <Button onClick={load}>加载更多</Button>
    </div>
  );
};

export default Form.create()(Qinniu);
