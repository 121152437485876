export default {
  'menu.welcome': '歡迎',
  'menu.more-blocks': '更多區塊',
  'menu.home': '首頁',
  'menu.login': '登錄',
  'menu.admin': '权限',
  'menu.exception.403': '403',
  'menu.exception.404': '404',
  'menu.exception.500': '500',
  'menu.register': '註冊',
  'menu.register.result': '註冊結果',
  'menu.dashboard': 'Dashboard',
  'menu.dashboard.analysis': '分析頁',
  'menu.dashboard.monitor': '監控頁',
  'menu.dashboard.workplace': '工作臺',
  'menu.form': '表單頁',
  'menu.form.basic-form': '基礎表單',
  'menu.form.step-form': '分步表單',
  'menu.form.step-form.info': '分步表單（填寫轉賬信息）',
  'menu.form.step-form.confirm': '分步表單（確認轉賬信息）',
  'menu.form.step-form.result': '分步表單（完成）',
  'menu.form.advanced-form': '高級表單',
  'menu.list': '列表頁',
  'menu.list.table-list': '查詢表格',
  'menu.list.basic-list': '標淮列表',
  'menu.list.card-list': '卡片列表',
  'menu.list.search-list': '搜索列表',
  'menu.list.search-list.articles': '搜索列表（文章）',
  'menu.list.search-list.projects': '搜索列表（項目）',
  'menu.list.search-list.applications': '搜索列表（應用）',
  'menu.profile': '詳情頁',
  'menu.profile.basic': '基礎詳情頁',
  'menu.profile.advanced': '高級詳情頁',
  'menu.result': '結果頁',
  'menu.result.success': '成功頁',
  'menu.result.fail': '失敗頁',
  'menu.account': '個人頁',
  'menu.account.center': '個人中心',
  'menu.account.settings': '個人設置',
  'menu.account.password': '修改密码',
  'menu.account.trigger': '觸發報錯',
  'menu.account.logout': '退出登錄',
  'menu.exception': '异常页',
  'menu.exception.not-permission': '403',
  'menu.exception.not-find': '404',
  'menu.exception.server-error': '500',
  'menu.exception.trigger': '触发错误',
  'menu.editor': '圖形編輯器',
  'menu.editor.flow': '流程編輯器',
  'menu.editor.mind': '腦圖編輯器',
  'menu.editor.koni': '拓撲編輯器',
};
