import { add, query, update, getPwd, savePwd } from './service';

const Model = {
  namespace: 'tConfigModel',
  state: {
    data: {
      list: [],
      page: 0,
      size: 50,
      count: 0,
      sdPwd: '',
    },
  },
  effects: {
    *fetch({ payload }, { call, put }) {
      let tempPage = payload.page;
      if (tempPage > 0) {
        tempPage -= 1;
      }

      const res = yield call(query, {
        ...payload,
        page: tempPage,
      });
      const {
        code, data: { rows, page, size, count },
      } = res;
      if (code === 1) {
        yield put({
          type: 'save',
          payload: {
            list: rows,
            page,
            size,
            count,
          },
        });
      }
      yield put({
        type: 'global/handlerResponse',
        payload: {
          ...res,
          successNotice: false,
        },
      });
    },

    *add({ payload, callback }, { call, put }) {
      const { forms } = payload;
      const res = yield call(add, forms);
      yield put({
        type: 'global/handlerResponse',
        payload: {
          ...res,
        },
      });
      if (callback) callback();
    },
    *getSdPwd({ payload, callback }, { call, put }) {
      const res = yield call(getPwd);
      yield put({
        type: 'save',
        payload: {
          sdPwd: res.data,
        },
      });
      if (callback) callback();
    },
    *saveSdPwd({ payload, callback }, { call, put }) {
      const res = yield call(savePwd, payload);
      yield put({
        type: 'global/handlerResponse',
        payload: {
          ...res,
        },
      });
      if (callback) callback();
    },
    *update({ payload, callback }, { call, put }) {
      const res = yield call(update, payload);
      yield put({
        type: 'global/handlerResponse',
        payload: {
          ...res,
        },
      });
      if (callback) callback();
    },
  },
  reducers: {
    save(state, action) {
      return { ...state, data: action.payload };
    },
  },
};
export default Model;
