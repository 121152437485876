import request from '@/utils/request';

export async function query(params) {
  return request('/api/scoreVip', {
    params,
  });
}

export async function update ({ id, ...data }) {
  return request(`/api/scoreVip/${id}`, {
    method: 'PUT',
    data,
  });
}


export async function add(data) {
  return request('/api/scoreVip', {
    method: 'POST',
    data,
  });
}

export async function activeVip(data) {
  return request('/api/scoreVipActive', {
    method: 'POST',
    data,
  });
}

export async function updateScore(data) {
  return request('/api/updateScore', {
    method: 'POST',
    data,
  });
}