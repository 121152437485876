import request from '@/utils/request';

export async function query(params) {
  return request('/api/mobile', {
    params,
  });
}

export async function addMobile(data) {
  return request('/api/mobile', {
    method: 'POST',
    data
  });
}

export async function updateMobile ({id, ...data}) {
  return request(`/api/mobile/${id}`, {
    method: 'PUT',
    data
  });
}

export async function getMobile (id) {
  return request(`/api/mobile/${id}`);
}

export async function getAsset() {
  return request(`/api/asset`)
}

export async function bind () {

}
