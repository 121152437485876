import request from '@/utils/request';

export async function query(params) {
  return request('/api/wechatAdDetail', {
    params,
  });
}

export async function update ({id, ...data}) {
  return request(`/api/wechatAdOut/${id}`, {
    method: 'PUT',
    data
  });
}