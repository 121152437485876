import {
  Button,
  Card,
  Dropdown,
  Form,
  Icon,
  Menu,
  Tag,
  Popconfirm,
  Divider, Tooltip, Popover,
} from 'antd'
import React, { Component, Fragment } from 'react';
import { PageHeaderWrapper } from '@ant-design/pro-layout';
import { connect } from 'dva';
import moment from 'moment';
import CreateForm from './components/CreateForm';
import StandardTable from '@/components/StandardTable';
import PowerAssert from '@/components/PowerAssert';
import SearchForm from '@/components/SearchForm';

import styles from './style.less';

const getValue = obj =>
  Object.keys(obj)
    .map(key => obj[key])
    .join(',');

/* eslint react/no-multi-comp:0 */
@connect(({ aliActMaterialAndList: { data, config }, loading }) => ({
  data,
  page: data.page,
  size: data.size,
  count: data.count,
  loading: loading.models.aliActMaterialAndList,
}))
class TableList extends Component {
  state = {
    modalVisible: false,
    selectedRows: [],
    formValues: {},
    modfiyForm: {},
  };

  columns = [
    {
      title: '活动id',
      dataIndex: 'material_id',
    },
    {
      title: '活动名称',
      dataIndex: 'title',
    },
    {
      title: '活动开始时间',
      dataIndex: 'start_time',
      render: t => moment(t).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: '活动结束时间',
      dataIndex: 'end_time',
      render: t => moment(t).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: '活动新增时间',
      dataIndex: 'createdAt',
      render: t => t && moment(t).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: '活动更新时间',
      dataIndex: 'updatedAt',
      render: t => t && moment(t).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: '操作',
      render: (text, record) => (
        <Fragment>
          <PowerAssert code="material_update">
            <a onClick={() => this.handleUpdateModal(record)}>更新</a>
            <br/>
            <a onClick={() => this.handleFetchProd(record)}>爬取商品</a>
          </PowerAssert>
          <PowerAssert code="material_super">
            <a onClick={() => this.handleUpdateProd(record)}>置0</a>
          </PowerAssert>
        </Fragment>
      ),
    },
  ];

  componentDidMount() {
    const { page, size } = this.props;
    this.fetchData({ page, size });
  }

  handleStandardTableChange = (pagination, filtersArg, sorter) => {
    const { formValues } = this.state;
    const filters = Object.keys(filtersArg).reduce((obj, key) => {
      const newObj = { ...obj };
      newObj[key] = getValue(filtersArg[key]);
      return newObj;
    }, {});
    const params = {
      ...formValues,
      ...filters,
    };

    if (sorter.field) {
      params.sorter = `${sorter.field}_${sorter.order}`;
    }
    this.handleSelectRows([]);

    this.fetchData({
      page: pagination.current,
      size: pagination.pageSize,
    }, params);
  };

  handleFormReset = () => {
    const { form, size } = this.props;
    form.resetFields();
    this.handleSelectRows([]);
    this.setState({
      formValues: {},
    });
    this.fetchData({ page: 0, size });
  };

  handleMenuClick = e => {
    const { dispatch, size } = this.props;
    const { selectedRows } = this.state;
    if (!selectedRows) return;

    switch (e.key) {
      case 'remove':
        dispatch({
          type: 'aliActMaterialAndList/remove',
          payload: {
            ids: selectedRows.map(row => row.id),
          },
          callback: () => {
            this.handleSelectRows([]);
            this.fetchData({ page: 0, size });
          },
        });
        break;

      default:
        break;
    }
  };

  handleSelectRows = rows => {
    this.setState({
      selectedRows: rows,
    }, () => {
    });
  };

  handleSearch = values => {
    const { size } = this.props;
    // 更新state，这样子翻页什么的时候也能跟进
    this.handleSelectRows([]);
    for (const key in values) {
      if (values[key] === '') {
        values[key] = void 0
      }
    }
    this.setState({
      formValues: values,
    });
    this.fetchData({ page: 0, size }, values);
  };

  handleModalVisible = flag => {
    this.setState({
      modalVisible: !!flag,
    });
  };

  closeModal = () => {
    this.setState({
      modalVisible: false,
      modfiyForm: {},
    });
  }

  handleUpdateModal = record => {
    this.setState({
      modfiyForm: record || {},
      modalVisible: true,
    });
  };

  handleFetchProd = record => {
    const { dispatch } = this.props;
    dispatch({
      type: 'aliActMaterialAndList/fetchProd',
      payload: {
        id: record.id,
      },
    })
  }

  handleUpdateProd = record => {
    const { dispatch } = this.props;
    dispatch({
      type: 'aliActMaterialAndList/update',
      payload: {
        id: record.id,
        progress: 0,
      },
    })
  }

  handleAdd = fields => {
    const { dispatch, size } = this.props;
    dispatch({
      type: 'aliActMaterialAndList/add',
      payload: {
        forms: fields,
      },
      callback: () => {
        this.fetchData({ page: 0, size });
      },
    });
    this.handleModalVisible();
  };

  handleUpdate = fields => {
    const { dispatch, size } = this.props;
    const { modfiyForm: { id } } = this.state;
    dispatch({
      type: 'aliActMaterialAndList/update',
      payload: {
        id,
        ...fields,
      },
      callback: () => {
        this.fetchData({ page: 0, size });
      },
    });
    this.closeModal();
  };


  fetchData(pager = null, fieldValue = {}, saveParams) {
    const { page, size, dispatch } = this.props;
    let tempPager = {
      page,
      size,
    }
    tempPager = {
      ...tempPager,
      ...pager,
    }
    let params = {
      ...fieldValue,
      ...tempPager,
    }
    if (saveParams) {
      const { formValues } = this.state;
      params = {
        ...params,
        ...formValues,
      }
    }
    dispatch({
      type: 'aliActMaterialAndList/fetch',
      payload: params,
    });
  }

  render() {
    const {
      data,
      page,
      count,
      size,
      loading,
    } = this.props;

    const searchKeys = [{
      label: '活动名称',
      type: 'input',
      key: 'title',
    }, {
      label: '活动id',
      type: 'input',
      key: 'material_id',
    }];

    const { selectedRows, modalVisible, modfiyForm } = this.state;
    const parentMethods = {
      handleAdd: this.handleAdd,
      handleUpdate: this.handleUpdate,
      handleModalVisible: this.handleModalVisible,
      closeModal: this.closeModal,
    };
    const pager = {
      page,
      size,
      count,
    }
    return (
      <PageHeaderWrapper>
        <Card bordered={false}>
          <div className={styles.tableList}>
            <SearchForm search={this.handleSearch} reset={this.handleFormReset} searchConfigs={searchKeys} />
            <div className={styles.tableListOperator}>
             <PowerAssert code="material_add">
                <Button icon="plus" type="primary" onClick={() => this.handleModalVisible(true)}>
                  增加活动配置
                </Button>
              </PowerAssert>
            </div>
            <StandardTable
              selectedRows={selectedRows}
              loading={loading}
              data={data}
              columns={this.columns}
              onSelectRow={this.handleSelectRows}
              onChange={this.handleStandardTableChange}
              pager={pager}
            />
          </div>
        </Card>
        <CreateForm {...parentMethods} modfiyForm={modfiyForm} modalVisible={modalVisible} />
      </PageHeaderWrapper>
    );
  }
}

export default Form.create()(TableList);
