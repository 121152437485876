import { Form, Modal } from 'antd';
import React from 'react';
import FormCreator from '@/components/FormCreator';

const CreateForm = props => {
  const { modalVisible, handleAdd, closeModal, form } = props;
  const okHandle = () => {
    form.validateFields((err, fieldsValue) => {
      if (err) return;
      form.resetFields();
      handleAdd(fieldsValue);
    });
  };

  const layout = {
    labelCol: {
      span: 5,
    },
    wrapperCol: {
      span: 15,
    },
  }

  const configs = [
    {
      key: 'remark',
      name: '用户备注',
      config: {
        rules: [
          {
            required: true,
            message: '请输入',
          },
        ],
      },
    },
    {
      key: 'orderNos',
      name: '订单编号',
      type: 'textArea',
      props: {
        rows: 4,
        placeholder: '每行一个，不要有空行',
      },
      config: {
        rules: [
          {
            required: true,
            message: '请输入',
          },
        ],
      },
    },
    {
      key: 'amount',
      name: '返款',
      props: {
        style: { width: '100%' },
      },
      type: 'number',
    },
    {
      key: 'score',
      name: '积分',
      props: {
        style: { width: '100%' },
      },
      type: 'number',
    },
    {
      key: 'start',
      name: '开始上传时间',
      type: 'datePicker',
    },
  ];
  
  return (
    <Modal
      destroyOnClose
      title="上传订单"
      visible={modalVisible}
      onOk={okHandle}
      onCancel={() => closeModal()}
    >
      <FormCreator form={ form } configs={configs} params={{}} layout={layout} />
    </Modal>
  );
};

export default Form.create()(CreateForm);
