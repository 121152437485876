import { Form, Modal } from 'antd';
import React from 'react';
import FormCreator from '@/components/FormCreator';

const CreateForm = props => {
  const { modalVisible, handleAdd, handleModalVisible, form, alimamas } = props;

  const okHandle = () => {
    form.validateFields((err, fieldsValue) => {
      if (err) return;
      form.resetFields();
      handleAdd(fieldsValue);
    });
  };

  const layout = {
    labelCol: {
      span: 5,
    },
    wrapperCol: {
      span: 15,
    },
  }

  const configs = [
    {
      key: 'account',
      name: '联盟账号',
      type: 'select',
      props: {
        style: { width: '100%' },
        mode: 'multiple',
      },
      config: {
        rules: [
          {
            required: true,
            message: '请选择联盟账号',
          },
        ],
      },
      options: alimamas.map(item => ({
        value: item,
        name: item,
      })),
    },
    {
      key: 'start',
      name: '开始时间',
      type: 'datePicker',
      config: {
        rules: [
          {
            required: true,
            message: '请选择开始时间',
          },
        ],
      },
    },
    {
      key: 'end',
      name: '结束时间',
      type: 'datePicker',
      config: {
        rules: [
          {
            required: true,
            message: '请选择结束时间',
          },
        ],
      },
    },
    {
      key: 'query_type',
      name: '爬取类型',
      type: 'select',
      placeholder: '1创建, 2付款, 3结算, 4更新',
      props: {
        style: { width: '100%' },
      },
      config: {
        rules: [
          {
            required: true,
            message: '请选择爬取类型',
          },
        ],
      },
      options: [
        {
          value: 1,
          name: '按创建时间',
        },
        {
          value: 2,
          name: '按付款时间',
        },
        {
          value: 3,
          name: '按结算时间',
        },
        {
          value: 4,
          name: '按更新时间',
        },
      ],
    },
    {
      key: 'order_scene',
      name: '场景',
      type: 'select',
      placeholder: '1全部, 2渠道, 3会员',
      props: {
        style: { width: '100%' },
      },
      config: {
        rules: [
          {
            required: true,
            message: '请选择爬取类型',
          },
        ],
      },
      options: [
        {
          value: 1,
          name: '全部',
        },
        {
          value: 2,
          name: '渠道订单',
        },
        {
          value: 3,
          name: '会员订单',
        },
      ],
    },
    {
      key: 'isPunish',
      name: '是否专爬处罚订单',
      type: 'select',
      config: {
        rules: [
          {
            required: true,
            message: '请选择类型',
          },
        ],
      },
      props: {
        style: { width: '100%' },
      },
      options: [{
        value: 1,
        name: '是'
      }, {
        value: 0,
        name: '否'
      }],
    },
  ];

  const modfiyForm = {
    query_type: 1,
    order_scene: 1,
    isPunish: 0,
  };

  return (
    <Modal
      destroyOnClose
      title="新建任务"
      visible={modalVisible}
      onOk={okHandle}
      onCancel={() => handleModalVisible()}
    >
      <FormCreator form={ form } configs={configs} params={modfiyForm} layout={layout} />
    </Modal>
  );
};

export default Form.create()(CreateForm);
