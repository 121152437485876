import request from '@/utils/request';

export async function query(params) {
  return request('/api/miandanAct', {
    params,
  });
}
export async function add(data) {
  return request('/api/miandanAct', {
    method: 'POST',
    data,
  });
}

export async function update ({ id, ...data }) {
  return request(`/api/miandanAct/${id}`, {
    method: 'PUT',
    data,
  });
}

export async function uploadOrder(data) {
  return request('/api/uploadMd', {
    method: 'POST',
    data,
  });
}
