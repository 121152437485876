import request from '@/utils/request';

export async function query(params) {
  return request('/api/jdProd', {
    params,
  });
}

export async function update ({id, ...data}) {
  return request(`/api/jdProd/${id}`, {
    method: 'PUT',
    data
  });
}

export async function add(data) {
  return request('/api/jdProd', {
    method: 'POST',
    data,
  });
}
