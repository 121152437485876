import request from '@/utils/request';
import { openLink } from '@/utils/help';

export async function query(params) {
  return request('/order/ali', {
    params,
  });
}

export async function exportExcel(params) {
  return openLink('/order/ali/export', params);
}

export async function exportExcelByDay(params) {
  return openLink('/order/ali/exportByDay', params);
}
