import {
  Card,
  Form,
  Divider,
  Button,
  Row,
  Col,
  message,
  Descriptions,
} from 'antd';
import React, { Component, Fragment } from 'react';
import { PageHeaderWrapper } from '@ant-design/pro-layout';
import { connect } from 'dva';
import copy from 'copy-to-clipboard';
import FormCreator from '@/components/FormCreator';
import styles from './style.less';

@connect(({ tljSimpleModel7: { sd7, itemId, img, title, }, loading, tConfListModel: { configs }, }) => ({
  sd7,
  itemId,
  img,
  title,
  loading: loading.models.tljSimpleModel7,
  pidEmums: configs.type4 || [],
}))
class TableList extends Component {
  state = {
    modalVisible: false,
    selectedRows: [],
    formValues: {},
    modfiyForm: {},
  };

  componentDidMount() {
    this.props.dispatch({
      type: 'tConfListModel/fetch',
      payload: {
        type: 4,
      },
    });
  }
  handleAdd = fields => {
    const { dispatch } = this.props;
    dispatch({
      type: 'tljSimpleModel7/add',
      payload: fields,
      callback: () => {
      },
    });
  };

  handleCopy (text) {
    if (copy(text)) {
      message.success('复制成功！')
    } else {
      message.error('复制失败')
    }
  }

  render() {
    const {
      sd7,
      itemId,
      loading,
      pidEmums,
      form,
      img,
      title,
    } = this.props;
    const pids = (pidEmums || []).filter(pid => !!pid.isTlj);

    const layout = {
      labelCol: {
        span: 5,
      },
      wrapperCol: {
        span: 10,
      },
    }
  
    const configs = [
      {
        key: 'template',
        name: '文案模板',
        type: 'textArea',
      },
      {
        key: 'item_id',
        name: '商品ID',
        config: {
          rules: [
            {
              required: true,
              message: '请输入商品ID',
            },
          ],
        },
      },
      {
        key: 'pid7',
        name: '7首单pid',
        type: 'select',
        props: {
          style: { width: '100%' },
        },
        options: pids,
      },
      {
        key: 'per_face7',
        name: '7首单面额',
        type: 'number',
        placeholder: '1元起，可带2位小数',
        config: {
          rules: [
            {
              validator: (rule, value, callback) => {
                if(value > 10) {
                  callback('金额必须小于10元');
                } else {
                  callback();
                }
              },
            },
          ],
        },
        props: {
          style: { width: '100%' },
        },
      },
      {
        key: 'total_num7',
        name: '7首单个数',
        type: 'number',
        placeholder: '1个起',
        props: {
          style: { width: '100%' },
        },
      },
      
      
    ];
  
    const modfiyForm = {
      per_face7: 3.1,
      total_num7: 30,
      isToday: '1',
      pid: pids.length ? pids[0].value : '',
    };

    const okHandle = () => {
      form.validateFields((err, fieldsValue) => {
        if (err) return;
        const {
          per_face7,
          total_num7,
          pid7
        } = fieldsValue;
        this.handleAdd(fieldsValue);
      });
    };
    return (
      <PageHeaderWrapper>
        <Card bordered={false}>
          <Row>
            <Col span={12}>
            <FormCreator form={ form } configs={configs} params={modfiyForm} layout={layout} />
            <Button icon="plus" type="primary" onClick={okHandle} loading={loading}>
              新增
            </Button>
            </Col>
            <Col span={12}>
            {
                sd7 && (<Descriptions title="7首单转化结果" column={1} bordered>
                <Descriptions.Item label="强制链接">
                  <a href={`https://detail.tmall.com/item.htm?id=${itemId}&xxc=sdyh`} target="_blank">强制链接</a>
                  <Divider type="vertical" />
                  <Button onClick={() => this.handleCopy(`https://detail.tmall.com/item.htm?id=${itemId}&xxc=sdyh`)} icon="copy" type="primary" shape="round" size="small">复制强制链接</Button>
                </Descriptions.Item>
                <Descriptions.Item label="7首单口令">
                    {sd7.pwd}
                  <Divider type="vertical" />
                  <Button onClick={() => this.handleCopy(sd7.pwd)} icon="copy" type="primary" shape="round" size="small">复制7口令</Button>
                </Descriptions.Item>
                {!!sd7.template1 && <Descriptions.Item label="7文案1">
                  <textarea className={styles.template}>
                  {sd7.template1}
                  </textarea>
                  <Divider />
                  <Button onClick={() => this.handleCopy(sd7.template1)} icon="copy" type="primary" shape="round" size="small">复制7文案1</Button>
                </Descriptions.Item>}
              </Descriptions>)
            }
            </Col>
          </Row>
        </Card>
      </PageHeaderWrapper>
    );
  }
}

export default Form.create()(TableList);
