import {
  Card,
  Form,
  Button,
  Icon,
  message,
  Descriptions,
} from 'antd';
import React, { Component } from 'react';
import { PageHeaderWrapper } from '@ant-design/pro-layout';
import { connect } from 'dva';
import copy from 'copy-to-clipboard';
import FormCreator from '@/components/FormCreator';
import './style.less';

const FormItem = Form.Item;

class ParsePage extends Component {
  state = {
    pidOptions: this.props.pidEmums,
  };

  componentDidMount() {
    this.props.dispatch({
      type: 'tConfListModel/fetch',
      payload: {
        type: 4,
      },
      callback: () => {
        const pids = (this.props.pidEmums || []);
        this.setState({
          pidOptions: pids,
        });
      },
    });
  }

  componentWillUnmount() {
    this.props.dispatch({
      type: 'parseModel/reset',
    })
  }

  onSearch (value) {
    if (value) {
      this.setState({
        pidOptions: [
          {
            value,
            name: value,
          },
          ...this.props.pidEmums,
        ],
      })
    } else {
      this.setState({
        pidOptions: this.state.pidOptions,
      })
    }
  }

  handleSubmit = e => {
    const { dispatch, form } = this.props;
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        dispatch({
          type: 'parseModel/submit',
          payload: values,
        });
      } else {
        console.log(err);
      }
    });
  }

  handleCopy = text => {
    if (copy(text)) {
      message.success('复制成功！')
    } else {
      message.error('复制失败!')
    }
  }

  render() {
    const { fetching, submitting, form, result } = this.props;
    const modifyForm = {};
    const submitFormLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 10,
          offset: 7,
        },
      },
    };
    const configs = [
      {
        key: 'pid',
        name: 'PID',
        type: 'select',
        placeholder: '请输入PID',
        config: {
          rules: [
            {
              required: true,
              message: '请输入PID',
            },
          ],
        },
        props: {
          defaultActiveFirstOption: false,
          filterOption: false,
          showArrow: false,
          showSearch: true,
          notFoundContent: null,
          onSearch: this.onSearch,
        },
        options: this.state.pidOptions,
      },
      {
        key: 'pwd',
        name: '口令',
        config: {
          rules: [
            {
              required: true,
              message: '请输入口令',
            },
          ],
        },
      },
    ];
    const couponAmount = result && result.price_promotion_info.final_promotion_path_list ? result.price_promotion_info.final_promotion_path_list.final_promotion_path_map_data[0].promotion_fee : 0;

    const finalPrice = result ? (result.price_promotion_info.zk_final_price - couponAmount).toFixed(2) : 0;
    const rate = result ? result.publish_info.income_info.commission_rate / 10000 : 0;
    const commission = result ? (finalPrice * rate).toFixed(2) : 0;

    return (
      <PageHeaderWrapper>
        <Card bordered={false} loading={fetching}>
        <Form
          onSubmit={this.handleSubmit}
          style={{
            marginTop: 8,
          }}
        >
          <FormCreator form={ form } configs={configs} params={modifyForm}/>
            <FormItem
              {...submitFormLayout}
              style={{
                marginTop: 32,
              }}
            >
              <Button type="primary" htmlType="submit" loading={submitting}>
                提交
              </Button>
            </FormItem>
          </Form>
        </Card>
        {
          result
            ? (
              <Card bordered={false}>
                <Descriptions title="解析结果" column={1} bordered>
                  <Descriptions.Item label="商品ID">{result.item_id} <Button onClick={() => this.handleCopy(result.item_id)} icon="copy" type="primary" shape="round" size="small">一键复制</Button></Descriptions.Item>
                  <Descriptions.Item label="链接">
                    <a href={result.origin_url || result.url || result.click_url} target="_blank" style={{ width: '600px', display: 'block', wordBreak: 'break-all' }}>{result.origin_url || result.url || result.click_url}</a>
                  </Descriptions.Item>
                  <Descriptions.Item label="原价">{result.price_promotion_info.zk_final_price}</Descriptions.Item>
                  <Descriptions.Item label="券后价">{finalPrice}</Descriptions.Item>
                  <Descriptions.Item label="券面额">面值{couponAmount}</Descriptions.Item>
                  <Descriptions.Item label="佣金比例">{rate * 100}%</Descriptions.Item>
                  <Descriptions.Item label="预估佣金">{commission}元, 税后: {(commission * 0.9).toFixed(2)}</Descriptions.Item>
                  <Descriptions.Item label="标题">{result.item_basic_info.title}</Descriptions.Item>
                  <Descriptions.Item label="图片">
                    <img width="120" src={result.item_basic_info.pict_url} alt=""/>
                  </Descriptions.Item>
                </Descriptions>
              </Card>
            )
            :
            null
        }
      </PageHeaderWrapper>
    );
  }
}

export default Form.create()(
  connect(({ parseModel: { result }, tConfListModel: { configs }, loading }) => ({
    submitting: loading.effects['parseModel/submit'],
    result,
    pidEmums: configs.type4 || [],
  }))(ParsePage),
);
