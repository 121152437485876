import { Form, Button, Divider } from 'antd';
import React from 'react';
import FormCreator from '@/components/FormCreator';

const CreateForm = props => {
  const { handleQuery, form, pid } = props;

  const okHandle = () => {
    form.validateFields((err, fieldsValue) => {
      if (err) return;
      // form.resetFields();
      handleQuery(fieldsValue);
    });
  };

  const layout = {
    labelCol: {
      span: 5,
    },
    wrapperCol: {
      span: 15,
    },
  }

  const configs = [
    {
      key: 'pwd',
      name: '淘口令',
      config: {
        rules: [
          {
            required: true,
          },
        ],
      },
    },
    {
      key: 'pid',
      name: 'pid',
      type: 'select',
      props: {
        style: { width: '100%' },
      },
      config: {
        rules: [
          {
            required: true,
            message: '请选择pid',
          },
        ],
      },
      options: pid,
    },
  ];

  return (
    <div>
      <FormCreator form={ form } configs={configs} layout={layout} />
      <Button icon="plus" type="primary" onClick={okHandle}>
        查询
      </Button>
      <Divider />
    </div>
  );
};

export default Form.create()(CreateForm);
