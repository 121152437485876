import { Form, Modal } from 'antd';
import React from 'react';
import FormCreator from '@/components/FormCreator';

const CreateBindForm = props => {
  const { modalVisible, handleBind, handleBindModalVisible, form, modfiyForm, mobiles = [], handleSearch } = props;
  let timer = null;

  const okHandle = () => {
    form.validateFields((err, fieldsValue) => {
      if (err) return;
      form.resetFields();
      // 判断是更新还是新增
      handleBind(fieldsValue);
    });
  };

  const onSearch = (val) => {
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }

    timer = setTimeout(() => {
      handleSearch(val)
    }, 300)
  }

  const layout = {
    labelCol: {
      span: 5,
    },
    wrapperCol: {
      span: 15,
    },
  }

  const configs = [
    {
      key: 'mobile_id',
      name: '选择手机',
      type: 'select',
      config: {
        rules: [
          {
            required: true,
            message: '请选择绑定的手机',
          },
        ],
      },
      props: {
        style: { width: '100%' },
        placeholder: '请输入手机编号进行搜索',
        showSearch: true,
        filterOption: false,
        onSearch,
        notFoundContent: null,
        showArrow: false
      },
      options: mobiles.map(mobile => ({
        value: mobile.id,
        name: `${mobile.brand}_${mobile.e_no}`,
      })),
    },
  ];

  return (
    <Modal
      destroyOnClose
      title="绑定到手机"
      visible={modalVisible}
      onOk={okHandle}
      onCancel={() => handleBindModalVisible()}
    >
      <FormCreator form={ form } configs={configs} params={modfiyForm} layout={layout} />
    </Modal>
  );
};

export default Form.create()(CreateBindForm);
