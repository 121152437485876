import React from 'react';
import QRCode from 'qrcode.react';
import style from './qr.less';

const CreateQr = props => {
  const {
    result,
    pid,
  } = props;
  const pwd = result.pwd;
  let url = `https://dldnj3.kuaizhan.com/?p=(${pwd})&pic=${encodeURIComponent(result.pict_url)}`;
  if (pid) {
    url += `&pid=${pid}`;
  }
  console.log(url);
  const afterPrice = (result.zk_final_price - (result.coupon_amount || 0)).toFixed(2);
  const imgUrl =  result.pict_url;
  return (
    <div className={style.createQr} id="createQr">
      <div className={style.imgWrap}>
        <img className={style.mainImage} src={imgUrl} alt=""/>
        <div className={style.tag}>{result.user_type ? '天猫' : '淘宝'}</div>
      </div>
      <div className={style.infoWrap}>
        <p className={style.title}>{result.title}</p>
        <div className={style.priceWrap}>
          <span className={style.newPrice}>
          <span className={style.prefix}>￥</span>{afterPrice}
          </span>
          {result.coupon_amount > 0 && (<span className={style.quanTag}>券后</span>)}
          {result.coupon_amount > 0 && (<span className={style.oldPrice}>原价<del>￥{result.zk_final_price}</del></span>)}
        </div>
        <div className={style.otherWrap}>
          <span
            className={style.box}
          >已售{result.volume}件</span>
          {result.coupon_amount > 0 && (<span
            className={[style.box, style.boxRed].join(' ')}
          >领券减{result.coupon_amount}元</span>)}
        </div>
        <div className={style.qrWrap}>
          <QRCode className={style.qr} value={url} />
          <p className={style.text}>长按识别二维码购买</p>
        </div>
      </div>
    </div>
  );
};

export default CreateQr;
