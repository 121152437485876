import {
  Card,
  Form,
  Button,
  Icon,
  Descriptions,
  message,
  Divider,
} from 'antd';
import React, { Component } from 'react';
import { PageHeaderWrapper } from '@ant-design/pro-layout';
import { connect } from 'dva';
import copy from 'copy-to-clipboard';
import FormCreator from '@/components/FormCreator';

import style from './style.less';

const FormItem = Form.Item;

class SendCard extends Component {
  componentDidMount() {
  }

  handleSubmit = e => {
    const { dispatch, form } = this.props;
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        dispatch({
          type: 'getPwdModel/submit',
          payload: values,
        });
      } else {
        console.log(err);
      }
    });
  };

  handleCopy (text) {
    if (copy(text)) {
      message.success('复制成功！')
    } else {
      message.error('复制失败')
    }
  }

  render() {
    const { fetching, submitting, form, result } = this.props;
    const modifyForm = {};
    const submitFormLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 10,
          offset: 7,
        },
      },
    };
    const configs = [
      {
        key: 'url',
        name: '链接',
        config: {
          rules: [
            {
              required: true,
              message: '请输入链接',
            },
          ]
        },
      },
      {
        key: 'text',
        name: '文案',
      },
    ];

    return (
      <PageHeaderWrapper>
        <Card bordered={false} loading={fetching}>
              
              <Form
                onSubmit={this.handleSubmit}
                style={{
                  marginTop: 8,
                }}
              >
            <FormCreator form={ form } configs={configs} params={modifyForm}/>
              <FormItem
                {...submitFormLayout}
                style={{
                  marginTop: 32,
                }}
              >
                <Button type="primary" htmlType="submit" loading={submitting}>
                  提交
                </Button>
              </FormItem>
            </Form>
          
          {
            result 
              ? (
                <Card bordered={false}>
                  <Descriptions title="结果" column={1} bordered>
                    <Descriptions.Item label="口令">
                      <a href={result} target="_blank" style={{width: '600px', display: 'block', wordBreak: 'break-all'}}>{result}</a>
                      <Divider/>
                      <Button onClick={() => this.handleCopy(result)} icon="copy" type="primary" shape="round" size="small">一键复制</Button>
                    </Descriptions.Item>
                  </Descriptions>
                </Card>
              )
              :
              null
          }
        </Card>
      </PageHeaderWrapper>
    );
  }
}

export default Form.create()(
  connect(({ getPwdModel: { result }, loading }) => ({
    submitting: loading.effects['getPwdModel/submit'],
    result,
  }))(SendCard),
);
