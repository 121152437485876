import {
  Button,
  Card,
  Form,
  Divider,
  Tag,
  Modal,
} from 'antd'
import React, { Component, Fragment } from 'react';
import { PageHeaderWrapper } from '@ant-design/pro-layout';
import { connect } from 'dva';
import moment from 'moment';
import StandardTable from '@/components/StandardTable';
import PowerAssert from '@/components/PowerAssert';
import CreateForm from './components/CreateForm';
import SearchForm from '@/components/SearchForm';

import styles from './style.less';

const getValue = obj =>
  Object.keys(obj)
    .map(key => obj[key])
    .join(',');

const getStatus = status => {
  const maps = {
    '0': <Tag color="#a3da1d">待审核</Tag>,
    '1': <Tag color="#17913a">通过</Tag>,
    '2': <Tag color="#f00">失败</Tag>,
  }
  return maps[status];
}

/* eslint react/no-multi-comp:0 */
@connect(({ wechatAdDetail: { data }, loading }) => ({
  data,
  page: data.page,
  size: data.size,
  count: data.count,
  loading: loading.models.wechatAdDetail,
}))
class TableList extends Component {
  state = {
    modalVisible: false,
    selectedRows: [],
    formValues: {},
    modfiyForm: {},
    visible: false,
    src: '',
  };

  columns = [
    {
      title: '活动',
      dataIndex: 'wechatAd.title',
      render: (val, rec) => <span>id:{rec.id}-{rec['wechatAd.title']}</span>
    },
    {
      title: '积分',
      dataIndex: 'score',
    },
    {
      title: '用户id',
      dataIndex: 'score_user_id',
      render: (val, rec) => <span>{val} - {rec['scoreUser.nickname']}({rec['scoreUser.remark']})</span>
    },
    {
      title: '图片',
      dataIndex: 'img',
      render: (val, record) => <a onClick={() => {
        this.handleShowImage(val)
      }} hrefs={'http://wechatad.xuanwonainiu.com/' + val} target="_blank"><img alt="图片" style={{ width: 200, height: 450 }} src={'http://wechatad.xuanwonainiu.com/' + val} /></a>,
    },
    {
      title: '状态',
      dataIndex: 'status',
      render: text => getStatus(text),
    },
    {
      title: '失败原因',
      dataIndex: 'remark',
    },
    {
      title: '外部状态',
      dataIndex: 'out_status',
      render: text => getStatus(text),
    },
    {
      title: '外部失败原因',
      dataIndex: 'out_remark',
    },
    {
      title: '时间',
      dataIndex: 'createdAt',
      render: (val, rec) => <div>传: {moment(val).format('YYYY-MM-DD HH:mm:ss')}<br />改: {moment(rec.updatedAt).format('YYYY-MM-DD HH:mm:ss')}</div>,
    },
    {
      title: '操作',
      render: (text, record) => (
        <Fragment>
            <PowerAssert code={['uconfig_query']}>
            {record.status === 0 || record.status === 2 ? (
              <Fragment>
                <a onClick={() => this.handlePass(record)}>通过</a>
              </Fragment>
            ) : ''}
            {record.status === 0 ? (
              <Divider />
            ) : ''}
            
            {record.status === 0 || record.status === 1 ? (
              <Fragment>
                <a onClick={() => this.handleUpdateModal(record)}>拒绝</a>
              </Fragment>
            ) : ''}
            </PowerAssert>
        </Fragment>
      ),
    },
  ];

  componentDidMount() {
    const { page, size } = this.props;
    this.fetchData({ page, size });
  }

  handleStandardTableChange = (pagination, filtersArg, sorter) => {
    const { formValues } = this.state;
    const filters = Object.keys(filtersArg).reduce((obj, key) => {
      const newObj = { ...obj };
      newObj[key] = getValue(filtersArg[key]);
      return newObj;
    }, {});
    const params = {
      ...formValues,
      ...filters,
    };

    if (sorter.field) {
      params.sorter = `${sorter.field}_${sorter.order}`;
    }
    this.handleSelectRows([]);

    this.fetchData({
      page: pagination.current,
      size: pagination.pageSize,
    }, params);
  };

  handleFormReset = () => {
    const { form, size } = this.props;
    form.resetFields();
    this.handleSelectRows([]);
    this.setState({
      formValues: {},
    });
    this.fetchData({ page: 0, size });
  };

  handleSelectRows = rows => {
    this.setState({
      selectedRows: rows,
    }, () => {
    });
  };

  handleSearch = values => {
    const { size } = this.props;
    // 更新state，这样子翻页什么的时候也能跟进
    this.handleSelectRows([]);
    this.setState({
      formValues: values,
    });
    this.fetchData({ page: 0, size }, values);
  };

  handleModalVisible = flag => {
    this.setState({
      modalVisible: !!flag,
    });
    if (!flag) {
      this.setState({
        modfiyForm: {},
      });
    }
  };

  closeModal = () => {
    this.setState({
      modalVisible: false,
      modfiyForm: {},
    });
  }
  
  handlePass = record => {
    const { dispatch, size, page } = this.props;
    dispatch({
      type: 'wechatAdDetail/update',
      payload: {
        id: record.id,
        status: 1,
      },
      callback: () => {
        this.fetchData({ page: page + 1, size }, {}, true);
      },
    });
  }

  handleUpdateModal = record => {
    this.setState({
      modfiyForm: record || {},
      modalVisible: true,
    });
  };

  handleUpdate = fields => {
    const { dispatch, size, page } = this.props;
    const { modfiyForm: { id } } = this.state;
    const remark = fields.remark === '其他' ? fields.reason : fields.remark;
    dispatch({
      type: 'wechatAdDetail/update',
      payload: {
        id,
        remark,
        status: 2,
      },
      callback: () => {
        this.fetchData({ page: page + 1, size }, {}, true);
      },
    });
    this.closeModal();
  };

  handleOk = () => {
    this.setState({
      src: '',
      visible: false,
    })
  }

  handleShowImage = src => {
    this.setState({
      src,
      visible: true,
    })
  }


  fetchData(pager = null, fieldValue = {}, saveParams) {
    const { page, size, dispatch, location: { query: { id } } } = this.props;

    let tempPager = {
      page,
      size,
    }
    tempPager = {
      ...tempPager,
      ...pager,
    }
    let params = {
      ...fieldValue,
      ...tempPager,
    }
    if (saveParams) {
      const { formValues } = this.state;
      params = {
        ...params,
        ...formValues,
      }
    }
    dispatch({
      type: 'wechatAdDetail/fetch',
      payload: {
        ...params,
        wechat_ad_id: id || null,
      },
    });
  }

  render() {
    const {
      data,
      page,
      count,
      size,
      loading,
    } = this.props;

    const searchKeys = [ {
      label: '会员id',
      type: 'input',
      key: 'score_user_id',
    },{
      label: '状态',
      type: 'select',
      key: 'status',
      options: [
        {
          key: 0,
          val: '待审核'
        },
        {
          key: 1,
          val: '通过'
        },
        {
          key: 2,
          val: '拒绝'
        }
      ],
    },{
      label: '外部审核',
      type: 'select',
      key: 'out_status',
      options: [
        {
          key: 0,
          val: '待审核'
        },
        {
          key: 1,
          val: '通过'
        },
        {
          key: 2,
          val: '拒绝'
        }
      ],
    }];

    const { selectedRows, modalVisible, modfiyForm } = this.state;
    const parentMethods = {
      handleUpdate: this.handleUpdate,
      handleModalVisible: this.handleModalVisible,
      closeModal: this.closeModal,
    };
    const pager = {
      page,
      size,
      count,
    }
    return (
      <PageHeaderWrapper>
        <Card bordered={false}>
          <div className={styles.tableList}>
             <SearchForm search={this.handleSearch} reset={this.handleFormReset} searchConfigs={searchKeys} />
            <div className={styles.tableListOperator}>
            </div>
            <StandardTable
              selectedRows={selectedRows}
              loading={loading}
              data={data}
              columns={this.columns}
              onSelectRow={this.handleSelectRows}
              onChange={this.handleStandardTableChange}
              pager={pager}
            />
          </div>
        </Card>
        <CreateForm  {...parentMethods} modfiyForm={modfiyForm} modalVisible={modalVisible} />
        <Modal
          title="上传图片"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleOk}
        >
          <img src={'http://wechatad.xuanwonainiu.com/' + this.state.src} alt="" width={500}/>
        </Modal>
      </PageHeaderWrapper>
    );
  }
}

export default Form.create()(TableList);
