import { Form, Button, Divider } from 'antd';
import React from 'react';
import FormCreator from '@/components/FormCreator';

const CreateForm = props => {
  const { handleAdd, form } = props;

  const okHandle = () => {
    form.validateFields((err, fieldsValue) => {
      if (err) return;
      form.resetFields();
      const list = fieldsValue.info.split('\n').map(r => r.replace(/\s/g, ''));

      const countMap = {};
      list.forEach(item => {
        const key = item.substr(13,6)
        if (countMap[key]) {
          countMap[key] += 1;
        } else {
          countMap[key] = 1;
        }
      })
      const params = {
        countMap,
        title: fieldsValue.title,
        every: fieldsValue.every,
        amount: fieldsValue.amount,
      }
      handleAdd(params);
    });
  };

  const layout = {
    labelCol: {
      span: 5,
    },
    wrapperCol: {
      span: 15,
    },
  }

  const configs = [
    {
      key: 'title',
      name: '订单标题',
    },
    {
      key: 'every',
      name: '每',
      type: 'number'
    },
    {
      key: 'amount',
      name: '几分',
      type: 'number'
    },
    {
      key: 'info',
      name: '订单信息',
      type: 'textArea',
      props: {
        rows: 4,
      },
      config: {
        rules: [
          {
            required: true,
            message: '请输入订单信息',
          },
        ],
      },
    },
  ];

  return (
    <div>
      <FormCreator form={form} configs={configs} layout={layout} />
      <Button icon="plus" type="primary" onClick={okHandle}>
        新增
      </Button>
    </div>
  );
};

export default Form.create()(CreateForm);
