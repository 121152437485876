import { query, add, add2, update, updateOrder, del, exportExcel, getTljStat } from './service';

const moment = require('moment');
import { sleep } from '@/utils/utils';
import html2canvas from 'html2canvas';

const Model = {
  namespace: 'tljModel2',
  state: {
    data: {
      list: [],
      page: 0,
      size: 20,
      count: 0,
    },
    prodCount: [],
    money: [],
    result: null,
    img: '',
    coupon: 0,
    imgCheck: 0,
    title: '',
  },
  effects: {
    *getStat({ }, { call, put }) {
      const res = yield call(getTljStat);
      const {
        data: { count, money },
      } = res;
      yield put({
        type: 'saveStat',
        payload: {
          prodCount: count,
          money,
        },
      });
    },
    *exportExcel({ payload, callback }, { call, put }) {
      const res = yield call(exportExcel, payload);
      yield put({
        type: 'global/handlerResponse',
        payload: {
          ...res,
        },
      });
      if (callback) callback();
    },
    *add({ payload, callback }, { call, put }) {
      const { forms } = payload;
      const res = yield call(add, forms);
      yield put({
        type: 'global/handlerResponse',
        payload: {
          ...res,
        },
      });
      if (callback) callback();
    },
    *add2({ payload, callback }, { call, put }) {
      const { forms } = payload;
      const res = yield call(add2, forms);
      yield put({
        type: 'global/handlerResponse',
        payload: {
          ...res,
        },
      });
      if (res.code === 1) { 
        yield put({
          type: 'saveStat',
          payload: {
            result: res.data,
          },
        });
        if(res.data.coupon_amount) {
          yield put({
            type: 'saveStat',
            payload: {
              coupon: res.data.coupon_amount,
              title: res.data.title,
            },
          });
        }
        document.documentElement.scrollTop = 0;
        document.documentElement.scrollLeft = 0;
        document.body.scrollTop = 0;
        document.body.scrollLeft = 0;
        // yield put({
        //   type: 'saveStat',
        //   payload: {
        //     coupon: res.data.coupon_amount || 0,
        //     title: res.data.title,
        //     img: res.data.pict_url,
        //   },
        // });
        yield sleep(300);
        const canvas = yield html2canvas(document.getElementById('createQr'),{
          allowTaint: true,
          useCORS: true,
          scrollX: 0,
          scrollY: 0
        });
        const _img = canvas.toDataURL();
        console.log(_img)
        yield put({
          type: 'saveStat',
          payload: {
            img: _img,
          },
        });
        if (callback) callback();
      }
      
    },
    *update({ payload, callback }, { call, put }) {
      const res = yield call(update, payload);
      yield put({
        type: 'global/handlerResponse',
        payload: {
          ...res,
        },
      });
      if (callback) callback();
    },
    *updateOrder({ payload, callback }, { call, put }) {
      const res = yield call(updateOrder, payload);
      yield put({
        type: 'global/handlerResponse',
        payload: {
          ...res,
        },
      });
      if (callback) callback();
    },
    *del({ payload, callback }, { call, put }) {
      const res = yield call(del, payload);
      yield put({
        type: 'global/handlerResponse',
        payload: {
          ...res,
        },
      });
      if (callback) callback();
    },
    *fetch({ payload }, { call, put }) {
      let tempPage = payload.page;
      if (tempPage > 0) {
        tempPage -= 1;
      }

      const res = yield call(query, {
        ...payload,
        page: tempPage,
      });
      const {
        code, data: { rows, page, size, count },
      } = res;
      if (code === 1) {
        yield put({
          type: 'save',
          payload: {
            list: rows,
            page,
            size,
            count,
          },
        });
      }
      yield put({
        type: 'global/handlerResponse',
        payload: {
          ...res,
          successNotice: false,
        },
      });
    },
  },
  reducers: {
    save(state, action) {
      return { ...state, data: action.payload };
    },
    saveStat(state, action) {
      console.log({ ...state, ...action.payload })
      return { ...state, ...action.payload };
    },
  },
};
export default Model;
