import { getRes } from './service';

const Model = {
  namespace: 'getActPwdModel',
  state: {
    result: null,
  },
  effects: {
    *submit({ payload }, { call, put }) {
      const res = yield call(getRes, payload);
      yield put({
        type: 'global/handlerResponse',
        payload: {
          ...res,
          successNotice: false
        },
      });
      if (res.code === 1) {
        yield put({
          type: 'save',
          payload: {
            result: res.data,
          },
        });
      }
    },
  },
  reducers: {
    save(state, action) {
      return { ...state, ...action.payload };
    },
  },
};
export default Model;
