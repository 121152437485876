import {
  Card,
  Form,
  Button,
  Icon,
} from 'antd';
import React, { Component } from 'react';
import { PageHeaderWrapper } from '@ant-design/pro-layout';
import { connect } from 'dva';
import FormCreator from '@/components/FormCreator';
import './style.less';

const FormItem = Form.Item;


class AliActDetail extends Component {

  componentDidMount() {
    const { location: { query: { id } } } = this.props;
    if (id) {
      this.fetchDetail(id)
    }
  }

  componentWillUnmount() {
    this.props.dispatch({
      type: 'aliActDetail/reset'
    })
  }

  fetchDetail(id) {
    const { dispatch } = this.props;
    dispatch({
      type: 'aliActDetail/fetchDetail',
      payload: {
        id
      }
    })
  }

  handleSubmit = e => {
    const { dispatch, form, location: { query: { id } } } = this.props;
    e.preventDefault();
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const { material_id, title, ...others } = values;
        const params = {
          material_id,
          title,
          content: JSON.stringify(others),
          id: id || void 0
        }
        dispatch({
          type: id ? 'aliActDetail/update' : 'aliActDetail/add',
          payload: params,
          callback: () => {
            setTimeout(() => {
              this.props.history.goBack()
            }, 2000)
          },
        });
      } else {
        console.log(err);
      }
    });
  };

  render() {
    const { fetching, submitting, form, detailInfo } = this.props;
    const modifyForm = detailInfo || {};
    const submitFormLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 10,
          offset: 7,
        },
      },
    };
    const configs = [
      {
        key: 'title',
        name: 'h5显示标题',
        config: {
          rules: [
            {
              required: true,
              message: '请输入h5显示标题',
            },
          ],
        },
      },
      {
        key: 'material_id',
        name: '活动id',
        placeholder: '活动id，从官方获取',
        config: {
          rules: [
            {
              required: true,
              message: '请输入活动id，从官方获取',
            },
          ],
        },
      },
      {
        key: 'banner',
        name: 'banner图',
        type: 'img',
      },
      {
        key: 'bgColor',
        name: '页面背景色',
        type: 'colorSelect',
      },
      {
        key: 'actTag',
        name: '活动标识图',
        type: 'img',
      },
      {
        key: 'showSalePrice',
        name: '大促预热显示',
        type: 'select',
        options: [{
          value: 0,
          name: '不显示',
        }, {
          value: 1,
          name: '显示',
        }],
      },
      {
        key: 'showPlayInfo',
        name: '显示优惠信息',
        type: 'select',
        options: [{
          value: 0,
          name: '不显示',
        }, {
          value: 1,
          name: '显示',
        }],
      },
      {
        key: 'priceText',
        name: '到手价文案',
        placeholder: '默认到手价',
      },
      {
        key: 'playTips',
        name: '玩法提示',
        placeholder: '0点秒杀的时候可以用，可以写开枪时间',
      },
      {
        key: 'textColor',
        name: '商品标题文字颜色',
        type: 'colorSelect',
      },
      {
        key: 'subColor',
        name: '辅助文字颜色',
        type: 'colorSelect',
      },
      {
        key: 'bgLinerGradientStartColor',
        name: '背景渐变色起始色',
        type: 'colorSelect',
      },
      {
        key: 'cardLinerGradientStartColor',
        name: '卡片渐变色起始色',
        type: 'colorSelect',
      },
      {
        key: 'cardLinerGradientEndColor',
        name: '卡片渐变色结束色',
        type: 'colorSelect',
      },
      {
        key: 'originalPosterImg',
        name: '海报图',
        type: 'img',
      },
      {
        key: 'shareText',
        name: '分享按钮文案',
      },
    ];

    return (
      <PageHeaderWrapper>
        <Card bordered={false} loading={fetching}>
        <Form
          onSubmit={this.handleSubmit}
          style={{
            marginTop: 8,
          }}
        >
          <FormCreator form={ form } configs={configs} params={modifyForm}/>
            <FormItem
              {...submitFormLayout}
              style={{
                marginTop: 32,
              }}
            >
              <Button type="primary" htmlType="submit" loading={submitting}>
                提交
              </Button>
            </FormItem>
          </Form>
        </Card>
      </PageHeaderWrapper>
    );
  }
}

export default Form.create()(
  connect(({ aliActDetail, loading }) => {
    return ({
      submitting: loading.effects['aliActDetail/add'],
      detailInfo: aliActDetail.detailInfo
    })
  })(AliActDetail),
);
