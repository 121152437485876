import {
  Button,
  Card,
  Form,
  Divider,
} from 'antd';
import React, { Component } from 'react';
import { PageHeaderWrapper } from '@ant-design/pro-layout';
import { connect } from 'dva';
import CreateForm from './components/CreateForm';


import './style.less';

/* eslint react/no-multi-comp:0 */
@connect(({ loading }) => ({
  loading: loading.models.outOrderModel,
}))
class TableList extends Component {
  state = {
  };

  handleAdd = fields => {
    const { dispatch } = this.props;
    dispatch({
      type: 'outOrderModel/add',
      payload: fields,
    });
  };

  render() {
    const {
      text,
      loading,
    } = this.props;
    const parentMethods = {
      handleAdd: this.handleAdd,
    };
    return (
      <PageHeaderWrapper>
        <Card bordered={false}>
          <CreateForm {...parentMethods} />
          <Divider />
        </Card>
      </PageHeaderWrapper>
    );
  }
}

export default Form.create()(TableList);
