import { get, update } from './service';

const Model = {
  namespace: 'timelineConfig',
  state: {
    config: {
      type: 0,
      start: 0,
      token: '',
      end: 24,
    },
  },
  effects: {
    *fetch({ payload }, { call, put }) {
      const res = yield call(get);
      const {
        code, data,
      } = res;
      if (code === 1) {
        yield put({
          type: 'save',
          payload: {
            type: data.type,
            start: data.start,
            end: data.end,
            token: data.token,
          },
        });
      }
      yield put({
        type: 'global/handlerResponse',
        payload: {
          ...res,
          successNotice: false,
        },
      });
    },

    *add({ payload, callback }, { call, put }) {
      const res = yield call(update, payload);
      yield put({
        type: 'global/handlerResponse',
        payload: {
          ...res,
        },
      });
      if (callback) callback();
    },
  },
  reducers: {
    save(state, action) {
      return { ...state, config: action.payload };
    },
  },
};
export default Model;
