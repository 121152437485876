import { Form, Modal } from 'antd';
import React from 'react';
import FormCreator from '@/components/FormCreator';

const CreateForm = props => {
  const { modalVisible, handleModalVisible, form, handleUpdate, modfiyForm } = props;

  const okHandle = () => {
    form.validateFields((err, fieldsValue) => {
      if (err) return;
      form.resetFields();
      // 判断是更新还是新增
      const fn = handleUpdate;
      fn(fieldsValue);
    });
  };

  const layout = {
    labelCol: {
      span: 5,
    },
    wrapperCol: {
      span: 15,
    },
  }

  const configs = [
    {
      key: 'out_remark',
      name: '拒绝原因',
      type: 'select',
      config: {
        rules: [
          {
            required: true,
            message: '请选择拒绝原因',
          },
        ],
      },
      props: {
        style: { width: '100%' },
      },
      options: [
        {
          value: '未收藏',
          name: '未收藏',
        },
        {
          value: '其他',
          name: '其他',
        },
      ],
    },
    {
      key: 'reason',
      name: '具体原因',
    },
  ];

  return (
    <Modal
      destroyOnClose
      title={'审核'}
      visible={modalVisible}
      onOk={okHandle}
      onCancel={() => handleModalVisible()}
    >
      <FormCreator form={ form } configs={configs} params={modfiyForm} layout={layout} />
    </Modal>
  );
};

export default Form.create()(CreateForm);
