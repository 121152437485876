import { Form, Button } from 'antd';
import React from 'react';
import FormCreator from '@/components/FormCreator';
import PowerAssert from '@/components/PowerAssert';

const CreateForm = props => {
  const { form, handleExport, modfiyForm = {
    keys: ['order_no', 'item_id', 'item_title', 'order_status'],
  } } = props;

  const okHandle = () => {
    form.validateFields((err, fieldsValue) => {
      if (err) return;
      handleExport(fieldsValue);
    });
  };

  const layout = {
    labelCol: {
      span: 2,
    },
    wrapperCol: {
      span: 20,
    },
  }

  const configs = [
    {
      key: 'keys',
      name: '导出字段',
      type: 'checkbox',
      options: [
        {
          value: 'order_no',
          label: '订单编号',
        },
        {
          value: 'order_status',
          label: '订单状态',
        },
        {
          value: 'item_id',
          label: '商品id',
        },
        {
          value: 'item_title',
          label: '商品标题',
        },
        {
          value: 'account_no',
          label: '阿里妈妈',
        },
        {
          value: 'pid',
          label: 'pid',
        },
        {
          value: 'alipay_total_price',
          label: '付款金额',
        },
        {
          value: 'effect_money',
          label: '效果预估',
        },
        {
          value: 'effect_get',
          label: '去税佣金',
        },
        {
          value: 'tk_create_time',
          label: '创建时间',
        },
        {
          value: 'tk_paid_time',
          label: '付款时间',
        },
        {
          value: 'item_num',
          label: '商品件数',
        },
        {
          value: 'adzone_name',
          label: '推广位名称',
        },
        {
          value: 'violation_type',
          label: '处罚原因',
        },
      ],
    },
  ];

  return (
    <PowerAssert code="aliorder_export">
      <FormCreator form={ form } configs={configs} params={modfiyForm} layout={layout} />
      <Button icon="plus" type="primary" onClick={okHandle}>
        导出
      </Button>
    </PowerAssert>
  );
};

export default Form.create()(CreateForm);
