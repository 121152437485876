import { query as getTConfig } from '@/services/tconfig';

const Model = {
  namespace: 'tConfListModel',
  state: {
    configs: {},
  },
  effects: {
    *fetch({ payload: { type }, callback }, { call, put }) {
      const res = yield call(getTConfig, type);
      const {
        code, data,
      } = res;
      if (code === 1) {
        console.log(res)
        yield put({
          type: 'save',
          payload: {
            type,
            data: data.data,
          },
        });
        callback && callback();
      }
    },
  },
  reducers: {
    save(state, action) {
      const key = action.payload.type;
      const val = eval(action.payload.data);
      const newModel = {
        [`type${key}`]: val,
      }
      return { ...state, configs: {
        ...state.configs,
        ...newModel,
      }};
    },
  },
};
export default Model;
