import {
  Button,
  Card,
  Dropdown,
  Form,
  Icon,
  Menu,
  Tag,
  Popconfirm,
  Divider, Tooltip, Popover,
} from 'antd'
import React, { Component, Fragment } from 'react';
import { PageHeaderWrapper } from '@ant-design/pro-layout';
import { connect } from 'dva';
import moment from 'moment';
import CreateForm from './components/CreateForm';
import CreateBindCardForm from './components/CreateBindCardForm';
import CreateBindWechatForm from './components/CreateBindWechatForm';
import StandardTable from '@/components/StandardTable';
import PowerAssert from '@/components/PowerAssert';
import SearchForm from '@/components/SearchForm';

import styles from './style.less';

const getValue = obj =>
  Object.keys(obj)
    .map(key => obj[key])
    .join(',');

const checkIfSameTel = (val, record) => {
  const { tels, wechats } = record;
  if (!tels || !wechats) return <Tag color="#f5222d">否</Tag>
  let same = false;
  tels.forEach(tel => {
    wechats.forEach(wechat => {
      if (tel.tel_no === wechat.reg_tel) {
        same = true;
      }
    })
  });
  return same ? <Tag color="#17913a">是</Tag> : <Tag color="#f5222d">否</Tag>;
}

/* eslint react/no-multi-comp:0 */
@connect(({ mobileAndList: { data, config }, loading, mobileCardAndList, wechatAndList }) => ({
  data,
  page: data.page,
  size: data.size,
  count: data.count,
  loading: loading.models.mobileAndList,
  brand: config.brand,
  mobileCards: mobileCardAndList.data.list,
  wechats: wechatAndList.data.list,
}))
class TableList extends Component {
  state = {
    modalVisible: false,
    selectedRows: [],
    formValues: {},
    modfiyForm: {},
    mobileCardBindModalVisible: false,
    wechatBindModalVisible: false,
    bindTarget: {},
  };

  columns = [
    {
      title: 'id',
      dataIndex: 'id',
    },
    {
      title: '品牌',
      dataIndex: 'brand',
    },
    {
      title: '设备编号',
      dataIndex: 'e_no',
    },
    {
      title: '手机卡',
      dataIndex: 'tels',
      render: (val, row) => val.map(tel => (
        <div style={{ whiteSpace: 'nowrap' }}>
          <Popover content={this.renderTelPop(row, tel)}>
            {tel.tel_no} {tel.tel_type}
          </Popover>
          <Popconfirm
            title="确定解绑？"
            onConfirm={() => this.unBind('mobileCard', tel.id)}
          >
            <Tooltip title="解绑">
              <Icon type="disconnect" style={{ color: 'red', fontSize: '16px', fontWeight: 'bold', marginLeft: '5px' }} />
            </Tooltip>
          </Popconfirm>
        </div>
      )),
    },
    {
      title: '微信',
      dataIndex: 'wechats',
      render: val => val.map(wechat => (
        <div style={{ whiteSpace: 'nowrap' }}>
          <Popover content={this.renderWechatPop(wechat)}>
            {wechat.reg_tel}
          </Popover>
          <Popconfirm
            title="确定解绑？"
            onConfirm={() => this.unBind('wechat', wechat.id)}
          >
            <Tooltip title="解绑">
              <Icon type="disconnect" style={{ color: 'red', fontSize: '16px', fontWeight: 'bold', marginLeft: '5px' }} />
            </Tooltip>
          </Popconfirm>
        </div>),
      ),
    },
    {
      title: '备注',
      dataIndex: 'remark',
      width: 120,
    },
    {
      title: '同号',
      dataIndex: 'remark',
      render: checkIfSameTel,
    },
    {
      title: '操作',
      render: (text, record) => (
          <PowerAssert code={['mobile_edit', 'mobile_all']}>
            <a onClick={() => this.handleUpdateModal(record)}>编辑</a>

            <br/>
            <a onClick={() => this.handleBindModal('mobileCard', record)}>绑卡</a>
            <br/>
            <a onClick={() => this.handleBindModal('wechat', record)}>绑微信</a>
          </PowerAssert>
      ),
    },
  ];

  componentDidMount() {
    const { page, size } = this.props;
    this.fetchData({ page, size });
    this.fetchAsset()
  }

  renderTelPop (mobile, tel) {
    return (
      <div>
        <p>手机品牌：{mobile.brand}</p>
        <p>号码：{tel.tel_no}</p>
        <p>运营商：{tel.tel_type}</p>
        <p>充值类型：{tel.incharge_type}</p>
        <p>备注：{tel.remark || '--'}</p>
      </div>
    )
  }

  renderWechatPop (wechat) {
    return (
      <div>
        <p>昵称：{wechat.nick_name}</p>
        <p>微信号：{wechat.user_name}</p>
        <p>状态：{wechat.status}</p>
        <p>是否实名：{wechat.is_auth ? '已实名' : '未实名'}</p>
        <p>好友人数：{wechat.count}</p>
        <p>使用人：{wechat.owner}</p>
        <p>备注：{wechat.remark || '--'}</p>
      </div>
    )
  }

  handleStandardTableChange = (pagination, filtersArg, sorter) => {
    const { formValues } = this.state;
    const filters = Object.keys(filtersArg).reduce((obj, key) => {
      const newObj = { ...obj };
      newObj[key] = getValue(filtersArg[key]);
      return newObj;
    }, {});
    const params = {
      ...formValues,
      ...filters,
    };

    if (sorter.field) {
      params.sorter = `${sorter.field}_${sorter.order}`;
    }
    this.handleSelectRows([]);

    this.fetchData({
      page: pagination.current,
      size: pagination.pageSize,
    }, params);
  };

  handleFormReset = () => {
    const { form, size } = this.props;
    form.resetFields();
    this.handleSelectRows([]);
    this.setState({
      formValues: {},
    });
    this.fetchData({ page: 0, size });
  };

  handleMenuClick = e => {
    const { dispatch, size } = this.props;
    const { selectedRows } = this.state;
    if (!selectedRows) return;

    switch (e.key) {
      case 'remove':
        dispatch({
          type: 'mobileAndList/remove',
          payload: {
            ids: selectedRows.map(row => row.id),
          },
          callback: () => {
            this.handleSelectRows([]);
            this.fetchData({ page: 0, size });
          },
        });
        break;

      default:
        break;
    }
  };

  handleRemove = row => {
    const { dispatch, size } = this.props;
    dispatch({
      type: 'mobileAndList/remove',
      payload: {
        ids: [row.id],
      },
      callback: () => {
        this.fetchData({ page: 0, size });
        this.fetchMobileCards({ page: 0, size: 500 })
        this.fetchWechat({ page: 0, size: 500 })
      },
    });
  };

  handleSelectRows = rows => {
    this.setState({
      selectedRows: rows,
    }, () => {
    });
  };

  handleSearch = values => {
    const { size } = this.props;
    // 更新state，这样子翻页什么的时候也能跟进
    this.handleSelectRows([]);
    this.setState({
      formValues: values,
    });
    this.fetchData({ page: 0, size }, values);
  };

  handleModalVisible = flag => {
    this.setState({
      modalVisible: !!flag,
    });
  };

  closeModal = () => {
    this.setState({
      modalVisible: false,
      modfiyForm: {},
    });
  }

  handleUpdateModal = record => {
    this.setState({
      modfiyForm: record || {},
      modalVisible: true,
    });
  };

  handleBind = (type, fields) => {
    const { dispatch, size } = this.props;
    const { bindTarget: { id } } = this.state;
    dispatch({
      type: `${type}AndList/bind`,
      payload: {
        id: fields.id,
        mobile_id: id,
      },
      callback: () => {
        this.fetchData({ page: 0, size }, {}, 1);
        this.fetchMobileCards({ page: 0, size: 100 })
        this.fetchWechat({ page: 0, size: 100 })
      },
    });
    this.handleBindModalVisible(type);
  }

  handleBindModalVisible = (type, flag) => {
    this.setState({
      mobileCardBindModalVisible: type === 'mobileCard' && !!flag,
      wechatBindModalVisible: type === 'wechat' && !!flag,
    })
  }

  handleBindModal = (type, record) => {
    type === 'mobileCard' ? this.fetchMobileCards({ page: 0, size: 100 }) : this.fetchWechat({ page: 0, size: 100 })
    this.setState({
      bindTarget: record || {},
      mobileCardBindModalVisible: type === 'mobileCard',
      wechatBindModalVisible: type === 'wechat',
    })
  }

  unBind = (type, id) => {
    const { dispatch, size } = this.props;
    dispatch({
      type: `${type}AndList/unbind`,
      payload: id,
      callback: () => {
        this.fetchData({ page: 0, size }, {}, 1)
        this.fetchMobileCards({ page: 0, size: 100 })
        this.fetchWechat({ page: 0, size: 100 })
      },
    })
  }

  handleAdd = fields => {
    const { dispatch, size } = this.props;
    dispatch({
      type: 'mobileAndList/add',
      payload: {
        forms: fields,
      },
      callback: () => {
        this.fetchData({ page: 0, size });
      },
    });
    this.handleModalVisible();
  };

  handleUpdate = fields => {
    const { dispatch, size } = this.props;
    const { modfiyForm: { id } } = this.state;
    dispatch({
      type: 'mobileAndList/update',
      payload: {
        id,
        ...fields,
      },
      callback: () => {
        this.fetchData({ page: 0, size });
      },
    });
    this.closeModal();
  };


  fetchData(pager = null, fieldValue = {}, saveParams) {
    const { page, size, dispatch } = this.props;
    let tempPager = {
      page,
      size,
    }
    tempPager = {
      ...tempPager,
      ...pager,
    }
    let params = {
      ...fieldValue,
      ...tempPager,
    }
    if (saveParams) {
      const { formValues } = this.state;
      params = {
        ...params,
        ...formValues,
      }
    }
    dispatch({
      type: 'mobileAndList/fetch',
      payload: params,
    });
  }

  fetchAsset () {
    this.props.dispatch({
      type: 'mobileAndList/fetchAsset',
    });
  }

  fetchMobileCards (payload) {
    this.props.dispatch({
      type: 'mobileCardAndList/fetch',
      payload,
    })
  }

  fetchWechat (payload) {
    this.props.dispatch({
      type: 'wechatAndList/fetch',
      payload,
    })
  }

  handleCardSearch = val => {
    if (val) {
      this.fetchMobileCards({ page: 0, size: 100, tel_no: val })
    } else {
      this.fetchMobileCards({ page: 0, size: 100 })
    }
  }

  handleWechatSearch = val => {
    if (val) {
      this.fetchWechat({ page: 0, size: 100, user_name: val })
    } else {
      this.fetchWechat({ page: 0, size: 100 })
    }
  }

  render() {
    const {
      data,
      page,
      count,
      size,
      loading,
      brand,
      mobileCards,
      wechats,
    } = this.props;

    const searchKeys = [{
      label: '品牌',
      type: 'select',
      key: 'brand',
      options: brand.map(item => ({
        key: item,
        val: item,
      })),
    }, {
      label: '设备编号',
      type: 'input',
      key: 'e_no',
    }, {
      label: '备注',
      type: 'input',
      key: 'remark',
    }];

    const { selectedRows, modalVisible, modfiyForm, bindTarget, mobileCardBindModalVisible, wechatBindModalVisible } = this.state;
    const parentMethods = {
      handleAdd: this.handleAdd,
      handleUpdate: this.handleUpdate,
      handleModalVisible: this.handleModalVisible,
      closeModal: this.closeModal,
    };
    const bindModalMethods = {
      handleBind: this.handleBind,
      handleBindModalVisible: this.handleBindModalVisible,
      handleCardSearch: this.handleCardSearch,
      handleWechatSearch: this.handleWechatSearch,
    }
    const pager = {
      page,
      size,
      count,
    }
    return (
      <PageHeaderWrapper>
        <Card bordered={false}>
          <div className={styles.tableList}>
            <SearchForm search={this.handleSearch} reset={this.handleFormReset} searchConfigs={searchKeys} />
            <div className={styles.tableListOperator}>
             <PowerAssert code={['mobile_add', 'mobile_all']}>
                <Button icon="plus" type="primary" onClick={() => this.handleModalVisible(true)}>
                  新增手机
                </Button>
              </PowerAssert>
            </div>
            <StandardTable
              selectedRows={selectedRows}
              loading={loading}
              data={data}
              columns={this.columns}
              onSelectRow={this.handleSelectRows}
              onChange={this.handleStandardTableChange}
              pager={pager}
            />
          </div>
        </Card>
        <CreateForm brand={brand} {...parentMethods} modfiyForm={modfiyForm} modalVisible={modalVisible} />
        <CreateBindCardForm mobileCards={mobileCards} {...bindModalMethods} modifyForm={bindTarget} modalVisible={mobileCardBindModalVisible}/>
        <CreateBindWechatForm wechats={wechats} {...bindModalMethods} modifyForm={bindTarget} modalVisible={wechatBindModalVisible}/>
      </PageHeaderWrapper>
    );
  }
}

export default Form.create()(TableList);
