import request from '@/utils/request';

export async function query(params) {
  return request('/api/wechat', {
    params,
  });
}

export async function add(data) {
  return request('/api/wechat', {
    method: 'POST',
    data
  });
}

export async function update ({id, ...data}) {
  return request(`/api/wechat/${id}`, {
    method: 'PUT',
    data
  });
}

export async function getAsset() {
  return request(`/api/asset`)
}

export async function bind ({id, mobile_id}) {
  return request(`/api/wechat/${id}`, {
    method: 'PUT',
    data: {
      mobile_id
    }
  })
}

export async function unbind (id) {
  return request(`/api/wechat/${id}`, {
    method: 'PUT',
    data: {
      mobile_id: null
    }
  })
}
