import React, { Component } from 'react'
import { Card } from 'antd'
import { connect } from 'dva';
import Echart from '@/components/Echart'

@connect(({ fentuStat: { chartOption } }) => ({
  chartOption,
}))
class DataChart extends Component {
  render() {
    const chartOption = this.props.chartOption;
    const chartStyle = {
      margin: '20px auto',
      height: 400
    };
    return (
      <Card>
        <Echart option={chartOption} style={chartStyle}/>
      </Card>
    )
  }
}

export default DataChart
