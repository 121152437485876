import {
  Button,
  Card,
  Dropdown,
  Form,
  Icon,
  Menu,
  Tag,
  Popconfirm,
  Divider, Tooltip,
} from 'antd'
import React, { Component, Fragment } from 'react';
import { PageHeaderWrapper } from '@ant-design/pro-layout';
import { connect } from 'dva';
import moment from 'moment';
import CreateForm from './components/CreateForm';
import UpdateScore from './components/UpdateScore';
import CreateKeyForm from './components/CreateKeyForm';
import StandardTable from '@/components/StandardTable';
import PowerAssert from '@/components/PowerAssert';
import SearchForm from '@/components/SearchForm';

import styles from './style.less';

const getValue = obj =>
  Object.keys(obj)
    .map(key => obj[key])
    .join(',');

/* eslint react/no-multi-comp:0 */
@connect(({ scoreVipModel: { data }, loading, scoreEventModel }) => ({
    data,
    page: data.page,
    size: data.size,
    count: data.count,
    loading: loading.models.scoreVipModel,
    events: scoreEventModel.data.list,
    addKeyModalVisible: false,
  }))
class TableList extends Component {
  state = {
    modalVisible: false,
    selectedRows: [],
    formValues: {},
    modfiyForm: {},
    exportModalVisible: false,
    scoreModalVisible: false,
  };

  columns = [
    {
      title: '会员id',
      dataIndex: 'score_user_id',
    },
    {
      title: '昵称',
      dataIndex: 'nickname',
      render: (val, rec) => <span>{rec.scoreUser.nickname}</span>
    },
    {
      title: '头像',
      dataIndex: 'headimgurl',
      width: 70,
      render: (val, rec) =><span> <img alt="图片" style={{ width: 60, height: 60 }} src={rec.scoreUser.headimgurl} /></span>,
    },
    {
      title: '昵称',
      dataIndex: 'official',
      render: (val, rec) => <span>{val ? '正式' : '预备'}</span>
    },
    {
      title: '普通积分',
      dataIndex: 'score',
      render: (val, rec) => <span>{val}/{rec.total_score}</span>
    },
    // {
    //   title: '限制积分',
    //   dataIndex: 'specail_score',
    //   render: (val, rec) => <span>{val}/{rec.total_special_score}</span>
    // },
    {
      title: '最后访问时间',
      dataIndex: 'last_visit_time',
      render: (val, rec) => <span>{moment(rec.scoreUser.last_visit_time).format('YYYY-MM-DD HH:mm:ss')}</span>,
    },
    {
      title: '最早vip时间',
      dataIndex: 'createdAt',
      render: val => <span>{moment(val).format('YYYY-MM-DD HH:mm:ss')}</span>,
    },
    {
      title: '老会员',
      dataIndex: 'scoreUser',
      render(val, rec) {
        return <span>{val && val.uconfig ? val.uconfig.username : '未关联'}</span>;
      },
    },
    {
      title: '订单尾号',
      dataIndex: 'ukeys',
      render: (val, rec) => <span className={styles.keys}>{rec.scoreUser.ukeys.map(v => {
        if (v.duplicate) return <span className={styles.duplicate}>{v.key},</span>
        return <span>{v.key},</span>
      })}</span>
    },
    {
      title: '操作',
      render: (text, record) => (
        <Fragment>
          <a onClick={() => this.handleKeyModal(record)}>新增尾号</a>
            <Divider type="vertical" />
          {
            !!record.status && (
            <PowerAssert code="uconfig_query">
            <a onClick={() => this.handleUpdateModal(record)}>积分</a>
          </PowerAssert>
            )
          }
          <Divider type="vertical"/>
          {
            !record.status && (
              <PowerAssert code="uconfig_query">
                <Popconfirm
                title="确定升级为vip？"
                onConfirm={() => this.handleVip(record.score_user_id, 1)}
              ><a>升vip</a></Popconfirm>
              </PowerAssert>
            )
          }
          {
            !!record.status && (
              <PowerAssert code="uconfig_query">
                <Popconfirm
                title="确定降级为平民？"
                onConfirm={() => this.handleVip(record.score_user_id, 0)}
              ><a>变平民</a></Popconfirm>
              </PowerAssert>
            )
          }
        </Fragment>
      ),
    },
  ];

  componentDidMount() {
    const { page, size } = this.props;
    this.fetchEvent();
    this.fetchData({ page, size });
  }

  handleStandardTableChange = (pagination, filtersArg, sorter) => {
    const { formValues } = this.state;
    const filters = Object.keys(filtersArg).reduce((obj, key) => {
      const newObj = { ...obj };
      newObj[key] = getValue(filtersArg[key]);
      return newObj;
    }, {});
    const params = {
      ...formValues,
      ...filters,
    };

    if (sorter.field) {
      params.sorter = `${sorter.field}_${sorter.order}`;
    }
    this.handleSelectRows([]);

    this.fetchData({
      page: pagination.current,
      size: pagination.pageSize,
    }, params);
  };

  handleFormReset = () => {
    const { form, size } = this.props;
    form.resetFields();
    this.handleSelectRows([]);
    this.setState({
      formValues: {},
    });
    this.fetchData({ page: 0, size });
  };

  handleSelectRows = rows => {
    this.setState({
      selectedRows: rows,
    }, () => {
    });
  };

  handleSearch = values => {
    const { size } = this.props;
    // 更新state，这样子翻页什么的时候也能跟进
    this.handleSelectRows([]);
    this.setState({
      formValues: values,
    });
    this.fetchData({ page: 0, size }, values);
  };

  handleModalVisible = flag => {
    this.setState({
      modalVisible: !!flag,
    });
  };
  handleKeyModal = record => {
    this.setState({
      modfiyForm: record || {},
      addKeyModalVisible: true,
    });
  };

  closeModal = () => {
    this.setState({
      modalVisible: false,
      exportModalVisible: false,
      scoreModalVisible:  false,
      addKeyModalVisible: false,
      modfiyForm: {},
    });
  }

  handleUpdateModal = record => {
    this.setState({
      modfiyForm: record || {},
      modalVisible: true,
    });
  };

  handleUpdateScoreModal = record => {
    this.setState({
      modfiyForm: record || {},
      scoreModalVisible: true,
    });
  }

  handleAdd = fields => {
    const { formValues } = this.state;
    const { dispatch, size, page } = this.props;
    const params = {
      vip: this.state.modfiyForm,
      form: fields,
    }
    if (params.form.active_date) {
      params.form.active_date = params.form.active_date.format('YYYY-MM-DD HH:mm:ss');
    }
    dispatch({
      type: 'scoreFlowModel/add',
      payload: params,
      callback: () => {
        this.fetchData({ page: page + 1, size }, formValues);
      },
    });
    this.closeModal();
    
  };

  handleUpdate = fields => {
    const { formValues } = this.state;
    const { dispatch, size, page } = this.props;
    const { modfiyForm: { id } } = this.state;
    dispatch({
      type: 'scoreVipModel/update',
      payload: {
        id,
        ...fields,
      },
      callback: () => {
        this.fetchData({ page: page + 1, size }, formValues);
      },
    });
    this.closeModal();
  };

  handleScore = fields => {
    const { dispatch, size } = this.props;
    const { modfiyForm: { score_user_id } } = this.state;
    dispatch({
      type: 'scoreVipModel/updateScore',
      payload: {
        score_user_id,
        ...fields,
      },
      callback: () => {
      },
    });
    this.closeModal();
  };

  handleVip = (id, flag) => {
    const { formValues } = this.state;
    const { dispatch, size, page } = this.props;
    dispatch({
      type: 'scoreVipModel/beVip',
      payload: {
        id,
        flag,
      },
      callback: () => {
        this.fetchData({ page: page + 1, size }, formValues);
      },
    });
  };

  handleAddKey = fields => {
    const { formValues } = this.state;
    const { dispatch, size, page } = this.props;
    dispatch({
      type: 'uKeyModel/add',
      payload: {
        forms: fields,
      },
      callback: () => {
        this.fetchData({ page: page + 1, size }, formValues);
      },
    });
    this.closeModal();
  };

  fetchEvent() {
    const { dispatch } = this.props;
    dispatch({
      type: 'scoreEventModel/fetch',
      payload: {
        page: 0,
        size: 100,
        status:1,
      },
    });
  }

  fetchData(pager = null, fieldValue = {}, saveParams) {
    const { page, size, dispatch } = this.props;
    let tempPager = {
      page,
      size,
    }
    tempPager = {
      ...tempPager,
      ...pager,
    }
    let params = {
      ...fieldValue,
      ...tempPager,
    }
    if (saveParams) {
      const { formValues } = this.state;
      params = {
        ...params,
        ...formValues,
      }
    }
    dispatch({
      type: 'scoreVipModel/fetch',
      payload: params,
    });
  }

  render() {
    const {
      data,
      page,
      count,
      size,
      loading,
      events,
    } = this.props;
    const searchKeys = [{
      label: '会员id',
      key: 'score_user_id',
    }, {
      label: '昵称',
      key: 'nickname',
    }, {
      label: '正式vip',
      key: 'official',
      type: 'select',
      options: [
        {
          key: 0,
          val: '预备',
        },
        {
          key: 1,
          val: '正式',
        },
      ],
    }];

    const { selectedRows, modalVisible, scoreModalVisible, addKeyModalVisible, modfiyForm } = this.state;
    const parentMethods = {
      handleAdd: this.handleAdd,
      closeModal: this.closeModal,
    };

    const updateScoreMethods = {
      handleUpdate: this.handleScore,
      closeModal: this.closeModal,
    };

    const keyMethods = {
      handleAdd: this.handleAddKey,
      closeModal: this.closeModal,
    };
    const pager = {
      page,
      size,
      count,
    }
    return (
      <PageHeaderWrapper>
        <Card bordered={false}>
          <div className={styles.tableList}>
            <SearchForm search={this.handleSearch} reset={this.handleFormReset} searchConfigs={searchKeys} />
            <div className={styles.tableListOperator}>
            </div>
            <StandardTable
              selectedRows={selectedRows}
              loading={loading}
              data={data}
              columns={this.columns}
              onSelectRow={this.handleSelectRows}
              onChange={this.handleStandardTableChange}
              pager={pager}
            />
          </div>
        </Card>
        <CreateKeyForm {...keyMethods} modfiyForm={modfiyForm} modalVisible={addKeyModalVisible}/>
        <CreateForm {...parentMethods}  modalVisible={modalVisible} events={events}/>
        <UpdateScore {...updateScoreMethods} modalVisible={scoreModalVisible} />
      </PageHeaderWrapper>
    );
  }
}

export default Form.create()(TableList);
