import { getPrivilege } from './service';
import { message } from 'antd';
import html2canvas from 'html2canvas';
import { sleep } from '@/utils/utils';

const Model = {
  namespace: 'privilegeModel',
  state: {
    result: null,
    img: '',
    coupon: 0,
    imgCheck: 0,
    title: '',
  },
  effects: {
    *submit({ payload }, { call, put }) {
      yield put({
        type: 'save',
        payload: {
          result: null,
          coupon: 0,
          img: '',
          imgCheck: 0,
          title: '',
        },
      });
      const res = yield call(getPrivilege, payload);
      yield put({
        type: 'global/handlerResponse',
        payload: {
          ...res,
          successNotice: false
        },
      });
      if (res.code === 1) {
        if (res.data.sub_msg || res.data.message) {
          message.error(res.data.sub_msg || res.data.message);
        } else {
          message.success('操作成功');
          yield put({
            type: 'save',
            payload: {
              result: res.data,
            },
          });
          console.log('data',res.data)
          if (res.data.price_promotion_info) {
          const couponAmount = res && res.data.price_promotion_info.final_promotion_path_list ? res.data.price_promotion_info.final_promotion_path_list.final_promotion_path_map_data[0].promotion_fee : 0;

            yield put({
              type: 'save',
              payload: {
                coupon: couponAmount,
                title: res.data.item_basic_info.title,
              },
            });
          }
        }
      }
    },
    *reset({ callback}, { put }) {
      yield put({
        type: 'save',
        payload: {
          result: null,
          img: '',
          coupon: 0,
        },
      });
      if (callback) callback();
    },
    *createQr({ callback, payload: { coupon, imgCheck, title } }, { put }) {
      document.documentElement.scrollTop = 0;
      document.documentElement.scrollLeft = 0;
      document.body.scrollTop = 0;
      document.body.scrollLeft = 0;
      yield put({
        type: 'save',
        payload: {
          coupon: coupon || 0,
          imgCheck,
          title,
          img: '',
        },
      });
      yield sleep(300);
      const canvas = yield html2canvas(document.getElementById('createQr'),{
        allowTaint: true,
        useCORS: true,
        scrollX: 0,
        scrollY: 0
      });
      yield put({
        type: 'save',
        payload: {
          img: canvas.toDataURL(),
        },
      });
    }
  },
  reducers: {
    save(state, action) {
      return { ...state, ...action.payload };
    },
  },
};
export default Model;
