import { query } from './service';

const Model = {
  namespace: 'RatioModel',
  state: {
    data: {
      sd6: [],
      sd5: [],
      showSd5: true,
    },
    chartOption: {}
  },
  effects: {
    *setChart({ payload }, { call, put }) {
      yield put({
        type: 'save',
        payload: {
          showSd5: payload,
        },
      });
      yield put({
        type: 'setChartOptions',
        payload: {
          isSd5: payload,
        }
      })
    },
    *submit({ payload }, { call, put }) {
      yield put({
        type: 'save',
        payload: {
          sd5: [],
          sd6: [],
          showSd5: true,
        },
      });
      const params = {
        start: payload.searchTime[0].startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        end: payload.searchTime[1].endOf('day').format('YYYY-MM-DD HH:mm:ss'),
      };
      const res = yield call(query, params);
      const {
        code, data,
      } = res;
      if (code === 1) {
        yield put({
          type: 'save',
          payload: {
            sd5: data.sd5,
            sd6: data.sd6,
          },
        });
        yield put({
          type: 'setChartOptions',
          payload: {
            isSd5: 1,
          }
        })
      }
      yield put({
        type: 'global/handlerResponse',
        payload: {
          ...res,
          successNotice: false,
        },
      });
    },
  },
  reducers: {
    save(state, action) {
      const res = {
        ...state.data,
        ...action.payload,
      }
      return { ...state, data: res};
    },
    setChartOptions(state, action) {
      const { payload: { isSd5 } } = action
      const enumLegend = [
        {value: 'sumTotal', name: '创建数'},
        {value: 'sumWinNum', name: '领取数'},
        {value: 'sumUseNum', name: '核销数'},
        {value: '领取率', name: '领取率'},
        {value: '核销率', name: '核销率'},
        {value: '核销金额', name: '核销金额'},
      ];
      const list = isSd5 ? state.data.sd5 : state.data.sd6;
      const chartOption = {
        title: {
          show: true
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: enumLegend.map(i => i.name),
          selectedMode: 'multiple',
          selected: {
            '创建数': false,
            '领取数': false,
            '核销数': false,
            '领取率': true,
            '核销率': true,
            '核销金额': false,
          }
        },
        toolbox: {
          feature: {
            // saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: []
        },
        yAxis: {
          type: 'value'
        },
        series: []
      };
      chartOption.xAxis.data = list.map(i => i.日期)
      chartOption.series = enumLegend.map(i => {
        const item = {};
        item.name = i.name;
        item.type = 'line';
        item.show = true;
        item.itemStyle = {
          normal: {
            label: {
              show: true,
              position: 'top',
              textStyle: {
                color: 'black',
                fontSize: 12,
              }
            }
          }
        }
        item.data = list.map(j => {
          if (i.value === 'sumWinNum' || i.value === 'sumUseNum') {
            return j[i.value] *1;
          } else if (i.value === '领取率' || i.value === '核销率') {
            return (j[i.value] *100).toFixed(2);
          } else {
            return j[i.value];
          }
        })
        return item;
      })
      return {...state, chartOption}
    }
  },
};
export default Model;
