import {
  Card,
  Form,
  Tag,
  Divider,
} from 'antd';
import React, { Component, Fragment } from 'react';
import { PageHeaderWrapper } from '@ant-design/pro-layout';
import { connect } from 'dva';
import moment from 'moment';
import StandardTable from '@/components/StandardTable';
import SearchForm from '@/components/SearchForm';
import RowPopover from '@/components/RowPopover';
import BreakLine from '@/components/BreakLine';
import Export from './Export';

import styles from './style.less';

const getOrderStatus = status => {
  let el;
  switch (status) {
    case 'FINISH':
      el = <Tag color="#87d068">结算</Tag>;
      break;
    case 'PAID':
      el = <Tag color="#1890ff">付款</Tag>;
      break;
    case 'SUCCESS':
      el = <Tag color="#fa8c16">商家没钱</Tag>;
      break;
    case 'CANCEL':
      el = <Tag color="#f5222d">取消</Tag>;
      break;
    default:
      el = <Tag color="#333">未知</Tag>;
  }
  return el;
}

const getValue = obj =>
  Object.keys(obj)
    .map(key => obj[key])
    .join(',');

/* eslint react/no-multi-comp:0 */
@connect(({ aliPunishOrder: { data }, tConfListModel: { configs }, loading }) => ({
  data,
  page: data.page,
  size: data.size,
  count: data.count,
  pay: data.pay,
  effect: data.effect,
  own: data.own,
  sum_effect_get: data.sum_effect_get,
  loading: loading.models.aliOrder,
  configs,
}))
class TableList extends Component {
  state = {
    modalVisible: false,
    selectedRows: [],
    formValues: {},
    modfiyForm: {},
  };

  columns = [
    {
      title: '处罚原因',
      dataIndex: 'violation_type',
    },
    {
      title: '创建-付款-入库',
      dataIndex: 'tk_create_time',
      render: (val, rec) => <div>
        创 {moment(val).format('MM-DD HH:mm:ss')}
        <BreakLine />
        付 {moment(rec.tk_paid_time).format('MM-DD HH:mm:ss')}
        <BreakLine />
        入 {moment(rec.createdAt).format('MM-DD HH:mm:ss')}
        <BreakLine />
        结 {moment(rec.tk_earning_time).format('MM-DD HH:mm:ss')}
        </div>,
    },
    {
      title: '订单编号',
      dataIndex: 'order_no',
    },
    {
      title: '图片',
      dataIndex: 'item_img',
      width: 70,
      render: (val, record) => <a href={`http://detail.tmall.com/item.htm?id=${record.item_id}`} target="_blank" rel="noopener noreferrer"><img alt="商品图片" style={{ width: 60, height: 60 }} src={val} /></a>,
    },
    {
      title: '商品ID',
      dataIndex: 'item_id',
      render: (val, record) => <div>
        <a href={`http://detail.tmall.com/item.htm?id=${record.item_id}`} target="_blank" rel="noopener noreferrer">{val}</a>
      <BreakLine />
      <RowPopover text={record.item_title} length="25" />
      </div>,
    },
    {
      title: '付款金额',
      dataIndex: 'alipay_total_price',
      render: (val, record) => <div> {/** 定金: {record ? (record.deposit_price / 100).toFixed(2) : ''} <br /> **/}￥{(val / 100).toFixed(2)}
      <BreakLine />
      {getOrderStatus(record.order_status)}{record.deposit_price ? <Tag color="#333">预售</Tag> : ''} 
      <BreakLine />
      {record.order_type}
       </div>,
    },
    {
      title: '佣金比例',
      dataIndex: 'income_rate',
      sorter: true,
      render: (val, record) => <div>比例: {(record.income_rate / 100).toFixed(2)}% 
      <BreakLine />
      效果：{(record.effect_money / 100).toFixed(2)}
      <BreakLine />
      去税：{(record.effect_get / 100).toFixed(2)}
      <BreakLine />
      {Math.round(record.effect_get * 100 / record.effect_money)} %
      </div>,
    },
    {
      title: '推广类型',
      dataIndex: 'flow_source',
      render: (val, rec) => <div>
        {val}
        <BreakLine />
        {rec.account_no}
      </div>
    },
    {
      title: 'pid',
      dataIndex: 'pid',
      render: (val, record) => <span> {val}<br /> {record.site_name} <br /> {record.adzone_name}
       </span>,
    },
  ];

  componentDidMount() {
    const { size } = this.props;
    this.props.dispatch({
      type: 'tConfListModel/fetch',
      payload: {
        type: 3,
      },
    });
    this.props.dispatch({
      type: 'tConfListModel/fetch',
      payload: {
        type: 2,
      },
    });
    // this.fetchData({ page: 0, size });
  }

  handleStandardTableChange = (pagination, filtersArg, sorter) => {
    const { formValues } = this.state;
    const filters = Object.keys(filtersArg).reduce((obj, key) => {
      const newObj = { ...obj };
      newObj[key] = getValue(filtersArg[key]);
      return newObj;
    }, {});
    const params = {
      ...formValues,
      ...filters,
    };

    if (sorter.field && sorter.order) {
      params.sorter = `${sorter.field}_${sorter.order}`;
    }
    this.handleSelectRows([]);

    this.fetchData({
      page: pagination.current,
      size: pagination.pageSize,
    }, params);
  };

  handleFormReset = () => {
    const { form, size } = this.props;
    form.resetFields();
    this.handleSelectRows([]);
    this.setState({
      formValues: {},
    });
    this.fetchData({ page: 0, size });
  };

  handleSelectRows = rows => {
    this.setState({
      selectedRows: rows,
    }, () => {
    });
  };

  handleSearch = values => {
    const { size } = this.props;
    // 更新state，这样子翻页什么的时候也能跟进
    this.handleSelectRows([]);
    this.setState({
      formValues: values,
    });
    this.fetchData({ page: 0, size }, values);
  };

  handleModalVisible = flag => {
    this.setState({
      modalVisible: !!flag,
    });
  };

  handleUpdateModal = record => {
    this.setState({
      modfiyForm: record || {},
      modalVisible: true,
    });
  };

  handleExport = values => {
    const { dispatch } = this.props;
    const { formValues } = this.state;
    dispatch({
      type: 'aliPunishOrder/exportExcel',
      payload: {
        ...formValues,
        keys: values.keys.join(','),
      },
    });
  };

  fetchData(pager = null, fieldValue = {
    start: moment().startOf('day').format(),
    end: moment().endOf('day').format(),
  }) {
    const { page, size, dispatch } = this.props;
    let tempPager = {
      page,
      size,
    }
    tempPager = {
      ...tempPager,
      ...pager,
    }
    dispatch({
      type: 'aliPunishOrder/fetch',
      payload: {
        ...fieldValue,
        ...tempPager,
      },
    });
  }

  render() {
    const {
      data,
      page,
      count,
      pay,
      effect,
      own,
      sum_effect_get,
      size,
      loading,
      configs,
    } = this.props;
    const alimamas = configs.type2 || [];
    const bizNums = configs.type3 || [];
    const searchKeys = [{
      label: '联盟账号',
      type: 'select',
      key: 'account_no',
      options: alimamas.map(item => ({
        key: item,
        val: item,
      })),
    }, {
      label: '业务',
      type: 'select',
      key: 'biz',
      options: bizNums,
    }, {
      label: '订单号',
      key: 'order_no',
    }, {
      label: 'pid',
      key: 'pid',
    }, {
      label: '标题',
      key: 'item_title',
    }, {
      label: '商品ID',
      key: 'item_id',
    }, {
      label: '站点名',
      key: 'site_name',
    }, {
      label: '推广位名',
      key: 'adzone_name',
    }, {
      label: '卖家名称',
      key: 'seller_nick',
    }, {
      label: '店铺名',
      key: 'seller_shop_title',
    }, {
      label: '订单类型',
      key: 'order_type',
    }, {
      label: '订单状态',
      type: 'select',
      key: 'order_status',
      options: [
        {
          key: 'PAID',
          val: '付款',
        },
        {
          key: 'FINISH',
          val: '结算',
        },
        {
          key: 'CANCEL',
          val: '取消',
        },
        {
          key: 'SUCCESS',
          val: '商家欠款',
        }, {
          key: 'UNKNOWN',
          val: '未知',
        },
      ],
    }, {
      label: '订单时间',
      type: 'dateRanger',
      key: 'createTime',
      startKey: 'start',
      endKey: 'end',
      startOf: 'day',
      endOf: 'day',
    }, {
      label: '结算时间',
      type: 'dateRanger',
      key: 'settleTime',
      startKey: 'settleStart',
      endKey: 'settleEnd',
      startOf: 'day',
      endOf: 'day',
    }, {
      label: '原因',
      key: 'violation_type',
    }];

    const { selectedRows } = this.state;
    const pager = {
      page,
      size,
      count,
    }
    const selectStr = ` GMV： ${pay}, 效果预估：${effect}, 留存： ${own}, 去税： ${sum_effect_get}, `;
    return (
      <PageHeaderWrapper>
        <Card bordered={false}>
          <div className={styles.tableList}>
            <SearchForm expandForm search={this.handleSearch} reset={this.handleFormReset} searchConfigs={searchKeys} />
            <Divider />
            <Export handleExport = {this.handleExport} />
            <Divider />
            <StandardTable
              selectStr={selectStr}
              selectedRows={selectedRows}
              loading={loading}
              data={data}
              columns={this.columns}
              onSelectRow={this.handleSelectRows}
              onChange={this.handleStandardTableChange}
              pager={pager}
              scroll={{ x: '90vw' }}
            />
          </div>
        </Card>
      </PageHeaderWrapper>
    );
  }
}

export default Form.create()(TableList);
