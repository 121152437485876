import request from '@/utils/request';

export async function getByDay(params) {
  return request('/api/fentuByDay', {
    params,
  });
}

export async function getByHour(params) {
  return request('/api/fentuByHour', {
    params,
  });
}
