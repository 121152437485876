import {
  Button,
  Card,
  Dropdown,
  Form,
  Icon,
  Menu,
  Tag,
  Popconfirm,
  Divider, Tooltip,
} from 'antd'
import React, { Component, Fragment } from 'react';
import { PageHeaderWrapper } from '@ant-design/pro-layout';
import { connect } from 'dva';
import moment from 'moment';
import CreateForm from './components/CreateForm';
import StandardTable from '@/components/StandardTable';
import PowerAssert from '@/components/PowerAssert';
import SearchForm from '@/components/SearchForm';

import styles from './style.less';

const getValue = obj =>
  Object.keys(obj)
    .map(key => obj[key])
    .join(',');

/* eslint react/no-multi-comp:0 */
@connect(({ uKeyModel: { data }, loading }) => ({
    data,
    page: data.page,
    size: data.size,
    count: data.count,
    loading: loading.models.uKeyModel,
  }))
class TableList extends Component {
  state = {
    modalVisible: false,
    selectedRows: [],
    formValues: {},
    modfiyForm: {},
    exportModalVisible: false,
  };

  columns = [
    {
      title: 'id',
      dataIndex: 'id',
    },
    {
      title: '尾号',
      dataIndex: 'key',
    },
    {
      title: '是否重复',
      dataIndex: 'duplicate',
    },
    {
      title: '老用户id',
      dataIndex: 'uconfig_id',
    },
    {
      title: '新用户id',
      dataIndex: 'score_user_id',
    },
    {
      title: '旧昵称',
      dataIndex: 'uconfig',
      render: val => <span>{val ? val.username : ''}</span>
    },
    {
      title: '昵称',
      dataIndex: 'scoreUser',
      render: val => <span>{val ? val.nickname : ''}</span>
    },
    {
      title: '备注',
      dataIndex: 'r',
      render: (val, rec) => <span>{rec.scoreUser ? rec.scoreUser.remark :  ''}</span>
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      render: val => <span>{moment(val).format('YYYY-MM-DD HH:mm:ss')}</span>,
    },
    {
      title: '操作',
      render: (text, record) => (
        <Fragment>
          <PowerAssert code="uconfig_query">
            <a onClick={() => this.handleUpdateModal(record)}>编辑</a>
          </PowerAssert>
          <Divider type="vertical"/>
          <PowerAssert code="uconfig_query">
            <Popconfirm
            title="确定删除？"
            onConfirm={() => this.handleDel(record)}
          ><a>删除</a></Popconfirm>
          </PowerAssert>
        </Fragment>
      ),
    },
  ];

  componentDidMount() {
    const { page, size } = this.props;
    this.fetchData({ page, size });
  }

  handleStandardTableChange = (pagination, filtersArg, sorter) => {
    const { formValues } = this.state;
    const filters = Object.keys(filtersArg).reduce((obj, key) => {
      const newObj = { ...obj };
      newObj[key] = getValue(filtersArg[key]);
      return newObj;
    }, {});
    const params = {
      ...formValues,
      ...filters,
    };

    if (sorter.field) {
      params.sorter = `${sorter.field}_${sorter.order}`;
    }
    this.handleSelectRows([]);

    this.fetchData({
      page: pagination.current,
      size: pagination.pageSize,
    }, params);
  };

  handleFormReset = () => {
    const { form, size } = this.props;
    form.resetFields();
    this.handleSelectRows([]);
    this.setState({
      formValues: {},
    });
    this.fetchData({ page: 0, size });
  };

  handleSelectRows = rows => {
    this.setState({
      selectedRows: rows,
    }, () => {
    });
  };

  handleSearch = values => {
    const { size } = this.props;
    // 更新state，这样子翻页什么的时候也能跟进
    this.handleSelectRows([]);
    this.setState({
      formValues: values,
    });
    this.fetchData({ page: 0, size }, values);
  };

  handleModalVisible = flag => {
    this.setState({
      modalVisible: !!flag,
    });
  };

  handleExportModalVisible = flag => {
    this.setState({
      exportModalVisible: !!flag,
    });
  };

  closeModal = () => {
    this.setState({
      modalVisible: false,
      exportModalVisible: false,
      modfiyForm: {},
    });
  }

  handleUpdateModal = record => {
    this.setState({
      modfiyForm: record || {},
      modalVisible: true,
    });
  };

  handleExport = fields => {
    const { dispatch, size } = this.props;
    dispatch({
      type: 'uKeyModel/export',
      payload: {
        forms: fields,
      },
      callback: () => {
        this.fetchData({ page: 0, size });
      },
    });
    // this.handleExportModalVisible();
  }

  handleAdd = fields => {
    const { dispatch, size } = this.props;
    dispatch({
      type: 'uKeyModel/add',
      payload: {
        forms: fields,
      },
      callback: () => {
        this.fetchData({ page: 0, size });
      },
    });
    this.handleModalVisible();
  };

  handleDel = record => {
    const { dispatch, size } = this.props;
    dispatch({
      type: 'uKeyModel/del',
      payload: record.id,
      callback: () => {
        this.fetchData({ page: 0, size });
      },
    });
    this.handleModalVisible();
  };

  handleUpdate = fields => {
    const { dispatch, size } = this.props;
    const { modfiyForm: { id } } = this.state;
    dispatch({
      type: 'uKeyModel/update',
      payload: {
        id,
        ...fields,
      },
      callback: () => {
        this.fetchData({ page: 0, size });
      },
    });
    this.closeModal();
  };

  fetchData(pager = null, fieldValue = {}, saveParams) {
    const { page, size, dispatch } = this.props;
    let tempPager = {
      page,
      size,
    }
    tempPager = {
      ...tempPager,
      ...pager,
    }
    let params = {
      ...fieldValue,
      ...tempPager,
    }
    if (saveParams) {
      const { formValues } = this.state;
      params = {
        ...params,
        ...formValues,
      }
    }
    dispatch({
      type: 'uKeyModel/fetch',
      payload: params,
    });
  }

  render() {
    const {
      data,
      page,
      count,
      size,
      loading,
    } = this.props;

    const searchKeys = [{
      label: '老用户id',
      key: 'uconfig_id',
    }, {
      label: '新用户id',
      key: 'score_user_id',
    }, {
      label: '尾号',
      key: 'key',
    },
    {
      label: '重复',
      key: 'duplicate',
    }];

    const { selectedRows, modalVisible, modfiyForm, exportModalVisible } = this.state;
    const parentMethods = {
      handleAdd: this.handleAdd,
      handleUpdate: this.handleUpdate,
      closeModal: this.closeModal,
    };
    const exportMethods = {
      handleExport: this.handleExport,
      closeModal: this.closeModal,
    };
    const pager = {
      page,
      size,
      count,
    }
    return (
      <PageHeaderWrapper>
        <Card bordered={false}>
          <div className={styles.tableList}>
            <SearchForm search={this.handleSearch} reset={this.handleFormReset} searchConfigs={searchKeys} />
            <div className={styles.tableListOperator}>
              <PowerAssert code={['uconfig_query', 'uconfig_all']}>
                <Button icon="plus" type="primary" onClick={() => this.handleModalVisible(true)}>
                  新增
                </Button>
              </PowerAssert>
            </div>
            <StandardTable
              selectedRows={selectedRows}
              loading={loading}
              data={data}
              columns={this.columns}
              onSelectRow={this.handleSelectRows}
              onChange={this.handleStandardTableChange}
              pager={pager}
            />
          </div>
        </Card>
        <CreateForm {...parentMethods} modfiyForm={modfiyForm} modalVisible={modalVisible}/>
      </PageHeaderWrapper>
    );
  }
}

export default Form.create()(TableList);
