import { Form, Modal } from 'antd';
import React from 'react';
import FormCreator from '@/components/FormCreator';

const CreateForm = props => {
  const { modalVisible, handleAdd, closeModal, form, title } = props;

  const okHandle = () => {
    form.validateFields((err, fieldsValue) => {
      if (err) return;
      form.resetFields();
      handleAdd(fieldsValue);
    });
  };

  const layout = {
    labelCol: {
      span: 5,
    },
    wrapperCol: {
      span: 15,
    },
  }

  const configs = [
    {
      key: 'title',
      name: '标题',
    },
    {
      key: 'price',
      name: '价格',
    },
  ];

  return (
    <Modal
      destroyOnClose
      title={title}
      visible={modalVisible}
      onOk={okHandle}
      onCancel={() => closeModal()}
    >
      <FormCreator form={ form } configs={configs} params={{price: 0.1}} layout={layout} />
    </Modal>
  );
};

export default Form.create()(CreateForm);
