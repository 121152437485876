import dva from 'dva';
import { Component } from 'react';
import createLoading from 'dva-loading';
import history from '@tmp/history';

let app = null;

export function _onCreate() {
  const plugins = require('umi/_runtimePlugin');
  const runtimeDva = plugins.mergeConfig('dva');
  app = dva({
    history,
    
    ...(runtimeDva.config || {}),
    ...(window.g_useSSR ? { initialState: window.g_initialData } : {}),
  });
  
  app.use(createLoading());
  (runtimeDva.plugins || []).forEach(plugin => {
    app.use(plugin);
  });
  
  app.model({ namespace: 'global', ...(require('/Users/maat/Documents/code/xuanwo/front/xwadmin/src/models/global.js').default) });
app.model({ namespace: 'login', ...(require('/Users/maat/Documents/code/xuanwo/front/xwadmin/src/models/login.js').default) });
app.model({ namespace: 'setting', ...(require('/Users/maat/Documents/code/xuanwo/front/xwadmin/src/models/setting.js').default) });
app.model({ namespace: 'tconfig', ...(require('/Users/maat/Documents/code/xuanwo/front/xwadmin/src/models/tconfig.js').default) });
app.model({ namespace: 'user', ...(require('/Users/maat/Documents/code/xuanwo/front/xwadmin/src/models/user.js').default) });
app.model({ namespace: 'model', ...(require('/Users/maat/Documents/code/xuanwo/front/xwadmin/src/pages/Admin/Manage/model.js').default) });
app.model({ namespace: 'model', ...(require('/Users/maat/Documents/code/xuanwo/front/xwadmin/src/pages/Qiniu/List/model.js').default) });
app.model({ namespace: 'model', ...(require('/Users/maat/Documents/code/xuanwo/front/xwadmin/src/pages/UserPid/List/model.js').default) });
app.model({ namespace: 'model', ...(require('/Users/maat/Documents/code/xuanwo/front/xwadmin/src/pages/UserPromotion/List/model.js').default) });
app.model({ namespace: 'model', ...(require('/Users/maat/Documents/code/xuanwo/front/xwadmin/src/pages/WechatAdOut/model.js').default) });
app.model({ namespace: 'model', ...(require('/Users/maat/Documents/code/xuanwo/front/xwadmin/src/pages/WechatAdDetailOut/model.js').default) });
app.model({ namespace: 'model', ...(require('/Users/maat/Documents/code/xuanwo/front/xwadmin/src/pages/TimelineConfig/List/model.js').default) });
app.model({ namespace: 'model', ...(require('/Users/maat/Documents/code/xuanwo/front/xwadmin/src/pages/TimelinePlan/List/model.js').default) });
app.model({ namespace: 'model', ...(require('/Users/maat/Documents/code/xuanwo/front/xwadmin/src/pages/AuthTB/model.js').default) });
app.model({ namespace: 'model', ...(require('/Users/maat/Documents/code/xuanwo/front/xwadmin/src/pages/Fentu/model.js').default) });
app.model({ namespace: 'model', ...(require('/Users/maat/Documents/code/xuanwo/front/xwadmin/src/pages/AddCoupon/List/model.js').default) });
app.model({ namespace: 'model', ...(require('/Users/maat/Documents/code/xuanwo/front/xwadmin/src/pages/AddBlackProduct/List/model.js').default) });
app.model({ namespace: 'model', ...(require('/Users/maat/Documents/code/xuanwo/front/xwadmin/src/pages/CouponLog/List/model.js').default) });
app.model({ namespace: 'model', ...(require('/Users/maat/Documents/code/xuanwo/front/xwadmin/src/pages/Ukey/model.js').default) });
app.model({ namespace: 'model', ...(require('/Users/maat/Documents/code/xuanwo/front/xwadmin/src/pages/ScoreUser/model.js').default) });
app.model({ namespace: 'model', ...(require('/Users/maat/Documents/code/xuanwo/front/xwadmin/src/pages/ScoreVip/model.js').default) });
app.model({ namespace: 'model', ...(require('/Users/maat/Documents/code/xuanwo/front/xwadmin/src/pages/ScoreFlow/model.js').default) });
app.model({ namespace: 'model', ...(require('/Users/maat/Documents/code/xuanwo/front/xwadmin/src/pages/ScoreEvent/model.js').default) });
app.model({ namespace: 'model', ...(require('/Users/maat/Documents/code/xuanwo/front/xwadmin/src/pages/ScoreUserCount/model.js').default) });
app.model({ namespace: 'model', ...(require('/Users/maat/Documents/code/xuanwo/front/xwadmin/src/pages/CountOrder/model.js').default) });
app.model({ namespace: 'model', ...(require('/Users/maat/Documents/code/xuanwo/front/xwadmin/src/pages/ScoreOrder/model.js').default) });
app.model({ namespace: 'model', ...(require('/Users/maat/Documents/code/xuanwo/front/xwadmin/src/pages/JdOrder/List/model.js').default) });
app.model({ namespace: 'model', ...(require('/Users/maat/Documents/code/xuanwo/front/xwadmin/src/pages/AddOuterOrder/List/model.js').default) });
app.model({ namespace: 'model', ...(require('/Users/maat/Documents/code/xuanwo/front/xwadmin/src/pages/AddInnerOrder/List/model.js').default) });
app.model({ namespace: 'model', ...(require('/Users/maat/Documents/code/xuanwo/front/xwadmin/src/pages/WechatAd/model.js').default) });
app.model({ namespace: 'model', ...(require('/Users/maat/Documents/code/xuanwo/front/xwadmin/src/pages/WechatAdDetail/model.js').default) });
app.model({ namespace: 'model', ...(require('/Users/maat/Documents/code/xuanwo/front/xwadmin/src/pages/MiandanAct/model.js').default) });
app.model({ namespace: 'model', ...(require('/Users/maat/Documents/code/xuanwo/front/xwadmin/src/pages/MiandanOrder/model.js').default) });
app.model({ namespace: 'model', ...(require('/Users/maat/Documents/code/xuanwo/front/xwadmin/src/pages/Tlj/List/model.js').default) });
app.model({ namespace: 'model', ...(require('/Users/maat/Documents/code/xuanwo/front/xwadmin/src/pages/Tlj/Simple/model.js').default) });
app.model({ namespace: 'model', ...(require('/Users/maat/Documents/code/xuanwo/front/xwadmin/src/pages/Tlj/Simple7/model.js').default) });
app.model({ namespace: 'model', ...(require('/Users/maat/Documents/code/xuanwo/front/xwadmin/src/pages/SdRatio/model.js').default) });
app.model({ namespace: 'model', ...(require('/Users/maat/Documents/code/xuanwo/front/xwadmin/src/pages/ScoreItem/List/model.js').default) });
app.model({ namespace: 'model', ...(require('/Users/maat/Documents/code/xuanwo/front/xwadmin/src/pages/WechatAct/model.js').default) });
app.model({ namespace: 'model', ...(require('/Users/maat/Documents/code/xuanwo/front/xwadmin/src/pages/WechatLj/model.js').default) });
app.model({ namespace: 'model', ...(require('/Users/maat/Documents/code/xuanwo/front/xwadmin/src/pages/Lj/model.js').default) });
app.model({ namespace: 'model', ...(require('/Users/maat/Documents/code/xuanwo/front/xwadmin/src/pages/Yyg/List/model.js').default) });
app.model({ namespace: 'model', ...(require('/Users/maat/Documents/code/xuanwo/front/xwadmin/src/pages/JdProd/List/model.js').default) });
app.model({ namespace: 'model', ...(require('/Users/maat/Documents/code/xuanwo/front/xwadmin/src/pages/H5page/model.js').default) });
app.model({ namespace: 'model', ...(require('/Users/maat/Documents/code/xuanwo/front/xwadmin/src/pages/H5PagesDetail/model.js').default) });
app.model({ namespace: 'model', ...(require('/Users/maat/Documents/code/xuanwo/front/xwadmin/src/pages/PwdRepord/List/model.js').default) });
app.model({ namespace: 'model', ...(require('/Users/maat/Documents/code/xuanwo/front/xwadmin/src/pages/BizPlus/model.js').default) });
app.model({ namespace: 'model', ...(require('/Users/maat/Documents/code/xuanwo/front/xwadmin/src/pages/ShortUrl/List/model.js').default) });
app.model({ namespace: 'model', ...(require('/Users/maat/Documents/code/xuanwo/front/xwadmin/src/pages/KzMix/List/model.js').default) });
app.model({ namespace: 'model', ...(require('/Users/maat/Documents/code/xuanwo/front/xwadmin/src/pages/Parse/model.js').default) });
app.model({ namespace: 'model', ...(require('/Users/maat/Documents/code/xuanwo/front/xwadmin/src/pages/Privilege/model.js').default) });
app.model({ namespace: 'model', ...(require('/Users/maat/Documents/code/xuanwo/front/xwadmin/src/pages/JdPromotion/model.js').default) });
app.model({ namespace: 'model', ...(require('/Users/maat/Documents/code/xuanwo/front/xwadmin/src/pages/KzParse/model.js').default) });
app.model({ namespace: 'model', ...(require('/Users/maat/Documents/code/xuanwo/front/xwadmin/src/pages/Tlj/Miren/model.js').default) });
app.model({ namespace: 'model', ...(require('/Users/maat/Documents/code/xuanwo/front/xwadmin/src/pages/Yp/List/model.js').default) });
app.model({ namespace: 'model', ...(require('/Users/maat/Documents/code/xuanwo/front/xwadmin/src/pages/Tconfig/model.js').default) });
app.model({ namespace: 'model', ...(require('/Users/maat/Documents/code/xuanwo/front/xwadmin/src/pages/BizStat/model.js').default) });
app.model({ namespace: 'model', ...(require('/Users/maat/Documents/code/xuanwo/front/xwadmin/src/pages/GetPwd/model.js').default) });
app.model({ namespace: 'model', ...(require('/Users/maat/Documents/code/xuanwo/front/xwadmin/src/pages/GetActPwd/model.js').default) });
app.model({ namespace: 'model', ...(require('/Users/maat/Documents/code/xuanwo/front/xwadmin/src/pages/OTask/List/model.js').default) });
app.model({ namespace: 'model', ...(require('/Users/maat/Documents/code/xuanwo/front/xwadmin/src/pages/AliOrder/model.js').default) });
app.model({ namespace: 'model', ...(require('/Users/maat/Documents/code/xuanwo/front/xwadmin/src/pages/AliPunishOrder/model.js').default) });
app.model({ namespace: 'model', ...(require('/Users/maat/Documents/code/xuanwo/front/xwadmin/src/pages/AliActMaterials/List/model.js').default) });
app.model({ namespace: 'model', ...(require('/Users/maat/Documents/code/xuanwo/front/xwadmin/src/pages/AliActProducts/List/model.js').default) });
app.model({ namespace: 'model', ...(require('/Users/maat/Documents/code/xuanwo/front/xwadmin/src/pages/AliActPages/List/model.js').default) });
app.model({ namespace: 'model', ...(require('/Users/maat/Documents/code/xuanwo/front/xwadmin/src/pages/AliActDetail/model.js').default) });
app.model({ namespace: 'model', ...(require('/Users/maat/Documents/code/xuanwo/front/xwadmin/src/pages/Admin/List/model.js').default) });
app.model({ namespace: 'model', ...(require('/Users/maat/Documents/code/xuanwo/front/xwadmin/src/pages/Code/List/model.js').default) });
app.model({ namespace: 'model', ...(require('/Users/maat/Documents/code/xuanwo/front/xwadmin/src/pages/Role/List/model.js').default) });
app.model({ namespace: 'model', ...(require('/Users/maat/Documents/code/xuanwo/front/xwadmin/src/pages/Role/Manage/model.js').default) });
app.model({ namespace: 'model', ...(require('/Users/maat/Documents/code/xuanwo/front/xwadmin/src/pages/Mobile/List/model.js').default) });
app.model({ namespace: 'model', ...(require('/Users/maat/Documents/code/xuanwo/front/xwadmin/src/pages/MobileCard/List/model.js').default) });
app.model({ namespace: 'model', ...(require('/Users/maat/Documents/code/xuanwo/front/xwadmin/src/pages/Wechat/List/model.js').default) });
  return app;
}

export function getApp() {
  return app;
}

export class _DvaContainer extends Component {
  render() {
    const app = getApp();
    app.router(() => this.props.children);
    return app.start()();
  }
}
