import request from '@/utils/request';

export async function query(params) {
  return request('/api/ypRecord', {
    params,
  });
}
export async function add(params) {
  return request('/api/ypRecord', {
    method: 'POST',
    data: { ...params },
  });
}
