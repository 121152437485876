import { Form, Button, Divider } from 'antd';
import React from 'react';
import FormCreator from '@/components/FormCreator';

const CreateForm = props => {
  const { handleAdd, form } = props;

  const okHandle = () => {
    form.validateFields((err, fieldsValue) => {
      if (err) return;
      form.resetFields();
      handleAdd(fieldsValue);
    });
  };

  const layout = {
    labelCol: {
      span: 5,
    },
    wrapperCol: {
      span: 15,
    },
  }

  const configs = [
    {
      key: 'info',
      name: '订单信息',
      type: 'textArea',
      props: {
        rows: 4,
      },
      config: {
        rules: [
          {
            required: true,
            message: '请输入订单信息',
          },
        ],
      },
    },
    {
      key: 'title',
      name: '订单标题',
    },
  ];

  return (
    <div>
      <FormCreator form={form} configs={configs} layout={layout} />
      <Button icon="plus" type="primary" onClick={okHandle}>
        新增
      </Button>
    </div>
  );
};

export default Form.create()(CreateForm);
