import { getStat } from './service';

const Model = {
  namespace: 'bizStatModel',
  state: {
  },
  effects: {
    *submit({ payload }, { call, put }) {
      const params = {
        code: payload.code.join(','),
        start: payload.searchTime[0].startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        end: payload.searchTime[1].endOf('day').format('YYYY-MM-DD HH:mm:ss'),
      };
      const res = yield call(getStat, params);
      yield put({
        type: 'global/handlerResponse',
        payload: {
          ...res,
        },
      });
    },
  },
  reducers: {
  },
};
export default Model;
