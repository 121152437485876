import {
  Button,
  Card,
  Dropdown,
  Form,
  Icon,
  Menu,
  Tag,
  Popconfirm,
  Divider, Tooltip,
} from 'antd'
import React, { Component, Fragment } from 'react';
import { PageHeaderWrapper } from '@ant-design/pro-layout';
import { connect } from 'dva';
import moment from 'moment';
import CreateForm from './components/CreateForm';
import CreateBindForm from './components/CreateBindForm';
import StandardTable from '@/components/StandardTable';
import PowerAssert from '@/components/PowerAssert';
import SearchForm from '@/components/SearchForm';

import styles from './style.less';

const getValue = obj =>
  Object.keys(obj)
    .map(key => obj[key])
    .join(',');

/* eslint react/no-multi-comp:0 */
@connect(({ wechatAndList: { data, config }, loading, mobileAndList }) => ({
  data,
  page: data.page,
  size: data.size,
  count: data.count,
  loading: loading.models.wechatAndList,
  wechatStatus: config.wechatStatus,
  department: config.department,
  staff: config.staff,
  mobiles: mobileAndList.data.list,
}))
class TableList extends Component {
  state = {
    modalVisible: false,
    selectedRows: [],
    formValues: {},
    modfiyForm: {},
  };

  columns = [
    {
      title: 'id',
      dataIndex: 'id',
    },
    {
      title: '微信号',
      dataIndex: 'user_name',
    },
    {
      title: '昵称',
      dataIndex: 'nick_name',
    },
    {
      title: '是否实名',
      dataIndex: 'is_auth',
      render: text => (text ? <Tag color="#87d068">已实名</Tag> : <Tag color="gray">未实名</Tag>),
    },
    {
      title: '使用部门',
      dataIndex: 'department',
    },
    {
      title: '使用人',
      dataIndex: 'owner',
    },
    {
      title: '号状态',
      dataIndex: 'status',
      render: text => this.getStatus(text),
    },
    {
      title: '手机',
      dataIndex: 'mobile',
      width: 120,
      render: (text, record) => !!text && (
        <div style={{ whiteSpace: 'nowrap' }}>
          {text.e_no}
          <Popconfirm
            title="确定解绑？"
            onConfirm={() => this.handleUnBind(record)}
          >
            <Tooltip title="解绑">
              <Icon type="disconnect" style={{ color: 'red', fontSize: '16px', fontWeight: 'bold', marginLeft: '5px' }} />
            </Tooltip>
          </Popconfirm>
        </div>
      ),
    },
    {
      title: '密码',
      dataIndex: 'password',
      render: val => (val ? <span>有：<PowerAssert code={['wechat_edit', 'wechat_all']}>{val}</PowerAssert></span> : '未知'),
    },
    {
      title: '注册手机号',
      dataIndex: 'reg_tel',
    },
    {
      title: '支付密码',
      dataIndex: 'pay_password',
      render: val => (val ? <span>有：<PowerAssert code={['wechat_edit', 'wechat_all']}>{val}</PowerAssert></span> : '未知'),
    },
    
    {
      title: '好友数',
      dataIndex: 'count',
    },
    {
      title: '实名人',
      dataIndex: 'auth_user',
    },
    {
      title: '银行卡',
      dataIndex: 'bank',
    },
    {
      title: 'wx_id',
      dataIndex: 'wx_id',
    },
    {
      title: '备注',
      dataIndex: 'remark',
    },
    {
      title: '操作',
      fixed: 'right',
      render: (text, record) => (
        <Fragment>
            <PowerAssert code={['wechat_edit', 'wechat_all']}>
              <a onClick={() => this.handleUpdateModal(record)}>编辑</a>
            </PowerAssert>
            {
              (!record.mobile || !record.mobile.e_no) && (
                <>
                  <br/>
                  <PowerAssert code={['wechat_add', 'wechat_all']}>
                    <a onClick={() => this.handleBindModal(record)}>绑定到手机</a>
                  </PowerAssert>
                </>
              )
            }
        </Fragment>
      ),
    },
  ];

  componentDidMount() {
    const { page, size } = this.props;
    this.fetchData({ page, size });
    this.fetchAsset()
    // this.fetchMobiles({ page: 0, size: 100 })
  }

  getStatus (status) {
    switch (status) {
      case '正常':
        return <Tag color="#17913a">正常</Tag>
      case '封过目前正常':
        return <Tag color="#a3da1d">封过目前正常</Tag>
      case '已封':
        return <Tag color="#f5222d">已封</Tag>
      default:
        return <Tag color="gray">其他</Tag>
    }
  }

  handleStandardTableChange = (pagination, filtersArg, sorter) => {
    const { formValues } = this.state;
    const filters = Object.keys(filtersArg).reduce((obj, key) => {
      const newObj = { ...obj };
      newObj[key] = getValue(filtersArg[key]);
      return newObj;
    }, {});
    const params = {
      ...formValues,
      ...filters,
    };

    if (sorter.field) {
      params.sorter = `${sorter.field}_${sorter.order}`;
    }
    this.handleSelectRows([]);

    this.fetchData({
      page: pagination.current,
      size: pagination.pageSize,
    }, params);
  };

  handleFormReset = () => {
    const { form, size } = this.props;
    form.resetFields();
    this.handleSelectRows([]);
    this.setState({
      formValues: {},
    });
    this.fetchData({ page: 0, size });
  };

  handleMenuClick = e => {
    const { dispatch, size } = this.props;
    const { selectedRows } = this.state;
    if (!selectedRows) return;

    switch (e.key) {
      case 'remove':
        dispatch({
          type: 'wechatAndList/remove',
          payload: {
            ids: selectedRows.map(row => row.id),
          },
          callback: () => {
            this.handleSelectRows([]);
            this.fetchData({ page: 0, size });
          },
        });
        break;

      default:
        break;
    }
  };

  handleRemove = row => {
    const { dispatch, size } = this.props;
    dispatch({
      type: 'wechatAndList/remove',
      payload: {
        ids: [row.id],
      },
      callback: () => {
        this.fetchData({ page: 0, size });
      },
    });
  };

  handleSelectRows = rows => {
    this.setState({
      selectedRows: rows,
    }, () => {
    });
  };

  handleSearch = values => {
    const { size } = this.props;
    // 更新state，这样子翻页什么的时候也能跟进
    this.handleSelectRows([]);
    this.setState({
      formValues: values,
    });
    this.fetchData({ page: 0, size }, values);
  };

  handleModalVisible = flag => {
    this.setState({
      modalVisible: !!flag,
    });
    if (!flag) {
      this.setState({
        modfiyForm: {},
      });
    }
  };

  closeModal = () => {
    this.setState({
      modalVisible: false,
      modfiyForm: {},
    });
  }

  handleUpdateModal = record => {
    this.setState({
      modfiyForm: record || {},
      modalVisible: true,
    });
  };

  handleBindModalVisible = flag => {
    this.props.dispatch({
      type: 'mobileAndList/resetList'
    })
    this.setState({
      bindModalVisible: !!flag,
    })
  }

  handleBindModal = record => {
    this.setState({
      bindTarget: record || {},
      bindModalVisible: true,
    })
  }

  handleBind = fields => {
    const { dispatch, size } = this.props;
    const { bindTarget: { id } } = this.state;
    dispatch({
      type: 'wechatAndList/bind',
      payload: {
        id,
        ...fields,
      },
      callback: () => {
        this.fetchData({ page: 0, size }, {}, 1);
      },
    });
    this.handleBindModalVisible();
  }

  handleUnBind = record => {
    const { dispatch, size } = this.props;
    dispatch({
      type: 'wechatAndList/unbind',
      payload: record.id,
      callback: () => {
        this.fetchData({ page: 0, size }, {}, 1);
      },
    });
  }

  handleAdd = fields => {
    const { dispatch, size } = this.props;
    dispatch({
      type: 'wechatAndList/add',
      payload: {
        forms: fields,
      },
      callback: () => {
        this.fetchData({ page: 0, size });
      },
    });
    this.handleModalVisible();
  };

  handleUpdate = fields => {
    const { dispatch, size } = this.props;
    const { modfiyForm: { id } } = this.state;
    dispatch({
      type: 'wechatAndList/update',
      payload: {
        id,
        ...fields,
      },
      callback: () => {
        this.fetchData({ page: 0, size });
      },
    });
    this.closeModal();
  };


  fetchData(pager = null, fieldValue = {}, saveParams) {
    const { page, size, dispatch } = this.props;
    let tempPager = {
      page,
      size,
    }
    tempPager = {
      ...tempPager,
      ...pager,
    }
    let params = {
      ...fieldValue,
      ...tempPager,
    }
    if (saveParams) {
      const { formValues } = this.state;
      params = {
        ...params,
        ...formValues,
      }
    }
    dispatch({
      type: 'wechatAndList/fetch',
      payload: params,
    });
  }

  fetchAsset () {
    this.props.dispatch({
      type: 'wechatAndList/fetchAsset',
    });
  }

  fetchMobiles (payload) {
    this.props.dispatch({
      type: 'mobileAndList/fetch',
      payload,
    })
  }
  handleModalSearch = (val) => {
    if (val) {
      this.fetchMobiles({page: 0, size: 100, e_no: val })
    } else {
      this.props.dispatch({
        type: 'mobileAndList/resetList'
      })
    }
  }


  render() {
    const {
      data,
      page,
      count,
      size,
      loading,
      wechatStatus = [],
      department = [],
      staff = [],
      mobiles,
    } = this.props;

    const searchKeys = [ {
      label: '设备编号',
      type: 'input',
      key: 'e_no',
    }, {
      label: '昵称',
      type: 'input',
      key: 'nick_name',
    }, {
      label: '使用部门',
      type: 'select',
      key: 'department',
      options: department.map(item => ({
        key: item,
        val: item,
      })),
    }, {
      label: '使用人',
      type: 'select',
      key: 'owner',
      options: staff.map(item => ({
        key: item,
        val: item,
      })),
    },  {
      label: '微信号',
      type: 'input',
      key: 'user_name',
    }, {
      label: 'wx_id',
      type: 'input',
      key: 'wx_id',
    }, {
      label: '注册手机号',
      type: 'input',
      key: 'reg_tel',
    }, {
      label: '是否实名',
      type: 'select',
      key: 'is_auth',
      options: [{
        key: 1,
        val: '已实名'
      }, {
        key: 0,
        val: '未实名'
      }]
    }, {
      label: '实名人',
      type: 'input',
      key: 'auth_user'
    }, {
      label: '绑定的银行卡号',
      type: 'input',
      key: 'bank'
    }, {
      label: '号状态',
      type: 'select',
      key: 'status',
      options: wechatStatus.map(item => ({
        key: item,
        val: item,
      })),
    },{
      label: '备注',
      type: 'input',
      key: 'remark',
    }];

    const { selectedRows, modalVisible, modfiyForm, bindTarget, bindModalVisible } = this.state;
    const parentMethods = {
      handleAdd: this.handleAdd,
      handleUpdate: this.handleUpdate,
      handleModalVisible: this.handleModalVisible,
      closeModal: this.closeModal,
    };
    const bindModalMethods = {
      handleBind: this.handleBind,
      handleBindModalVisible: this.handleBindModalVisible,
      handleSearch: this.handleModalSearch,
      closeModal: this.handleBindModalVisible
    }
    const pager = {
      page,
      size,
      count,
    }
    return (
      <PageHeaderWrapper>
        <Card bordered={false}>
          <div className={styles.tableList}>
             <SearchForm search={this.handleSearch} reset={this.handleFormReset} searchConfigs={searchKeys} />
            <div className={styles.tableListOperator}>
              <PowerAssert code={['wechat_add', 'wechat_all']}>
                <Button icon="plus" type="primary" onClick={() => this.handleModalVisible(true)}>
                  新增微信
                </Button>
              </PowerAssert>
            </div>
            <StandardTable
              selectedRows={selectedRows}
              loading={loading}
              data={data}
              columns={this.columns}
              onSelectRow={this.handleSelectRows}
              onChange={this.handleStandardTableChange}
              pager={pager}
              scroll={{ x: '150vw' }}
            />
          </div>
        </Card>
        <CreateForm wechatStatus={wechatStatus} department={department} staff={staff} {...parentMethods} modfiyForm={modfiyForm} modalVisible={modalVisible} />
        <CreateBindForm {...bindModalMethods} modfiyForm={bindTarget} modalVisible={bindModalVisible} mobiles={mobiles} />
      </PageHeaderWrapper>
    );
  }
}

export default Form.create()(TableList);
