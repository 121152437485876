import { Form, Modal } from 'antd';
import React from 'react';
import FormCreator from '@/components/FormCreator';

const CreateForm = props => {
  const { modalVisible, handleAdd, closeModal, form, handleUpdate, modfiyForm } = props;
  const isModify = Object.keys(modfiyForm).length > 0;
  if (isModify) {
    if (modfiyForm.content && !modfiyForm.origin_content) {
      modfiyForm.origin_content = modfiyForm.content;
    }
  }

  const okHandle = () => {
    form.validateFields((err, fieldsValue) => {
      console.log(err);
      if (err) return;
      form.resetFields();
      // 判断是更新还是新增
      const fn = isModify ? handleUpdate : handleAdd;
      const {
        origin_content,
        item_id,
      } = fieldsValue;
      const reg = /([a-zA-Z0-9]{11})/g;
      const _content = origin_content.match(reg).join(',');
      const params = {
        ...fieldsValue,
        origin_content,
        content: _content,
        item_id: item_id.trim()
      };
      if(isModify) {
        params.id = modfiyForm.id;
      }
      fn(params);
    });
  };

  const layout = {
    labelCol: {
      span: 5,
    },
    wrapperCol: {
      span: 15,
    },
  }

  const configs = [
    {
      key: 'item_id',
      name: '商品id',
      config: {
        rules: [
          {
            required: true,
            message: '请输入',
          },
        ],
      },
    },
    {
      key: 'title',
      name: '备注',
      config: {
        rules: [
          {
            required: true,
            message: '请输入',
          },
        ],
      },
    },
    {
      key: 'ot',
      name: '非vip延迟分钟',
    },
    {
      key: 'type',
      name: '特价类型',
      type: 'select',
      config: {
        rules: [
          {
            required: true,
            message: '请选择类型',
          },
        ],
      },
      props: {
        style: { width: '100%' },
      },
      options: [ 
        {
          value: 1,
          name: '普通',
        },
        {
          value: 2,
          name: '专属',
        }
      ],
    },
    {
      key: 'origin_content',
      name: '口令原始',
      type: 'textArea',
      config: {
        rules: [
          {
            required: true,
            message: '请输入',
          },
        ],
      },
    },
    {
      key: 'content',
      name: '口令(不要动)',
      type: 'textArea',
    },
  ];

  return (
    <Modal
      destroyOnClose
      title="新增口令"
      visible={modalVisible}
      onOk={okHandle}
      onCancel={() => closeModal()}
    >
      <FormCreator form={ form } configs={configs} params={modfiyForm} layout={layout} />
    </Modal>
  );
};

export default Form.create()(CreateForm);
