import request from '@/utils/request';

export async function add(data) {
  return request('/api/aliAct', {
    method: 'POST',
    data,
  });
}

export async function update ({id, ...data}) {
  return request(`/api/aliAct/${id}`, {
    method: 'PUT',
    data
  });
}

export async function getDetail ({id}) {
  return request(`/api/aliAct/${id}`);
}

