import { Form, Modal } from 'antd';
import React from 'react';
import FormCreator from '@/components/FormCreator';

const CreateForm = props => {
  const { modalVisible, form, handleCreate, handleHideAllModal, modfiyForm } = props;
  const okHandle = () => {
    form.validateFields((err, fieldsValue) => {
      if (err) return;
      form.resetFields();
      handleCreate(fieldsValue);
    });
  };
  const layout = {
    labelCol: {
      span: 5,
    },
    wrapperCol: {
      span: 15,
    },
  }

  const configs = [
    {
      key: 'relation_id',
      name: '渠道id',
      config: {
        rules: [
          {
            required: true,
          },
        ],
      },
    },
    {
      key: 'num',
      name: '每日商品上限',
      type: 'number',
      config: {
        rules: [
          {
            required: true,
          },
        ],
      },
    },
  ];

  return (
    <Modal
      destroyOnClose
      title="变为代理"
      visible={modalVisible}
      onOk={okHandle}
      onCancel={() => handleHideAllModal()}
    >
      <FormCreator form={ form } configs={configs} params={modfiyForm} layout={layout} />
    </Modal>
  );
};

export default Form.create()(CreateForm);
