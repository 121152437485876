import React from 'react';
import { Upload, Icon, message, Modal } from 'antd';
import './index.less';
import { uploadUrl } from '../../../config/custom';

const maxSize = 2;

class Uploader extends React.Component {
  state = {
    previewVisible: false,
    previewImage: '',
    fileList: [],
  };

  componentWillMount () {
    const { value } = this.props;
    this.setFileList(value);
  }

  componentWillReceiveProps (nextProps) {
    const { fileList } = this.state;
    const { value } = nextProps;
    if (value && !(fileList && fileList.length && fileList[0].url === value)) {
      this.setFileList(value);
    }
  }

  setFileList (fileList) {
    let tempFiles = [];
    if (typeof fileList === 'string' && fileList.length) {
      tempFiles.push(fileList);
    }
    if (Object.prototype.toString.call(fileList) === '[object Array]') {
      tempFiles = [...fileList];
    }
    if (tempFiles) {
      if (tempFiles.length) {
        this.handleChange({ fileList: this.makeFile(tempFiles) });
      } else {
        this.handleChange({ fileList: [] })
      }
    }
  }

  handleCancel = () => this.setState({ previewVisible: false })

  handlePreview = file => {
    const previewImage = file.url || file.thumbUrl;
    this.setState({
      previewImage,
      previewVisible: true,
    });
  };

  handleChange = changeRes => {
    const { count, onChange } = this.props;
    let { fileList, file } = changeRes;
    file = handelFile(file);
    if (fileList) {
      fileList = fileList.map(f => handelFile(f));
    }
    const targetFileList = [...fileList].slice(0, count);
    this.setState({ fileList: targetFileList }, () => {
      if (onChange && file) {
        const images = targetFileList.filter(file => file.status === 'done').map(file => file.url)
        if (file.status === 'done') {
          onChange(file.url);
        } else if (file.status === 'removed') {
          onChange();
        }
      }
    });

    function handelFile (tempFile) {
      if (!tempFile) {
        return tempFile;
      }
      const { status } = tempFile;
      const { response } = tempFile;
      if (status === 'done' && response && (response.code === 1 || response.status === 1)) {
        tempFile.url = response.data.url;
      }
      return tempFile;
    }
  }

  makeFile (files) {
    return files.map((file, index) => ({
        uid: index,
        name: `${Date.now() + Math.random() + index}.png`,
        status: 'done',
        url: file,
      }));
  }

  render () {
    const { uploadType, data } = this.props;
    const MAX_SIZE = this.props.maxSize || maxSize;
    const { previewVisible, previewImage, fileList } = this.state;
    const settings = {
      action: uploadUrl,
      listType: 'picture-card',
      multiple: false,
      accept: 'image/jpg,image/jpeg,image/png,image/gif',
      withCredentials: true,
      name: 'file',
      ...this.props,
      data: {
        uploadType: uploadType || '未知', // 0定时清理，1持久化
        key: `image_${Date.now()}`,
        ...data,
      },
    };
    function beforeUpload(file) {
      let isImage = file.type.match(/^image\//i);
      if (!isImage) {
        message.error('您上传的不是图片文件!');
      } else if (!settings.accept.includes(file.type)) {
          isImage = false;
          message.error('您上传的图片文件格式不支持!');
        }
      const isLtM = file.size / 1024 / 1024 < MAX_SIZE;
      if (!isLtM) {
        message.error(`上传的图片必须小于${MAX_SIZE}M`);
      }
      return isImage && isLtM;
    }

    const uploadButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">{this.props.text || '点击上传图片'}</div>
      </div>
    );
    return (
      <div className="clearfix">
        <Upload {...settings}
          fileList={fileList} beforeUpload={beforeUpload}
          onChange={this.handleChange} onPreview={this.handlePreview}
        >
          {fileList.length >= (this.props.count || 1) ? null : uploadButton}
        </Upload>
        <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
          <img style={{ width: '100%' }} src={previewImage} />
        </Modal>
      </div>
    );
  }
}

export default Uploader;
