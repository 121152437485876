import { Tree } from 'antd';
import React from 'react';


const { TreeNode } = Tree;

class FormTree extends React.Component {
  onCheck = checkedKeys => {
    const { onChange } = this.props;
    onChange(checkedKeys.join(','));
  };

  renderTree = configs => {
    const items = configs.map(config => (
      <TreeNode title={config.title} key={config.code}>
        {this.renderTree(config.children || [])}
      </TreeNode>
    ));
    return items;
  }

  render() {
    const { options, value, ...otherProps } = this.props;
    const checkKeys = value ? value.split(',') : [];
    return (
      <Tree
        checkable
        onCheck={this.onCheck}
        defaultExpand
        autoExpandParent
        checkedKeys={checkKeys}
        {...otherProps}
      >
        {this.renderTree(options)}
      </Tree>
    );
  }
}

export default FormTree;
