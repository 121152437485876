import React from 'react';
import {
  Router as DefaultRouter,
  Route,
  Switch,
  StaticRouter,
} from 'react-router-dom';
import dynamic from 'umi/dynamic';
import renderRoutes from 'umi/lib/renderRoutes';
import history from '@@/history';
import RendererWrapper0 from '/Users/maat/Documents/code/xuanwo/front/xwadmin/src/pages/.umi-production/LocaleWrapper.jsx';

const Router = require('dva/router').routerRedux.ConnectedRouter;

const routes = [
  {
    path: '/user',
    component: require('../../layouts/UserLayout').default,
    routes: [
      {
        name: 'login',
        path: '/user/login',
        component: require('../user/login').default,
        exact: true,
      },
    ],
  },
  {
    path: '/',
    component: require('../../layouts/SecurityLayout').default,
    routes: [
      {
        path: '/',
        component: require('../../layouts/BasicLayout').default,
        routes: [
          {
            path: '/',
            redirect: '/welcome',
            exact: true,
          },
          {
            path: '/welcome',
            name: 'welcome',
            icon: 'smile',
            component: require('../Welcome').default,
            exact: true,
          },
          {
            path: '/password',
            name: '修改密码',
            icon: 'user',
            hideInMenu: true,
            component: require('../Admin/Manage').default,
            exact: true,
          },
          {
            name: '七牛图片管理',
            icon: 'user',
            path: '/admin/qiniu',
            authority: ['root', 'qiniu_query'],
            component: require('../Qiniu/List').default,
            exact: true,
          },
          {
            name: 'pid管理',
            icon: 'user',
            path: '/admin/userPid',
            authority: ['root', 'taoteMs', 'taoteMs_query'],
            component: require('../UserPid/List').default,
            exact: true,
          },
          {
            name: '推广',
            icon: 'user',
            path: '/admin/promotion',
            authority: ['root', 'taoteMs', 'taoteMs_query'],
            component: require('../UserPromotion/List').default,
            exact: true,
          },
          {
            name: '得物',
            icon: 'user',
            path: '/admin/dw',
            authority: ['root', 'wechatAd_out'],
            component: require('../WechatAdOut').default,
            exact: true,
          },
          {
            name: '得物详情',
            path: '/admin/dwDetail',
            hideInMenu: true,
            authority: ['root', 'wechatAd_out'],
            component: require('../WechatAdDetailOut').default,
            exact: true,
          },
          {
            name: '朋友圈配置',
            icon: 'user',
            path: '/admin/timelineConfig',
            authority: ['root', 'timelineConfig'],
            component: require('../TimelineConfig/List').default,
            exact: true,
          },
          {
            name: '朋友圈任务',
            icon: 'user',
            path: '/admin/timelinePlan',
            authority: ['root', 'timelineConfig'],
            component: require('../TimelinePlan/List').default,
            exact: true,
          },
          {
            name: '联盟授权管理',
            icon: 'user',
            path: '/admin/auth',
            authority: ['root'],
            component: require('../AuthTB/List').default,
            exact: true,
          },
          {
            name: '粉兔统计',
            icon: 'user',
            path: '/admin/fentu',
            authority: ['root', 'privilege_add'],
            component: require('../Fentu/Day').default,
            exact: true,
          },
          {
            name: '新增券',
            icon: 'gift',
            path: '/admin/addCoupon',
            authority: ['root', 'coupon_add'],
            component: require('../AddCoupon/List').default,
            exact: true,
          },
          {
            name: '新增黑名单商品',
            icon: 'gift',
            path: '/admin/addBlackProduct',
            authority: ['root'],
            component: require('../AddBlackProduct/List').default,
            exact: true,
          },
          {
            name: '券记录',
            icon: 'gift',
            path: '/admin/couponLog',
            authority: ['root', 'couponLog_query'],
            component: require('../CouponLog/List').default,
            exact: true,
          },
          {
            name: '群用户',
            icon: 'code',
            path: '/ug',
            authority: ['root', 'uconfig_query'],
            component: require('../../layouts/AuthLayout').default,
            routes: [
              {
                name: '尾号管理',
                icon: 'user',
                authority: ['root', 'uconfig_query'],
                path: '/ug/key',
                component: require('../Ukey/index').default,
                exact: true,
              },
              {
                name: '用户列表',
                icon: 'user',
                authority: ['root', 'uconfig_query'],
                path: '/ug/user',
                component: require('../ScoreUser/index').default,
                exact: true,
              },
              {
                name: 'vip列表',
                icon: 'user',
                authority: ['root', 'uconfig_query'],
                path: '/ug/vip',
                component: require('../ScoreVip/index').default,
                exact: true,
              },
              {
                name: '流水',
                icon: 'user',
                authority: ['root', 'uconfig_query'],
                path: '/ug/flow',
                component: require('../ScoreFlow/index').default,
                exact: true,
              },
              {
                name: '事件',
                icon: 'user',
                authority: ['root', 'uconfig_query'],
                path: '/ug/event',
                component: require('../ScoreEvent/index').default,
                exact: true,
              },
              {
                name: '新排行',
                icon: 'user',
                authority: ['root', 'uconfig_query'],
                path: '/ug/scoreRank',
                component: require('../ScoreUserCount/index').default,
                exact: true,
              },
              {
                name: '算重复订单',
                icon: 'user',
                authority: ['root', 'uconfig_query'],
                path: '/ug/countOrder',
                component: require('../CountOrder/List').default,
                exact: true,
              },
              {
                name: '群订单',
                icon: 'user',
                authority: ['root', 'uconfig_query'],
                path: '/ug/scoreOrder',
                component: require('../ScoreOrder/List').default,
                exact: true,
              },
              {
                name: '京东订单',
                icon: 'user',
                authority: ['root', 'uconfig_query'],
                path: '/ug/jdorder',
                component: require('../JdOrder/List').default,
                exact: true,
              },
              {
                name: '新增外部订单',
                icon: 'user',
                authority: ['root', 'uconfig_query'],
                path: '/ug/addOuterOrder',
                component: require('../AddOuterOrder/List').default,
                exact: true,
              },
              {
                name: '新增内部订单',
                icon: 'user',
                authority: ['root', 'uconfig_query'],
                path: '/ug/addInnerOrder',
                component: require('../AddInnerOrder/List').default,
                exact: true,
              },
            ],
          },
          {
            name: '苏小叶专区',
            icon: 'code',
            path: '/sxy',
            authority: [
              'root',
              'tlj_query',
              'tlj_all',
              'bizPlus',
              'uconfig_query',
            ],
            component: require('../../layouts/AuthLayout').default,
            routes: [
              {
                name: '京东好评',
                icon: 'code',
                path: '/sxy/wechatAd',
                authority: ['root', 'uconfig_query', 'uconfig_query'],
                component: require('../WechatAd').default,
                exact: true,
              },
              {
                name: '京东好评',
                icon: 'code',
                hideInMenu: true,
                path: '/sxy/wechatAdDetail',
                authority: ['root', 'uconfig_query', 'uconfig_query'],
                component: require('../WechatAdDetail').default,
                exact: true,
              },
              {
                name: '免单后返',
                icon: 'code',
                path: '/sxy/miandanAct',
                authority: ['root', 'wechatAct_query', 'wechatAct_all'],
                component: require('../MiandanAct').default,
                exact: true,
              },
              {
                name: '后返订单',
                icon: 'code',
                path: '/sxy/miandanOrder',
                authority: ['root', 'wechatAct_query', 'wechatAct_all'],
                component: require('../MiandanOrder').default,
                exact: true,
              },
              {
                name: '淘礼金',
                icon: 'code',
                path: '/sxy/tlj',
                authority: ['root', 'tlj_query', 'tlj_all'],
                component: require('../Tlj/List').default,
                exact: true,
              },
              {
                name: '简洁版淘礼金',
                icon: 'code',
                path: '/sxy/tljSimple',
                authority: ['root', 'tlj_query', 'tlj_all'],
                component: require('../Tlj/Simple').default,
                exact: true,
              },
              {
                name: '7首单淘礼金',
                icon: 'code',
                path: '/sxy/tljSimple7',
                authority: ['root', 'tlj_query', 'tlj_all'],
                component: require('../Tlj/Simple7').default,
                exact: true,
              },
              {
                name: '首单效率',
                icon: 'code',
                path: '/sxy/sdRatio',
                authority: ['root', 'tlj_query', 'tlj_all'],
                component: require('../SdRatio/List').default,
                exact: true,
              },
              {
                name: '商品积分',
                icon: 'tool',
                authority: ['root', 'scoreItem_query', 'scoreItem_all'],
                path: '/sxy/item',
                component: require('../ScoreItem/List').default,
                exact: true,
              },
              {
                name: '微信单口令活动',
                icon: 'code',
                path: '/sxy/wechatAct',
                authority: ['root', 'wechatAct_query', 'wechatAct_all'],
                component: require('../WechatAct').default,
                exact: true,
              },
              {
                name: '微信单口令礼金',
                icon: 'code',
                path: '/sxy/wechatLj',
                authority: ['root', 'wechatLj_query', 'wechatLj_all'],
                component: require('../WechatLj').default,
                exact: true,
              },
              {
                name: '口令合集',
                icon: 'code',
                path: '/sxy/lj',
                authority: ['root', 'lj_query', 'lj_all'],
                component: require('../Lj').default,
                exact: true,
              },
            ],
          },
          {
            name: '悦惠相关',
            icon: 'code',
            path: '/yh',
            authority: ['root', 'yyg_query'],
            component: require('../../layouts/AuthLayout').default,
            routes: [
              {
                name: '一元购商品',
                icon: 'user',
                authority: ['root', 'yyg_query'],
                path: '/yh/yyg',
                component: require('../Yyg/List').default,
                exact: true,
              },
              {
                name: '京东商品',
                icon: 'user',
                authority: ['root', 'jdProd_query'],
                path: '/yh/jdProd',
                component: require('../JdProd/List').default,
                exact: true,
              },
              {
                name: '活动页面',
                icon: 'user',
                authority: ['root', 'h5page_query'],
                path: '/yh/h5page',
                component: require('../H5page').default,
                exact: true,
              },
              {
                name: '编辑活动页',
                path: '/yh/detail',
                authority: ['root', 'h5page_query', 'h5page_query'],
                component: require('../H5PagesDetail').default,
                hideInMenu: true,
                exact: true,
              },
            ],
          },
          {
            name: '工具',
            icon: 'tool',
            path: '/tool',
            authority: [
              'root',
              'smsTaskDetail_query',
              'smsTask_query',
              'getZbj',
              'shortUrl',
              'privilege_add',
              'dlog_query',
              'dtag_query',
              'tlj_query',
              'tlj_all',
              'yp_all',
              'yp_query',
              'tconfig_all',
              'tconfig_query',
              'stat_all',
              'stat_lz_common',
              'stat_lz_super',
              'stat_yh_common',
              'stat_yh_super',
              'stat_zq_common',
              'stat_zq_super',
              'stat_gh_common',
              'stat_gh_super',
              'stat_fd_common',
              'stat_fd_super',
              'tool_domain',
              'sd_pwd',
              'bizStat_query',
              'tlj_query',
            ],
            component: require('../../layouts/AuthLayout').default,
            routes: [
              {
                name: '淘口令回流',
                icon: 'tool',
                authority: ['root', 'tlj_query'],
                path: '/tool/pwdReport',
                component: require('../PwdRepord/List').default,
                exact: true,
              },
              {
                name: '收益预估',
                icon: 'tool',
                authority: ['root', 'bizPlus'],
                path: '/tool/bizPlus',
                component: require('../BizPlus').default,
                exact: true,
              },
              {
                name: '短链工具',
                icon: 'tool',
                authority: ['root', 'shortUrl'],
                path: '/tool/shortUrl',
                component: require('../ShortUrl/List').default,
                exact: true,
              },
              {
                name: '快站链接工具',
                icon: 'tool',
                authority: ['root', 'shortUrl'],
                path: '/tool/kzMix',
                component: require('../KzMix/List').default,
                exact: true,
              },
              {
                name: '解析口令',
                icon: 'tool',
                authority: ['root', 'privilege_add'],
                path: '/tool/parse',
                component: require('../Parse').default,
                exact: true,
              },
              {
                name: '高佣转链工具',
                icon: 'tool',
                authority: ['root', 'privilege_add'],
                path: '/tool/privilege',
                component: require('../Privilege').default,
                exact: true,
              },
              {
                name: '京东转链',
                icon: 'tool',
                authority: ['root', 'privilege_add'],
                path: '/tool/jdPromotion',
                component: require('../JdPromotion').default,
                exact: true,
              },
              {
                name: '签到|吃货|百亿|超返',
                icon: 'tool',
                authority: ['root', 'privilege_add'],
                path: '/tool/kzParse',
                component: require('../KzParse').default,
                exact: true,
              },
              {
                name: '米仁淘礼金',
                icon: 'code',
                path: '/tool/mrtlj',
                authority: ['root', 'tlj_query', 'tlj_all'],
                component: require('../Tlj/Miren').default,
                exact: true,
              },
              {
                name: '红包',
                icon: 'code',
                path: '/tool/yp',
                authority: ['root', 'yp_all', 'yp_query'],
                component: require('../Yp/List').default,
                exact: true,
              },
              {
                name: '配置管理',
                icon: 'code',
                path: '/tool/tconfig',
                authority: ['root', 'tconfig_all', 'tconfig_query'],
                component: require('../Tconfig/List').default,
                exact: true,
              },
              {
                name: '周报数据统计',
                icon: 'tool',
                authority: ['root', 'bizStat_query'],
                path: '/tool/bizStat',
                component: require('../BizStat').default,
                exact: true,
              },
              {
                name: '链接转口令',
                icon: 'tool',
                authority: ['root', 'shortUrl'],
                path: '/tool/getPwd',
                component: require('../GetPwd').default,
                exact: true,
              },
              {
                name: '活动id转口令',
                icon: 'tool',
                authority: ['root', 'shortUrl'],
                path: '/tool/getActPwd',
                component: require('../GetActPwd').default,
                exact: true,
              },
            ],
          },
          {
            name: '阿里订单',
            icon: 'code',
            path: '/ali',
            authority: [
              'root',
              'async_query',
              'aliorder_query',
              'aliorder_all',
              'aliorder_stat',
            ],
            component: require('../../layouts/AuthLayout').default,
            routes: [
              {
                name: '任务',
                icon: 'code',
                path: '/ali/async',
                authority: ['root', 'async_query'],
                component: require('../OTask/List').default,
                exact: true,
              },
              {
                name: '列表',
                icon: 'code',
                path: '/ali/list',
                authority: ['root', 'aliorder_query', 'aliorder_all'],
                component: require('../AliOrder/List').default,
                exact: true,
              },
              {
                name: '按商品排行',
                icon: 'code',
                path: '/ali/stat',
                authority: ['root', 'aliorder_stat', 'aliorder_all'],
                component: require('../AliOrder/Stat').default,
                exact: true,
              },
              {
                name: '日排行',
                icon: 'code',
                path: '/ali/day',
                authority: ['root', 'aliorder_stat', 'aliorder_all'],
                component: require('../AliOrder/Day').default,
                exact: true,
              },
              {
                name: '处罚订单',
                icon: 'code',
                path: '/ali/punish',
                authority: ['root', 'aliorder_query', 'aliorder_all'],
                component: require('../AliPunishOrder/List').default,
                exact: true,
              },
              {
                name: '处罚订单按商品',
                icon: 'code',
                path: '/ali/punishStat',
                authority: ['root', 'aliorder_query', 'aliorder_all'],
                component: require('../AliPunishOrder/Stat').default,
                exact: true,
              },
            ],
          },
          {
            name: '阿里活动',
            icon: 'star',
            path: '/aliAct',
            component: require('../../layouts/AuthLayout').default,
            authority: [
              'root',
              'material_all',
              'material_query',
              'prod_all',
              'prod_query',
              'aliAct_all',
              'aliAct_query',
            ],
            routes: [
              {
                name: '活动配置',
                icon: 'setting',
                path: '/aliAct/materials',
                authority: ['root', 'material_all', 'material_query'],
                component: require('../AliActMaterials/List').default,
                exact: true,
              },
              {
                name: '活动商品',
                icon: 'appstore',
                path: '/aliAct/products',
                authority: ['root', 'prod_all', 'prod_query'],
                component: require('../AliActProducts/List').default,
                exact: true,
              },
              {
                name: '活动页',
                icon: 'profile',
                path: '/aliAct/pages',
                authority: ['root', 'aliAct_all', 'aliAct_query'],
                component: require('../AliActPages/List').default,
                exact: true,
              },
              {
                name: '编辑活动页',
                path: '/aliAct/detail',
                authority: ['root', 'aliAct_all', 'aliAct_query'],
                component: require('../AliActDetail').default,
                hideInMenu: true,
                exact: true,
              },
            ],
          },
          {
            path: '/power',
            name: '权限',
            icon: 'setting',
            hideInMenu: false,
            authority: [
              'root',
              'code_all',
              'role_all',
              'code_query',
              'role_query',
              'user_all',
              'user_query',
            ],
            component: require('../../layouts/AuthLayout').default,
            routes: [
              {
                name: '用户管理',
                icon: 'user',
                path: '/power/user',
                authority: ['root', 'user_all', 'user_query'],
                component: require('../Admin/List').default,
                exact: true,
              },
              {
                name: '权限值',
                icon: 'code',
                path: '/power/code',
                authority: ['root', 'code_all', 'code_query'],
                component: require('../Code/List').default,
                exact: true,
              },
              {
                name: '角色列表',
                icon: 'code',
                path: '/power/role',
                authority: ['root', 'role_all', 'role_query'],
                component: require('../Role/List').default,
                exact: true,
              },
              {
                name: '角色维护',
                icon: 'code',
                hideInMenu: true,
                path: '/power/roleManage',
                authority: ['root', 'role_all', 'role_add'],
                component: require('../Role/Manage').default,
                exact: true,
              },
            ],
          },
          {
            name: '资产管理',
            icon: 'code',
            path: '/res',
            authority: [
              'root',
              'mobile_all',
              'wechat_all',
              'tel_all',
              'tel_query',
              'mobile_query',
              'wechat_query',
            ],
            component: require('../../layouts/AuthLayout').default,
            routes: [
              {
                name: '手机管理',
                icon: 'mobile',
                path: '/res/mobile',
                authority: ['root', 'mobile_all', 'mobile_query'],
                component: require('../Mobile/List').default,
                exact: true,
              },
              {
                name: '手机卡管理',
                icon: 'credit-card',
                path: '/res/card',
                authority: ['root', 'tel_all', 'tel_query'],
                component: require('../MobileCard/List').default,
                exact: true,
              },
              {
                name: '微信管理',
                icon: 'wechat',
                path: '/res/wechat',
                authority: ['root', 'wechat_all', 'wechat_query'],
                component: require('../Wechat/List').default,
                exact: true,
              },
            ],
          },
          {
            component: require('../404').default,
            exact: true,
          },
        ],
      },
      {
        component: require('../404').default,
        exact: true,
      },
    ],
  },
  {
    component: require('../404').default,
    exact: true,
  },
];
window.g_routes = routes;
const plugins = require('umi/_runtimePlugin');
plugins.applyForEach('patchRoutes', { initialValue: routes });

export { routes };

export default class RouterWrapper extends React.Component {
  unListen() {}

  constructor(props) {
    super(props);

    // route change handler
    function routeChangeHandler(location, action) {
      plugins.applyForEach('onRouteChange', {
        initialValue: {
          routes,
          location,
          action,
        },
      });
    }
    this.unListen = history.listen(routeChangeHandler);
    // dva 中 history.listen 会初始执行一次
    // 这里排除掉 dva 的场景，可以避免 onRouteChange 在启用 dva 后的初始加载时被多执行一次
    const isDva =
      history.listen
        .toString()
        .indexOf('callback(history.location, history.action)') > -1;
    if (!isDva) {
      routeChangeHandler(history.location);
    }
  }

  componentWillUnmount() {
    this.unListen();
  }

  render() {
    const props = this.props || {};
    return (
      <RendererWrapper0>
        <Router history={history}>{renderRoutes(routes, props)}</Router>
      </RendererWrapper0>
    );
  }
}
