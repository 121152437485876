import {
  Card,
  Form,
  Button,
  Descriptions,
  message,
} from 'antd';
import React, { Component } from 'react';
import { PageHeaderWrapper } from '@ant-design/pro-layout';
import { connect } from 'dva';
import FormCreator from '@/components/FormCreator';

import styles from './style.less';

const FormItem = Form.Item;

class Pager extends Component {
  state = {
  };

  componentDidMount() {
    this.props.dispatch({
      type: 'timelineConfig/fetch',
    });
    this.props.dispatch({
      type: 'tConfListModel/fetch',
      payload: {
        type: 5002,
      },
    });
  }

  handleSubmit = e => {
    const { dispatch, form } = this.props;
    e.preventDefault();
    form.validateFields((err, values) => {
      console.log(values);
      if (!err) {
        dispatch({
          type: 'timelineConfig/add',
          payload: {
            ...values,
          },
        });
      } else {
        console.log(err);
      }
    });
  };


  render() {
    const { fetching, submitting, form, config } = this.props;
    const modifyForm = {
      type: config.type,
      start: config.start,
      end: config.end,
      token: config.token || '',
    };
    const submitFormLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 10,
          offset: 7,
        },
      },
    };
    const configs = [
      {
        key: 'type',
        name: '跟发类型',
        type: 'select',
        props: {
          style: { width: '100%' },
        },
        options: [
          {
            value: 0,
            name: '老林(延迟半小时左右)',
          },
          {
            value: 1,
            name: '大牛(延迟15分钟)',
          },
          {
            value: 2,
            name: '坚果(延迟短)',
          },
          {
            value: 3,
            name: '关闭',
          },
        ],
      },
      {
        key: 'token',
        name: '老林跟发者',
        type: 'select',
        props: {
          style: { width: '100%' },
        },
        options: this.props.ll
      },
      {
        key: 'start',
        name: '开始时间',
        config: {
          rules: [
            {
              required: true,
              message: '请输入',
            },
          ],
        },
      },
      {
        key: 'end',
        name: '结束时间',
        config: {
          rules: [
            {
              required: true,
              message: '请输入',
            },
          ],
        },
      },
    ];

    return (
      <PageHeaderWrapper>
        <Card bordered={false} loading={fetching}>
           <Form
                  onSubmit={this.handleSubmit}
                  style={{
                    marginTop: 8,
                  }}
                >
              <FormCreator form={ form } configs={configs} params={modifyForm}/>
                <FormItem
                  {...submitFormLayout}
                  style={{
                    marginTop: 32,
                  }}
                >
                  <Button type="primary" htmlType="submit" loading={submitting}>
                    提交
                  </Button>
                </FormItem>
              </Form>
        </Card>
      </PageHeaderWrapper>
    );
  }
}

export default Form.create()(
  connect(({ timelineConfig: { config }, tConfListModel: { configs }, loading }) => ({
    submitting: loading.effects['timelineConfig/add'],
    ll: configs.type5002 || [],
    config,
  }))(Pager),
);
