import React from 'react';
import { Popover } from 'antd';

class RowPopover extends React.PureComponent {
  render () {
    const {
      text = '',
      length = 12,
    } = this.props;

    return (
      <Popover placement="top" title={null} content={text} trigger="click">
          <span>{text && text.substring(0, length)}{(text ?  text.length : 0 ) > length ?  '...' : ''  }</span>
      </Popover>
    );
  }
}

export default RowPopover;
