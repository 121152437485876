import {
  Card,
  Form,
  Button,
  Table,
} from 'antd';
import React, { Component } from 'react';
import { PageHeaderWrapper } from '@ant-design/pro-layout';
import { connect } from 'dva';
import moment from 'moment';
import StandardTable from '@/components/StandardTable';
import SearchForm from '@/components/SearchForm';
import DataChart from './components/DataChart'

import styles from './style.less';

/* eslint react/no-multi-comp:0 */
@connect(({ fentuStat: { data, isShowChart }, loading }) => ({
  data,
  loading: loading.models.fentuStat,
  isShowChart,
}))
class TableList extends Component {
  state = {
    method: 'getByDay'
  };

  columns = [
    {
      title: '微信',
      dataIndex: 'weixin_nickname',
    },
    {
      title: '次数',
      dataIndex: 'total',
    },
    {
      title: '日期',
      dataIndex: 'datetime',
    },
  ];
  columns2 =[
    {
      title: '几点钟',
      dataIndex: 'no',
    },
   
    {
      title: '次数',
      dataIndex: 'total',
    },
  ]

  componentDidMount() {
    this.fetchData(this.state.method);
  }

  fetchData(method, params = {
    datetime: moment().format('YYYYMMDD')
  }) {
    const {dispatch} = this.props;
    dispatch({
      type: 'fentuStat/fetch',
      payload: {
        method,
        params,
      },
    });
  }

  handleToggleChart = () => {
    this.props.dispatch({
      type: 'fentuStat/toggleChart'
    })
  }

  handleSearch = values => {
    this.setState({
      method: values.method
    });
    const params = {};
    if (values.method === 'getByDay') {
      params.datetime = values.value;
    } else {
      params.day = values.value;
    }
    this.props.dispatch({
      type: 'fentuStat/fetch',
      payload: {
        method: values.method,
        params,
      },
    });
  };

  render() {
    const {
      data,
      isShowChart,
    } = this.props;
    const searchKeys = [{
      label: '统计形式',
      type: 'select',
      key: 'method',
      options: [
        {
          key: 'getByDay',
          val: '按日统计',
        },
        {
          key: 'getByHour',
          val: '按时间统计'
        }
      ],
      init: {
        initialValue: 'getByDay'
      }
    }, {
      label: '数值',
      key: 'value',
      init: {
        initialValue: moment().format('YYYYMMDD')
      }
    }];
    const c = this.state.method === 'getByDay' ? this.columns : this.columns2;
    return (
      <PageHeaderWrapper>
        <Card bordered={false}>
          <div className={styles.tableList}>
            <SearchForm expandForm search={this.handleSearch} reset={this.handleFormReset} searchConfigs={searchKeys} />
            <div className={styles.tableListOperator}>
              <Button icon="line-chart" type="primary" onClick={this.handleToggleChart}>{isShowChart ? '收起图表' : '查看图表'}</Button>
            </div>
            {
              isShowChart && <DataChart/>
            }
            <Table
              columns = {c}
              dataSource={data}
            />
          </div>
        </Card>
      </PageHeaderWrapper>
    );
  }
}

export default Form.create()(TableList);
