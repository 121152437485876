import {
  Button,
  Card,
  Dropdown,
  Form,
  Icon,
  Menu,
  Tag,
  Popconfirm,
  Divider, Tooltip,
} from 'antd'
import React, { Component, Fragment } from 'react';
import { PageHeaderWrapper } from '@ant-design/pro-layout';
import { connect } from 'dva';
import moment from 'moment';
import CreateForm from './components/CreateForm';
import CreateBindForm from './components/CreateBindForm';
import StandardTable from '@/components/StandardTable';
import PowerAssert from '@/components/PowerAssert';
import SearchForm from '@/components/SearchForm';

import styles from './style.less';

const getValue = obj =>
  Object.keys(obj)
    .map(key => obj[key])
    .join(',');

/* eslint react/no-multi-comp:0 */
@connect(({ mobileCardAndList: { data, config }, loading, mobileAndList }) => {
  return ({
    data,
    page: data.page,
    size: data.size,
    count: data.count,
    loading: loading.models.mobileCardAndList,
    telType: config.telType,
    inChargeType: config.inChargeType,
    mobiles: mobileAndList.data.list
  })
})
class TableList extends Component {
  state = {
    modalVisible: false,
    selectedRows: [],
    formValues: {},
    modfiyForm: {},
    bindModalVisible: false,
    bindTarget: {}
  };

  columns = [
    {
      title: 'id',
      dataIndex: 'id',
    },
    {
      title: '号码',
      dataIndex: 'tel_no',
    },
    {
      title: '运营商',
      dataIndex: 'tel_type',
    },
    {
      title: '充值类型',
      dataIndex: 'incharge_type',
    },
    {
      title: '备注',
      dataIndex: 'remark',
    },
    {
      title: '绑定手机',
      dataIndex: 'mobile',
      render: (text, record) => !!text && (
        <div style={{whiteSpace: 'nowrap'}}>
          {text.e_no}
          <Popconfirm
            title="确定解绑？"
            onConfirm={() => this.handleUnBind(record)}
          >
            <Tooltip title="解绑">
              <Icon type="disconnect" style={{color: 'red', fontSize: '16px', fontWeight: 'bold', marginLeft: '5px'}} />
            </Tooltip>
          </Popconfirm>
        </div>
      )
    },
    {
      title: '操作',
      width: 140,
      render: (text, record) => (
        <Fragment>
            <PowerAssert code={['tel_edit', 'tel_all']}>
              <a onClick={() => this.handleUpdateModal(record)}>编辑</a>
            </PowerAssert>
            {
              (!record.mobile || !record.mobile.e_no) && (
                <>
                  <br/>
                  <PowerAssert code={['tel_add', 'tel_all']}>
                    <a onClick={() => this.handleBindModal(record)}>绑定到手机</a>
                  </PowerAssert>
                </>
              )
            }
        </Fragment>
      ),
    },
  ];

  componentDidMount() {
    const { page, size } = this.props;
    this.fetchData({ page, size });
    this.fetchAsset()
    // this.fetchMobiles({page: 0, size: 100})
  }

  handleStandardTableChange = (pagination, filtersArg, sorter) => {
    const { formValues } = this.state;
    const filters = Object.keys(filtersArg).reduce((obj, key) => {
      const newObj = { ...obj };
      newObj[key] = getValue(filtersArg[key]);
      return newObj;
    }, {});
    const params = {
      ...formValues,
      ...filters,
    };

    if (sorter.field) {
      params.sorter = `${sorter.field}_${sorter.order}`;
    }
    this.handleSelectRows([]);

    this.fetchData({
      page: pagination.current,
      size: pagination.pageSize,
    }, params);
  };

  handleFormReset = () => {
    const { form, size } = this.props;
    form.resetFields();
    this.handleSelectRows([]);
    this.setState({
      formValues: {},
    });
    this.fetchData({ page: 0, size });
  };

  handleMenuClick = e => {
    const { dispatch, size } = this.props;
    const { selectedRows } = this.state;
    if (!selectedRows) return;

    switch (e.key) {
      case 'remove':
        dispatch({
          type: 'mobileCardAndList/remove',
          payload: {
            ids: selectedRows.map(row => row.id),
          },
          callback: () => {
            this.handleSelectRows([]);
            this.fetchData({ page: 0, size });
          },
        });
        break;

      default:
        break;
    }
  };

  handleRemove = row => {
    const { dispatch, size } = this.props;
    dispatch({
      type: 'mobileCardAndList/remove',
      payload: {
        ids: [row.id],
      },
      callback: () => {
        this.fetchData({ page: 0, size });
      },
    });
  };

  handleSelectRows = rows => {
    this.setState({
      selectedRows: rows,
    }, () => {
    });
  };

  handleSearch = values => {
    const { size } = this.props;
    // 更新state，这样子翻页什么的时候也能跟进
    this.handleSelectRows([]);
    this.setState({
      formValues: values,
    });
    this.fetchData({ page: 0, size }, values);
  };

  handleModalVisible = flag => {
    this.setState({
      modalVisible: !!flag,
    });
  };

  closeModal = () => {
    this.setState({
      modalVisible: false,
      modfiyForm: {},
    });
  }

  handleUpdateModal = record => {
    this.setState({
      modfiyForm: record || {},
      modalVisible: true,
    });
  };

  handleBindModalVisible = flag => {
    this.props.dispatch({
      type: 'mobileAndList/resetList'
    })
    this.setState({
      bindModalVisible: !!flag
    })
  }

  handleBindModal = record => {
    this.setState({
      bindTarget: record || {},
      bindModalVisible: true
    })
  }

  handleBind = fields => {
    const { dispatch, size } = this.props;
    const { bindTarget: { id } } = this.state;
    dispatch({
      type: 'mobileCardAndList/bind',
      payload: {
        id,
        ...fields,
      },
      callback: () => {
        this.fetchData({ page: 0, size }, {}, 1);
      },
    });
    this.handleBindModalVisible();
  }

  handleUnBind = record => {
    const { dispatch, size } = this.props;
    dispatch({
      type: 'mobileCardAndList/unbind',
      payload: record.id,
      callback: () => {
        this.fetchData({ page: 0, size }, {}, 1);
      },
    });
  }

  handleAdd = fields => {
    const { dispatch, size } = this.props;
    dispatch({
      type: 'mobileCardAndList/add',
      payload: {
        forms: fields,
      },
      callback: () => {
        this.fetchData({ page: 0, size });
      },
    });
    this.handleModalVisible();
  };

  handleUpdate = fields => {
    const { dispatch, size } = this.props;
    const { modfiyForm: { id } } = this.state;
    dispatch({
      type: 'mobileCardAndList/update',
      payload: {
        id,
        ...fields,
      },
      callback: () => {
        this.fetchData({ page: 0, size });
      },
    });
    this.closeModal();
  };


  fetchData(pager = null, fieldValue = {}, saveParams) {
    const { page, size, dispatch } = this.props;
    let tempPager = {
      page,
      size,
    }
    tempPager = {
      ...tempPager,
      ...pager,
    }
    let params = {
      ...fieldValue,
      ...tempPager,
    }
    if (saveParams) {
      const { formValues } = this.state;
      params = {
        ...params,
        ...formValues,
      }
    }
    dispatch({
      type: 'mobileCardAndList/fetch',
      payload: params,
    });
  }

  fetchAsset () {
    this.props.dispatch({
      type: 'mobileCardAndList/fetchAsset'
    });
  }

  fetchMobiles (payload) {
    this.props.dispatch({
      type: 'mobileAndList/fetch',
      payload
    })
  }

  handleModalSearch = (val) => {
    if (val) {
      this.fetchMobiles({page: 0, size: 100, e_no: val })
    } else {
      this.props.dispatch({
        type: 'mobileAndList/resetList'
      })
    }
  }

  render() {
    const {
      data,
      page,
      count,
      size,
      loading,
      telType = [],
      inChargeType = [],
      mobiles,
    } = this.props;

    const searchKeys = [{
      label: '号码',
      type: 'input',
      key: 'tel_no',
    }, {
      label: '运营商',
      type: 'select',
      key: 'tel_type',
      options: telType.map(item => ({
        key: item,
        val: item
      }))
    }, {
      label: '充值类型',
      type: 'select',
      key: 'incharge_type',
      options: inChargeType.map(item => ({
        key: item,
        val: item,
      })),
    }, {
      label: '备注',
      type: 'input',
      key: 'remark',
    }];

    const { selectedRows, modalVisible, modfiyForm, bindTarget, bindModalVisible } = this.state;
    const parentMethods = {
      handleAdd: this.handleAdd,
      handleUpdate: this.handleUpdate,
      closeModal: this.closeModal,
    };
    const bindModalMethods = {
      handleBind: this.handleBind,
      handleBindModalVisible: this.handleBindModalVisible,
      handleSearch: this.handleModalSearch
    }
    const pager = {
      page,
      size,
      count,
    }
    return (
      <PageHeaderWrapper>
        <Card bordered={false}>
          <div className={styles.tableList}>
            <SearchForm search={this.handleSearch} reset={this.handleFormReset} searchConfigs={searchKeys} />
            <div className={styles.tableListOperator}>
              <PowerAssert code={['tel_add', 'tel_all']}>
                <Button icon="plus" type="primary" onClick={() => this.handleModalVisible(true)}>
                  新增手机卡
                </Button>
              </PowerAssert>
            </div>
            <StandardTable
              selectedRows={selectedRows}
              loading={loading}
              data={data}
              columns={this.columns}
              onSelectRow={this.handleSelectRows}
              onChange={this.handleStandardTableChange}
              pager={pager}
            />
          </div>
        </Card>
        <CreateForm telType={telType} inChargeType={inChargeType} {...parentMethods} modfiyForm={modfiyForm} modalVisible={modalVisible} />
        <CreateBindForm {...bindModalMethods} modfiyForm={bindTarget} modalVisible={bindModalVisible} mobiles={mobiles} />
      </PageHeaderWrapper>
    );
  }
}

export default Form.create()(TableList);
