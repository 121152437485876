import {
  Card,
  Form,
  Button,
  Icon,
  Descriptions,
  message,
  Divider,
  Row,
  Col
} from 'antd';
import React, { Component } from 'react';
import { PageHeaderWrapper } from '@ant-design/pro-layout';
import { connect } from 'dva';
import QRCode from 'qrcode.react';
import copy from 'copy-to-clipboard';
import FormCreator from '@/components/FormCreator';

import styles from './style.less';

const FormItem = Form.Item;

class SendCard extends Component {
  state = {
    pidOptions: this.props.pidEmums,
    pid: '',
    rid: '',
  };

  componentDidMount() {
    this.props.dispatch({
      type: 'tConfListModel/fetch',
      payload: {
        type: 4,
      },
      callback: () => {
        const pids = (this.props.pidEmums || []).filter(pid => !pid.isTlj);
        this.setState({
          pidOptions: pids,
        });
      }
    });
  }

  handleSubmit = e => {
    const { dispatch, form } = this.props;
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        this.setState({
          pid: values.pid,
          rid: values.relationId || '',
        }, () => {
          dispatch({
            type: 'kzParseModel/submit',
            payload: {
              ...values,
              notice: true,
            },
          });
        })
      } else {
        console.log(err);
      }
    });
  };

  onSearch = (value) => {
    if (value) {
      this.setState({
        pidOptions: [
          {
            value,
            name: value,
          },
          ...this.props.pidEmums
        ]
      })
    } else {
      this.setState({
        pidOptions: this.state.pidOptions
      })
    }
  }

  handleCopy (text) {
    if (copy(text)) {
      message.success('复制成功！')
    } else {
      message.error('复制失败')
    }
  }

  render() {
    const { fetching, submitting, form, result } = this.props;
    const { pidOptions, pid, rid } = this.state;
    const modifyForm = {
      type: 0,
    };
    const submitFormLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 10,
          offset: 7,
        },
      },
    };
    const configs = [
      {
        key: 'pid',
        name: 'PID',
        type: 'select',
        placeholder: '请输入PID',
        config: {
          rules: [
            {
              required: true,
              message: '请输入PID',
            },
          ],
        },
        props: {
          defaultActiveFirstOption: false,
          filterOption: false,
          showArrow: false,
          showSearch: true,
          notFoundContent: null,
          onSearch: this.onSearch
        },
        options: pidOptions,
      },
      {
        key: 'itemStr',
        name: '商品信息',
        type: 'textArea',
        config: {
          rules: [
            {
              required: true,
              message: '请输入商品信息',
            },
          ]
        },
      },
      {
        key: 'type',
        name: '类型',
        type: 'select',
        props: {
          style: { width: '100%' },
        },
        options: [
          {
            value: 5,
            name: '正常高佣',
          },
          {
            value: 0,
            name: '签到红包',
          },
          {
            value: 7,
            name: '淘特秒杀',
          },
          {
            value: 8,
            name: '首单',
          },
          {
            value: 1,
            name: '淘宝吃货',
          },
          {
            value: 2,
            name: '百亿补贴',
          },
          {
            value: 3,
            name: '超值买返',
          },
          {
            value: 4,
            name: '1元购',
          },
        ],
      },
      {
        key: 'relationId',
        name: '渠道ID',
      },
    ];

    return (
      <PageHeaderWrapper>
        <h1>可以转签到红包，吃货红包，百亿补贴，超值买返, 1元购</h1>
        <Card bordered={false} loading={fetching}>
           <Form
                  onSubmit={this.handleSubmit}
                  style={{
                    marginTop: 8,
                  }}
                >
              <FormCreator form={ form } configs={configs} params={modifyForm}/>
                <FormItem
                  {...submitFormLayout}
                  style={{
                    marginTop: 32,
                  }}
                >
                  <Button type="primary" htmlType="submit" loading={submitting}>
                    提交
                  </Button>
                </FormItem>
              </Form>
            
            {
              result.info
                ? (
                  <Card bordered={false}>
                    <Descriptions title="结果" column={1} bordered>
                      <Descriptions.Item label="链接">
                      <textarea className={styles.template}>
                      {result.info}
                      </textarea>
                        <Button onClick={() => this.handleCopy(result.info)} icon="copy" type="primary" shape="round" size="small">复制链接</Button>
                      </Descriptions.Item>
                      {
                        result.pwd ? (
                          <Descriptions.Item label="口令">
                            <textarea className={styles.template}>
                            {result.pwd}
                            </textarea>
                              <Button onClick={() => this.handleCopy(result.pwd)} icon="copy" type="primary" shape="round" size="small">复制口令</Button>
                            </Descriptions.Item>
                        ) : null
                      }
                    </Descriptions>
                  </Card>
                )
                :
                null
            }
        </Card>
      </PageHeaderWrapper>
    );
  }
}

export default Form.create()(
  connect(({ kzParseModel: { result }, tConfListModel: { configs }, loading }) => ({
    submitting: loading.effects['kzParseModel/submit'],
    result,
    pidEmums: configs.type4 || [],
  }))(SendCard),
);
