import { Form, Input, Modal } from 'antd';
import React from 'react';
import FormCreator from '@/components/FormCreator';

const CreateForm = props => {
  const { modalVisible, form, handleAdd, handleModalVisible } = props;

  const okHandle = () => {
    form.validateFields((err, fieldsValue) => {
      if (err) return;
      form.resetFields();
      // 判断是更新还是新增
      handleAdd(fieldsValue);
    });
  };

  const layout = {
    labelCol: {
      span: 5,
    },
    wrapperCol: {
      span: 15,
    },
  }

  const configs = [
    {
      key: 'itemId',
      name: '商品id',
    },
    {
      key: 'floorid',
      name: 'floorID',
      type: 'select',
      props: {
        style: { width: '100%' },
      },
      options: [{
        value: 46006,
        name: 46006
      }, {
        value: 46007,
        name: 46007
      },{
        value: 46012,
        name: 46012
      },{
        value: 46098,
        name: 46098
      }]
    },
  ];

  const modfiyForm = {
   
  };

  return (
    <Modal
      destroyOnClose
      title="新增商品"
      visible={modalVisible}
      onOk={okHandle}
      onCancel={() => handleModalVisible()}
    >
      <FormCreator form={ form } configs={configs} params={modfiyForm} layout={layout} />
    </Modal>
  );
};

export default Form.create()(CreateForm);
