import {
  Card,
  Form,
} from 'antd';
import React, { Component } from 'react';
import { PageHeaderWrapper } from '@ant-design/pro-layout';
import { connect } from 'dva';
import CreateForm from './components/CreateForm';
import styles from './style.less';

/* eslint react/no-multi-comp:0 */
@connect(({ kzMixModel: { shortUrl }, tConfListModel: { configs }, }) => ({
  shortUrl,
  jmPwd: configs.type3001 ? configs.type3001[0].value : '',
  lxPwd: configs.type3000 ? configs.type3000[0].value : '',
}))
class TableList extends Component {
  state = {
  };
  componentDidMount() {
    this.props.dispatch({
      type: 'tConfListModel/fetch',
      payload: {
        type: 3000,
      },
    });
    this.props.dispatch({
      type: 'tConfListModel/fetch',
      payload: {
        type: 3001,
      },
    });
  }
  handleAdd = fields => {
    const { dispatch } = this.props;
    const {
      isTt,
      pwd,
      title,
      pwd1,
      pwd2,
    } = fields;
    let params = {};
    if (isTt === '0') {
      params = {
        search: `title=${title}&pwd=${pwd}&type=0`,
      };
    }
    if (isTt === '2') {
      let search = `type=2&title=${title}&pwd=${pwd}`;
      if (pwd1) {
        search += `&pwd1=${pwd1}`;
      }
      if (pwd2) {
        search += `&pwd2=${pwd2}`;
      }
      params = {
        search,
      };
    }
    dispatch({
      type: 'kzMixModel/add',
      payload: {
        forms: params,
      },
    });
  };

  render() {
    const parentMethods = {
      handleAdd: this.handleAdd,
    };
    const {
      shortUrl,
      jmPwd,
      lxPwd,
    } = this.props;
    console.log(jmPwd)
    return (
      <PageHeaderWrapper>
        <Card bordered={false}>
          <CreateForm {...parentMethods} jmPwd={jmPwd} lxPwd={lxPwd}  shortUrl={shortUrl} />
        </Card>
      </PageHeaderWrapper>
    );
  }
}

export default Form.create()(TableList);
