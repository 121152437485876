import {
  Card,
  Form,
  Button,
  Icon,
  Descriptions,
  message,
  Divider,
  Row,
  Col
} from 'antd';
import React, { Component } from 'react';
import { PageHeaderWrapper } from '@ant-design/pro-layout';
import { connect } from 'dva';
import QRCode from 'qrcode.react';
import copy from 'copy-to-clipboard';
import FormCreator from '@/components/FormCreator';
import CreateForm from './components/CreateForm';

import styles from './style.less';

const FormItem = Form.Item;

class SendCard extends Component {
  state = {
    pidOptions: this.props.pidEmums,
    pid: '',
  };

  componentDidMount() {
    this.props.dispatch({
      type: 'tConfListModel/fetch',
      payload: {
        type: 10,
      },
      callback: () => {
        const pids = (this.props.pidEmums || []);
        this.setState({
          pidOptions: pids,
        });
      }
    });
  }

  handleSubmit = e => {
    const { dispatch, form } = this.props;
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        this.setState({
          pid: values.pid,
        }, () => {
          dispatch({
            type: 'jdPromotionModel/submit',
            payload: values,
          });
        })
      } else {
        console.log(err);
      }
    });
  };

  onSearch = (value) => {
    if (value) {
      this.setState({
        pidOptions: [
          {
            value,
            name: value,
          },
          ...this.props.pidEmums
        ]
      })
    } else {
      this.setState({
        pidOptions: this.state.pidOptions
      })
    }
  }

  handleCopy (text) {
    if (copy(text)) {
      message.success('复制成功！')
    } else {
      message.error('复制失败')
    }
  }

  render() {
    const { fetching, submitting, form, result } = this.props;
    const { pidOptions, pid } = this.state;
    const modifyForm = {};
    const submitFormLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 10,
          offset: 7,
        },
      },
    };
    const configs = [
      {
        key: 'pid',
        name: 'PID',
        type: 'select',
        placeholder: '请输入PID',
        config: {
          rules: [
            {
              required: true,
              message: '请输入PID',
            },
          ],
        },
        props: {
          defaultActiveFirstOption: false,
          filterOption: false,
          showArrow: false,
          showSearch: true,
          notFoundContent: null,
          onSearch: this.onSearch
        },
        options: pidOptions,
      },
      {
        key: 'url',
        name: '链接(不要空格)',
        type: 'input',
        config: {
          rules: [
            {
              required: true,
              message: '请输入链接',
            },
          ]
        },
      },
    ];

    return (
      <PageHeaderWrapper>
        <h1>京东转链</h1>
        <Card bordered={false} loading={fetching}>
           <Form
                  onSubmit={this.handleSubmit}
                  style={{
                    marginTop: 8,
                  }}
                >
              <FormCreator form={ form } configs={configs} params={modifyForm}/>
                <FormItem
                  {...submitFormLayout}
                  style={{
                    marginTop: 32,
                  }}
                >
                  <Button type="primary" htmlType="submit" loading={submitting}>
                    提交
                  </Button>
                </FormItem>
              </Form>
            
            {
              result 
                ? (
                  <Card bordered={false}>
                    <Descriptions title="结果" column={1} bordered>
                      <Descriptions.Item label="链接">
                      <textarea className={styles.template}>
                      {result}
                      </textarea>
                        <Button onClick={() => this.handleCopy(result)} icon="copy" type="primary" shape="round" size="small">一键复制</Button>
                      </Descriptions.Item>
                    </Descriptions>
                  </Card>
                )
                :
                null
            }
        </Card>
      </PageHeaderWrapper>
    );
  }
}

export default Form.create()(
  connect(({ jdPromotionModel: { result }, tConfListModel: { configs }, loading }) => ({
    submitting: loading.effects['jdPromotionModel/submit'],
    result,
    pidEmums: configs.type10 || [],
  }))(SendCard),
);
