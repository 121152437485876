import { add, update, getDetail } from './service';

const Model = {
  namespace: 'aliActDetail',
  state: {
    detailInfo: null
  },
  effects: {
    *fetchDetail({ payload, callback }, { call, put }) {
      const res = yield call(getDetail, { id: payload.id });
      const {
        code, data: { content, ...others }
      } = res;
      if (code === 1) {
        content.showPlayInfo = content.showPlayInfo === void 0 ? 0 : content.showPlayInfo
        yield put({
          type: 'save',
          payload: {...others, ...content}
        });
        if (callback) callback();
      }
      yield put({
        type: 'global/handlerResponse',
        payload: {
          ...res,
          successNotice: false,
        },
      });
    },
    *add({ payload, callback }, { call, put }) {
      const res = yield call(add, payload);
      yield put({
        type: 'global/handlerResponse',
        payload: {
          ...res,
        },
      });
      if (callback) callback();
    },
    *update({ payload, callback }, { call, put }) {
      const res = yield call(update, payload);
      yield put({
        type: 'global/handlerResponse',
        payload: {
          ...res,
        },
      });
      if (callback) callback();
    },
    *reset({ callback}, { put }) {
      yield put({
        type: 'save',
        payload: {
          detailInfo: null
        },
      });
      if (callback) callback();
    }
  },
  reducers: {
    save(state, action) {
      return { ...state, detailInfo: action.payload };
    }
  },
};
export default Model;
