import request from '@/utils/request';
import { openLink } from '@/utils/help';


export async function query(params) {
  return request('/api/wechatAd', {
    params,
  });
}

export async function add(data) {
  return request('/api/wechatAd', {
    method: 'POST',
    data
  });
}

export async function update ({id, ...data}) {
  return request(`/api/wechatAd/${id}`, {
    method: 'PUT',
    data
  });
}

export async function calc(id) {
  return request(`/api/wechatAdCalc/${id}`, {
    params: {},
  });
}

export async function exportExcel(id) {
  return openLink(`/api/wechatAdExport/${id}`, {});
}

export async function passAll(id) {
  return request(`/api/wechatAdPass/${id}`, {
    params: {},
  });
}

export async function exportAllExcel(params) {
  return openLink('/api/wechatAdExportAll', params);
}