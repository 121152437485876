import {
  Badge,
  Button,
  Card,
  Divider,
  Dropdown,
  Form,
  Icon,
  Menu,
} from 'antd';
import React, { Component, Fragment } from 'react';
import { PageHeaderWrapper } from '@ant-design/pro-layout';
import { connect } from 'dva';
import moment from 'moment';
import StandardTable from '@/components/StandardTable';
import PowerAssert from '@/components/PowerAssert';
import CreateForm from './components/CreateForm';
import EditForm from './components/EditForm';

import styles from './style.less';

@connect(({ yygModel: { data }, loading }) => ({
  data,
  page: data.page,
  size: data.size,
  count: data.count,
  loading: loading.models.yygModel,
}))
class TableList extends Component {
  state = {
    modalVisible: false,
    editModalVisible: false,
    selectedRows: [],
    formValues: {},
    modfiyForm: {},
  };

  columns = [
    {
      title: 'id',
      dataIndex: 'id',
    },
    {
      title: '商品ID',
      dataIndex: 'itemid',
    },
    {
      title: 'floorID',
      dataIndex: 'floorid',
    },
    {
      title: '标题',
      dataIndex: 'title',
    },
    {
      title: '图片',
      dataIndex: 'pic',
      width: 70,
      render: (val, record) => <a href={`http://detail.tmall.com/item.htm?id=${record.itemid}`} target="_blank" rel="noopener noreferrer"><img alt="商品图片" style={{ width: 60, height: 60 }} src={val} /></a>,
    },
    {
      title: '状态',
      dataIndex: 'status',
    },
    {
      title: '上次更新时间',
      dataIndex: 'updatedAt',
      sorter: true,
      render: val => <span>{moment(val).format('YYYY-MM-DD HH:mm:ss')}</span>,
    },
    {
      title: '操作',
      render: (text, record) => (
        <Fragment>
          <PowerAssert code="yyg_update">
            {record.status ? <a onClick={() => this.handleUpdate(record, 0)}>下架</a> : <a onClick={() => this.handleUpdate(record, 1)}>上架</a>}
          </PowerAssert>
          <Divider />
          <PowerAssert code="yyg_update">
            <a onClick={() => this.handleEditModalVisible(record)}>编辑</a>
          </PowerAssert>
        </Fragment>
      ),
    },
  ];

  componentDidMount() {
    const { page, size } = this.props;
    this.fetchData({ page, size });
  }

  handleStandardTableChange = (pagination, filtersArg, sorter) => {
    const { formValues } = this.state;
    const params = {
      ...formValues,
    };

    if (sorter.field) {
      params.sorter = `${sorter.field}_${sorter.order}`;
    }
    this.handleSelectRows([]);

    this.fetchData({
      page: pagination.current,
      size: pagination.pageSize,
    }, params);
  };

  handleFormReset = () => {
    const { form, size } = this.props;
    form.resetFields();
    this.handleSelectRows([]);
    this.setState({
      formValues: {},
    });
    this.fetchData({ page: 0, size });
  };

  handleMenuClick = e => {
    
  };

  handleSelectRows = rows => {
    this.setState({
      selectedRows: rows,
    }, () => {
    });
  };

  handleSearch = values => {
    const { size } = this.props;
    // 更新state，这样子翻页什么的时候也能跟进
    this.handleSelectRows([]);
    this.setState({
      formValues: values,
    });
    this.fetchData({ page: 0, size }, values);
  };

  handleModalVisible = flag => {
    this.setState({
      modalVisible: !!flag,
    });
  };

  handleEditModalVisible = record => {
    console.log(record);
    this.setState({
      modfiyForm: record,
      editModalVisible: 1,
    });
  };

  closeModal = () => {
    this.setState({
      modalVisible: false,
      editModalVisible: false,
      modfiyForm: {},
    });
  }

  handleUpdate = (fields, flag) => {
    const params = {
      ...fields,
    };
    if (flag !== undefined) {
      params.status = flag;
    }
    const { dispatch, size } = this.props;
    dispatch({
      type: 'yygModel/update',
      payload: params,
      callback: () => {
        this.closeModal();
        this.fetchData({ page: 0, size });
      },
    });
  }
  handleEdit = (fields) => {
    const params = {
      ...this.state.modfiyForm,
      ...fields,
    };
    const { dispatch, size } = this.props;
    dispatch({
      type: 'yygModel/update',
      payload: params,
      callback: () => {
        this.closeModal();
        this.fetchData({ page: 0, size });
      },
    });
  }

  handleAdd = fields => {
    const { dispatch, size } = this.props;
    dispatch({
      type: 'yygModel/add',
      payload: fields,
      callback: () => {
        this.handleModalVisible();
        this.fetchData({ page: 0, size });
      },
    });
  };

  fetchData(pager = null, fieldValue = {}) {
    const { page, size, dispatch } = this.props;
    let tempPager = {
      page,
      size,
    }
    tempPager = {
      ...tempPager,
      ...pager,
    }
    dispatch({
      type: 'yygModel/fetch',
      payload: {
        ...fieldValue,
        ...tempPager,
      },
    });
  }

  render() {
    const {
      data,
      page,
      count,
      size,
      loading,
    } = this.props;

    const { selectedRows, modalVisible, editModalVisible, modfiyForm } = this.state;
    const pager = {
      page,
      size,
      count,
    }
    const parentMethods = {
      handleAdd: this.handleAdd,
      handleModalVisible: this.handleModalVisible,
    };

    const editMethods = {
      handleUpdate: this.handleEdit,
      closeModal: this.closeModal,
    };
    return (
      <PageHeaderWrapper>
        <Card bordered={false}>
          <div className={styles.tableList}>
            <div className={styles.tableListOperator}>
              <PowerAssert code="yyg_update">
                <Button icon="plus" type="primary" onClick={() => this.handleModalVisible(true)}>
                  新增商品
                </Button>
              </PowerAssert>
            </div>
            <StandardTable
              selectedRows={selectedRows}
              loading={loading}
              data={data}
              columns={this.columns}
              onSelectRow={this.handleSelectRows}
              onChange={this.handleStandardTableChange}
              pager={pager}
            />
          </div>
        </Card>
        <CreateForm {...parentMethods}  modalVisible={modalVisible} />
        <EditForm  {...editMethods} modfiyForm={modfiyForm} modalVisible={editModalVisible}  />
      </PageHeaderWrapper>
    );
  }
}

export default Form.create()(TableList);
