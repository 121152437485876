import { query, update, add, order } from './service';

const Model = {
  namespace: 'scoreFlowModel',
  state: {
    data: {
      list: [],
      page: 0,
      size: 20,
      count: 0,
    },
    config: {
    },
  },
  effects: {
    *fetch({ payload, callback }, { call, put }) {
      let tempPage = payload.page;
      if (tempPage > 0) {
        tempPage -= 1;
      }

      const res = yield call(query, {
        ...payload,
        page: tempPage,
      });
      const {
        code, data: { rows, page, size, count },
      } = res;
      if (code === 1) {
        yield put({
          type: 'save',
          payload: {
            list: rows,
            page,
            size,
            count,
          },
        });
        if (callback) callback();
      }
      yield put({
        type: 'global/handlerResponse',
        payload: {
          ...res,
          successNotice: false,
        },
      });
    },
    *order({ payload, callback }, { call, put }) {
      const res = yield call(order, payload);
      yield put({
        type: 'global/handlerResponse',
        payload: {
          ...res,
        },
      });
      if (callback) callback();
    },
    *update({ payload, callback }, { call, put }) {
      const res = yield call(update, payload);
      yield put({
        type: 'global/handlerResponse',
        payload: {
          ...res,
        },
      });
      if (callback) callback();
    },
    *add({ payload, callback }, { call, put }) {
      const {
        form,
        vip,
      } = payload;
      const params = {
        vipId: vip.id,
        user_remark: form.user_remark,
        remark: form.remark,
        score_user_id: vip.scoreUserId,
        score_event_id: form.score_event_id,
        score: form.score,
        special: form.special,
        active_date: form.active_date,
      }
      const res = yield call(add, params);
      yield put({
        type: 'global/handlerResponse',
        payload: {
          ...res,
        },
      });
      if (callback) callback();
    },
  },
  reducers: {
    save(state, action) {
      return { ...state, data: action.payload };
    },
  },
};
export default Model;
