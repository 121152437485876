import {
  Button,
  Card,
  Dropdown,
  Form,
  Icon,
  Menu,
  Tag,
  Popconfirm,
  Divider, Tooltip, Popover,
  message
} from 'antd'
import React, { Component, Fragment } from 'react';
import { PageHeaderWrapper } from '@ant-design/pro-layout';
import { connect } from 'dva';
import moment from 'moment';
import copy from 'copy-to-clipboard';
import CreateForm from './components/CreateForm';
import StandardTable from '@/components/StandardTable';
import PowerAssert from '@/components/PowerAssert';
import SearchForm from '@/components/SearchForm';

import styles from './style.less';

const getValue = obj =>
  Object.keys(obj)
    .map(key => obj[key])
    .join(',');

/* eslint react/no-multi-comp:0 */
@connect(({ JdProdModel: { data, config }, loading}) => ({
  data,
  page: data.page,
  size: data.size,
  count: data.count,
  loading: loading.models.aliActPageAndList,
}))
class TableList extends Component {
  state = {
    modalVisible: false,
    selectedRows: [],
    formValues: {},
    modfiyForm: {},
  };

  columns = [
    {
      title: '备注',
      dataIndex: 'item_name',
    },
    {
      title: '链接',
      dataIndex: 'url',
      width: 200,
      render: (t, r) => <a href={t} className={styles.link} target="_blank">{t}</a>
    },
    {
      title: '图片',
      dataIndex: 'img',
      render: (t, r) => <img src={t}  width="80"/>
    },
    {
      title: '佣金',
      dataIndex: 'commission',
    },
    {
      title: '活动状态',
      dataIndex: 'status',
      render: (t) => t === 1 ? <Tag color="#17913a">在线</Tag> : <Tag color="gray">下线</Tag>
    },
    {
      title: '模板',
      dataIndex: 'template',
      width: 200,
    },
    {
      title: '生效时间',
      dataIndex: 'start_time',
      render: (val, rec) => <div>
        {moment(rec.start_time).format('MM-DD HH:mm:ss')} - {moment(rec.end_time).format('MM-DD HH:mm:ss')}
        </div>,
    },
    {
      title: '操作',
      render: (text, record) => (
        <Fragment>
          <PowerAssert code="jdProd_update">
            <Popconfirm title={`确定${record.status === 1 ? '下架' : '上架'}该活动`} onConfirm={() => this.toggleOnLine(record)}>
              <a>{ record.status ? '下架' : '上架'}</a>
            </Popconfirm>
            <Divider type="vertical" />
            <Popconfirm title="确认删除" onConfirm={() => this.del(record)}>
              <a>删除</a>
            </Popconfirm>
            <Divider type="vertical" />
            <a onClick={() => this.handleUpdateModal(record)}>更新</a>
            <Divider type="vertical" />
            <a onClick={() => this.handleCopy(record)}>复制h5链接</a>
          </PowerAssert>
        </Fragment>
      ),
    },
  ];

  componentDidMount() {
    const { page, size } = this.props;
    this.fetchData({ page, size });
  }

  handleStandardTableChange = (pagination, filtersArg, sorter) => {
    const { formValues } = this.state;
    const filters = Object.keys(filtersArg).reduce((obj, key) => {
      const newObj = { ...obj };
      newObj[key] = getValue(filtersArg[key]);
      return newObj;
    }, {});
    const params = {
      ...formValues,
      ...filters,
    };

    if (sorter.field) {
      params.sorter = `${sorter.field}_${sorter.order}`;
    }
    this.handleSelectRows([]);

    this.fetchData({
      page: pagination.current,
      size: pagination.pageSize,
    }, params);
  };

  handleFormReset = () => {
    const { form, size } = this.props;
    form.resetFields();
    this.handleSelectRows([]);
    this.setState({
      formValues: {},
    });
    this.fetchData({ page: 0, size });
  };

  handleSelectRows = rows => {
    this.setState({
      selectedRows: rows,
    }, () => {
    });
  };

  handleSearch = values => {
    const { size } = this.props;
    // 更新state，这样子翻页什么的时候也能跟进
    this.handleSelectRows([]);
    for (let key in values) {
      if (values[key] === '') {
        values[key] = void 0
      }
    }
    this.setState({
      formValues: values,
    });
    this.fetchData({ page: 0, size }, values);
  };

  handleModalVisible = flag => {
    this.setState({
      modalVisible: !!flag,
    });
  };

  closeModal = () => {
    this.setState({
      modalVisible: false,
      modfiyForm: {},
    });
  }

  handleUpdateModal = record => {
    this.setState({
      modfiyForm: record || {},
      modalVisible: true,
    });
  };

  handleUpdate = fields => {
    const { dispatch, size } = this.props;
    const { modfiyForm: { id } } = this.state;
    dispatch({
      type: 'JdProdModel/update',
      payload: {
        id,
        ...fields,
      },
      callback: () => {
        this.fetchData({ page: 0, size });
      },
    });
    this.closeModal();
  };

  toggleOnLine = record => {
    const { dispatch, size } = this.props;
    dispatch({
      type: 'JdProdModel/update',
      payload: {
        id: record.id,
        status: record.status === 1 ? 0 : 1
      },
      callback: () => {
        this.fetchData({ page: 0, size });
      },
    })
  }

  del = record => {
    const { dispatch, size } = this.props;
    dispatch({
      type: 'JdProdModel/update',
      payload: {
        id: record.id,
        status: -1
      },
      callback: () => {
        this.fetchData({ page: 0, size });
      },
    })
  }

  handleCopy = (record) => {
    const res = copy(record.url);
    if (res) {
      message.success('链接复制成功！')
    } else {
      message.error('复制失败')
    }
  }

  handleAdd = fields => {
    const { dispatch, size } = this.props;
    dispatch({
      type: 'JdProdModel/add',
      payload: {
        forms: fields,
      },
      callback: () => {
        this.fetchData({ page: 0, size });
      },
    });
    this.handleModalVisible();
  };

  fetchData(pager = null, fieldValue = {}, saveParams) {
    const { page, size, dispatch } = this.props;
    let tempPager = {
      page,
      size,
    }
    tempPager = {
      ...tempPager,
      ...pager,
    }
    let params = {
      ...fieldValue,
      ...tempPager,
    }
    if (saveParams) {
      const { formValues } = this.state;
      params = {
        ...params,
        ...formValues,
      }
    }
    dispatch({
      type: 'JdProdModel/fetch',
      payload: params,
    });
  }

  render() {
    const {
      data,
      page,
      count,
      size,
      loading,
    } = this.props;

    const searchKeys = [{
      label: '链接',
      type: 'input',
      key: 'url',
    }, {
      label: '活动标题',
      type: 'input',
      key: 'item_name',
    }];

    const { selectedRows, modalVisible, modfiyForm, } = this.state;
    const parentMethods = {
      handleAdd: this.handleAdd,
      handleUpdate: this.handleUpdate,
      handleModalVisible: this.handleModalVisible,
      closeModal: this.closeModal,
    };
    const pager = {
      page,
      size,
      count,
    }
    return (
      <PageHeaderWrapper>
        <Card bordered={false}>
          <div className={styles.tableList}>
            <SearchForm search={this.handleSearch} reset={this.handleFormReset} searchConfigs={searchKeys} />
            <div className={styles.tableListOperator}>
              <PowerAssert code="jdProd_add">
                <Button icon="plus" type="primary" onClick={() => this.handleModalVisible(1)}>
                  新增商品/活动
                </Button>
              </PowerAssert>
            </div>
            <StandardTable
              selectedRows={selectedRows}
              loading={loading}
              data={data}
              columns={this.columns}
              onSelectRow={this.handleSelectRows}
              onChange={this.handleStandardTableChange}
              pager={pager}
            />
          </div>
        </Card>
        <CreateForm {...parentMethods} modfiyForm={modfiyForm} modalVisible={modalVisible} />
      </PageHeaderWrapper>
    );
  }
}

export default Form.create()(TableList);
