import React from 'react';
import echarts from 'echarts';

class Echart extends React.Component {
  componentDidMount () {
    this.chart = echarts.init(this.$el);
    this.chart.setOption(this.props.option);
  }

  componentWillReceiveProps (nextProps, props) {
    if (this.props.option === nextProps.option) {
      return;
    }
    this.chart.setOption(nextProps.option);
  }

  render () {
    const { style } = this.props;
    return (
      <div style={style} ref={el => this.$el = el} />
    )
  }
}

export default Echart;
