import {
  Button,
  Card,
  Form,
  Popconfirm,
  Divider, Tooltip,
} from 'antd'
import { Link } from 'dva/router';
import React, { Component, Fragment } from 'react';
import { PageHeaderWrapper } from '@ant-design/pro-layout';
import { connect } from 'dva';
import moment from 'moment';
import StandardTable from '@/components/StandardTable';
import SearchForm from '@/components/SearchForm';

import styles from './style.less';

const getValue = obj =>
  Object.keys(obj)
    .map(key => obj[key])
    .join(',');

const getStatus = status => {
  const maps = {
    '0': '未开始',
    '1': '进行中',
    '2': '已结算',
  }
  return maps[status];
}

/* eslint react/no-multi-comp:0 */
@connect(({ wechatAdOut: { data }, loading }) => ({
  data,
  page: data.page,
  size: data.size,
  count: data.count,
  loading: loading.models.wechatAdOut,
}))
class TableList extends Component {
  state = {
    modalVisible: false,
    selectedRows: [],
    formValues: {},
    modifyForm: {},
  };

  columns = [
    {
      title: 'id',
      dataIndex: 'id',
    },
    {
      title: '标题',
      dataIndex: 'title',
      render: (val, rec) => <Link to={"/admin/dwDetail?id=" + rec.id}>{val}</Link>
    },
    {
      title: '关键词',
      dataIndex: 'shop_name',
    },
    {
      title: '描述',
      dataIndex: 'desc',
    },
    {
      title: '状态',
      dataIndex: 'status',
      render: text => getStatus(text),
    },
    {
      title: '结束时间',
      dataIndex: 'end_time',
      render: val => moment(val).format('YYYY-MM-DD HH:mm:ss'),
    },
  ];

  componentDidMount() {
    const { page, size } = this.props;
    this.fetchData({ page, size });
  }

  handleStandardTableChange = (pagination, filtersArg, sorter) => {
    const { formValues } = this.state;
    const filters = Object.keys(filtersArg).reduce((obj, key) => {
      const newObj = { ...obj };
      newObj[key] = getValue(filtersArg[key]);
      return newObj;
    }, {});
    const params = {
      ...formValues,
      ...filters,
    };

    if (sorter.field) {
      params.sorter = `${sorter.field}_${sorter.order}`;
    }
    this.handleSelectRows([]);

    this.fetchData({
      page: pagination.current,
      size: pagination.pageSize,
    }, params);
  };

  handleFormReset = () => {
    const { form, size } = this.props;
    form.resetFields();
    this.handleSelectRows([]);
    this.setState({
      formValues: {},
    });
    this.fetchData({ page: 0, size });
  };

  handleSelectRows = rows => {
    this.setState({
      selectedRows: rows,
    }, () => {
    });
  };

  handleSearch = values => {
    const { size } = this.props;
    // 更新state，这样子翻页什么的时候也能跟进
    this.handleSelectRows([]);
    this.setState({
      formValues: values,
    });
    this.fetchData({ page: 0, size }, values);
  };

  handleModalVisible = flag => {
    this.setState({
      modalVisible: !!flag,
    });
    if (!flag) {
      this.setState({
        modifyForm: {},
      });
    }
  };

  closeModal = () => {
    this.setState({
      modalVisible: false,
      modifyForm: {},
    });
  }

  handleUpdateModal = record => {
    this.setState({
      modifyForm: record || {},
      modalVisible: true,
    });
  };
  

  fetchData(pager = null, fieldValue = {}, saveParams) {
    const { page, size, dispatch } = this.props;
    let tempPager = {
      page,
      size,
    }
    tempPager = {
      ...tempPager,
      ...pager,
    }
    let params = {
      ...fieldValue,
      ...tempPager,
    }
    if (saveParams) {
      const { formValues } = this.state;
      params = {
        ...params,
        ...formValues,
      }
    }
    dispatch({
      type: 'wechatAdOut/fetch',
      payload: params,
    });
  }

  render() {
    const {
      data,
      page,
      count,
      size,
      loading,
    } = this.props;

    const searchKeys = [ {
      label: '标题',
      type: 'input',
      key: 'title',
    }, {
      label: '关键词',
      type: 'input',
      key: 'shop_name',
    }, {
      label: '描述',
      type: 'input',
      key: 'desc',
    }, {
      label: '状态',
      type: 'select',
      key: 'status',
      options: [
        {
          key: 1,
          val: '进行中'
        },
        {
          key: -1,
          val: '关闭'
        },
        {
          key: 0,
          val: '未开始'
        }
      ],
    }];

    const { selectedRows, modalVisible, modifyForm } = this.state;
    const pager = {
      page,
      size,
      count,
    }
    return (
      <PageHeaderWrapper>
        <Card bordered={false}>
          <div className={styles.tableList}>
             <SearchForm search={this.handleSearch} reset={this.handleFormReset} searchConfigs={searchKeys} expandForm />
            <StandardTable
              selectedRows={selectedRows}
              loading={loading}
              data={data}
              columns={this.columns}
              onSelectRow={this.handleSelectRows}
              onChange={this.handleStandardTableChange}
              pager={pager}
            />
          </div>
        </Card>
      </PageHeaderWrapper>
    );
  }
}

export default Form.create()(TableList);
