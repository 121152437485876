import {
  Card,
  Form,
} from 'antd';
import React, { Component } from 'react';
import { PageHeaderWrapper } from '@ant-design/pro-layout';
import { connect } from 'dva';
import CreateForm from './components/CreateForm';
import styles from './style.less';

/* eslint react/no-multi-comp:0 */
@connect(({ shortModel: { shortUrl } }) => ({
  shortUrl,
}))
class TableList extends Component {
  state = {
  };

  handleAdd = fields => {
    const { dispatch } = this.props;
    dispatch({
      type: 'shortModel/add',
      payload: {
        forms: fields,
      },
    });
  };

  render() {
    const parentMethods = {
      handleAdd: this.handleAdd,
    };
    const {
      shortUrl,
    } = this.props;
    return (
      <PageHeaderWrapper>
        <Card bordered={false}>
          <CreateForm {...parentMethods} shortUrl={shortUrl} />
        </Card>
      </PageHeaderWrapper>
    );
  }
}

export default Form.create()(TableList);
