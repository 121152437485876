/**
 * Ant Design Pro v4 use `@ant-design/pro-layout` to handle Layout.
 * You can view component api by:
 * https://github.com/ant-design/ant-design-pro-layout
 */
import React from 'react';
import Link from 'umi/link';
import { connect } from 'dva';
import { Result, Button } from 'antd';
import Authorized from '@/utils/Authorized'; // 这里是获取用户真实权限的地方
import { getAuthorityFromRouter } from '@/utils/utils';

const noMatch = (
  <Result
    status="403"
    title="403"
    subTitle="Sorry, you are not authorized to access this page."
    extra={
      <Button type="primary">
        <Link to="/user/login">Go Login</Link>
      </Button>
    }
  />
);

const AuthLayout = props => {
  const {
    children,
    location = {
      pathname: '/',
    },
  } = props;
  /**
   * constructor
   */

  // 获取路由权限信息，并不是获取当前用户权限信息
  const authorized = getAuthorityFromRouter(props.route.routes, location.pathname || '/') || {
    authority: undefined,
  };
  return (
    <Authorized authority={authorized.authority} noMatch={noMatch}>
      {children}
    </Authorized>
  );
};

export default connect(() => ({
}))(AuthLayout);
