import { query, exportExcel, stat } from './service';

const Model = {
  namespace: 'aliPunishOrder',
  state: {
    data: {
      list: [],
      page: 0,
      size: 20,
      count: 0,
      pay: 0,
      effect: 0,
      own: 0,
      sum_effect_get: 0,
      data: [],
    },
    stat: [],
  },
  effects: {
    *exportExcel({ payload, callback }, { call, put }) {
      const res = yield call(exportExcel, payload);
      yield put({
        type: 'global/handlerResponse',
        payload: {
          ...res,
        },
      });
      if (callback) callback();
    },
    *fetch({ payload }, { call, put }) {
      let tempPage = payload.page;
      if (tempPage > 0) {
        tempPage -= 1;
      }

      const res = yield call(query, {
        ...payload,
        page: tempPage,
      });
      const {
        code, data: { rows, page, size, count, pay, effect, own, sum_effect_get },
      } = res;
      if (code === 1) {
        yield put({
          type: 'save',
          payload: {
            data: res.data,
            list: rows,
            page,
            size,
            count,
            pay,
            effect,
            own,
            sum_effect_get,
          },
        });
      }
      yield put({
        type: 'global/handlerResponse',
        payload: {
          ...res,
          successNotice: false,
        },
      });
    },
    *getStat({ payload }, { call, put }) {
      const res = yield call(stat, {
        ...payload,
      });
      if (res.code === 1) {
        yield put({
          type: 'saveStat',
          payload: res.data,
        });
      }
      yield put({
        type: 'global/handlerResponse',
        payload: {
          ...res,
          successNotice: false,
        },
      });
    },
  },
  reducers: {
    save(state, action) {
      return { ...state, data: action.payload };
    },
    saveStat(state, action) {
      return { ...state, stat: action.payload };
    },
  },
};
export default Model;
