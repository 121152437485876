import { queryCurrent, query as queryUsers } from '@/services/user';

const UserModel = {
  namespace: 'user',
  state: {
    currentUser: {},
  },
  effects: {
    *fetch(_, { call, put }) {
      const response = yield call(queryUsers);
      yield put({
        type: 'save',
        payload: response,
      });
    },

    *fetchCurrent({ callback }, { call, put }) {
      const res = yield call(queryCurrent);
      const { code, data } = res;
      if (code === 1) {
        data.role = {
          ...data.role,
          codes: data.role.codes ? data.role.codes.split(',') : [],
        }
        yield put({
          type: 'saveCurrentUser',
          payload: data,
        });
      }
      if (callback && typeof callback === 'function') {
        callback();
      }
    },
  },
  reducers: {
    saveCurrentUser(state, action) {
      return { ...state, currentUser: action.payload || {} };
    },

    changeNotifyCount(
      state = {
        currentUser: {},
      },
      action,
    ) {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          notifyCount: action.payload.totalCount,
          unreadCount: action.payload.unreadCount,
        },
      };
    },
  },
};
export default UserModel;
