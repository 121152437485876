import { add, getDetail } from './service';

const Model = {
  namespace: 'h5PagesDetail',
  state: {
    detailInfo: {}
  },
  effects: {
    *fetchDetail({ payload, callback }, { call, put }) {
      const res = yield call(getDetail, { id: payload.id });
      const {
        code, data: { content }
      } = res;
      if (code === 1) {
        const values = JSON.parse(content)
        yield put({
          type: 'save',
          payload: values.h5Options,
        });
        if (callback) callback();
      }
      yield put({
        type: 'global/handlerResponse',
        payload: {
          ...res,
          successNotice: false,
        },
      });
    },
    *add({ payload, callback }, { call, put }) {
      const res = yield call(add, payload);
      yield put({
        type: 'global/handlerResponse',
        payload: {
          ...res,
        },
      });
      yield put({
        type: 'global/handlerResponse',
        payload: {
          ...res,
          successNotice: false,
        },
      });
      if (callback) callback();
    },
    *reset({ callback}, { put }) {
      yield put({
        type: 'save',
        payload: {},
      });
      if (callback) callback();
    }
  },
  reducers: {
    save(state, action) {
      console.log(action);
      const newV = { ...state, detailInfo: action.payload };
      console.log(newV);
      return newV;
    }
  },
};
export default Model;
