import {
  Button,
  Card,
  Dropdown,
  Form,
  Icon,
  Menu,
  Tag,
  Popconfirm,
  Divider, Tooltip, Popover,
} from 'antd'
import React, { Component, Fragment } from 'react';
import { PageHeaderWrapper } from '@ant-design/pro-layout';
import { connect } from 'dva';
import moment from 'moment';
import CreateForm from './components/CreateForm';
import StandardTable from '@/components/StandardTable';
import PowerAssert from '@/components/PowerAssert';
import SearchForm from '@/components/SearchForm';

import styles from './style.less';

const getValue = obj =>
  Object.keys(obj)
    .map(key => obj[key])
    .join(',');

/* eslint react/no-multi-comp:0 */
@connect(({ aliActProdAndList: { data, config }, loading}) => ({
  data,
  page: data.page,
  size: data.size,
  count: data.count,
  loading: loading.models.aliActProdAndList,
}))
class TableList extends Component {
  state = {
    modalVisible: false,
    selectedRows: [],
    formValues: {},
    modfiyForm: {},
  };

  columns = [
    {
      fixed: 'left',
      title: '商品id',
      dataIndex: 'item_id',
      render: (t, r) => <a href={`https://detail.tmall.com/item.htm?id=${t}`} target="_blank">{t}</a>
    },
    {
      title: '商品图片',
      dataIndex: 'pict_url',
      render: t => <img src={t} alt="" style={{width: '80px', height: '80px'}}/>
    },
    {
      title: '商品标题',
      dataIndex: 'title',
    },
    {
      title: '佣金比例',
      dataIndex: 'commission_rate',
      sorter: true,
      render: t => t ? <span>{(t / 100).toFixed(2)}%</span> : ''
    },
    
    
    {
      title: '用户到手价格',
      dataIndex: 'user_final_price',
      sorter: true,
      render: t => !!t && <span>￥{(t / 100).toFixed(2)}</span>,
    },
    {
      title: '券面额',
      dataIndex: 'coupon_amount',
      sorter: true,
      render: t => !!t && <span>￥{t}</span>,
    },
    {
      title: '佣金',
      dataIndex: 'final_effect',
      sorter: true,
      render: t => !!t && <span>￥{(t / 100).toFixed(2)}</span>,
    },
    {
      title: '30天销量',
      dataIndex: 'volume',
      sorter: true,
      fixed: 'right',
    },
    {
      title: '定金',
      dataIndex: 'presale_deposit',
      render: t => !!t && <span>￥{(t / 100).toFixed(2)}</span>,
    },
    {
      title: '付定立减',
      dataIndex: 'presale_reduce',
      render: t => !!t && <span>￥{(t / 100).toFixed(2)}</span>,
    },
  ];

  componentDidMount() {
    const { page, size } = this.props;
    this.fetchData({ page, size });
  }

  handleStandardTableChange = (pagination, filtersArg, sorter) => {
    const { formValues } = this.state;
    const filters = Object.keys(filtersArg).reduce((obj, key) => {
      const newObj = { ...obj };
      newObj[key] = getValue(filtersArg[key]);
      return newObj;
    }, {});
    const params = {
      ...formValues,
      ...filters,
    };

    if (sorter.field) {
      params.sorter = `${sorter.field}_${sorter.order}`;
    }
    this.handleSelectRows([]);

    this.fetchData({
      page: pagination.current,
      size: pagination.pageSize,
    }, params);
  };

  handleFormReset = () => {
    const { form, size } = this.props;
    form.resetFields();
    this.handleSelectRows([]);
    this.setState({
      formValues: {},
    });
    this.fetchData({ page: 0, size });
  };

  handleMenuClick = e => {
    const { dispatch, size } = this.props;
    const { selectedRows } = this.state;
    if (!selectedRows) return;

    switch (e.key) {
      case 'remove':
        dispatch({
          type: 'aliActProdAndList/remove',
          payload: {
            ids: selectedRows.map(row => row.id),
          },
          callback: () => {
            this.handleSelectRows([]);
            this.fetchData({ page: 0, size });
          },
        });
        break;

      default:
        break;
    }
  };

  handleSelectRows = rows => {
    this.setState({
      selectedRows: rows,
    }, () => {
    });
  };

  handleSearch = values => {
    const { size } = this.props;
    // 更新state，这样子翻页什么的时候也能跟进
    this.handleSelectRows([]);
    for (let key in values) {
      if (values[key] === '') {
        values[key] = void 0
      }
    }
    this.setState({
      formValues: values,
    });
    this.fetchData({ page: 0, size }, values);
  };

  handleModalVisible = flag => {
    this.setState({
      modalVisible: !!flag,
    });
  };

  closeModal = () => {
    this.setState({
      modalVisible: false,
      modfiyForm: {},
    });
  }

  handleUpdateModal = record => {
    this.setState({
      modfiyForm: record || {},
      modalVisible: true,
    });
  };

  handleAdd = fields => {
    const { dispatch, size } = this.props;
    dispatch({
      type: 'aliActProdAndList/add',
      payload: {
        forms: fields,
      },
      callback: () => {
        this.fetchData({ page: 0, size });
      },
    });
    this.handleModalVisible();
  };

  handleUpdate = fields => {
    const { dispatch, size } = this.props;
    const { modfiyForm: { id } } = this.state;
    dispatch({
      type: 'aliActProdAndList/update',
      payload: {
        id,
        ...fields,
      },
      callback: () => {
        this.fetchData({ page: 0, size });
      },
    });
    this.closeModal();
  };


  fetchData(pager = null, fieldValue = {}, saveParams) {
    const { page, size, dispatch } = this.props;
    let tempPager = {
      page,
      size,
    }
    tempPager = {
      ...tempPager,
      ...pager,
    }
    let params = {
      ...fieldValue,
      ...tempPager,
    }
    if (saveParams) {
      const { formValues } = this.state;
      params = {
        ...params,
        ...formValues,
      }
    }
    dispatch({
      type: 'aliActProdAndList/fetch',
      payload: params,
    });
  }

  render() {
    const {
      data,
      page,
      count,
      size,
      loading,
    } = this.props;

    const searchKeys = [{
      label: '活动id',
      type: 'input',
      key: 'material_id',
    }, {
      label: '商品id',
      type: 'input',
      key: 'item_id',
    }, {
      label: '商品名称',
      type: 'input',
      key: 'title',
    }];

    const { selectedRows, modalVisible, modfiyForm, } = this.state;
    const parentMethods = {
      handleAdd: this.handleAdd,
      handleUpdate: this.handleUpdate,
      handleModalVisible: this.handleModalVisible,
      closeModal: this.closeModal,
    };
    const pager = {
      page,
      size,
      count,
    }
    return (
      <PageHeaderWrapper>
        <Card bordered={false}>
          <div className={styles.tableList}>
            <SearchForm search={this.handleSearch} reset={this.handleFormReset} searchConfigs={searchKeys} />
            <StandardTable
              selectedRows={selectedRows}
              loading={loading}
              data={data}
              columns={this.columns}
              onSelectRow={this.handleSelectRows}
              onChange={this.handleStandardTableChange}
              pager={pager}
              scroll={{ x: '150vw' }}
            />
          </div>
        </Card>
        <CreateForm {...parentMethods} modfiyForm={modfiyForm} modalVisible={modalVisible} />
      </PageHeaderWrapper>
    );
  }
}

export default Form.create()(TableList);
