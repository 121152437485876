import { Form, Button } from 'antd';
import React from 'react';
import FormCreator from '@/components/FormCreator';
import PowerAssert from '@/components/PowerAssert';

const CreateForm = props => {
  const { form, handleExport, modfiyForm = {
    keys: ['order_id', 'sku_id', 'sku_name', 'order_status', 'order_time'],
  } } = props;

  const okHandle = () => {
    form.validateFields((err, fieldsValue) => {
      if (err) return;
      handleExport(fieldsValue);
    });
  };

  const layout = {
    labelCol: {
      span: 2,
    },
    wrapperCol: {
      span: 20,
    },
  }

  const configs = [
    {
      key: 'keys',
      name: '导出字段',
      type: 'checkbox',
      options: [
        {
          value: 'order_id',
          label: '订单编号',
        },
        {
          value: 'order_status',
          label: '订单状态',
        },
        {
          value: 'sku_id',
          label: '商品id',
        },
        {
          value: 'sku_name',
          label: '商品标题',
        },
        {
          value: 'union_id',
          label: '联盟id',
        },
        {
          value: 'position_id',
          label: 'position_id',
        },
        {
          value: 'price',
          label: '付款金额',
        },
        {
          value: 'estimate_fee',
          label: '效果预估',
        },
        {
          value: 'order_time',
          label: '订单时间',
        },
        {
          value: 'sku_num',
          label: '商品件数',
        },
      ],
    },
  ];

  return (
    <PowerAssert code="aliorder_export">
      <FormCreator form={ form } configs={configs} params={modfiyForm} layout={layout} />
      <Button icon="plus" type="primary" onClick={okHandle}>
        导出
      </Button>
    </PowerAssert>
  );
};

export default Form.create()(CreateForm);
