import {
  Card,
  Form,
  Button,
  Descriptions,
  message,
} from 'antd';
import React, { Component } from 'react';
import { PageHeaderWrapper } from '@ant-design/pro-layout';
import { connect } from 'dva';
import copy from 'copy-to-clipboard';
import FormCreator from '@/components/FormCreator';

import styles from './style.less';

const FormItem = Form.Item;

class Pager extends Component {
  state = {
  };

  componentDidMount() {
    this.props.dispatch({
      type: 'userPid/fetch',
      payload: {
        page: 0,
        size: 50,
      },
    });
  }

  handleSubmit = e => {
    const { dispatch, form } = this.props;
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        this.setState({
          pid: values.pid,
        }, () => {
          dispatch({
            type: 'userPromotion/submit',
            payload: {
              ...values,
            },
          });
        })
      } else {
        console.log(err);
      }
    });
  };

  handleCopy (text) {
    if (copy(text)) {
      message.success('复制成功！')
    } else {
      message.error('复制失败')
    }
  }

  render() {
    const { fetching, submitting, form, result, pidList } = this.props;
    const options = pidList.map(pid => ({
      value: pid.pid,
      name: pid.remark,
    }));
    const modifyForm = {
      type: 7,
    };
    const submitFormLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 10,
          offset: 7,
        },
      },
    };
    const configs = [
      {
        key: 'pid',
        name: 'PID',
        type: 'select',
        placeholder: '请输入PID',
        config: {
          rules: [
            {
              required: true,
              message: '请输入PID',
            },
          ],
        },
        props: {
          defaultActiveFirstOption: false,
          showArrow: false,
        },
        options,
      },
      {
        key: 'itemStr',
        name: '商品信息',
        type: 'textArea',
        config: {
          rules: [
            {
              required: true,
              message: '请输入商品信息',
            },
          ],
        },
      },
      {
        key: 'type',
        name: '类型',
        type: 'select',
        props: {
          style: { width: '100%' },
        },
        options: [
          {
            value: 5,
            name: '正常高佣',
          },
          {
            value: 7,
            name: '淘特秒杀',
          },
        ],
      },
    ];

    return (
      <PageHeaderWrapper>
        <Card bordered={false} loading={fetching}>
           <Form
                  onSubmit={this.handleSubmit}
                  style={{
                    marginTop: 8,
                  }}
                >
              <FormCreator form={ form } configs={configs} params={modifyForm}/>
                <FormItem
                  {...submitFormLayout}
                  style={{
                    marginTop: 32,
                  }}
                >
                  <Button type="primary" htmlType="submit" loading={submitting}>
                    提交
                  </Button>
                </FormItem>
              </Form>

            {
              result.info
                ? (
                  <Card bordered={false}>
                    <Descriptions title="结果" column={1} bordered>
                      {
                        result.pwd ? (
                          <Descriptions.Item label="口令">
                            <textarea className={styles.template}>
                            {result.pwd}
                            </textarea>
                              <Button onClick={() => this.handleCopy(result.pwd)} icon="copy" type="primary" shape="round" size="small">复制口令</Button>
                            </Descriptions.Item>
                        ) : null
                      }
                    </Descriptions>
                  </Card>
                )
                :
                null
            }
        </Card>
      </PageHeaderWrapper>
    );
  }
}

export default Form.create()(
  connect(({ userPromotion: { result }, userPid: { pidList }, loading }) => ({
    submitting: loading.effects['userPromotion/submit'],
    result,
    pidList: pidList.list || [],
  }))(Pager),
);
