import { Form, Button } from 'antd';
import React from 'react';
import FormCreator from '@/components/FormCreator';
import Clipboard from 'react-clipboard.js';

const CreateForm = props => {
  const { handleAdd, form, shortUrl } = props;

  const okHandle = () => {
    form.validateFields((err, fieldsValue) => {
      if (err) return;
      handleAdd(fieldsValue);
    });
  };

  const layout = {
    labelCol: {
      span: 5,
    },
    wrapperCol: {
      span: 15,
    },
  }

  const configs = [
    {
      key: 'url',
      name: '链接',
      config: {
        rules: [
          {
            required: true,
            message: '请输入链接',
          },
        ],
      },
    },
    {
      key: 'urlType',
      name: '短链类型',
      type: 'select',
      props: {
        style: { width: '100%' },
      },
      config: {
        rules: [
          {
            required: true,
            message: '请选择类型',
          },
        ],
      },
      options: 'default,mrw.so,t.cn,w.url.cn'.split(',').map(r => ({
        value: r,
        name: r,
      })),
    },
  ];

  const modfiyForm = {
    urlType: 'default'
  };

  return (
    <div>
      <FormCreator form={ form } configs={configs} params={modfiyForm} layout={layout} />
      <Button icon="plus" type="primary" onClick={okHandle}>
        新增
      </Button>
      <p>{shortUrl ? `点击下方按钮复制到剪贴板:  ${shortUrl}` : ''}</p>
      <Clipboard className="ant-btn ant-btn-primary" data-clipboard-text={shortUrl}>一键复制</Clipboard>
    </div>
  );
};

export default Form.create()(CreateForm);
