import { Form, Modal, Button } from 'antd';
import React from 'react';
import FormCreator from '@/components/FormCreator';
import PowerAssert from '@/components/PowerAssert';

const CreateForm = props => {
  const { modalVisible, handleAdd, handleModalVisible, form, pidEmums, currentUser } = props;

  const max = currentUser ? (currentUser.login_name === 'sgc' ? 100 : 100) : 100;

  const pids = (pidEmums || []);
  const okHandle = () => {
    form.validateFields((err, fieldsValue) => {
      if (err) return;
      // form.resetFields();
      handleAdd(fieldsValue);
    });
  };

  const layout = {
    labelCol: {
      span: 5,
    },
    wrapperCol: {
      span: 15,
    },
  }

  const configs = [
    {
      key: 'item_id',
      name: '商品ID',
      config: {
        rules: [
          {
            required: true,
            message: '请输入商品ID',
          },
        ],
      },
    },
    {
      key: 'per_face',
      name: '面额',
      type: 'number',
      placeholder: '1元起，可带2位小数',
      config: {
        rules: [
          {
            required: true,
            message: '请输入面额',
          },
          {
            validator: (rule, value, callback) => {
              if(value > max) {
                callback('金额必须小于'+ max +'元');
              } else {
                callback();
              }
            },
          },
        ],
      },
      props: {
        style: { width: '100%' },
      },
    },
    {
      key: 'total_num',
      name: '个数',
      type: 'number',
      placeholder: '1个起',
      config: {
        rules: [
          {
            required: true,
            message: '请输入个数',
          },
        ],
      },
      props: {
        style: { width: '100%' },
      },
    },
    {
      key: 'pid',
      name: 'pid',
      type: 'select',
      props: {
        style: { width: '100%' },
      },
      config: {
        rules: [
          {
            required: true,
            message: '请选择pid',
          },
        ],
      },
      options: pids,
    },
    {
      key: 'activityId',
      name: '券id',
    },
    {
      key: 'relationId',
      name: '渠道id',
    },
    {
      key: 'isToday',
      name: '今天可用',
      type: 'select',
      props: {
        style: { width: '100%' },
      },
      options: [
        {
          value: '1',
          name: '是',
        },
        {
          value: '0',
          name: '否',
        },
      ],
    },
    {
      key: 'user_total_win_num_limit',
      name: '单用户领取个数',
      type: 'number',
    },
    {
      key: 'campaign_type',
      name: '是否定向',
      type: 'select',
      props: {
        style: { width: '100%' },
      },
      options: [
        {
          value: 'DX',
          name: '是',
        },
        {
          value: '',
          name: '否',
        },
      ],
    },
    {
      key: 'use_threshold',
      name: '多少起用',
      type: 'number',
      placeholder: '0元起可用',
      config: {
        rules: [
          {
            required: true,
            message: '请输入多少起用',
          },
        ],
      },
      props: {
        style: { width: '100%' },
      },
    },
  ];

  const modfiyForm = {
    per_face: 1,
    total_num: 1,
    isToday: '1',
    pid: pids.length ? pids[0].value : '',
    user_total_win_num_limit: 1,
    use_threshold: 0,
  };

  return (
    <PowerAssert code={['tlj_add']}>
      <FormCreator form={ form } configs={configs} params={modfiyForm} layout={layout} />
      <Button icon="plus" type="primary" onClick={okHandle}>
        新增
      </Button>
    </PowerAssert>
  );
};

export default Form.create()(CreateForm);
