import { query, batchRemove } from './service';

const Model = {
  namespace: 'qiniuModel',
  state: {
    bucket: 'xwdefault',
    data: {
      items: [],
      nextMarker: null,
    },
  },
  effects: {
    *fetch({ payload }, { call, put, select }) {
      const nextMark = yield select(state => state.qiniuModel.data.nextMarker);
      const bucket = yield select(state => state.qiniuModel.bucket);
      const res = yield call(query, {
        marker: nextMark,
        bucket,
      });
      const {
        code, data: { nextMarker, items },
      } = res;
      if (code === 1) {
        yield put({
          type: 'save',
          payload: {
            items,
            nextMarker,
          },
        });
      }
      yield put({
        type: 'global/handlerResponse',
        payload: {
          ...res,
          successNotice: false,
        },
      });
    },

    *del({ payload, callback }, { call, put }) {
      const { forms } = payload;
      const res = yield call(batchRemove, {
        ...forms,
      });
      yield put({
        type: 'global/handlerResponse',
        payload: {
          ...res,
        },
      });
      if (callback) callback();
    },
    *modifyBucket({ payload, callback }, { call, put }) {
      yield put({
        type: 'updateBucket',
        payload,
      });
      if (callback) callback();
    },
  },
  reducers: {
    save(state, action) {
      console.log(action.payload.nextMarker);
      const data = {
        nextMarker: action.payload.nextMarker,
        items: [...state.data.items, ... action.payload.items]
      };
      console.log(data);
      return { ...state, data };
    },
    updateBucket(state, action) {
      return { ...state, data: {
        nextMarker: null,
        items: [],
      }, bucket: action.payload};
    },
  },
};
export default Model;
