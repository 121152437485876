import React from 'react';
import { connect } from 'dva';


@connect(({ user: { currentUser } }) => ({
  userCodes: currentUser ? currentUser.role.codes : [],
}))
class PowerAssert extends React.Component {
  render () {
    const {
      userCodes,
      code = '',
      children,
    } = this.props;
    const needCodes = typeof code === 'string' ? [code] : code;
    const isValid = userCodes.some(item => needCodes.includes(item)) || code === '' || userCodes.includes('root');
    const item = isValid ? children : <></>;
    return (
      <>{item}</>
    );
  }
}


export default PowerAssert;
