import { Form, Button } from 'antd';
import React from 'react';
import FormCreator from '@/components/FormCreator';
import Clipboard from 'react-clipboard.js';

const CreateForm = props => {
  const { handleAdd, form, shortUrl, jmPwd, lxPwd } = props;

  const okHandle = () => {
    form.validateFields((err, fieldsValue) => {
      if (err) return;
      handleAdd(fieldsValue);
    });
  };

  const layout = {
    labelCol: {
      span: 5,
    },
    wrapperCol: {
      span: 15,
    },
  }

  const configs = [
    {
      key: 'title',
      name: '标题',
    },
    {
      key: 'pwd',
      name: '商品口令',
    },
    {
      key: 'isTt',
      name: '是否淘特秒杀',
      type: 'select',
      props: {
        style: { width: '100%' },
      },
      options: [
        {
          value: '0',
          name: '否',
        },
        {
          value: '2',
          name: '是',
        },
      ],
    },
    {
      key: 'pwd1',
      name: '见面福利口令',
    },
    {
      key: 'pwd2',
      name: '淘特拉新口令',
    },
  ];

  return (
    <div>
      <FormCreator form={ form } configs={configs} params={{ isTt: '0', title: '一键复娡-打开淘.宝APP', pwd1: jmPwd, pwd2: lxPwd }} layout={layout} />
      <Button icon="plus" type="primary" onClick={okHandle}>
        新增
      </Button>
      <p>{shortUrl ? `点击下方按钮复制到剪贴板:  ${shortUrl}` : ''}</p>
      <Clipboard className="ant-btn ant-btn-primary" data-clipboard-text={shortUrl}>一键复制</Clipboard>
    </div>
  );
};

export default Form.create()(CreateForm);
