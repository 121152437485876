import request from '@/utils/request';

export async function query(params) {
  return request('/async/list', {
    params,
  });
}

export async function fetchOrder(data) {
  return request('/async/taobao', {
    method: 'POST',
    data,
  });
}
