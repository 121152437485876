import {
  Card,
  Form,
  Button,
  Divider,
} from 'antd'
import React, { Component } from 'react';
import { PageHeaderWrapper } from '@ant-design/pro-layout';
import { connect } from 'dva';
import FormCreator from '@/components/FormCreator';
import './style.less';

const FormItem = Form.Item;

class CountOrderPager extends Component {

  handleSubmit = e => {
    const { dispatch, form } = this.props;
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        dispatch({
          type: 'countOrderModel/submit',
          payload: values,
        });
      } else {
        console.log(err);
      }
    });
  };

  render() {
    const { submitting, form, result, } = this.props;
    console.log(result)
    const submitFormLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 10,
          offset: 7,
        },
      },
    };
    const configs = [
      {
        key: 'orders',
        name: '订单号列表',
        type: 'textArea',
        config: {
          rules: [
            {
              required: true,
              message: '请输入订单号列表',
            },
          ],
        },
      },
      {
        key: 'date',
        name: '统计日期',
      },
      {
        key: 'key',
        name: '尾号6位',
      },
    ];
    return (
      <PageHeaderWrapper>
        <Card bordered={false}>
        <Form
          onSubmit={this.handleSubmit}
          style={{
            marginTop: 8,
          }}
        >
          <FormCreator form={ form } configs={configs}/>
            <FormItem
              {...submitFormLayout}
              style={{
                marginTop: 32,
              }}
            >
              <Button type="primary" htmlType="submit" loading={submitting}>
                提交
              </Button>
            </FormItem>
          </Form>
          <Divider />
          <p>{result ? `条数:${result}` : '0'}</p>
        </Card>
      </PageHeaderWrapper>
    );
  }
}

export default Form.create()(
  connect(({ countOrderModel: { result }, loading }) => ({
    result,
    submitting: loading.effects['countOrderModel/submit'],
  }))(CountOrderPager),
);
