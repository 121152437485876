import request from '@/utils/request';

export async function query(params) {
  return request('/api/code', {
    params,
  });
}
export async function batchRemove(params) {
  return request('/api/code/batchRemove', {
    method: 'POST',
    data: { ...params },
  });
}
export async function add(params) {
  return request('/api/code', {
    method: 'POST',
    data: { ...params },
  });
}
export async function update({ id, ...params }) {
  return request(`/api/code/${id}`, {
    method: 'PUT',
    data: { ...params },
  });
}
