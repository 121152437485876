import { routerRedux } from 'dva/router';
import { stringify } from 'querystring';
import { accountLogin, getFakeCaptcha, logout } from '@/services/login';
import { setAuthority } from '@/utils/authority';
import { getPageQuery } from '@/utils/utils';

const Model = {
  namespace: 'login',
  state: {
    status: undefined,
  },
  effects: {
    * login({ payload }, { call, put }) {
      const res = yield call(accountLogin, payload);
      const { code, data } = res;
      // 如果登录成功
      if (code === 1) {
        data.role = {
          ...data.role,
          codes: data.role.codes ? data.role.codes.split(',') : [],
        }
        yield put({
          type: 'changeLoginStatus',
          payload: {
            status: code,
            codes: data.role.codes,
          },
        });
        const urlParams = new URL(window.location.href);
        const params = getPageQuery();
        let { redirect } = params;


        if (redirect) {
          const redirectUrlParams = new URL(redirect);

          if (redirectUrlParams.origin === urlParams.origin) {
            redirect = redirect.substr(urlParams.origin.length);

            if (redirect.match(/^\/.*#/)) {
              redirect = redirect.substr(redirect.indexOf('#') + 1);
            }
          } else {
            window.location.href = '/';
            return;
          }
        }
        yield put(routerRedux.replace(redirect || '/'));
      } else {
        yield put({
          type: 'changeLoginStatus',
          payload: {
            status: code,
            role: [],
          },
        });
      }
      yield put({
        type: 'global/handlerResponse',
        payload: {
          ...res,
        },
      });
    },

    * getCaptcha({ payload }, { call }) {
      yield call(getFakeCaptcha, payload);
    },

    * logout(_, { put, call }) {
      const { redirect } = getPageQuery(); // redirect
      yield put({
        type: 'changeLoginStatus',
        payload: {
          status: 0,
          role: [],
        },
      });
      yield call(logout);
      if (window.location.pathname !== '/user/login' && !redirect) {
        yield put(
          routerRedux.replace({
            pathname: '/user/login',
            search: stringify({
              redirect: window.location.href,
            }),
          }),
        );
      }
    },
  },
  reducers: {
    changeLoginStatus(state, { payload }) {
      setAuthority(payload.codes);
      return { ...state, status: payload.status };
    },
  },
};
export default Model;
