import { Form, Input, Modal } from 'antd';
import React from 'react';
import FormCreator from '@/components/FormCreator';

const CreateForm = props => {
  const { modalVisible, form, handleAdd, handleModalVisible } = props;

  const okHandle = () => {
    form.validateFields((err, fieldsValue) => {
      if (err) return;
      form.resetFields();
      // 判断是更新还是新增
      handleAdd(fieldsValue);
    });
  };

  const layout = {
    labelCol: {
      span: 5,
    },
    wrapperCol: {
      span: 15,
    },
  }

  const configs = [
    {
      key: 'amount',
      name: '金额',
      config: {
        type: 'number',
        rules: [
          {
            required: true,
            message: '请输入金额',
            min: 0.1,
            max: 100,
          },
        ],
      },
    },
    {
      key: 'describe',
      name: '客户看到的描述',
      config: {
        rules: [
          {
            required: true,
            message: '请输入客户看到的描述',
          },
        ],
      },
    },
    {
      key: 'num',
      name: '数量',
      type: 'number',
    },
  ];

  const modfiyForm = {
    describe: '小助手的红包 请查收',
    num: 1,
  };

  return (
    <Modal
      destroyOnClose
      title="申请红包"
      visible={modalVisible}
      onOk={okHandle}
      onCancel={() => handleModalVisible()}
    >
      <FormCreator form={ form } configs={configs} params={modfiyForm} layout={layout} />
    </Modal>
  );
};

export default Form.create()(CreateForm);
