import { Form, Modal } from 'antd';
import React from 'react';
import FormCreator from '@/components/FormCreator';

const CreateForm = props => {
  const { modalVisible, form, handleAdd, handleUpdate, handleModalVisible, modfiyForm, roles = [] } = props;

  const isModify = Object.keys(modfiyForm).length > 0;

  const okHandle = () => {
    form.validateFields((err, fieldsValue) => {
      if (err) return;
      form.resetFields();
      // 判断是更新还是新增
      const fn = isModify ? handleUpdate : handleAdd;
      fn(fieldsValue);
    });
  };

  const compareToFirstPassword = (rule, value, callback) => {
    if (value && value !== form.getFieldValue('password')) {
      callback('两次密码输入不一致!');
    } else {
      callback();
    }
  };

  const layout = {
    labelCol: {
      span: 5,
    },
    wrapperCol: {
      span: 15,
    },
  }

  let configs = [
    {
      key: 'login_name',
      name: '登录名',
      config: {
        rules: [
          {
            required: true,
            min: 3,
          },
        ],
      },
    },
    {
      key: 'nick_name',
      name: '昵称',
      config: {
        rules: [
          {
            required: true,
            message: '请输入昵称',
            min: 1,
          },
        ],
      },
    },
    {
      key: 'tel',
      name: '手机号',
      config: {
        rules: [
          {
            required: true,
            message: '请输入手机号',
          },
        ],
      },
    },
    {
      key: 'role_id',
      name: '角色',
      type: 'select',
      props: {
        placeholder: '选择角色',
        optionFilterProp: 'children',
        style: { width: '100%' },
      },
      options: roles.map(role => ({
          value: role.id,
          name: role.title,
        })),
    },
  ];

  if (!isModify) {
    configs = [
      ...configs,
      {
        key: 'password',
        name: '密码',
        type: 'password',
        config: {
          rules: [
            {
              required: true,
              message: '请输入至少6个字符的密码',
              min: 6,
            },
          ],
        },
      },
      {
        key: 'repassword',
        name: '确认密码',
        type: 'password',
        config: {
          rules: [
            {
              required: true,
              min: 6,
              message: '请输入至少6个字符的密码',
            },
            {
              validator: compareToFirstPassword,
            },
          ],
        },
      },
    ]
  }

  return (
    <Modal
      destroyOnClose
      title="新建用户"
      visible={modalVisible}
      onOk={okHandle}
      onCancel={() => handleModalVisible()}
    >
      <FormCreator form={ form } configs={configs} params={modfiyForm} layout={layout} />
    </Modal>
  );
};

export default Form.create()(CreateForm);
