import {
  Card,
  Form,
  Divider,
  Button,
  Row,
  Col,
  message,
  Descriptions,
} from 'antd';
import React, { Component, Fragment } from 'react';
import { PageHeaderWrapper } from '@ant-design/pro-layout';
import { connect } from 'dva';
import copy from 'copy-to-clipboard';
import FormCreator from '@/components/FormCreator';
import styles from './style.less';

@connect(({ tljSimpleModel: { sd5, sd6, itemId, img, title, }, loading, tConfListModel: { configs }, }) => ({
  sd5,
  sd6,
  itemId,
  img,
  title,
  loading: loading.models.tljSimpleModel,
  pidEmums: configs.type4 || [],
}))
class TableList extends Component {
  state = {
    modalVisible: false,
    selectedRows: [],
    formValues: {},
    modfiyForm: {},
  };

  componentDidMount() {
    this.props.dispatch({
      type: 'tConfListModel/fetch',
      payload: {
        type: 4,
      },
    });
  }
  handleAdd = fields => {
    const { dispatch } = this.props;
    dispatch({
      type: 'tljSimpleModel/add',
      payload: fields,
      callback: () => {
      },
    });
  };

  handleCopy (text) {
    if (copy(text)) {
      message.success('复制成功！')
    } else {
      message.error('复制失败')
    }
  }

  render() {
    const {
      sd5,
      sd6,
      itemId,
      loading,
      pidEmums,
      form,
      img,
      title,
    } = this.props;
    const pids = (pidEmums || []).filter(pid => !!pid.isTlj);

    const layout = {
      labelCol: {
        span: 5,
      },
      wrapperCol: {
        span: 10,
      },
    }
  
    const configs = [
      {
        key: 'template',
        name: '文案模板',
        type: 'textArea',
      },
      {
        key: 'item_id',
        name: '商品ID',
        config: {
          rules: [
            {
              required: true,
              message: '请输入商品ID',
            },
          ],
        },
      },
      {
        key: 'pid6',
        name: '6首单pid',
        type: 'select',
        props: {
          style: { width: '100%' },
        },
        options: pids,
      },
      
      {
        key: 'per_face6',
        name: '6首单面额',
        type: 'number',
        placeholder: '1元起，可带2位小数',
        config: {
          rules: [
            {
              validator: (rule, value, callback) => {
                if(value > 10) {
                  callback('金额必须小于10元');
                } else {
                  callback();
                }
              },
            },
          ],
        },
        props: {
          style: { width: '100%' },
        },
      },
      {
        key: 'total_num6',
        name: '6首单个数',
        type: 'number',
        placeholder: '1个起',
        props: {
          style: { width: '100%' },
        },
      },
      {
        key: 'pid5',
        name: '5首单pid',
        type: 'select',
        props: {
          style: { width: '100%' },
        },
        options: pids,
      },
      {
        key: 'per_face5',
        name: '5首单面额',
        type: 'number',
        placeholder: '1元起，可带2位小数',
        config: {
          rules: [
            {
              validator: (rule, value, callback) => {
                if(value > 10) {
                  callback('金额必须小于10元');
                } else {
                  callback();
                }
              },
            },
          ],
        },
        props: {
          style: { width: '100%' },
        },
      },
      {
        key: 'total_num5',
        name: '5首单个数',
        type: 'number',
        placeholder: '1个起',
        props: {
          style: { width: '100%' },
        },
      },
      
      
    ];
  
    const modfiyForm = {
      per_face5: 5.1,
      per_face6: 4.1,
      isToday: '1',
      pid: pids.length ? pids[0].value : '',
    };

    const okHandle = () => {
      form.validateFields((err, fieldsValue) => {
        if (err) return;
        const {
          per_face5,
          per_face6,
          total_num5,
          total_num6,
          pid5,
          pid6
        } = fieldsValue;
        if (per_face5 && per_face6 && per_face5 < per_face6) {
          message.error('淘礼金金额: 6首单的不能比5首单的小');
          return;
        }
        if (total_num5 && !pid5) {
          message.error('要设置首单5，pid5不能为空');
          return;
        }
        if (total_num6 && !pid6) {
          message.error('要设置首单6，pid6不能为空');
          return;
        }
        if (!total_num5 && !total_num6) {
          message.error('5或6的淘礼金至少设置一种，个数不能都为空');
          return;
        }
        this.handleAdd(fieldsValue);
      });
    };
  

    const {  } = this.state;
    if (sd6) {
      console.log(sd6.template)
    }
    return (
      <PageHeaderWrapper>
        <Card bordered={false}>
          <Row>
            <Col span={12}>
            <FormCreator form={ form } configs={configs} params={modfiyForm} layout={layout} />
            <Button icon="plus" type="primary" onClick={okHandle} loading={loading}>
              新增
            </Button>
            <Divider />
              {
                sd5 && (<Descriptions title="5首单转化结果" column={1} bordered>
                  <Descriptions.Item label="强制链接">
                  <a href={`https://detail.tmall.com/item.htm?id=${itemId}&xxc=sdyh`} target="_blank">强制链接</a>
                  <Divider type="vertical" />
                  <Button onClick={() => this.handleCopy(`https://detail.tmall.com/item.htm?id=${itemId}&xxc=sdyh`)} icon="copy" type="primary" shape="round" size="small">复制强制链接</Button>
                </Descriptions.Item>
                <Descriptions.Item label="5首单口令">
                  {sd5.pwd}
                  <Divider type="vertical" />
                  <Button onClick={() => this.handleCopy(sd5.pwd)} icon="copy" type="primary" shape="round" size="small">复制5口令</Button>
                </Descriptions.Item>
                {!!sd5.template1 && <Descriptions.Item label="5文案1">
                <textarea className={styles.template}>
                  {sd5.template1}
                  </textarea>
                  <Divider />
                  <Button onClick={() => this.handleCopy(sd5.template1)} icon="copy" type="primary" shape="round" size="small">复制5文案1</Button>
                </Descriptions.Item>}
                {!!sd5.template2 && <Descriptions.Item label="5文案2">
                <textarea className={styles.template}>
                  {sd5.template2}
                  </textarea>
                  <Divider />
                  <Button onClick={() => this.handleCopy(sd5.template2)} icon="copy" type="primary" shape="round" size="small">复制5文案2</Button>
                </Descriptions.Item>}
              </Descriptions>)
              }
            </Col>
            <Col span={12}>
            <Card bordered={false} loading={loading}>
            <Descriptions title="基础属性" column={1} bordered>
             {title && (
                <Descriptions.Item label="标题">
                  {title}
                  <Divider type="vertical" />
                  <Button onClick={() => this.handleCopy(title)} icon="copy" type="primary" shape="round" size="small">复制标题</Button>
                </Descriptions.Item>
              )}
              
              {
                img && (
                  <Descriptions.Item label="图片">
                  <img className={styles.img} src={img} />
                </Descriptions.Item>
                )
              }
              </Descriptions>
              {
                sd6 && (<Descriptions title="6首单转化结果" column={1} bordered>
                <Descriptions.Item label="强制链接">
                  <a href={`https://detail.tmall.com/item.htm?id=${itemId}&xxc=sdyh`} target="_blank">强制链接</a>
                  <Divider type="vertical" />
                  <Button onClick={() => this.handleCopy(`https://detail.tmall.com/item.htm?id=${itemId}&xxc=sdyh`)} icon="copy" type="primary" shape="round" size="small">复制强制链接</Button>
                </Descriptions.Item>
                <Descriptions.Item label="6首单口令">
                    {sd6.pwd}
                  <Divider type="vertical" />
                  <Button onClick={() => this.handleCopy(sd6.pwd)} icon="copy" type="primary" shape="round" size="small">复制6口令</Button>
                </Descriptions.Item>
                {!!sd6.template1 && <Descriptions.Item label="6文案1">
                  <textarea className={styles.template}>
                  {sd6.template1}
                  </textarea>
                  <Divider />
                  <Button onClick={() => this.handleCopy(sd6.template1)} icon="copy" type="primary" shape="round" size="small">复制6文案1</Button>
                </Descriptions.Item>}
                {!!sd6.template2 && <Descriptions.Item label="6文案2">
                  <textarea className={styles.template}>
                  {sd6.template2}
                  </textarea>
                  <Divider />
                  <Button onClick={() => this.handleCopy(sd6.template2)} icon="copy" type="primary" shape="round" size="small">复制6文案2</Button>
                </Descriptions.Item>}
              </Descriptions>)
              }
              <Divider />
              
              </Card>
            </Col>
          </Row>
        </Card>
      </PageHeaderWrapper>
    );
  }
}

export default Form.create()(TableList);
