import { parse } from 'querystring';
import pathRegexp from 'path-to-regexp';
/* eslint no-useless-escape:0 import/prefer-default-export:0 */

const reg = /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/;
export const isUrl = path => reg.test(path);
export const isAntDesignPro = () => true;

export const isAntDesignProOrDev = () => {
  const { NODE_ENV } = process.env;

  if (NODE_ENV === 'development') {
    return true;
  }

  return isAntDesignPro();
};
export const getPageQuery = () => parse(window.location.href.split('?')[1]);
/**
 * props.route.routes
 * @param router [{}]
 * @param pathname string
 */

export const getAuthorityFromRouter = (router = [], pathname) => {
  const authority = findPathAuthority(router, pathname);
  if (authority) return authority;
  return undefined;
};

function findPathAuthority(allRouter, pathname) {
  const authority = allRouter.find(({ path, routes }) => {
    if (routes) {
      return findPathAuthority(routes, pathname);
    }
    return path && pathRegexp(path).exec(pathname);
  });
  return authority;
}

export const sleep = time => new Promise((resolve, reject) => {
    setTimeout(resolve, time);
  })


  export const wrapIosPwd  = (pwd, prefix = '1') => {
    const pwdNew = pwd.replace(/￥/, '').replace('￥', '');
    return `${prefix}-> ${pwdNew}/`;
};

export const replaceWord  = (pwd) => {
  let res = pwd;
  res = res.replace(/已拍/, '巳拍')
  .replace(/复制/, '覆制')
  .replace(/红包/, '荭苞')
  .replace(/淘宝/, '陶寶')
  .replace(/免单/, '兔単')
  .replace(/福利/, '福俐')
  .replace(/漏洞/, '陋洞')
  .replace(/反馈/, '反聩')
  .replace(/劵后/, '卷.后')
  .replace(/包邮/, '包由')
  .replace(/领取/, '翎取')
  .replace(/台湾/, '湾湾')
  .replace(/反馈/, '反聩')
  .replace(/快递/, '块递')
  .replace(/口罩/, '口羄')
  return res;
};