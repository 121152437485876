import {
  Button,
  Card,
  Form,
  message,
  Divider,
} from 'antd'
import { Link } from 'dva/router';
import React, { Component, Fragment } from 'react';
import { PageHeaderWrapper } from '@ant-design/pro-layout';
import { connect } from 'dva';
import moment from 'moment';
import CreateForm from './components/CreateForm';
import UploadForm from './components/UploadForm';
import StandardTable from '@/components/StandardTable';
import PowerAssert from '@/components/PowerAssert';
import SearchForm from '@/components/SearchForm';
import copy from 'copy-to-clipboard';



import styles from './style.less';
import BreakLine from '@/components/BreakLine';

const getStatus = ['下线', '在线'];
const platType = ['淘宝', '京东', '拼多多', '其他'];

const getValue = obj =>
  Object.keys(obj)
    .map(key => obj[key])
    .join(',');

/* eslint react/no-multi-comp:0 */
@connect(({ miandanActModel: { data }, loading }) => ({
    data,
    page: data.page,
    size: data.size,
    count: data.count,
    loading: loading.models.miandanActModel,
  }))
class TableList extends Component {
  state = {
    modalVisible: false,
    modalLjVisible: false,
    selectedRows: [],
    formValues: {},
    modfiyForm: {},
    uploadModalVisible: false,
  };

  columns = [
    {
      title: 'id',
      dataIndex: 'id',
    },
    {
      title: 'code',
      dataIndex: 'code',
    },
    {
      title: '标题',
      dataIndex: 'title',
    },
    {
      title: '用户备注',
      dataIndex: 'remark',
    },
    {
      title: '商品ID',
      dataIndex: 'item_id',
      render: (val, record) => <div>{val} <BreakLine /> {platType[record.plat_type]}</div>
    },
    {
      title: '其他配置',
      dataIndex: 'only_white',
      width: 120,
      render: (val, record) => <div>总数: {record.total} <BreakLine /> 返款: {record.price}<BreakLine />积分: {record.score}<BreakLine />开启兑换: {record.auto_exchange ? '是' : '否'}<BreakLine />vip: {val ? '是' : '否'}<BreakLine />自动爬: {record.auto_spider ? '是' : '否'}<BreakLine />单人{record.min} - {record.max}</div>
    },
    {
      title: '状态',
      dataIndex: 'status',
      render: val => <span>{getStatus[val]}</span>,
    },
    {
      title: '更新时间',
      dataIndex: 'updatedAt',
      render: val => <span>{moment(val).format('YYYY-MM-DD HH:mm:ss')}</span>,
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      render: val => <span>{moment(val).format('YYYY-MM-DD HH:mm:ss')}</span>,
    },
    {
      title: '操作',
      width: 120,
      render: (text, record) => (
          <Fragment>
            <PowerAssert code="wechatAct_update">
              <a onClick={() => this.handleUpdateModal(record)}>编辑</a>
              <BreakLine />
              {record.status ? <a onClick={() => this.handleUpdate(record, 0)}>下架</a> : <a onClick={() => this.handleUpdate(record, 1)}>上架</a>}
              <BreakLine />
              {record.auto_exchange ? <a onClick={() => this.handleUpdateExchange(record, 0)}>关闭兑换</a> : <a onClick={() => this.handleUpdateExchange(record, 1)}>开启兑换</a>}
              <BreakLine />
              <a onClick={() => this.handleUploadModal(record)}>上传订单</a>
            </PowerAssert>
            <BreakLine />
            <a onClick={() => this.handleCopy(record)}>复制链接</a>
            <BreakLine />
            <Link to={`/sxy/miandanOrder?id=${record.id}`}>查看订单</Link>
          </Fragment>
      ),
    },
  ];

  handleCopy = record => {
    const url = `https://sxy.aa7w.cn/mdact?k=${record.code}`
    if (copy(url)) {
      message.success('复制成功！')
    } else {
      message.error('复制失败!')
    }
  }
  componentDidMount() {
    const { page, size } = this.props;
    this.fetchData({ page, size });
  }

  handleStandardTableChange = (pagination, filtersArg, sorter) => {
    const { formValues } = this.state;
    const filters = Object.keys(filtersArg).reduce((obj, key) => {
      const newObj = { ...obj };
      newObj[key] = getValue(filtersArg[key]);
      return newObj;
    }, {});
    const params = {
      ...formValues,
      ...filters,
    };

    if (sorter.field) {
      params.sorter = `${sorter.field}_${sorter.order}`;
    }
    this.handleSelectRows([]);

    this.fetchData({
      page: pagination.current,
      size: pagination.pageSize,
    }, params);
  };

  handleFormReset = () => {
    const { form, size } = this.props;
    form.resetFields();
    this.handleSelectRows([]);
    this.setState({
      formValues: {},
    });
    this.fetchData({ page: 0, size });
  };

  handleSelectRows = rows => {
    this.setState({
      selectedRows: rows,
    }, () => {
    });
  };

  handleSearch = values => {
    const { size } = this.props;
    this.handleSelectRows([]);
    this.setState({
      formValues: values,
    });
    this.fetchData({ page: 0, size }, values);
  };

  handleModalVisible = flag => {
    this.setState({
      modalVisible: !!flag,
    });
  };

  closeModal = () => {
    this.setState({
      modalVisible: false,
      modalLjVisible: false,
      uploadModalVisible: false,
      modfiyForm: {},
    });
  }

  handleUpdateModal = record => {
    this.setState({
      modfiyForm: record || {},
      modalVisible: true,
    });
  };

  handleUploadModal = record => {
    this.setState({
      modfiyForm: record || {},
      uploadModalVisible: true,
    });
  };

  handleAdd = fields => {
    const { dispatch, size } = this.props;
    dispatch({
      type: 'miandanActModel/add',
      payload: {
        forms: fields,
      },
      callback: () => {
        this.fetchData({ page: 0, size });
      },
    });
    this.closeModal();
  };

  handleUpdate = (fields, flag) => {
    const params = {
      ...fields,
    };
    const { modfiyForm: { id } } = this.state;
    if (flag !== undefined) {
      params.status = flag;
    }
    const { dispatch, size } = this.props;
    dispatch({
      type: 'miandanActModel/update',
      payload: {
        id,
        ...params
      },
      callback: () => {
        this.closeModal();
        this.fetchData({ page: 0, size });
      },
    });
  }
  handleUpdateExchange = (fields, flag) => {
    const params = {
      ...fields,
    };
    if (flag !== undefined) {
      params.auto_exchange = flag;
    }
    const { dispatch, size } = this.props;
    dispatch({
      type: 'miandanActModel/update',
      payload: {
        ...params
      },
      callback: () => {
        this.closeModal();
        this.fetchData({ page: 0, size });
      },
    });
  }

  handleUpload = fields => {
    const { dispatch, size } = this.props;
    const { modfiyForm: { id } } = this.state;
    dispatch({
      type: 'miandanActModel/uploadOrder',
      payload: {
        miandan_act_id: id,
        orderNos: fields.orderNos,
        amount: fields.amount,
        remark: fields.remark,
        start: fields.start,
        score: fields.score || 0,
      },
      callback: () => {
      },
    });
    this.closeModal();
  };

  fetchData(pager = null, fieldValue = {}, saveParams) {
    const { page, size, dispatch } = this.props;
    let tempPager = {
      page,
      size,
    }
    tempPager = {
      ...tempPager,
      ...pager,
    }
    let params = {
      ...fieldValue,
      ...tempPager,
    }
    if (saveParams) {
      const { formValues } = this.state;
      params = {
        ...params,
        ...formValues,
      }
    }
    dispatch({
      type: 'miandanActModel/fetch',
      payload: params,
    });
  }

  render() {
    const {
      data,
      page,
      count,
      size,
      loading,
    } = this.props;

    const searchKeys = [{
      label: '标题',
      type: 'input',
      key: 'title',
    },
    {
      label: '商品ID',
      type: 'input',
      key: 'item_id',
    },{
      label: '是否在线',
      type: 'select',
      key: 'status',
      options: [
        {
          key: 1,
          val: '在线',
        },
        {
          key: 0,
          val: '下线',
        }
      ]
    }];

    const { selectedRows, modalVisible, modfiyForm, uploadModalVisible } = this.state;
    const parentMethods = {
      handleAdd: this.handleAdd,
      handleUpdate: this.handleUpdate,
      closeModal: this.closeModal,
    };
    const pager = {
      page,
      size,
      count,
    }
    return (
      <PageHeaderWrapper>
        <Card bordered={false}>
          <div className={styles.tableList}>
            <SearchForm  expandForm search={this.handleSearch} reset={this.handleFormReset} searchConfigs={searchKeys} />
            <div className={styles.tableListOperator}>
              <PowerAssert code={['wechatAct_add', 'wechatAct_all']}>
                <Button icon="plus" type="primary" onClick={() => this.handleModalVisible(true)}>
                  新增
                </Button>
              </PowerAssert>
            </div>
            <StandardTable
              selectedRows={selectedRows}
              loading={loading}
              data={data}
              columns={this.columns}
              onSelectRow={this.handleSelectRows}
              onChange={this.handleStandardTableChange}
              pager={pager}
            />
          </div>
        </Card>
        <CreateForm {...parentMethods} modfiyForm={modfiyForm} modalVisible={modalVisible} />
        <UploadForm modalVisible={uploadModalVisible} handleAdd={this.handleUpload} closeModal={this.closeModal} />
      </PageHeaderWrapper>
    );
  }
}

export default Form.create()(TableList);
