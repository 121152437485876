import {
  Button,
  Card,
  Form,
  message,
  Divider,
} from 'antd'
import { Link } from 'dva/router';
import React, { Component, Fragment } from 'react';
import { PageHeaderWrapper } from '@ant-design/pro-layout';
import { connect } from 'dva';
import moment from 'moment';
import CreateForm from './components/CreateForm';
import CreateLj from './components/CreateLj';
import StandardTable from '@/components/StandardTable';
import PowerAssert from '@/components/PowerAssert';
import SearchForm from '@/components/SearchForm';
import copy from 'copy-to-clipboard';


import styles from './style.less';

const getStatus = ['下线', '在线'];

const getValue = obj =>
  Object.keys(obj)
    .map(key => obj[key])
    .join(',');

/* eslint react/no-multi-comp:0 */
@connect(({ wechatActModel: { data }, loading }) => ({
    data,
    page: data.page,
    size: data.size,
    count: data.count,
    loading: loading.models.wechatActModel,
  }))
class TableList extends Component {
  state = {
    modalVisible: false,
    modalLjVisible: false,
    selectedRows: [],
    formValues: {},
    modfiyForm: {},
    exportModalVisible: false,
  };

  columns = [
    {
      title: 'id',
      dataIndex: 'id',
    },
    {
      title: 'code',
      dataIndex: 'code',
    },
    {
      title: '标题',
      dataIndex: 'title',
    },
    {
      title: '图片',
      dataIndex: 'img',
      width: 70,
      render: (val, record) => <a href={`http://detail.tmall.com/item.htm?id=${record.item_id}`} target="_blank" rel="noopener noreferrer"><img alt="商品图片" style={{ width: 60, height: 60 }} src={val} /></a>,
    },
    {
      title: '商品ID',
      dataIndex: 'item_id',
    },
    {
      title: '口令个数',
      dataIndex: 'total',
    },
    {
      title: '剩余个数',
      dataIndex: 'left',
    },
    {
      title: '状态',
      dataIndex: 'status',
      render: val => <span>{getStatus[val]}</span>,
    },
    {
      title: '更新时间',
      dataIndex: 'updatedAt',
      render: val => <span>{moment(val).format('YYYY-MM-DD HH:mm:ss')}</span>,
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      render: val => <span>{moment(val).format('YYYY-MM-DD HH:mm:ss')}</span>,
    },
    {
      title: '操作',
      render: (text, record) => (
          <Fragment>
            <PowerAssert code="wechatAct_update">
              {record.status ? <a onClick={() => this.handleUpdate(record, 0)}>下架</a> : <a onClick={() => this.handleUpdate(record, 1)}>上架</a>}
            </PowerAssert>
            <Divider />
            <a onClick={() => this.handleCopy(record)}>复制链接</a>
            <Divider />
            <Link to={`/sxy/wechatLj?id=${record.id}`}>查看口令</Link>
            <Divider />
            <a  onClick={() => this.handleAddPwd(record)}>新增口令</a>
          </Fragment>
      ),
    },
  ];

  handleCopy = record => {
    const url = `https://sxy.aa7w.cn/wechatAct?code=${record.code}`;
    if (copy(url)) {
      message.success('复制成功！')
    } else {
      message.error('复制失败!')
    }
  }

  handleAddPwd = record => {
    this.setState({
      modfiyForm: record,
      modalLjVisible: true,
    })
  }

  componentDidMount() {
    const { page, size } = this.props;
    this.fetchData({ page, size });
  }

  handleStandardTableChange = (pagination, filtersArg, sorter) => {
    const { formValues } = this.state;
    const filters = Object.keys(filtersArg).reduce((obj, key) => {
      const newObj = { ...obj };
      newObj[key] = getValue(filtersArg[key]);
      return newObj;
    }, {});
    const params = {
      ...formValues,
      ...filters,
    };

    if (sorter.field) {
      params.sorter = `${sorter.field}_${sorter.order}`;
    }
    this.handleSelectRows([]);

    this.fetchData({
      page: pagination.current,
      size: pagination.pageSize,
    }, params);
  };

  handleFormReset = () => {
    const { form, size } = this.props;
    form.resetFields();
    this.handleSelectRows([]);
    this.setState({
      formValues: {},
    });
    this.fetchData({ page: 0, size });
  };

  handleSelectRows = rows => {
    this.setState({
      selectedRows: rows,
    }, () => {
    });
  };

  handleSearch = values => {
    const { size } = this.props;
    this.handleSelectRows([]);
    this.setState({
      formValues: values,
    });
    this.fetchData({ page: 0, size }, values);
  };

  handleModalVisible = flag => {
    this.setState({
      modalVisible: !!flag,
    });
  };

  closeModal = () => {
    this.setState({
      modalVisible: false,
      modalLjVisible: false,
      modfiyForm: {},
    });
  }

  handleUpdateModal = record => {
    this.setState({
      modfiyForm: record || {},
      modalVisible: true,
    });
  };

  handleAdd = fields => {
    const { dispatch, size } = this.props;
    dispatch({
      type: 'wechatActModel/add',
      payload: {
        forms: fields,
      },
      callback: () => {
        this.fetchData({ page: 0, size });
      },
    });
    this.closeModal();
  };

  handleUpdate = (fields, flag) => {
    const params = {
      ...fields,
    };
    if (flag !== undefined) {
      params.status = flag;
    }
    const { dispatch, size } = this.props;
    dispatch({
      type: 'wechatActModel/update',
      payload: params,
      callback: () => {
        this.closeModal();
        this.fetchData({ page: 0, size });
      },
    });
  }

  handleAddLj = fields => {
    const { dispatch, size } = this.props;
    dispatch({
      type: 'wechatLjModel/add',
      payload: {
        forms: {
          wechat_act_id: this.state.modfiyForm.id,
          pwd: fields.pwd,
        }
      },
      callback: () => {
        this.closeModal();
      },
    });

  }

  fetchData(pager = null, fieldValue = {}, saveParams) {
    const { page, size, dispatch } = this.props;
    let tempPager = {
      page,
      size,
    }
    tempPager = {
      ...tempPager,
      ...pager,
    }
    let params = {
      ...fieldValue,
      ...tempPager,
    }
    if (saveParams) {
      const { formValues } = this.state;
      params = {
        ...params,
        ...formValues,
      }
    }
    dispatch({
      type: 'wechatActModel/fetch',
      payload: params,
    });
  }

  render() {
    const {
      data,
      page,
      count,
      size,
      loading,
    } = this.props;

    const searchKeys = [{
      label: '标题',
      type: 'input',
      key: 'title',
    },
    {
      label: '商品ID',
      type: 'input',
      key: 'item_id',
    }];

    const { selectedRows, modalVisible, modfiyForm, modalLjVisible } = this.state;
    const parentMethods = {
      handleAdd: this.handleAdd,
      handleUpdate: this.handleUpdate,
      closeModal: this.closeModal,
    };

    const ljMethods = {
      handleAdd: this.handleAddLj,
      closeModal: this.closeModal,
    }

    const pager = {
      page,
      size,
      count,
    }
    return (
      <PageHeaderWrapper>
        <Card bordered={false}>
          <div className={styles.tableList}>
            <SearchForm search={this.handleSearch} reset={this.handleFormReset} searchConfigs={searchKeys} />
            <div className={styles.tableListOperator}>
              <PowerAssert code={['wechatAct_add', 'wechatAct_all']}>
                <Button icon="plus" type="primary" onClick={() => this.handleModalVisible(true)}>
                  新增
                </Button>
              </PowerAssert>
            </div>
            <StandardTable
              selectedRows={selectedRows}
              loading={loading}
              data={data}
              columns={this.columns}
              onSelectRow={this.handleSelectRows}
              onChange={this.handleStandardTableChange}
              pager={pager}
            />
          </div>
        </Card>
        <CreateForm {...parentMethods} modfiyForm={modfiyForm} modalVisible={modalVisible} />
        <CreateLj {...ljMethods} modalVisible={modalLjVisible} />
      </PageHeaderWrapper>
    );
  }
}

export default Form.create()(TableList);
