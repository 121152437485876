import { add } from './service';

const Model = {
  namespace: 'blackProductModel',
  state: {
  },
  effects: {
    *add({ payload }, { call, put }) {
      const res = yield call(add, payload);
      yield put({
        type: 'global/handlerResponse',
        payload: {
          ...res,
        },
      });
    },
  },
  reducers: {
    save(state, action) {
      return { ...state, ...action.payload };
    },
  },
};
export default Model;
