const isOld = location.href.indexOf('super.ab1a.cn') > -1;
const domain = isOld ? 'api.ab1a.cn' : 'apiadmin.ab1a.cn';
module.exports = {
  adDomain: '//wechatad.xuanwonainiu.com',
  uploadUrl: '//restapi.xuanwonainiu.com/agent/image/save',
  apiBaseDev: 'http://localhost:7001',
  apiBaseProd: `//${domain}`,
  yhDomain: 'http://yh.ab1a.cn',
  siteName: 'xw专用',
  siteDesc: '.',
  statSecret: '8fhh91039hkasjoasjc00213jcksajl',
}
