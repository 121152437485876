import { Button, Card, Form, message, Divider } from 'antd';
import { Link } from 'dva/router';
import React, { Component, Fragment } from 'react';
import { PageHeaderWrapper } from '@ant-design/pro-layout';
import { connect } from 'dva';
import moment from 'moment';
import StandardTable from '@/components/StandardTable';
import PowerAssert from '@/components/PowerAssert';
import SearchForm from '@/components/SearchForm';
import copy from 'copy-to-clipboard';

import styles from './style.less';
import BreakLine from '@/components/BreakLine';

const getValid = ['待定', '有效', '无效'];

const getValue = obj =>
  Object.keys(obj)
    .map(key => obj[key])
    .join(',');

/* eslint react/no-multi-comp:0 */
@connect(({ miandanOrderModel: { data }, loading }) => ({
  data,
  page: data.page,
  size: data.size,
  count: data.count,
  loading: loading.models.miandanOrderModel,
}))
class TableList extends Component {
  state = {
    modalVisible: false,
    modalLjVisible: false,
    selectedRows: [],
    formValues: {},
    modfiyForm: {},
    uploadModalVisible: false,
  };

  columns = [
    {
      title: 'id',
      dataIndex: 'id',
    },
    {
      title: '活动id',
      dataIndex: 'miandan_act_id',
    },
    {
      title: '用户id',
      dataIndex: 'score_user_id',
    },
    {
      title: '订单编号',
      dataIndex: 'order_no',
    },
    {
      title: '返款额',
      dataIndex: 'price',
    },
    {
      title: '积分',
      dataIndex: 'score',
    },
    {
      title: '备注',
      dataIndex: 'remark',
    },
    {
      title: '有效性',
      dataIndex: 'valid',
      render: val => <div>{getValid[val]}</div>,
    },
    {
      title: '上传时间',
      dataIndex: 'upload_time',
      render: val => <span>{moment(val).format('YYYY-MM-DD HH:mm:ss')}</span>,
    },
    {
      title: '起始兑换时间',
      dataIndex: 'start',
      render: val => <span>{moment(val).format('YYYY-MM-DD HH:mm:ss')}</span>,
    },
    {
      title: '更新时间',
      dataIndex: 'updatedAt',
      render: val => <span>{moment(val).format('YYYY-MM-DD HH:mm:ss')}</span>,
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      render: val => <span>{moment(val).format('YYYY-MM-DD HH:mm:ss')}</span>,
    },
    {
      title: '操作',
      width: 120,
      render: (text, record) => <Fragment></Fragment>,
    },
  ];

  componentDidMount() {
    const {
      page,
      size,
      location: {
        query: { id },
      },
    } = this.props;
    const fieldValue = {};
    if (id) {
      fieldValue.miandan_act_id = id;
    }
    this.fetchData({ page, size }, fieldValue);
  }

  handleStandardTableChange = (pagination, filtersArg, sorter) => {
    const { formValues } = this.state;
    const filters = Object.keys(filtersArg).reduce((obj, key) => {
      const newObj = { ...obj };
      newObj[key] = getValue(filtersArg[key]);
      return newObj;
    }, {});
    const params = {
      ...formValues,
      ...filters,
    };

    if (sorter.field) {
      params.sorter = `${sorter.field}_${sorter.order}`;
    }
    this.handleSelectRows([]);

    this.fetchData(
      {
        page: pagination.current,
        size: pagination.pageSize,
      },
      params,
    );
  };

  handleFormReset = () => {
    const { form, size } = this.props;
    form.resetFields();
    this.handleSelectRows([]);
    this.setState({
      formValues: {},
    });
    this.fetchData({ page: 0, size }, { miandan_act_id: this.props.location.query.id });
  };

  handleSelectRows = rows => {
    this.setState(
      {
        selectedRows: rows,
      },
      () => {},
    );
  };

  handleSearch = values => {
    const { size } = this.props;
    this.handleSelectRows([]);
    this.setState({
      formValues: values,
    });
    this.fetchData(
      { page: 0, size },
      {
        ...values,
        miandan_act_id: values.miandan_act_id || this.props.location.query.id,
      },
    );
  };

  handleModalVisible = flag => {
    this.setState({
      modalVisible: !!flag,
    });
  };

  closeModal = () => {
    this.setState({
      modalVisible: false,
      modalLjVisible: false,
      uploadModalVisible: false,
      modfiyForm: {},
    });
  };

  fetchData(pager = null, fieldValue = {}, saveParams) {
    const { page, size, dispatch } = this.props;
    let tempPager = {
      page,
      size,
    };
    tempPager = {
      ...tempPager,
      ...pager,
    };
    let params = {
      ...fieldValue,
      ...tempPager,
    };
    if (saveParams) {
      const { formValues } = this.state;
      params = {
        ...params,
        ...formValues,
      };
    }
    dispatch({
      type: 'miandanOrderModel/fetch',
      payload: params,
    });
  }

  render() {
    const { data, page, count, size, loading } = this.props;

    const searchKeys = [
      {
        label: '是否兑换',
        type: 'select',
        key: 'isCheck',
        options: [
          {
            key: 0,
            val: '没有',
          },
          {
            key: 1,
            val: '已兑换',
          },
        ],
      },
      {
        label: '备注',
        type: 'input',
        key: 'remark',
      },
      {
        label: '用户id',
        type: 'input',
        key: 'score_user_id',
      },
      {
        label: '订单id',
        type: 'input',
        key: 'order_no',
      },
      {
        label: '是否有效',
        type: 'select',
        key: 'valid',
        options: [
          {
            key: 1,
            val: '有效',
          },
          {
            key: 0,
            val: '待定',
          },
          {
            key: 2,
            val: '无效',
          },
        ],
      },
      {
        label: '活动ID',
        type: 'input',
        key: 'miandan_act_id',
      },
    ];

    const { selectedRows, modalVisible, modfiyForm, uploadModalVisible } = this.state;
    const pager = {
      page,
      size,
      count,
    };
    return (
      <PageHeaderWrapper>
        <Card bordered={false}>
          <div className={styles.tableList}>
            <SearchForm
              expandForm
              search={this.handleSearch}
              reset={this.handleFormReset}
              searchConfigs={searchKeys}
            />
            <div className={styles.tableListOperator}></div>
            <StandardTable
              selectedRows={selectedRows}
              loading={loading}
              data={data}
              columns={this.columns}
              onSelectRow={this.handleSelectRows}
              onChange={this.handleStandardTableChange}
              pager={pager}
            />
          </div>
        </Card>
      </PageHeaderWrapper>
    );
  }
}

export default Form.create()(TableList);
