import {
  Button,
  Card,
  Form,
  Divider,
} from 'antd';
import React, { Component } from 'react';
import { PageHeaderWrapper } from '@ant-design/pro-layout';
import { connect } from 'dva';
import CreateForm from './components/CreateForm';


import styles from './style.less';

/* eslint react/no-multi-comp:0 */
@connect(({ pwdReport: { data }, tConfListModel: { configs }, loading }) => ({
  data,
  pidEmums: configs.type4 || [],
  loading: loading.models.pwdReport,
}))
class TableList extends Component {
  state = {
  };

  componentDidMount() {
    this.props.dispatch({
      type: 'tConfListModel/fetch',
      payload: {
        type: 4,
      },
    });
  }

  handleQuery = fields => {
    const { dispatch } = this.props;
    dispatch({
      type: 'pwdReport/query',
      payload: {
        forms: fields,
      },
    });
  };

  render() {
    const {
      data,
      loading,
      pidEmums,
    } = this.props;
    const parentMethods = {
      handleQuery: this.handleQuery,
    };
    return (
      <PageHeaderWrapper>
        <Card bordered={false}>
          <CreateForm {...parentMethods}  pid={pidEmums}/>
          <Divider />
          <div>
            pv: {data.pv}, uv: {data.uv}, hour_pv: {data.hour_pv}, hour_uv: {data.hour_uv}
          </div>
        </Card>
      </PageHeaderWrapper>
    );
  }
}

export default Form.create()(TableList);
