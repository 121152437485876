import {
  Card,
  Form,
  Button,
} from 'antd';
import React, { Component } from 'react';
import { PageHeaderWrapper } from '@ant-design/pro-layout';
import { connect } from 'dva';
import moment from 'moment';
import StandardTable from '@/components/StandardTable';
import SearchForm from '@/components/SearchForm';
import DataChart from './components/DataChart'
import RowPopover from '@/components/RowPopover';

import styles from './style.less';

const getValue = obj =>
  Object.keys(obj)
    .map(key => obj[key])
    .join(',');

/* eslint react/no-multi-comp:0 */
@connect(({ aliOrder: { data, isShowChart }, tConfListModel: { configs }, loading }) => ({
  data,
  page: data.page,
  size: data.size,
  count: data.count,
  loading: loading.models.aliOrder,
  isShowChart,
  alimamas: configs.type2 || [],
  bizEnums: configs.type3 || [],
}))
class TableList extends Component {
  state = {
    modalVisible: false,
    selectedRows: [],
    formValues: {},
    modfiyForm: {},
  };

  columns = [
    {
      title: '日期',
      dataIndex: 'day',
    },
    {
      title: '出单',
      dataIndex: 'total',
    },
    {
      title: 'GMV',
      dataIndex: 'pay',
      render: val => <span>{(val / 100).toFixed(2)}</span>,
    },
    {
      title: '客单',
      dataIndex: 'sum_effect_get',
      render: (val, rec) => <span>{(rec.pay / 100 / rec.total).toFixed(2)}</span>,
    },
    {
      title: '佣金率',
      dataIndex: 'sum_effect_get',
      render: (val, rec) => <span>{(rec.effect * 100 / rec.pay).toFixed(2)} %</span>,
    },
    {
      title: '佣金',
      dataIndex: 'effect',
      render: val => <span>{(val / 100).toFixed(2)}</span>,
    },
    {
      title: '到手',
      dataIndex: 'sum_effect_get',
      render: val => <span>{(val / 100).toFixed(2)}</span>,
    },
  ];

  componentDidMount() {
    const { size } = this.props;
    this.props.dispatch({
      type: 'tConfListModel/fetch',
      payload: {
        type: 3,
      },
    });
    this.props.dispatch({
      type: 'tConfListModel/fetch',
      payload: {
        type: 2,
      },
    });
    this.fetchData({ page: 0, size });
  }

  handleStandardTableChange = (pagination, filtersArg, sorter) => {
    const { formValues } = this.state;
    const filters = Object.keys(filtersArg).reduce((obj, key) => {
      const newObj = { ...obj };
      newObj[key] = getValue(filtersArg[key]);
      return newObj;
    }, {});
    const params = {
      ...formValues,
      ...filters,
    };

    if (sorter.field) {
      params.sorter = `${sorter.field}_${sorter.order}`;
    }
    this.handleSelectRows([]);

    this.fetchData({
      page: pagination.current,
      size: pagination.pageSize,
    }, params);
  };

  handleFormReset = () => {
    const { form, size } = this.props;
    form.resetFields();
    this.handleSelectRows([]);
    this.setState({
      formValues: {},
    });
    this.fetchData({ page: 0, size });
  };

  handleSelectRows = rows => {
    this.setState({
      selectedRows: rows,
    }, () => {
    });
  };

  handleSearch = values => {
    const { size } = this.props;
    // 更新state，这样子翻页什么的时候也能跟进
    this.handleSelectRows([]);
    this.setState({
      formValues: values,
    });
    this.fetchData({ page: 0, size }, values);
  };

  handleModalVisible = flag => {
    this.setState({
      modalVisible: !!flag,
    });
  };

  handleUpdateModal = record => {
    this.setState({
      modfiyForm: record || {},
      modalVisible: true,
    });
  };

  fetchData(pager = null, fieldValue = {
    start: moment().add(-20, 'd').startOf('day').format(),
    end: moment().endOf('day').format(),
  }) {
    const { page, size, dispatch } = this.props;
    let tempPager = {
      page,
      size,
    }
    tempPager = {
      ...tempPager,
      ...pager,
    }
    dispatch({
      type: 'aliOrder/fetch',
      payload: {
        groupByDay: 1,
        ...fieldValue,
        ...tempPager,
      },
    });
  }

  handleToggleChart = () => {
    this.props.dispatch({
      type: 'aliOrder/toggleChart'
    })
  }

  render() {
    const {
      data,
      page,
      count,
      size,
      loading,
      isShowChart,
      bizEnums,
      alimamas,
    } = this.props;

    const searchKeys = [{
      label: '联盟账号',
      type: 'select',
      key: 'account_no',
      options: alimamas.map(item => ({
        key: item,
        val: item,
      })),
    }, {
      label: '业务',
      type: 'select',
      key: 'biz',
      options: bizEnums,
    }, {
      label: 'pid',
      key: 'pid',
    }, {
      label: '标题',
      key: 'item_title',
    }, {
      label: '订单类型',
      key: 'order_type',
    }, {
      label: '订单时间',
      type: 'dateRanger',
      key: 'createTime',
      startKey: 'start',
      endKey: 'end',
      startOf: 'day',
      endOf: 'day',
      init: {
        initialValue: [ moment().add(-20, 'd').startOf('day'), moment().endOf('day') ],
      },
    }, {
      label: '是否预售',
      type: 'select',
      key: 'isPre',
      options: [
        {
          key: '1',
          val: '预售',
        },
        {
          key: '-1',
          val: '正常',
        },
        {
          key: '0',
          val: '全部',
        },
      ],
    }];

    const { selectedRows } = this.state;
    const pager = {
      page,
      size,
      count,
    }
    return (
      <PageHeaderWrapper>
        <Card bordered={false}>
          <div className={styles.tableList}>
            <SearchForm expandForm search={this.handleSearch} reset={this.handleFormReset} searchConfigs={searchKeys} />
            <div className={styles.tableListOperator}>
              <Button icon="line-chart" type="primary" onClick={this.handleToggleChart}>{isShowChart ? '收起图表' : '查看图表'}</Button>
            </div>
            {
              isShowChart && <DataChart/>
            }
            <StandardTable
              selectedRows={selectedRows}
              loading={loading}
              data={data}
              columns={this.columns}
              onSelectRow={this.handleSelectRows}
              onChange={this.handleStandardTableChange}
              pager={pager}
            />
          </div>
        </Card>
      </PageHeaderWrapper>
    );
  }
}

export default Form.create()(TableList);
