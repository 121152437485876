import {
  Badge,
  Button,
  Card,
  Divider,
  Dropdown,
  Form,
  Icon,
  Menu,
} from 'antd';
import React, { Component, Fragment } from 'react';
import { PageHeaderWrapper } from '@ant-design/pro-layout';
import { connect } from 'dva';
import moment from 'moment';
import CreateForm from './components/CreateForm';
import CreateAgent from './components/CreateAgent';
import StandardTable from '@/components/StandardTable';
import SearchForm from '@/components/SearchForm';
import PowerAssert from '@/components/PowerAssert';

import styles from './style.less';


const getValue = obj =>
  Object.keys(obj)
    .map(key => obj[key])
    .join(',');

const statusMap = ['default', 'processing', 'success', 'error'];
const status = ['注销', '正常'];

/* eslint react/no-multi-comp:0 */
@connect(({ adminAndList: { data }, roleAndList: { data: { list } }, loading }) => ({
  data,
  page: data.page,
  size: data.size,
  count: data.count,
  loading: loading.models.adminAndList,
  roles: list,
}))
class TableList extends Component {
  state = {
    modalVisible: false,
    modalAgentVisible: false,
    selectedRows: [],
    formValues: {},
    modfiyForm: {},
  };

  columns = [
    {
      title: '登录名',
      dataIndex: 'login_name',
    },
    {
      title: '昵称',
      dataIndex: 'nick_name',
    },
    {
      title: '角色',
      dataIndex: 'role_id',
    },
    {
      title: 'email',
      dataIndex: 'email',
    },
    {
      title: '手机号',
      dataIndex: 'tel',
    },
    {
      title: '状态',
      dataIndex: 'status',
      filters: [
        {
          text: status[0],
          value: '0',
        },
        {
          text: status[1],
          value: '1',
        },
      ],
      render(val) {
        return <Badge status={statusMap[val]} text={status[val]} />;
      },
    },
    {
      title: '上次更新时间',
      dataIndex: 'updatedAt',
      render: val => <span>{moment(val).format('YYYY-MM-DD HH:mm:ss')}</span>,
    },
    {
      title: '操作',
      render: (text, record) => (
        <Fragment>
          <PowerAssert code="user_update">
            <a onClick={() => this.handleUpdateModal(record)}>编辑</a>
          </PowerAssert>
          <Divider type="vertical" />
          <PowerAssert code="user_remove">
            <a onClick={() => this.handleRemove(record)}>停用</a>
          </PowerAssert>
          <Divider type="vertical" />
          <PowerAssert code="agentUser_add">
            <a onClick={() => this.handleCreateAgentModal(record)}>开淘礼金</a>
          </PowerAssert>
        </Fragment>
      ),
    },
  ];

  componentDidMount() {
    const { page, size } = this.props;
    this.fetchData({ page, size });
    this.fetchRoles();
  }

  handleStandardTableChange = (pagination, filtersArg, sorter) => {
    const { formValues } = this.state;
    const filters = Object.keys(filtersArg).reduce((obj, key) => {
      const newObj = { ...obj };
      newObj[key] = getValue(filtersArg[key]);
      return newObj;
    }, {});
    const params = {
      ...formValues,
      ...filters,
    };

    if (sorter.field) {
      params.sorter = `${sorter.field}_${sorter.order}`;
    }
    this.handleSelectRows([]);

    this.fetchData({
      page: pagination.current,
      size: pagination.pageSize,
    }, params);
  };

  handleFormReset = () => {
    const { form, size } = this.props;
    form.resetFields();
    this.handleSelectRows([]);
    this.setState({
      formValues: {},
    });
    this.fetchData({ page: 0, size });
  };

  handleMenuClick = e => {
    const { dispatch, size } = this.props;
    const { selectedRows } = this.state;
    if (!selectedRows) return;

    switch (e.key) {
      case 'remove':
        dispatch({
          type: 'adminAndList/remove',
          payload: {
            ids: selectedRows.map(row => row.id),
          },
          callback: () => {
            this.handleSelectRows([]);
            this.fetchData({ page: 0, size });
          },
        });
        break;

      default:
        break;
    }
  };

  handleRemove = row => {
    const { dispatch, size } = this.props;
    dispatch({
      type: 'adminAndList/remove',
      payload: {
        ids: [row.id],
      },
      callback: () => {
        this.fetchData({ page: 0, size });
      },
    });
  };

  handleSelectRows = rows => {
    this.setState({
      selectedRows: rows,
    }, () => {
    });
  };

  handleSearch = values => {
    const { size } = this.props;
    // 更新state，这样子翻页什么的时候也能跟进
    this.handleSelectRows([]);
    this.setState({
      formValues: values,
    });
    this.fetchData({ page: 0, size }, values);
  };

  handleModalVisible = flag => {
    this.setState({
      modfiyForm: {},
      modalVisible: !!flag,
    });
  };

  handleHideAllModal = () => {
    this.setState({
      modfiyForm: {},
      modalVisible: false,
      modalAgentVisible: false,
    });
  };

  handleUpdateModal = record => {
    this.setState({
      modfiyForm: record || {},
      modalVisible: true,
    });
  };

  handleAdd = fields => {
    const { dispatch, size } = this.props;
    dispatch({
      type: 'adminAndList/add',
      payload: {
        forms: fields,
      },
      callback: () => {
        this.fetchData({ page: 0, size });
      },
    });
    this.handleModalVisible();
  };

  handleUpdate = fields => {
    const { dispatch, size } = this.props;
    const { modfiyForm: { id } } = this.state;
    dispatch({
      type: 'adminAndList/update',
      payload: {
        id,
        ...fields,
      },
      callback: () => {
        this.fetchData({ page: 0, size });
      },
    });
    this.handleModalVisible();
  };

  handleCreateAgent = fields => {
    const { dispatch, size } = this.props;
    const { modfiyForm: { id } } = this.state;
    dispatch({
      type: 'agentUser/add',
      payload: {
        user_id: id,
        ...fields,
      },
      callback: () => {
        this.fetchData({ page: 0, size });
        this.setState({
          modfiyForm: {},
          modalAgentVisible: false,
        });
      },
    });
  };

  handleCreateAgentModal = record => {
    this.setState({
      modfiyForm: record || {},
      modalAgentVisible: true,
    });
  };

  fetchData(pager = null, fieldValue = {}) {
    const { page, size, dispatch } = this.props;
    let tempPager = {
      page,
      size,
    }
    tempPager = {
      ...tempPager,
      ...pager,
    }
    dispatch({
      type: 'adminAndList/fetch',
      payload: {
        ...fieldValue,
        ...tempPager,
      },
    });
  }

  fetchRoles() {
    const { dispatch } = this.props;
    const tempPager = {
      page: 0,
      size: 50,
    }
    dispatch({
      type: 'roleAndList/fetch',
      payload: tempPager,
    });
  }

  render() {
    const {
      data,
      page,
      count,
      size,
      loading,
      roles,
    } = this.props;

    const searchKeys = [{
      label: '登录名',
      type: 'input',
      key: 'login_name',
    }, {
      label: '状态',
      type: 'select',
      key: 'status',
      options: [{
        key: 0,
        val: '注销',
      }, {
        key: 1,
        val: '正常',
      }],
    }, {
      label: '昵称',
      type: 'input',
      key: 'nick_name',
    }, {
      label: '手机号',
      type: 'input',
      key: 'tel',
    }];

    const { selectedRows, modalVisible, modfiyForm, modalAgentVisible } = this.state;
    const menu = (
      <Menu onClick={this.handleMenuClick} selectedKeys={[]}>
        <PowerAssert code="user_remove">
          <Menu.Item key="remove">停用</Menu.Item>
        </PowerAssert>
      </Menu>
    );
    const parentMethods = {
      handleAdd: this.handleAdd,
      handleUpdate: this.handleUpdate,
      handleModalVisible: this.handleModalVisible,
    };
    const pager = {
      page,
      size,
      count,
    }
    return (
      <PageHeaderWrapper>
        
        <Card bordered={false}>
          <div className={styles.tableList}>
            <SearchForm search={this.handleSearch} reset={this.handleFormReset} searchConfigs={searchKeys} />
            <div className={styles.tableListOperator}>
              <PowerAssert code="user_add">
                <Button icon="plus" type="primary" onClick={() => this.handleModalVisible(true)}>
                  新建
                </Button>
              </PowerAssert>
              {selectedRows.length > 0 && (
                <span>
                  {/* <Button>批量操作</Button> */}
                  <Dropdown overlay={menu}>
                    <Button>
                      更多操作 <Icon type="down" />
                    </Button>
                  </Dropdown>
                </span>
              )}
            </div>
            <StandardTable
              selectedRows={selectedRows}
              loading={loading}
              data={data}
              columns={this.columns}
              onSelectRow={this.handleSelectRows}
              onChange={this.handleStandardTableChange}
              pager={pager}
            />
          </div>
        </Card>
        <CreateAgent modalVisible={modalAgentVisible} modfiyForm={modfiyForm} handleCreate={this.handleCreateAgent} handleHideAllModal={this.handleHideAllModal} />
        <CreateForm {...parentMethods} roles={roles} modfiyForm={modfiyForm} modalVisible={modalVisible} />
      </PageHeaderWrapper>
    );
  }
}

export default Form.create()(TableList);
