import {
  Card,
  Form,
  Divider,
} from 'antd'
import React, { Component, Fragment } from 'react';
import { PageHeaderWrapper } from '@ant-design/pro-layout';
import { connect } from 'dva';
import moment from 'moment';
import StandardTable from '@/components/StandardTable';
import SearchForm from '@/components/SearchForm';
import Export from './components/Export';


import styles from './style.less';

const getValue = obj =>
  Object.keys(obj)
    .map(key => obj[key])
    .join(',');

const validCodeMap = {
  '-1': '未知',
  2: '无效-拆单',
  3: '无效-取消',
  4: '无效-京东帮帮主订单',
  5: '无效-账号异常',
  6: '无效-赠品类目不返佣',
  7: '无效-校园订单',
  8: '无效-企业订单',
  9: '无效-团购订单',
  11: '无效-乡村推广员下单',
  13: '无效-违规订单',
  14: '无效-来源与备案网址不符',
  15: '待付款',
  16: '已付款',
  17: '已完成（购买用户确认收货）',
  19: '无效-佣金比例为0',
  20: '无效-此复购订单对应的首购订单无效',
  21: '无效-云店订单',
  22: '无效-PLUS会员佣金比例为0',
  23: '无效-支付有礼',
  24: '已付定金',
}

const statusOptions = [];
Object.keys(validCodeMap).forEach(key => {
  statusOptions.push({
    key: key,
    val: validCodeMap[key],
  })
})

/* eslint react/no-multi-comp:0 */
@connect(({ JdOrderModel: { data }, loading, tConfListModel: { configs },}) => ({
  data,
  page: data.page,
  size: data.size,
  count: data.count,
  loading: loading.models.JdOrderModel,
  accounts: configs.type15 || [],
}))
class TableList extends Component {
  state = {
    modalVisible: false,
    selectedRows: [],
    formValues: {
    },
    modfiyForm: {},
    accountOptions: this.props.accounts || [],

  };

  columns = [
    {
      title: '订单id',
      dataIndex: 'order_id',
    },
    {
      title: '商品',
      dataIndex: 'sku_name',
      render: (t, r) => <a href={`https://item.jd.com/${r.sku_id}.html`} className={styles.link} target="_blank">{t}</a>
    },
    {
      title: '订单状态',
      dataIndex: 'valid_code',
      render: val => <div>
        {validCodeMap[val]}
      </div>
    },
    {
      title: '佣金',
      dataIndex: 'estimate_fee',
    },
    {
      title: '订单时间',
      dataIndex: 'order_time',
      render: (val, rec) => <div>
        {moment(val).format('MM-DD HH:mm:ss')}
        </div>,
    },
    {
      title: '联盟',
      dataIndex: 'union_id',
      render: val => <div>
        {this.mapAccount(val)}
      </div>
    },
  ];

  componentDidMount() {
    const { page, size } = this.props;
    this.fetchData({ page, size });
    this.props.dispatch({
      type: 'tConfListModel/fetch',
      payload: {
        type: 15,
      },
      callback: () => {
        const accounts = (this.props.accounts || []);
        this.setState({
          accountOptions: accounts,
        });
      }
    });
  }
  mapAccount = (account) => {
    const { accounts } = this.props;
    const item = accounts.find(it => it.key == account);
    if (item) return item.val;
    return account;
  }

  handleStandardTableChange = (pagination, filtersArg, sorter) => {
    const { formValues } = this.state;
    const filters = Object.keys(filtersArg).reduce((obj, key) => {
      const newObj = { ...obj };
      newObj[key] = getValue(filtersArg[key]);
      return newObj;
    }, {});
    const params = {
      ...formValues,
      ...filters,
    };

    if (sorter.field) {
      params.sorter = `${sorter.field}_${sorter.order}`;
    }
    this.handleSelectRows([]);

    this.fetchData({
      page: pagination.current,
      size: pagination.pageSize,
    }, params);
  };

  handleFormReset = () => {
    const { form, size } = this.props;
    form.resetFields();
    this.handleSelectRows([]);
    this.setState({
      formValues: {},
    });
    this.fetchData({ page: 0, size });
  };

  handleSelectRows = rows => {
    this.setState({
      selectedRows: rows,
    }, () => {
    });
  };

  handleSearch = values => {
    const { size } = this.props;
    // 更新state，这样子翻页什么的时候也能跟进
    this.handleSelectRows([]);
    for (let key in values) {
      if (values[key] === '') {
        values[key] = void 0
      }
    }
    this.setState({
      formValues: values,
    });
    this.fetchData({ page: 0, size }, values);
  };

  handleExport = values => {
    const { dispatch } = this.props;
    const { formValues } = this.state;
    console.log(formValues);
    dispatch({
      type: 'JdOrderModel/exportExcel',
      payload: {
        ...formValues,
        keys: values.keys.join(','),
      },
    });
  };

  fetchData(pager = null, fieldValue = {
    start: moment().startOf('day').format(),
    end: moment().endOf('day').format(),
  }, saveParams) {
    const { page, size, dispatch } = this.props;
    let tempPager = {
      page,
      size,
    }
    tempPager = {
      ...tempPager,
      ...pager,
    }
    let params = {
      ...fieldValue,
      ...tempPager,
    }
    if (saveParams) {
      const { formValues } = this.state;
      params = {
        ...params,
        ...formValues,
      }
    }
    dispatch({
      type: 'JdOrderModel/fetch',
      payload: params,
    });
  }

  render() {
    const {
      data,
      page,
      count,
      size,
      loading,
    } = this.props;
    const { accountOptions } = this.state;

    const searchKeys = [{
      label: '订单号',
      type: 'input',
      key: 'order_id',
    }, {
      label: '只看京喜',
      type: 'select',
      key: 'onlyJx',
      options: [
        {
          key: 1,
          val: '是'
        },
        {
          key: 0,
          val: '否'
        },
      ]
    },{
      label: '标题',
      type: 'input',
      key: 'sku_name',
    }, {
      label: '商品id',
      type: 'input',
      key: 'sku_id',
    }, {
      label: '订单时间',
      type: 'dateRanger',
      key: 'createTime',
      startKey: 'start',
      endKey: 'end',
      startOf: 'day',
      endOf: 'day',
      init: {
        initialValue: [ moment().startOf('day'), moment().endOf('day') ],
      },
    },{
      label: '订单状态',
      type: 'select',
      key: 'valid_code',
      options: statusOptions,
    }, {
      label: '联盟账号',
      key: 'union_id',
      type: 'select',
        options: accountOptions,
    }];

    const { selectedRows } = this.state;
    const pager = {
      page,
      size,
      count,
    }
    return (
      <PageHeaderWrapper>
        <Card bordered={false}>
          <div className={styles.tableList}>
            <SearchForm search={this.handleSearch} reset={this.handleFormReset} searchConfigs={searchKeys}  expandForm/>
            <Divider />
            <Export handleExport = {this.handleExport} />
            <StandardTable
              selectedRows={selectedRows}
              loading={loading}
              data={data}
              columns={this.columns}
              onSelectRow={this.handleSelectRows}
              onChange={this.handleStandardTableChange}
              pager={pager}
            />
          </div>
        </Card>
      </PageHeaderWrapper>
    );
  }
}

export default Form.create()(TableList);
