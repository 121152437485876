import request from '@/utils/request';

export async function query(params) {
  return request('/api/scoreUser', {
    params,
  });
}

export async function update ({ id, ...data }) {
  return request(`/api/scoreUser/${id}`, {
    method: 'PUT',
    data,
  });
}

