import { Form, Modal } from 'antd';
import React from 'react';
import FormCreator from '@/components/FormCreator';

const CreateForm = props => {
  const { modalVisible, handleAdd, closeModal, form, handleUpdate, modfiyForm } = props;
  const isModify = Object.keys(modfiyForm).length > 0;
  const okHandle = () => {
    form.validateFields((err, fieldsValue) => {
      if (err) return;
      form.resetFields();
      // 判断是更新还是新增
      const fn = isModify ? handleUpdate : handleAdd;
      fn(fieldsValue);
    });
  };

  const layout = {
    labelCol: {
      span: 5,
    },
    wrapperCol: {
      span: 15,
    },
  }

  const configs = [
    {
      key: 'item_id',
      name: '商品id',
      config: {
        rules: [
          {
            required: true,
            message: '请输入',
          },
        ],
      },
    },
    {
      key: 'title',
      name: '标题',
    },
    {
      key: 'remark',
      name: '用户看的备注',
    },
    {
      key: 'total',
      name: '总数量',
      props: {
        style: { width: '100%' },
      },
      type: 'number',
    },
    {
      key: 'price',
      name: '返款金额',
      props: {
        style: { width: '100%' },
      },
      type: 'number',
    },
    {
      key: 'score',
      name: '积分',
      props: {
        style: { width: '100%' },
      },
      type: 'number',
    },
    {
      key: 'min',
      name: '单人最少',
      props: {
        style: { width: '100%' },
      },
      type: 'number',
    },
    {
      key: 'max',
      name: '单人最大',
      props: {
        style: { width: '100%' },
      },
      type: 'number',
    },
    {
      key: 'plat_type',
      name: '平台类型',
      type: 'select',
      props: {
        style: { width: '100%' },
      },
      options: [{
        value: 0,
        name: '淘宝'
      }, {
        value: 1,
        name: '京东'
      }, {
        value: 2,
        name: '拼多多'
      }, {
        value: 3,
        name: '其他'
      }]
    },
    {
      key: 'auto_exchange',
      name: '开启兑换',
      type: 'select',
      props: {
        style: { width: '100%' },
      },
      options: [{
        value: 0,
        name: '否'
      }, {
        value: 1,
        name: '是'
      }]
    },
    {
      key: 'only_white',
      name: '只能vip传',
      type: 'select',
      props: {
        style: { width: '100%' },
      },
      options: [{
        value: 0,
        name: '否'
      }, {
        value: 1,
        name: '是'
      }]
    },
    {
      key: 'auto_spider',
      name: '自动爬取',
      type: 'select',
      props: {
        style: { width: '100%' },
      },
      options: [{
        value: 0,
        name: '否'
      }, {
        value: 1,
        name: '是'
      }]
    },
  ];
  let params = {
    ...modfiyForm
  }
  if (!isModify) {
    params = {
      ...params,
      score: 0,
      auto_exchange: 1,
      auto_spider: 0,
      only_white: 0,
      plat_type: 0,
      min: 1,
      max: 20,
    }
  }

  return (
    <Modal
      destroyOnClose
      title="新增"
      visible={modalVisible}
      onOk={okHandle}
      onCancel={() => closeModal()}
    >
      <FormCreator form={ form } configs={configs} params={params} layout={layout} />
    </Modal>
  );
};

export default Form.create()(CreateForm);
