import './index.less';

import React from 'react';
import BraftEditor from 'braft-editor';
import { ContentUtils } from 'braft-utils';
import { Upload, Icon, message } from 'antd';
import { uploadUrl } from '../../../config/custom';


class Editor extends React.Component {
  state = {
    editorState: null,
  };

  componentDidMount () {
    const { value = '' } = this.props;
    const html = this.state.editorState ? this.state.editorState.toHTML() : '';
    if (value !== html) {
      this.setState({
        editorState: BraftEditor.createEditorState(value),
      });
    }
  }

  componentWillReceiveProps (nextProps, props) {
    const thisHTML = this.state.editorState ? this.state.editorState.toHTML() : '';
    const nextHTML = nextProps.value;
    if (thisHTML !== nextHTML) {
      this.setState({
        editorState: BraftEditor.createEditorState(nextHTML),
      });
    }
  }

  submitContent = async () => {
    const { onChange } = this.props;
    const htmlContent = this.state.editorState.toHTML();
    onChange(htmlContent);
  }

  handleChange = editorState => {
    const { onChange } = this.props;
    const nextHtml = editorState.toHTML();
    const html = this.state.editorState ? this.state.editorState.toHTML() : '';
    if (html !== nextHtml) {
      this.setState({ editorState }, () => {
        onChange(editorState.toHTML());
      })
    }
  }

  handleUploadChange = changeRes => {
    const { file } = changeRes;
    if (file) {
      if (file.status === 'done' && file.response.code === 1) {
        const state = this.state.editorState;
        this.setState({
          editorState: ContentUtils.insertMedias(state, [{
            type: 'IMAGE',
            url: file.response.data.url,
          }]),
        })
      } else {
        return false;
      }
    }
  }

  render () {
    const settings = {
      action: uploadUrl,
      multiple: false,
      showUploadList: false,
      accept: 'image/jpg,image/jpeg,image/png,image/gif',
      withCredentials: true,
      name: 'file',
      data: {
        uploadType: '文章',
        key: `image_${Date.now()}`,
      },
    };
    function beforeUpload(file) {
      let isImage = file.type.match(/^image\//i);
      if (!isImage) {
        message.error('您上传的不是图片文件!');
      } else if (!settings.accept.includes(file.type)) {
          isImage = false;
          message.error('您上传的图片文件格式不支持!');
        }
      const isLtM = file.size / 1024 / 1024 < 2;
      if (!isLtM) {
        message.error(`上传的图片必须小于2M`);
      }
      return isImage && isLtM;
    }

    const extendControls = [
      {
        key: 'antd-uploader',
        type: 'component',
        component: (
          <Upload {...settings} beforeUpload={beforeUpload}
            onChange={this.handleUploadChange}
          >
            <button type="button" className="control-item button upload-button" data-title="插入图片">
              <Icon type="picture" theme="filled" />
            </button>
          </Upload>
        ),
      },
    ]
    return (
      <BraftEditor
          value={this.state.editorState}
          onChange={this.handleChange}
          onSave={this.submitContent}
          extendControls={extendControls}
        />
    );
  }
}

export default Editor;
