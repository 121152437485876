import { query, updateMobile, addMobile, getAsset} from './service';

const Model = {
  namespace: 'mobileAndList',
  state: {
    data: {
      list: [],
      page: 0,
      size: 30,
      count: 0,
    },
    config: {
      brand: []
    }
  },
  effects: {
    *fetch({ payload, callback }, { call, put }) {
      let tempPage = payload.page;
      if (tempPage > 0) {
        tempPage -= 1;
      }

      const res = yield call(query, {
        ...payload,
        page: tempPage,
      });
      const {
        code, data: { rows, page, size, count },
      } = res;
      if (code === 1) {
        yield put({
          type: 'save',
          payload: {
            list: rows,
            page,
            size,
            count,
          },
        });
        if (callback) callback();
      }
      yield put({
        type: 'global/handlerResponse',
        payload: {
          ...res,
          successNotice: false,
        },
      });
    },
    *fetchAsset({ payload, callback }, { call, put }) {
      const res = yield call(getAsset);
      if (res.code === 1) {
        yield put({
          type: 'saveConfig',
          payload: res.data,
        });
        if (callback) callback();
      }
      yield put({
        type: 'global/handlerResponse',
        payload: {
          ...res,
          successNotice: false,
        },
      });
    },
    *add({ payload, callback }, { call, put }) {
      const { forms } = payload
      const res = yield call(addMobile, forms);
      yield put({
        type: 'global/handlerResponse',
        payload: {
          ...res,
        },
      });
      if (callback) callback();
    },
    *update({ payload, callback }, { call, put }) {
      const res = yield call(updateMobile, payload);
      yield put({
        type: 'global/handlerResponse',
        payload: {
          ...res,
        },
      });
      if (callback) callback();
    },
    *resetList({ callback}, { put }) {
      yield put({
        type: 'save',
        payload: {
          list: [],
          page: 0,
          size: 50,
          count: 0,
        },
      });
      if (callback) callback();
    }
  },
  reducers: {
    save(state, action) {
      return { ...state, data: action.payload };
    },
    saveConfig (state, action) {
      return {...state, config: action.payload}
    }
  },
};
export default Model;
