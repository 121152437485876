import request from '@/utils/request';

export async function query(params) {
  console.log('query')
  return request('/api/qiniu', {
    params,
  });
}
export async function batchRemove(params) {
  return request('/api/qiniu', {
    method: 'POST',
    data: { ...params },
  });
}
