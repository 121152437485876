import React, { Component } from 'react'
import { SketchPicker } from 'react-color'
import { Input, Icon } from 'antd'
import style from './index.less'

class ColorSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      color: props.color,
      key: props.objKey,
      displayColorPicker: 'none'
    }
  }

  componentDidMount () {
    const { value } = this.props;
    this.setState({
      color: value
    });
  }

  componentWillReceiveProps (nextProps) {
    const { color } = this.state;
    const { value } = nextProps;
    if (value && color !== value) {
      this.setState({
        color: value
      });
    }
  }

  handleClick = (e) => {
    let { displayColorPicker, key, color, } = this.state;
    displayColorPicker = displayColorPicker === 'none' ? 'block' : 'none';
    this.setState({displayColorPicker});
  }

  onChangeComplete = (value, e) => {
    const color = value.hex;
    this.setState({
      color
    });
  }

  handleSubmit = () => {
    const { displayColorPicker, color } = this.state;
    if (displayColorPicker === 'block') {
      this.setState({
        displayColorPicker: 'none'
      }, () => {
        this.props.onChange(color)
      })
    }
  }

  inputChange = (e) => {
    e.persist()
    this.setState({
      color: e.target.value
    }, () => {
      this.props.onChange(e.target.value)
    })
  }

  render() {
    let { displayColorPicker, color, } = this.state;
    return (
      <div className={style['color-select-wrap']}>
        <Input
          value={color}
          onChange={this.inputChange}
          addonBefore={(
            <div className={style['color-select-button']} onClick={ this.handleClick } style={{background: color}}>
              <Icon type="down" />
            </div>
          )}
        />
        {
          displayColorPicker === 'block' && (
            <>
              <div className={style['color-select-picker']}>
                <SketchPicker color={this.state.color} onChangeComplete={this.onChangeComplete} />
              </div>
              <div className={style.mask} onClick={this.handleSubmit}></div>
            </>
          )
        }
      </div>
    )
  }
}

export default ColorSelect
