import { Form, Modal } from 'antd';
import React from 'react';
import FormCreator from '@/components/FormCreator';

const CreateBindForm = props => {
  const { modalVisible, handleBind, handleBindModalVisible, form, modfiyForm, mobileCards = [], handleCardSearch } = props;

  let timer = null

  const okHandle = () => {
    form.validateFields((err, fieldsValue) => {
      if (err) return;
      form.resetFields();
      // 判断是更新还是新增
      handleBind('mobileCard', fieldsValue);
    });
  };

  const onSearch = (val) => {
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }

    timer = setTimeout(() => {
      handleCardSearch(val)
    }, 300)
  }

  const layout = {
    labelCol: {
      span: 5,
    },
    wrapperCol: {
      span: 15,
    },
  }

  const configs = [
    {
      key: 'id',
      name: '选择手机号码',
      type: 'select',
      config: {
        rules: [
          {
            required: true,
            message: '请选择绑定的手机号',
          },
        ],
      },
      props: {
        style: { width: '100%' },
        placeholder: '请输入手机号进行搜索，若无数据请先解绑或添加手机卡',
        showSearch: true,
        filterOption: false,
        onSearch: onSearch,
        notFoundContent: null,
        showArrow: false
      },
      options: mobileCards.filter(item => !item.mobile).map(item => ({
        value: item.id,
        name: item.tel_no,
      }))
    },
  ];

  return (
    <Modal
      destroyOnClose
      title={'绑定手机卡'}
      visible={modalVisible}
      onOk={okHandle}
      onCancel={() => handleBindModalVisible('mobileCard')}
    >
      <FormCreator form={ form } configs={configs} params={modfiyForm} layout={layout} />
    </Modal>
  );
};

export default Form.create()(CreateBindForm);
