import { Form, Modal } from 'antd';
import React from 'react';
import FormCreator from '@/components/FormCreator';
import moment from 'moment';

const CreateForm = props => {
  const { modalVisible, handleAdd, handleModalVisible, form, handleUpdate, modifyForm } = props;
  const isModify = Object.keys(modifyForm).length > 0;

  const okHandle = () => {
    form.validateFields((err, fieldsValue) => {
      if (err) return;
      form.resetFields();
      // 判断是更新还是新增
      const fn = isModify ? handleUpdate : handleAdd;
      fn(fieldsValue);
    });
  };

  const layout = {
    labelCol: {
      span: 5,
    },
    wrapperCol: {
      span: 15,
    },
  }

  const configs = [
    {
      key: 'title',
      name: '标题',
      config: {
        rules: [
          {
            required: true,
            message: '请输入标题',
          },
        ],
      },
    },
    {
      key: 'shop_name',
      name: '店铺名字',
      config: {
        rules: [
          {
            required: true,
            message: '请输入店铺名字',
          },
        ],
      },
    },
    {
      key: 'desc',
      name: '描述',
      config: {
        rules: [
          {
            required: true,
            message: '请输入店铺名字',
          },
        ],
      },
    },
    {
      key: 'total',
      name: '每人上传数量',
      type: 'number',
      config: {
        rules: [
          {
            required: true,
            message: '请输入数量',
          },
        ],
      },
    },
    {
      key: 'score',
      name: '分数',
      type: 'number',
      config: {
        rules: [
          {
            required: true,
            message: '请输入分数',
          },
        ],
      },
    },
    {
      key: 'status',
      name: '状态',
      type: 'select',
      config: {
        rules: [
          {
            required: true,
            message: '请选择状态',
          },
        ],
      },
      props: {
        style: { width: '100%' },
      },
      options: [
        {
          value: 0,
          name: '未开始',
        },
        {
          value: 1,
          name: '进行中',
        },
        {
          value: 2,
          name: '已结算',
        }
      ],
    },
    {
      key: 'out',
      name: '外部',
      type: 'select',
      config: {
        rules: [
          {
            required: true,
            message: '请选择是否外部',
          },
        ],
      },
      props: {
        style: { width: '100%' },
      },
      options: [
        {
          value: 0,
          name: '否',
        },
        {
          value: 1,
          name: '是',
        },
      ],
    },
    {
      key: 'user_id',
      name: '客户',
      type: 'select',
      config: {
        
      },
      props: {
        style: { width: '100%' },
      },
      options: [
        {
          value: 34,
          name: '得物圣斌',
        },
        {
          value: 35,
          name: '得物0915',
        },
        {
          value: 36,
          name: '雷斌',
        },
      ],
    },
    {
      key: 'end_time',
      name: '上传结束时间',
      type: 'datePicker',
      config: {
        rules: [
          {
            required: true,
            message: '请选择上传结束时间'
          }
        ]
      },
    },
    {
      key: 'preview',
      name: '缩略图',
      type: 'img',
      placeholder: '预览图',
    },
  ];

  const params = isModify ? modifyForm : {
    score: 5,
    out: 0,
    end_time: moment().endOf('day'),
  }

  return (
    <Modal
      destroyOnClose
      title={isModify ? '更新' : '新增'}
      visible={modalVisible}
      onOk={okHandle}
      onCancel={() => handleModalVisible()}
    >
      <FormCreator form={ form } configs={configs} params={params} layout={layout} />
    </Modal>
  );
};

export default Form.create()(CreateForm);
