import { message } from 'antd';
import { getParse } from './service';

const Model = {
  namespace: 'parseModel',
  state: {
    result: null,
  },
  effects: {
    *submit({ payload }, { call, put }) {
      yield put({
        type: 'save',
        payload: {
          result: null,
        },
      });
      const res = yield call(getParse, payload);
      yield put({
        type: 'global/handlerResponse',
        payload: {
          ...res,
          successNotice: false,
        },
      });
      if (res.code === 1) {
        if (res.data.sub_msg || res.data.message) {
          message.error(res.data.sub_msg || res.data.message);
        } else {
          message.success('操作成功');
          yield put({
            type: 'save',
            payload: {
              result: res.data,
            },
          });
        }
      }
    },
    *reset({ callback }, { put }) {
      yield put({
        type: 'save',
        payload: {
          result: null,
        },
      });
      if (callback) callback();
    },
  },
  reducers: {
    save(state, action) {
      return { ...state, ...action.payload };
    },
  },
};
export default Model;
