import { Form, Modal } from 'antd';
import React from 'react';
import FormCreator from '@/components/FormCreator';

const CreateForm = props => {
  const { modalVisible, handleAdd, closeModal, form, handleUpdate, modfiyForm, telType = [], inChargeType = [] } = props;
  const isModify = Object.keys(modfiyForm).length > 0;

  const okHandle = () => {
    form.validateFields((err, fieldsValue) => {
      if (err) return;
      form.resetFields();
      // 判断是更新还是新增
      const fn = isModify ? handleUpdate : handleAdd;
      fn(fieldsValue);
    });
  };

  const layout = {
    labelCol: {
      span: 5,
    },
    wrapperCol: {
      span: 15,
    },
  }

  const configs = [
    {
      key: 'tel_no',
      name: '号码',
      config: {
        rules: [
          {
            required: true,
            message: '请输入号码',
          },
        ],
      },
    },
    {
      key: 'tel_type',
      name: '电话卡类型',
      type: 'select',
      config: {
        rules: [
          {
            required: true,
            message: '请选择电话卡类型',
          },
        ],
      },
      props: {
        style: { width: '100%' },
      },
      options: telType.map(item => ({
        value: item,
        name: item,
      })),
    },
    {
      key: 'incharge_type',
      name: '充值类型',
      type: 'select',
      config: {
        rules: [
          {
            required: true,
            message: '请选择充值类型',
          },
        ],
      },
      props: {
        style: { width: '100%' },
      },
      options: inChargeType.map(item => ({
        value: item,
        name: item,
      })),
    },
    {
      key: 'remark',
      name: '备注信息',
    },
  ];

  return (
    <Modal
      destroyOnClose
      title={isModify ? '更新手机卡信息' : '新增手机卡'}
      visible={modalVisible}
      onOk={okHandle}
      onCancel={() => closeModal()}
    >
      <FormCreator form={ form } configs={configs} params={modfiyForm} layout={layout} />
    </Modal>
  );
};

export default Form.create()(CreateForm);
