import request from '@/utils/request';

export async function add(params) {
  return request('/api/role', {
    method: 'POST',
    data: params,
  });
}

export async function modify({ id, ...params }) {
  return request(`/api/role/${id}`, {
    method: 'PUT',
    data: params,
  });
}

export async function detail(id) {
  return request(`/api/role/${id}`);
}
