import {
  Button,
  Card,
  Dropdown,
  Form,
  Icon,
  Menu,
  Tag,
  Popconfirm,
  Divider,
} from 'antd';
import React, { Component, Fragment } from 'react';
import { PageHeaderWrapper } from '@ant-design/pro-layout';
import { connect } from 'dva';
import moment from 'moment';
import CreateForm from './components/CreateForm';
import StandardTable from '@/components/StandardTable';
import SearchForm from '@/components/SearchForm';
import PowerAssert from '@/components/PowerAssert';

import styles from './style.less';

const getValue = obj =>
  Object.keys(obj)
    .map(key => obj[key])
    .join(',');

const getTaskType = (type, taskTypes) => {
  const item = taskTypes.find(obj => obj.type === type);
  return item ? item.name : '';
}

/* eslint react/no-multi-comp:0 */
@connect(({ orderTaskAndList: { data }, tConfListModel: { configs }, loading }) => ({
  data,
  page: data.page,
  size: data.size,
  count: data.count,
  loading: loading.models.orderTaskAndList,
  configs,
}))
class TableList extends Component {
  state = {
    modalVisible: false,
    selectedRows: [],
    formValues: {},
    modfiyForm: {},
  };

  columns = [
    {
      title: 'id',
      dataIndex: 'id',
    },
    {
      title: '账号',
      dataIndex: 'account',
    },
    {
      title: '开始时间',
      dataIndex: 'start',
      render: val => <span>{moment(val).format('MM-DD HH:mm:ss')}</span>,
    },
    {
      title: '结束时间',
      dataIndex: 'end',
      render: val => <span>{moment(val).format('MM-DD HH:mm:ss')}</span>,
    },
    {
      title: '类型',
      dataIndex: 'type',
      render: val => <>{getTaskType(val, this.props.configs.type5 || [])}</>,
    },
    {
      title: '完成时间',
      dataIndex: 'updatedAt',
      render: val => <span>{moment(val).format('MM-DD HH:mm:ss')}</span>,
    },
  ];

  componentDidMount() {
    const { page, size } = this.props;
    this.props.dispatch({
      type: 'tConfListModel/fetch',
      payload: {
        type: 5,
      },
    });
    this.props.dispatch({
      type: 'tConfListModel/fetch',
      payload: {
        type: 2,
      },
    });
    this.fetchData({ page, size });
  }

  handleStandardTableChange = (pagination, filtersArg, sorter) => {
    const { formValues } = this.state;
    const filters = Object.keys(filtersArg).reduce((obj, key) => {
      const newObj = { ...obj };
      newObj[key] = getValue(filtersArg[key]);
      return newObj;
    }, {});
    const params = {
      ...formValues,
      ...filters,
    };

    if (sorter.field) {
      params.sorter = `${sorter.field}_${sorter.order}`;
    }
    this.handleSelectRows([]);

    this.fetchData({
      page: pagination.current,
      size: pagination.pageSize,
    }, params);
  };

  handleFormReset = () => {
    const { form, size } = this.props;
    form.resetFields();
    this.handleSelectRows([]);
    this.setState({
      formValues: {},
    });
    this.fetchData({ page: 0, size });
  };

  handleSelectRows = rows => {
    this.setState({
      selectedRows: rows,
    }, () => {
    });
  };

  handleSearch = values => {
    const { size } = this.props;
    // 更新state，这样子翻页什么的时候也能跟进
    this.handleSelectRows([]);
    this.setState({
      formValues: values,
    });
    this.fetchData({ page: 0, size }, values);
  };

  handleModalVisible = flag => {
    this.setState({
      modalVisible: !!flag,
    });
  };

  handleUpdateModal = record => {
    this.setState({
      modfiyForm: record || {},
      modalVisible: true,
    });
  };

  handleAdd = fields => {
    const { dispatch } = this.props;
    dispatch({
      type: 'orderTaskAndList/add',
      payload: {
        forms: fields,
      },
      callback: () => {
        
      },
    });
    this.handleModalVisible();
  };

  fetchData(pager = null, fieldValue = {}) {
    const { page, size, dispatch } = this.props;
    let tempPager = {
      page,
      size,
    }
    tempPager = {
      ...tempPager,
      ...pager,
    }
    dispatch({
      type: 'orderTaskAndList/fetch',
      payload: {
        ...fieldValue,
        ...tempPager,
      },
    });
  }

  render() {
    const {
      data,
      page,
      count,
      size,
      loading,
      configs,
    } = this.props;

    const taskTypes = configs.type5 || [];
    const alimamas = configs.type2 || [];

    const searchKeys = [{
      label: '账号',
      type: 'select',
      key: 'account',
      options: alimamas.map(item => ({
        key: item,
        val: item,
      })),
    }, {
      label: '类型',
      type: 'select',
      key: 'type',
      options: taskTypes.map(item => ({
        key: item.type,
        val: item.name,
      })),
    }];

    const { selectedRows, modalVisible } = this.state;
    const pager = {
      page,
      size,
      count,
    }
    const parentMethods = {
      handleAdd: this.handleAdd,
      handleModalVisible: this.handleModalVisible,
    };
    return (
      <PageHeaderWrapper>
        <Card bordered={false}>
          <div className={styles.tableList}>
            <SearchForm search={this.handleSearch} reset={this.handleFormReset} searchConfigs={searchKeys} />
            <div className={styles.tableListOperator}>
             <PowerAssert code={['async_taobao']}>
                <Button icon="plus" type="primary" onClick={() => this.handleModalVisible(true)}>
                  增加爬取
                </Button>
              </PowerAssert>
            </div>
            <StandardTable
              selectedRows={selectedRows}
              loading={loading}
              data={data}
              columns={this.columns}
              onSelectRow={this.handleSelectRows}
              onChange={this.handleStandardTableChange}
              pager={pager}
            />
          </div>
        </Card>
        <CreateForm {...parentMethods} modalVisible={modalVisible} alimamas={alimamas} />
      </PageHeaderWrapper>
    );
  }
}

export default Form.create()(TableList);
