import {
  Card,
  Form,
  Button,
} from 'antd';
import React, { Component } from 'react';
import { PageHeaderWrapper } from '@ant-design/pro-layout';
import { connect } from 'dva';
import moment from 'moment';
import FormCreator from '@/components/FormCreator';
import DataChart from './components/DataChart'

import styles from './style.less';
const FormItem = Form.Item;

const getValue = obj =>
  Object.keys(obj)
    .map(key => obj[key])
    .join(',');

/* eslint react/no-multi-comp:0 */
@connect(({ RatioModel: { data, chartOption }, loading }) => ({
  data,
  chartOption,
  showSd5: data.showSd5,
  loading: loading.models.RatioModel,
}))
class TableList extends Component {
  state = {
   
  };
  componentDidMount() {
  }

  handleSubmit = e => {
    const { dispatch, form } = this.props;
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        dispatch({
          type: 'RatioModel/submit',
          payload: values,
        });
      } else {
        console.log(err);
      }
    });
  };
  setChart5 = (isSd5) => {
    this.props.dispatch({
      type: 'RatioModel/setChart',
      payload: isSd5,
    });
  }

  render() {
    const {
      data,
      showSd5,
      loading,
      form,
    } = this.props;
    const submitFormLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 10,
          offset: 7,
        },
      },
    };
    const configs = [
      {
        name: '时间',
        type: 'dateRanger',
        key: 'searchTime',
        startKey: 'start',
        endKey: 'end',
        startOf: 'day',
        endOf: 'day',
        config: {
          rules: [
            {
              required: true,
              message: '请选择时间',
            },
          ],
        },
        init: {
          initialValue: [ moment().startOf('day'), moment().endOf('day') ],
        },
      }, 
    ];
    return (
      <PageHeaderWrapper>
        <Card bordered={false}>
        <Form
                  onSubmit={this.handleSubmit}
                  style={{
                    marginTop: 8,
                  }}
                >
              <FormCreator form={ form } configs={configs} />
                <FormItem
                  {...submitFormLayout}
                  style={{
                    marginTop: 32,
                  }}
                >
                  <Button type="primary" htmlType="submit" loading={loading}>
                    提交
                  </Button>
                </FormItem>
              </Form>
              <div>
                {showSd5 ? <Button onClick={() => {this.setChart5(0)}} >看首单6</Button> : <Button onClick={() => {this.setChart5(1)}} >看首单5</Button> }
              </div>
              <DataChart />
        </Card>
      </PageHeaderWrapper>
    );
  }
}

export default Form.create()(TableList);
