import { Form, Modal } from 'antd';
import React from 'react';
import FormCreator from '@/components/FormCreator';

const CreateForm = props => {
  const { modalVisible, handleAdd, closeModal, form } = props;

  const okHandle = () => {
    form.validateFields((err, fieldsValue) => {
      if (err) return;
      form.resetFields();
      handleAdd(fieldsValue);
    });
  };

  const layout = {
    labelCol: {
      span: 5,
    },
    wrapperCol: {
      span: 15,
    },
  }

  const configs = [
    {
      key: 'title',
      name: '标题',
    },
    {
      key: 'pwd',
      name: '商品口令',
    },
    {
      key: 'isTt',
      name: '是否淘特',
      type: 'select',
      props: {
        style: { width: '100%' },
      },
      options: [
        {
          value: '0',
          name: '否',
        },
        {
          value: '1',
          name: '是',
        },
      ],
    },
  ];

  return (
    <Modal
      destroyOnClose
      title="生成超级单页"
      visible={modalVisible}
      onOk={okHandle}
      onCancel={() => closeModal()}
    >
      <FormCreator form={ form } configs={configs} params={{ isTt: '0', title: '一键复娡-打开淘.宝APP' }} layout={layout} />
    </Modal>
  );
};

export default Form.create()(CreateForm);
