import { Form, Modal } from 'antd';
import React from 'react';
import FormCreator from '@/components/FormCreator';

const CreateForm = props => {
  const { modalVisible, handleAdd, handleModalVisible, form, handleUpdate, modfiyForm, wechatStatus = [], department = [], staff = [], } = props;
  const isModify = Object.keys(modfiyForm).length > 0;

  const okHandle = () => {
    form.validateFields((err, fieldsValue) => {
      if (err) return;
      form.resetFields();
      // 判断是更新还是新增
      const fn = isModify ? handleUpdate : handleAdd;
      fn(fieldsValue);
    });
  };

  const layout = {
    labelCol: {
      span: 5,
    },
    wrapperCol: {
      span: 15,
    },
  }

  const configs = [
    {
      key: 'user_name',
      name: '微信号',
      config: {
        rules: [
          {
            required: true,
            message: '请输入微信号',
          },
        ],
      },
    },
    {
      key: 'wx_id',
      name: 'wx_id',
    },
    {
      key: 'nick_name',
      name: '昵称',
      config: {
        rules: [
          {
            required: true,
            message: '请输入昵称',
          },
        ],
      },
    },
    {
      key: 'status',
      name: '号状态',
      type: 'select',
      config: {
        rules: [
          {
            required: true,
            message: '请选择号状态',
          },
        ],
      },
      props: {
        style: { width: '100%' },
      },
      options: wechatStatus.map(item => ({
        value: item,
        name: item,
      })),
    },
    {
      key: 'password',
      name: '登录密码',
      type: 'password',
    },
    {
      key: 'reg_tel',
      name: '注册手机号',
      config: {
        rules: [
          {
            required: true,
            message: '请输入注册手机号',
          },
        ],
      },
    },
    {
      key: 'pay_password',
      name: '支付密码',
      config: {
        rules: [
        ],
      },
    },
    {
      key: 'is_auth',
      name: '是否实名',
      type: 'select',
      config: {
        rules: [],
      },
      props: {
        style: { width: '100%' },
      },
      options: [{
        value: 0,
        name: '未实名'
      }, {
        value: 1,
        name: '已实名'
      }]
    },
    {
      key: 'count',
      name: '好友数量',
      config: {
        rules: [],
      },
    },
    {
      key: 'department',
      name: '使用业务',
      type: 'select',
      config: {
        rules: [],
      },
      props: {
        style: { width: '100%' },
      },
      options: department.map(item => ({
        value: item,
        name: item,
      })),
    },
    {
      key: 'owner',
      name: '责任人',
      type: 'select',
      config: {
        rules: [],
      },
      props: {
        style: { width: '100%' },
      },
      options: staff.map(item => ({
        value: item,
        name: item,
      })),
    },
    {
      key: 'auth_user',
      name: '实名人',
      config: {
        rules: [
        ],
      },
    },
    {
      key: 'bank',
      name: '绑定银行卡号',
      config: {
        rules: [
        ],
      },
    },
    {
      key: 'remark',
      name: '备注信息',
      config: {
        rules: [],
      },
    },
  ];

  return (
    <Modal
      destroyOnClose
      title={isModify ? '更新微信信息' : '新增微信'}
      visible={modalVisible}
      onOk={okHandle}
      onCancel={() => handleModalVisible()}
    >
      <FormCreator form={ form } configs={configs} params={modfiyForm} layout={layout} />
    </Modal>
  );
};

export default Form.create()(CreateForm);
