import { Form, Input, Modal } from 'antd';
import React from 'react';
import FormCreator from '@/components/FormCreator';

const CreateForm = props => {
  const { modalVisible, form, handleAdd, handleUpdate, handleModalVisible, modfiyForm, pids } = props;
  const isModify = Object.keys(modfiyForm).length > 0;

  const okHandle = () => {
    form.validateFields((err, fieldsValue) => {
      if (err) return;
      form.resetFields();
      // 判断是更新还是新增
      const fn = isModify ? handleUpdate : handleAdd;
      fn(fieldsValue);
    });
  };

  const layout = {
    labelCol: {
      span: 5,
    },
    wrapperCol: {
      span: 15,
    },
  }

  const configs = [
    {
      key: 'code',
      name: '代号',
      config: {
        rules: [
          {
            required: true,
            message: '请输入代号',
            min: 2,
            max: 30,
          },
        ],
      },
    },
    {
      key: 'title',
      name: '名称',
      config: {
        rules: [
          {
            required: true,
            message: '请输入title',
            min: 1,
          },
        ],
      },
    },
    {
      key: 'pid',
      name: '父权限',
      type: 'treeSelect',
      config: {
      },
      options: pids,
    },
  ];

  return (
    <Modal
      destroyOnClose
      title="新建权限值"
      visible={modalVisible}
      onOk={okHandle}
      onCancel={() => handleModalVisible()}
    >
      <FormCreator form={ form } configs={configs} params={modfiyForm} layout={layout} />
    </Modal>
  );
};

export default Form.create()(CreateForm);
