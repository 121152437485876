import { getStat } from './service';

const Model = {
  namespace: 'bizPlus',
  state: {
    data: {
      tech: 0,
      effect: {
        list: [],
        sum: 0,
      },
      balance: {
        list: [],
        sum:0,
      }
    }
  },
  effects: {
    *submit({ payload }, { call, put }) {
      yield put({
        type: 'save',
        payload: {
          tech: 0,
          effect: {
            list: [],
            sum: 0,
          },
          balance: {
            list: [],
            sum:0,
          }
        },
      });
      const params = {
        start: payload.searchTime[0].startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        end: payload.searchTime[1].endOf('day').format('YYYY-MM-DD HH:mm:ss'),
      };
      const res = yield call(getStat, params);
      if (res.code === 1) {
        yield put({
          type: 'save',
          payload: {
            data: res.data,
          },
        });
      }
      yield put({
        type: 'global/handlerResponse',
        payload: {
          ...res,
        },
      });
    },
  },
  reducers: {
    save(state, action) {
      return { ...state, ...action.payload };
    },
  },
};
export default Model;
