import {
  Card,
  Form,
  Button,
  Icon,
  Descriptions,
  message,
  Divider,
  Row,
  Col,
  Table
} from 'antd';
import React, { Component } from 'react';
import { PageHeaderWrapper } from '@ant-design/pro-layout';
import { connect } from 'dva';
import FormCreator from '@/components/FormCreator';
const moment = require('moment');

import style from './style.less';

const FormItem = Form.Item;

const columns = [
  {
    title: '账号',
    dataIndex: 'account_no',
  },
  {
    title: '收益',
    dataIndex: 'money',
  },
];


class BizPlusPager extends Component {
  handleSubmit = e => {
    const { dispatch, form } = this.props;
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        dispatch({
          type: 'bizPlus/submit',
          payload: values,
        });
      } else {
        console.log(err);
      }
    });
  };

  render() {
    const { loading, data, form } = this.props;
    console.log(data);
    const submitFormLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 10,
          offset: 7,
        },
      },
    };
    const configs = [
      {
        name: '时间',
        type: 'dateRanger',
        key: 'searchTime',
        startKey: 'start',
        endKey: 'end',
        startOf: 'day',
        endOf: 'day',
        config: {
          rules: [
            {
              required: true,
              message: '请选择时间',
            },
          ],
        },
        init: {
          initialValue: [ moment().startOf('day'), moment().endOf('day') ],
        },
      }, 
    ];

    return (
      <PageHeaderWrapper>
        <Card bordered={false}>
          <Form
                  onSubmit={this.handleSubmit}
                  style={{
                    marginTop: 8,
                  }}
                >
              <FormCreator form={ form } configs={configs} />
                <FormItem
                  {...submitFormLayout}
                  style={{
                    marginTop: 32,
                  }}
                >
                  <Button type="primary" htmlType="submit" loading={loading}>
                    提交
                  </Button>
                </FormItem>
              </Form>
              <h2>悦惠渠道技术服务费: {data.tech}, 预计提现{data.balance.sum}, 还未确认: {data.effect.sum}</h2>
              <h2>预计提现</h2>
              <Table columns={columns} dataSource={data.balance.list} />
              <h2>还未确认收货</h2>
              <Table columns={columns} dataSource={data.effect.list} />
        </Card>
      </PageHeaderWrapper>
    );
  }
}

export default Form.create()(
  connect(({ bizPlus: { data }, loading }) => ({
    data,
    loading: loading.effects['bizPlus/submit'],
  }))(BizPlusPager),
);
