import { apiBaseDev, apiBaseProd } from '../../config/custom';
import qs from 'qs';

function getServerBase () {
  let prefix = '';
  if (API_SERVER === 'DEV') {
    prefix = apiBaseDev;
  } else if (API_SERVER === 'PROD') {
    prefix = apiBaseProd;
  }
  return prefix;
}

function openLink (url, params) {
  if (!url.startsWith('http')) {
    url = `${getServerBase()}${url}`;
  }
  const str = qs.stringify(params);
  url += `?${str}`;
  window.open(url, '_blank');
}

module.exports = {
  getServerBase,
  openLink,
};
