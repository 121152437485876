import {
  Card,
  Form,
  Button,
} from 'antd';
import React, { Component } from 'react';
import { PageHeaderWrapper } from '@ant-design/pro-layout';
import { connect } from 'dva';
import FormCreator from '@/components/FormCreator';
import './style.less';

const FormItem = Form.Item;

class Manage extends Component {
  state = {
    confirmDirty: false,
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('password')) {
      callback('两次密码输入不一致!');
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      console.log(1234);
      form.validateFields(['repassword'], { force: true });
    }
    callback();
  };

  handleConfirmBlur = e => {
    const { value } = e.target;
    this.setState(prevState => ({ confirmDirty: prevState.confirmDirty || !!value }));
  };

  handleSubmit = e => {
    const { dispatch, form } = this.props;
    e.preventDefault();
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        dispatch({
          type: 'userModify/submit',
          payload: values,
        });
      }
    });
  };

  render() {
    const { submitting, form } = this.props;
    const submitFormLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 10,
          offset: 7,
        },
      },
    };
    const configs = [
      {
        key: 'oldPassword',
        name: '旧密码',
        type: 'password',
        config: {
          rules: [
            {
              required: true,
              message: '请输入旧密码',
            },
          ],
        },
      },
      {
        key: 'password',
        name: '新密码',
        placeholder: '请输入新密码',
        type: 'password',
        config: {
          rules: [
            {
              required: true,
              min: 6,
              max: 30,
              message: '请输入6-30位长度的新密码',
            },
            {
              validator: this.validateToNextPassword,
            },
          ],
        },
      },
      {
        key: 'repassword',
        name: '再次新密码',
        placeholder: '请再次输入新密码',
        type: 'password',
        props: {
          onBlur: this.handleConfirmBlur,
        },
        config: {
          rules: [
            {
              required: true,
              message: '请再次输入新密码',
            },
            {
              validator: this.compareToFirstPassword,
            },
          ],
        },
      },
    ];

    return (
      <PageHeaderWrapper>
        <Card bordered={false}>
        <Form
          onSubmit={this.handleSubmit}
          style={{
            marginTop: 8,
          }}
        >
          <FormCreator form={ form } configs={configs} />
            <FormItem
              {...submitFormLayout}
              style={{
                marginTop: 32,
              }}
            >
              <Button type="primary" htmlType="submit" loading={submitting}>
                修改密码
              </Button>
            </FormItem>
          </Form>
        </Card>
      </PageHeaderWrapper>
    );
  }
}

export default Form.create()(
  connect(({ loading }) => ({
    submitting: loading.effects['userModify/submit'],
  }))(Manage),
);
