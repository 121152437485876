import request from '@/utils/request';
import { openLink } from '@/utils/help';

export async function query(params) {
  return request('/api/scoreUserCount', {
    params,
  });
}

export async function exportExcel(params) {
  return openLink('/api/scoreUserCountExport', params);
}

export async function add(data) {
  return request('/api/scoreUserCountUpdate', {
    method: 'POST',
    data,
  });
}

export async function update ({ id, ...data }) {
  return request(`/api/scoreUserCount/${id}`, {
    method: 'PUT',
    data,
  });
}
