import { add } from './service';

const moment = require('moment');
import { wrapIosPwd, replaceWord } from '@/utils/utils';

// const add = async function () {
//   return new Promise((resolve) => {
//     setTimeout(function () {
//       resolve(
//         {
//           "data": {
//           "id": 20617,
//           "available_fee": "2.88",
//           "rights_id": "4Nku5UZO8lpfjlUkT5R67Xt2Ri5nhDxf",
//           "send_url": "https://uland.taobao.com/taolijin/edetail?vegasCode=5YZVVWYY&type=qtz&union_lens=lensId%3A0b1476df_0885_1783a4a9ec2_74cc%3Btraffic_flag%3Dlm",
//           "vegas_code": "DV7JAHMP",
//           "pwd": "￥PT1nXaT0t25￥",
//           "title": "瑜伽服夏季运动套装女初学健身房专业高端跑步套装健身运动服速干",
//           "img": "https://img.alicdn.com/bao/uploaded/i1/3885321455/O1CN01foYnGS1McQwHKSAeN_!!0-item_pic.jpg"
//           },
//           "msg": "操作成功",
//           "error": null,
//           "code": 1,
//           "status": 1
//           }
//       )
//     }, 500);
//   })
// }

// const add3 = async function () {
//   return new Promise((resolve) => {
//     setTimeout(function () {
//       resolve(
//         {
//           "data": {
//           "id": 20617,
//           "available_fee": "2.88",
//           "rights_id": "4Nku5UZO8lpfjlUkT5R67Xt2Ri5nhDxf",
//           "send_url": "https://uland.taobao.com/taolijin/edetail?vegasCode=5YZVVWYY&type=qtz&union_lens=lensId%3A0b1476df_0885_1783a4a9ec2_74cc%3Btraffic_flag%3Dlm",
//           "vegas_code": "DV7JAHMP",
//           "pwd": "￥PT1nXaT0t26￥",
//           "title": "瑜伽服夏季运动套装女初学健身房专业高端跑步套装健身运动服速干",
//           "img": "https://img.alicdn.com/bao/uploaded/i1/3885321455/O1CN01foYnGS1McQwHKSAeN_!!0-item_pic.jpg"
//           },
//           "msg": "操作成功",
//           "error": null,
//           "code": 1,
//           "status": 1
//           }
//       )
//     }, 500);
//   })
// }

const Model = {
  namespace: 'tljSimpleModel7',
  state: {
    sd7: null,
    template: '',
    itemId: '',
    img: '',
    title: '',
  },
  effects: {
    *add({ payload, callback }, { call, put }) {
      yield put({
        type: 'save',
        payload: {
          sd7: null,
          template: '',
          itemId: '',
          img: '',
          title: '',
        },
      });
      const {
        per_face7,
        total_num7,
        item_id,
        template,
        pid7,
      } = payload;
      yield put({
        type: 'save',
        payload: {
          itemId: item_id,
        },
      });
      const temp = replaceWord(template || '');
      // 处理首单7的
      if (total_num7 && per_face7) {
        const form = {
          isToday: "1",
          item_id,
          per_face: per_face7,
          pid: pid7,
          total_num: total_num7,
        }
        const res = yield call(add, form);
        const { code, data } = res;
        if (code === 1) {
          const {
            pwd,
            img,
            title,
          } = data;
          const iOSPwd = wrapIosPwd(pwd, '7');
          const target = {
            pwd: iOSPwd,
          };
          if (temp) {
            target.template1 = `7首単的覆制我
[礼物]${temp}
扣囹：${iOSPwd}
-----`
          }
          yield put({
            type: 'save',
            payload: {
              sd7: target,
              img,
              title,
            },
          });
        } else {
          yield put({
            type: 'global/handlerResponse',
            payload: {
              ...res,
            },
          });
        }
      }
    },
  },
  reducers: {
    save(state, action) {
      return { ...state, ...action.payload };
    },
  },
};
export default Model;
