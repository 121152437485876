import {
  Button,
  Card,
  Dropdown,
  Form,
  Icon,
  Menu,
  Tag,
  Popconfirm,
  Divider, Tooltip,
  message,
} from 'antd'
import { Link } from 'dva/router';
import React, { Component, Fragment } from 'react';
import { PageHeaderWrapper } from '@ant-design/pro-layout';
import { connect } from 'dva';
import moment from 'moment';
import copy from 'copy-to-clipboard';
import CreateForm from './components/CreateForm';
import StandardTable from '@/components/StandardTable';
import PowerAssert from '@/components/PowerAssert';
import SearchForm from '@/components/SearchForm';


import styles from './style.less';

const getValue = obj =>
  Object.keys(obj)
    .map(key => obj[key])
    .join(',');

const getStatus = status => {
  const maps = {
    0: '未开始',
    1: '进行中',
    2: '已结算',
  }
  return maps[status];
}

const handleCopy = id => {
  const link = `https://sxy.aa7w.cn/wechatAdUpload?id=${id}`;
  if (copy(link)) {
    message.success('复制成功！')
  } else {
    message.error('复制失败!')
  }
}

/* eslint react/no-multi-comp:0 */
@connect(({ wechatAd: { data }, loading }) => ({
  data,
  page: data.page,
  size: data.size,
  count: data.count,
  loading: loading.models.wechatAd,
}))
class TableList extends Component {
  state = {
    modalVisible: false,
    selectedRows: [],
    formValues: {},
    modifyForm: {},
  };

  columns = [
    {
      title: 'id',
      dataIndex: 'id',
    },
    {
      title: '标题',
      dataIndex: 'title',
      render: (val, rec) => <Link to={`/sxy/wechatAdDetail?id=${rec.id}`}>{val}</Link>,
    },
    {
      title: '店铺名字',
      dataIndex: 'shop_name',
      render: (val, rec) => <span onClick={() => { handleCopy(rec.id) }}>{rec.shop_name}</span>,
    },
    {
      title: '描述',
      dataIndex: 'desc',
    },
    {
      title: '单人限制数',
      dataIndex: 'total',
    },
    {
      title: '分数',
      dataIndex: 'score',
    },
    {
      title: '状态',
      dataIndex: 'status',
      render: text => getStatus(text),
    },
    {
      title: '是否外部',
      dataIndex: 'out',
      render: text => <>{text ? '是' : '否'}</>,
    },
    {
      title: '客户名字',
      dataIndex: 'user.nick_name',
    },
    {
      title: '结束时间',
      dataIndex: 'end_time',
      render: val => moment(val).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: '预览图',
      dataIndex: 'preview',
      render: text => <img src={text} width="80" height="80" />,
    },
    {
      title: '操作',
      render: (text, record) => (
        <Fragment>
            <PowerAssert code={['uconfig_query']}>
            <a onClick={() => this.handRepeat(record)}>复制</a><Divider />
            <Popconfirm
                title="确定删除"
                onConfirm={() => this.handleDel(record)}
              ><a>删除</a></Popconfirm> <Divider />
            <a onClick={() => this.handleUpdateModal(record)}>更新</a>
            <Divider />
            { record.status === 1 ?
              <Popconfirm
                title="确定结算"
                onConfirm={() => this.handleCalc(record)}
              ><a>结算</a></Popconfirm> : ''}
              <Divider />
              { record.status === 1 ?
              <Popconfirm
                title="确定通过全部"
                onConfirm={() => this.handlePass(record)}
              ><a>通过</a></Popconfirm> : ''}
              <Divider />
              <a onClick={() => this.handleExport(record)}>导出</a>
            </PowerAssert>
        </Fragment>
      ),
    },
  ];

  componentDidMount() {
    const { page, size } = this.props;
    this.fetchData({ page, size });
  }


  handleStandardTableChange = (pagination, filtersArg, sorter) => {
    const { formValues } = this.state;
    const filters = Object.keys(filtersArg).reduce((obj, key) => {
      const newObj = { ...obj };
      newObj[key] = getValue(filtersArg[key]);
      return newObj;
    }, {});
    const params = {
      ...formValues,
      ...filters,
    };

    if (sorter.field) {
      params.sorter = `${sorter.field}_${sorter.order}`;
    }
    this.handleSelectRows([]);

    this.fetchData({
      page: pagination.current,
      size: pagination.pageSize,
    }, params);
  };

  handleFormReset = () => {
    const { form, size } = this.props;
    form.resetFields();
    this.handleSelectRows([]);
    this.setState({
      formValues: {},
    });
    this.fetchData({ page: 0, size });
  };

  handleSelectRows = rows => {
    this.setState({
      selectedRows: rows,
    }, () => {
    });
  };

  handleSearch = values => {
    const { size } = this.props;
    // 更新state，这样子翻页什么的时候也能跟进
    this.handleSelectRows([]);
    this.setState({
      formValues: values,
    });
    this.fetchData({ page: 0, size }, values);
  };

  handleModalVisible = flag => {
    this.setState({
      modalVisible: !!flag,
    });
    if (!flag) {
      this.setState({
        modifyForm: {},
      });
    }
  };

  closeModal = () => {
    this.setState({
      modalVisible: false,
      modifyForm: {},
    });
  }

  handleUpdateModal = record => {
    this.setState({
      modifyForm: record || {},
      modalVisible: true,
    });
  };

  handleAdd = fields => {
    const { dispatch, size } = this.props;
    dispatch({
      type: 'wechatAd/add',
      payload: {
        forms: fields,
      },
      callback: () => {
        this.fetchData({ page: 0, size });
      },
    });
    this.handleModalVisible();
  };

  handleDel = record => {
    const { formValues } = this.state;
    const { dispatch, size } = this.props;
    dispatch({
      type: 'wechatAd/update',
      payload: {
        id: record.id,
        isdel: 1,
      },
      callback: () => {
        this.fetchData({ page: 0, size }, formValues);
      },
    });
  };

  handRepeat = record => {
    const params = JSON.parse(JSON.stringify(record));
    delete params.id;
    params.title = `${params.title}-复制`;
    delete params.createdAt;
    delete params.updatedAt;
    const { dispatch, size } = this.props;
    dispatch({
      type: 'wechatAd/add',
      payload: {
        forms: params,
      },
      callback: () => {
        this.fetchData({ page: 0, size });
      },
    });
  }

  handleUpdate = fields => {
    const { formValues } = this.state;
    const { dispatch, size } = this.props;
    const { modifyForm: { id } } = this.state;
    dispatch({
      type: 'wechatAd/update',
      payload: {
        id,
        ...fields,
      },
      callback: () => {
        this.fetchData({ page: 0, size }, formValues);
      },
    });
    this.closeModal();
  };

  handleCalc = record => {
    const { formValues } = this.state;
    const { dispatch, size } = this.props;
    dispatch({
      type: 'wechatAd/doCalc',
      payload: record.id,
      callback: () => {
        this.fetchData({ page: 0, size }, formValues);
      },
    });
  }

  handlePass = record => {
    const { dispatch, size } = this.props;
    const { formValues } = this.state;
    dispatch({
      type: 'wechatAd/doPass',
      payload: record.id,
      callback: () => {
        this.fetchData({ page: 0, size }, formValues);
      },
    });
  }

  handleExport = record => {
    this.props.dispatch({
      type: 'wechatAd/export',
      payload: record.id,
    });
  }

  exportAll = () => {
    const { formValues } = this.state;
    console.log(formValues);
    this.props.dispatch({
      type: 'wechatAd/exportAll',
      payload: formValues,
    });
  }


  fetchData(pager = null, fieldValue = {}, saveParams) {
    const { page, size, dispatch } = this.props;
    let tempPager = {
      page,
      size,
    }
    tempPager = {
      ...tempPager,
      ...pager,
    }
    let params = {
      ...fieldValue,
      ...tempPager,
    }
    if (saveParams) {
      const { formValues } = this.state;
      params = {
        ...params,
        ...formValues,
      }
    }
    dispatch({
      type: 'wechatAd/fetch',
      payload: params,
    });
  }

  render() {
    const {
      data,
      page,
      count,
      size,
      loading,
    } = this.props;

    const searchKeys = [{
      label: '标题',
      type: 'input',
      key: 'title',
    }, {
      label: '店铺名',
      type: 'input',
      key: 'shop_name',
    }, {
      label: '描述',
      type: 'input',
      key: 'desc',
    }, {
      label: '状态',
      type: 'select',
      key: 'status',
      options: [
        {
          key: 1,
          val: '进行中',
        },
        {
          key: -1,
          val: '关闭',
        },
        {
          key: 0,
          val: '未开始',
        },
      ],
    }, {
      label: '外部',
      type: 'select',
      key: 'out',
      options: [
        {
          key: 1,
          val: '是',
        },
        {
          key: 0,
          val: '否',
        },
      ],
    }, {
      label: '客户',
      type: 'select',
      key: 'user_id',
      options: [
        {
          key: 34,
          val: '得物圣斌',
        },
        {
          key: 35,
          val: '得物0915',
        },
        {
          key: 36,
          val: '雷斌',
        },
      ],
    }, {
      label: '好评时间',
      type: 'dateRanger',
      key: 'created_at',
      startKey: 'start',
      endKey: 'end',
      startOf: 'day',
      endOf: 'day',
    }];

    const { selectedRows, modalVisible, modifyForm } = this.state;
    const parentMethods = {
      handleAdd: this.handleAdd,
      handleUpdate: this.handleUpdate,
      handleModalVisible: this.handleModalVisible,
      closeModal: this.closeModal,
    };
    const pager = {
      page,
      size,
      count,
    }
    return (
      <PageHeaderWrapper>
        <Card bordered={false}>
          <div className={styles.tableList}>
             <SearchForm search={this.handleSearch} reset={this.handleFormReset} searchConfigs={searchKeys} expandForm />
            <div className={styles.tableListOperator}>
              <PowerAssert code={['uconfig_query']}>
                <Button icon="plus" type="primary" onClick={() => this.handleModalVisible(true)}>
                  新增
                </Button>
                <Divider type="vertical" />
                <Button type="primary" onClick={() => this.exportAll()}>
                  导出
                </Button>
              </PowerAssert>
            </div>
            <StandardTable
              selectedRows={selectedRows}
              loading={loading}
              data={data}
              columns={this.columns}
              onSelectRow={this.handleSelectRows}
              onChange={this.handleStandardTableChange}
              pager={pager}
            />
          </div>
        </Card>
        <CreateForm {...parentMethods} modifyForm={modifyForm} modalVisible={modalVisible} />
      </PageHeaderWrapper>
    );
  }
}

export default Form.create()(TableList);
