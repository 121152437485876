import {
  Button,
  Card,
  Dropdown,
  Form,
  Icon,
  Menu,
  Tag,
  Row,
  Col,
  Popconfirm,
  Checkbox,
  Divider,
} from 'antd';
import React, { Component, Fragment } from 'react';
import { PageHeaderWrapper } from '@ant-design/pro-layout';
import { connect } from 'dva';
import moment from 'moment';
import CreateForm from './components/CreateForm';
import StandardTable from '@/components/StandardTable';
import SearchForm from '@/components/SearchForm';
import PowerAssert from '@/components/PowerAssert';
import RowPopover from '@/components/RowPopover';
import CreateQr from './components/CreateQr';

import styles from './style.less';
import { divide } from 'lodash';


/* eslint react/no-multi-comp:0 */
@connect(({ tljModel2: { data, prodCount, money, result, coupon, title, img }, loading, tConfListModel: { configs }, }) => ({
  data,
  page: data.page,
  size: data.size,
  count: data.count,
  loading: loading.models.tljModel2,
  pidEmums: [{
    "value": "mm_120165417_259050264_71893150218",
    "name": "米仁淘礼金-ronk"
  }, {
    "value": "mm_330420185_483800460_111144500246",
    "name": "米仁淘礼金-大白白"
  }],
  prodCount,
  money,
  result,
  title,
  coupon,
  img,
}))
class TableList extends Component {
  state = {
    modalVisible: false,
    selectedRows: [],
    formValues: {},
    modfiyForm: {},
  };

  componentDidMount() {
  }

  handleFormReset = () => {
    const { form, size } = this.props;
    form.resetFields();
    this.setState({
      formValues: {},
    });
  };

  handleModalVisible = flag => {
    this.setState({
      modalVisible: !!flag,
    });
  };


  handleAdd = fields => {
    const { dispatch, size } = this.props;
    dispatch({
      type: 'tljModel2/add2',
      payload: {
        forms: fields,
      },
      callback: () => {
      },
    });
    this.handleModalVisible();
  };

  render() {
    const {
      data,
      page,
      count,
      size,
      loading,
      pidEmums,
      money,
      prodCount,
      result,
      title,
      imgCheck,
      img,
      coupon,
    } = this.props;

    const { modalVisible } = this.state;
    const parentMethods = {
      handleAdd: this.handleAdd,
      handleModalVisible: this.handleModalVisible,
    };

    return (
      <PageHeaderWrapper>
        <Card bordered={false}>
          <Row>
            <Col span={14}>
              <CreateForm loading={loading} {...parentMethods} modalVisible={modalVisible} pidEmums={pidEmums} />
              <br />
              {
                result && (
                  result.pwd
                )
              }
            </Col>
            <Col span={10}>
            {
              result && (
                <CreateQr pid={'mm_120165417_259050264_71893150218'}  result={result} />
              )
            }
              <img className={styles.resultImg} src={img ? img : ''} />
            </Col>
          </Row>
        </Card>
      </PageHeaderWrapper>
    );
  }
}

export default Form.create()(TableList);
