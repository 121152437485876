import {
  Card,
  Form,
  Divider,
  Table
} from 'antd';
import React, { Component } from 'react';
import { PageHeaderWrapper } from '@ant-design/pro-layout';
import { connect } from 'dva';
import StandardTable from '@/components/StandardTable';
import SearchForm from '@/components/SearchForm';
import RowPopover from '@/components/RowPopover';

import styles from './style.less';

const getValue = obj =>
  Object.keys(obj)
    .map(key => obj[key])
    .join(',');

/* eslint react/no-multi-comp:0 */
@connect(({ aliPunishOrder: { stat }, tConfListModel: { configs }, loading }) => ({
  stat,
  loading: loading.models.aliPunishOrder,
  alimamas: configs.type2 || [],
  bizEnums: configs.type3 || [],
}))
class TableList extends Component {
  state = {
    modalVisible: false,
    selectedRows: [],
    formValues: {},
    modfiyForm: {},
  };

  columns = [
    {
      title: '图片',
      dataIndex: 'item_img',
      render: (val, record) => <a href={`http://detail.tmall.com/item.htm?id=${record.item_id}`} target="_blank" rel="npopener noreferrer"><img alt="商品图片" style={{ width: 80, height: 80 }} src={val} /></a>,
    },
    {
      title: '商品ID',
      dataIndex: 'item_id',
      render: (val, record) => <div><a href={`http://detail.tmall.com/item.htm?id=${record.item_id}`} target="_blank" rel="npopener noreferrer">{val}</a>
      <br /><RowPopover text={record.item_title} length={30} />
      </div>,
    },
    {
      title: '出单',
      dataIndex: 'total',
    },
    {
      title: 'GMV',
      dataIndex: 'pay',
      render: val => <span>{(val / 100).toFixed(2)}</span>,
    },
    {
      title: '佣金',
      dataIndex: 'effect',
      render: val => <span>{(val / 100).toFixed(2)}</span>,
    },
    {
      title: '到手',
      dataIndex: 'sum_effect_get',
      render: val => <span>{(val / 100).toFixed(2)}</span>,
    },
  ];

  componentDidMount() {
    const { size } = this.props;
    this.props.dispatch({
      type: 'tConfListModel/fetch',
      payload: {
        type: 3,
      },
    });
    this.props.dispatch({
      type: 'tConfListModel/fetch',
      payload: {
        type: 2,
      },
    });
    this.fetchData({ page: 0, size });
  }

  handleStandardTableChange = (pagination, filtersArg, sorter) => {
    const { formValues } = this.state;
    const filters = Object.keys(filtersArg).reduce((obj, key) => {
      const newObj = { ...obj };
      newObj[key] = getValue(filtersArg[key]);
      return newObj;
    }, {});
    const params = {
      ...formValues,
      ...filters,
    };

    if (sorter.field) {
      params.sorter = `${sorter.field}_${sorter.order}`;
    }
    this.handleSelectRows([]);

    this.fetchData({
      page: pagination.current,
      size: pagination.pageSize,
    }, params);
  };

  handleFormReset = () => {
    const { form, size } = this.props;
    form.resetFields();
    this.handleSelectRows([]);
    this.setState({
      formValues: {},
    });
    this.fetchData({ page: 0, size });
  };

  handleSelectRows = rows => {
    this.setState({
      selectedRows: rows,
    }, () => {
    });
  };

  handleSearch = values => {
    const { size } = this.props;
    // 更新state，这样子翻页什么的时候也能跟进
    this.handleSelectRows([]);
    this.setState({
      formValues: values,
    });
    this.fetchData({ page: 0, size }, values);
  };

  handleModalVisible = flag => {
    this.setState({
      modalVisible: !!flag,
    });
  };

  handleUpdateModal = record => {
    this.setState({
      modfiyForm: record || {},
      modalVisible: true,
    });
  };

  fetchData(pager = null, fieldValue = {
  }) {
    const { dispatch } = this.props;
    dispatch({
      type: 'aliPunishOrder/getStat',
      payload: {
        ...fieldValue,
      },
    });
  }

  render() {
    const {
      stat,
      loading,
      alimamas,
      bizEnums,
    } = this.props;

    const searchKeys = [{
      label: '联盟账号',
      type: 'select',
      key: 'account_no',
      options: alimamas.map(item => ({
        key: item,
        val: item,
      })),
    }, {
      label: '业务',
      type: 'select',
      key: 'biz',
      options: bizEnums,
    }, {
      label: '订单号',
      key: 'order_no',
    }, {
      label: 'pid',
      key: 'pid',
    }, {
      label: '标题',
      key: 'item_title',
    }, {
      label: '商品ID',
      key: 'item_id',
    }, {
      label: '站点名',
      key: 'site_name',
    }, {
      label: '推广位名',
      key: 'adzone_name',
    }, {
      label: '卖家名称',
      key: 'seller_nick',
    }, {
      label: '店铺名',
      key: 'seller_shop_title',
    }, {
      label: '订单类型',
      key: 'order_type',
    }, {
      label: '订单状态',
      type: 'select',
      key: 'order_status',
      options: [
        {
          key: 'PAID',
          val: '付款',
        },
        {
          key: 'FINISH',
          val: '结算',
        },
        {
          key: 'CANCEL',
          val: '取消',
        },
        {
          key: 'SUCCESS',
          val: '商家欠款',
        }, {
          key: 'UNKNOWN',
          val: '未知',
        },
      ],
    }, {
      label: '订单时间',
      type: 'dateRanger',
      key: 'createTime',
      startKey: 'start',
      endKey: 'end',
      startOf: 'day',
      endOf: 'day',
    }, {
      label: '结算时间',
      type: 'dateRanger',
      key: 'settleTime',
      startKey: 'settleStart',
      endKey: 'settleEnd',
      startOf: 'day',
      endOf: 'day',
    }, {
      label: '原因',
      key: 'violation_type',
    }];
    return (
      <PageHeaderWrapper>
        <Card bordered={false}>
          <div className={styles.tableList}>
            <SearchForm expandForm search={this.handleSearch} reset={this.handleFormReset} searchConfigs={searchKeys} />
            <Divider />
            <Table
              rowKey="id"
              columns={this.columns}
              dataSource={stat}
              pagination={false}
            />
          </div>
        </Card>
      </PageHeaderWrapper>
    );
  }
}

export default Form.create()(TableList);
