import {
  Form,
  Input,
  Select,
  Switch,
  Checkbox,
  DatePicker,
  InputNumber,
} from 'antd';
import React from 'react';
import moment from 'moment';
import Editor from '@/components/Editor';
import FormUpload from '@/components/FormUploader';
import TreeSelector from '@/components/TreeSelector';
import FormTree from '@/components/FormTree';
import ColorSelect from '@/components/ColorSelect';

const FormItem = Form.Item;
const { Option } = Select;

class FormCreator extends React.PureComponent {
  render () {
    const { form, configs, params, layout } = this.props;
    const formItemLayout = {
      labelCol: {
        xs: {
          span: 24,
        },
        sm: {
          span: 7,
        },
      },
      wrapperCol: {
        xs: {
          span: 24,
        },
        sm: {
          span: 12,
        },
        md: {
          span: 10,
        },
      },
    };
    const res = getFormList(configs, form, layout || formItemLayout, params);
    return res;
  }
}

export default FormCreator;


function getFormList (configs, form, formLayout, modifyForm) {
  const items = [];
  configs.forEach(conf => {
    items.push(getFormItem(conf, form, formLayout, modifyForm));
  });
  return items;
}

function getFormItem (conf = {}, form, formLayout, modifyForm = {}) {
  let tempConf = {
    ...conf,
  }
  let defaultValue = null;
  if (conf.key in modifyForm && modifyForm[conf.key] !== undefined) {
    defaultValue = modifyForm[conf.key];
  } else if (tempConf.setDefaultValue !== undefined) {
    defaultValue = tempConf.setDefaultValue;
  }

  if (defaultValue !== null) {
    if (conf.type === 'select' && conf.props && conf.props.mode === 'tags') {
      defaultValue = defaultValue.split(',');
    } else if (conf.type === 'datePicker' && defaultValue) {
      defaultValue = moment(defaultValue)
    }
    tempConf = {
      ...tempConf,
      config: {
        ...conf.config,
        initialValue: defaultValue,
      },
    }
  }

  let item;

  const {
    key,
    name,
    placeholder,
    type,
    config,
    uploadType,
    options = [],
    props,
  } = tempConf;

  const optionItems = options.map(op => <Option value={op.value}>{op.name}</Option>);
  switch (type) {
    case 'img':
      item = <FormUpload uploadType={uploadType} { ...props }/>;
      break;
    case 'number':
      item = <InputNumber allowClear placeholder={placeholder || '请输入'} { ...props }/>;
      break;
    case 'password':
      item = <Input.Password placeholder={placeholder || '请输入'} { ...props }/>
      break;
    case 'switch':
      item = <Switch checked={!!config.initialValue} />;
      break;
    case 'datePicker':
      item = <DatePicker allowClear showTime  { ...props }/>;
      break;
    case 'dateRanger':
      item = <DatePicker.RangePicker
                style={{
                  width: '100%',
                }}
                {...props}
              />;
      break;
    case 'select':
      item = <Select allowClear placeholder={placeholder || '请选择'} { ...props }>
          {optionItems}
        </Select>
      break;
    case 'checkbox':
      item = <Checkbox.Group options={options} placeholder={placeholder || '请选择'} { ...props } />
      break;
    case 'editor':
      item = <Editor placeholder={placeholder || '请选择'} { ...props }/>
      break;
    case 'treeSelect':
      item = <TreeSelector allowClear placeholder={placeholder || '请选择'} options={options} { ...props } />
      break;
    case 'tree':
      item = <FormTree allowClear options={options} { ...props } />
      break;
    case 'colorSelect':
      item = <ColorSelect allowClear {...props}/>
      break;
    case 'textArea':
      item = <Input.TextArea allowClear {...props} />
      break;
    default:
      item = <Input allowClear placeholder={placeholder || '请输入'} { ...props }/>;
  }
  return <FormItem key={key} {...formLayout} label={name}>
    {form.getFieldDecorator(key, config)(item)}</FormItem>;
}
