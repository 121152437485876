import { add } from './service';

const moment = require('moment');
import { wrapIosPwd, replaceWord } from '@/utils/utils';

// const add = async function () {
//   return new Promise((resolve) => {
//     setTimeout(function () {
//       resolve(
//         {
//           "data": {
//           "id": 20617,
//           "available_fee": "2.88",
//           "rights_id": "4Nku5UZO8lpfjlUkT5R67Xt2Ri5nhDxf",
//           "send_url": "https://uland.taobao.com/taolijin/edetail?vegasCode=5YZVVWYY&type=qtz&union_lens=lensId%3A0b1476df_0885_1783a4a9ec2_74cc%3Btraffic_flag%3Dlm",
//           "vegas_code": "DV7JAHMP",
//           "pwd": "￥PT1nXaT0t25￥",
//           "title": "瑜伽服夏季运动套装女初学健身房专业高端跑步套装健身运动服速干",
//           "img": "https://img.alicdn.com/bao/uploaded/i1/3885321455/O1CN01foYnGS1McQwHKSAeN_!!0-item_pic.jpg"
//           },
//           "msg": "操作成功",
//           "error": null,
//           "code": 1,
//           "status": 1
//           }
//       )
//     }, 500);
//   })
// }

// const add3 = async function () {
//   return new Promise((resolve) => {
//     setTimeout(function () {
//       resolve(
//         {
//           "data": {
//           "id": 20617,
//           "available_fee": "2.88",
//           "rights_id": "4Nku5UZO8lpfjlUkT5R67Xt2Ri5nhDxf",
//           "send_url": "https://uland.taobao.com/taolijin/edetail?vegasCode=5YZVVWYY&type=qtz&union_lens=lensId%3A0b1476df_0885_1783a4a9ec2_74cc%3Btraffic_flag%3Dlm",
//           "vegas_code": "DV7JAHMP",
//           "pwd": "￥PT1nXaT0t26￥",
//           "title": "瑜伽服夏季运动套装女初学健身房专业高端跑步套装健身运动服速干",
//           "img": "https://img.alicdn.com/bao/uploaded/i1/3885321455/O1CN01foYnGS1McQwHKSAeN_!!0-item_pic.jpg"
//           },
//           "msg": "操作成功",
//           "error": null,
//           "code": 1,
//           "status": 1
//           }
//       )
//     }, 500);
//   })
// }

const Model = {
  namespace: 'tljSimpleModel',
  state: {
    sd5: null,
    sd6: null,
    template: '',
    itemId: '',
    img: '',
    title: '',
  },
  effects: {
    *add({ payload, callback }, { call, put }) {
      console.log(payload);
      yield put({
        type: 'save',
        payload: {
          sd5: null,
          sd6: null,
          template: '',
          itemId: '',
          img: '',
          title: '',
        },
      });
      const {
        per_face5,
        per_face6,
        total_num5,
        total_num6,
        item_id,
        template,
        pid5,
        pid6
      } = payload;
      yield put({
        type: 'save',
        payload: {
          itemId: item_id,
        },
      });
      const temp = replaceWord(template || '');
      // 处理首单5的
      if (total_num5 && per_face5) {
        const form = {
          isToday: "1",
          item_id,
          per_face: per_face5,
          pid: pid5,
          total_num: total_num5,
        }
        const res = yield call(add, form);
        const { code, data } = res;
        if (code === 1) {
          const {
            pwd,
            img,
            title,
          } = data;
          const iOSPwd = wrapIosPwd(pwd, '5');
          const target = {
            pwd: iOSPwd,
          };
          if (temp) {
            target.template1 = `0】${temp}
---
翎取扣领 : ${iOSPwd}
若正常为0直接拍即可
---
首単5后拍为0元。`
            target.template2 = `5首単的覆制我
[礼物]${temp}
扣囹： ${iOSPwd}
-----`
          }
          yield put({
            type: 'save',
            payload: {
              sd5: target,
              img,
              title,
            },
          });
        } else {
          yield put({
            type: 'global/handlerResponse',
            payload: {
              ...res,
            },
          });
        }
      }
      if (total_num6 && per_face6) {
        const form = {
          isToday: "1",
          item_id,
          per_face: per_face6,
          pid: pid6,
          total_num: total_num6,
        }
        const res = yield call(add, form);
        const { code, data } = res;
        if (code === 1) {
          const {
            pwd,
            img,
            title,
          } = data;
          const iOSPwd = wrapIosPwd(pwd, '6');
          const target = {
            pwd: iOSPwd,
          };
          if (temp) {
            target.template1 = `6=0  5=1
⚠不显示首単的，覆制下面链接到TB搜索栏
粘贴后看首単 再去拍
[礼物]${temp}
扣囹：${iOSPwd}
-----`
            target.template2 = `6首単的覆制我
[礼物]${temp}
扣囹：${iOSPwd}
-----`
          }
          yield put({
            type: 'save',
            payload: {
              sd6: target,
              img,
              title,
            },
          });
        } else {
          yield put({
            type: 'global/handlerResponse',
            payload: {
              ...res,
            },
          });
        }
      }
    },
  },
  reducers: {
    save(state, action) {
      return { ...state, ...action.payload };
    },
  },
};
export default Model;
